import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    TableContainer
} from '@chakra-ui/react'
import PicturesPanelList from './PicturesPanelTable';
import ValidationPictureModal from './PicturesModalValidation';

function PicturesPanelFunction() {
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [total, setTotal] = useState('');
    const [profilePictures, setProfilePictures] = useState([]);
    const [modalValidateOpen, setModalValidateOpen] = useState(false);
    const [selectUserValidation, setSelectUserValidation] = useState(null);

    const fetchData = async () => {
        try {
            const response = await axios.get(
                `/panelProfilePicture?page=${currentPage}`
            );
            const responseData = response.data;
            setProfilePictures(responseData.data);
            setTotal(responseData.total)
            setTotalPages(responseData.last_page);
        } catch (error) {
            console.error('Error fetching Vacations:', error);
        }
    };

    const handleValidation = async (user) => {

        setSelectUserValidation(user);
        setModalValidateOpen(true);

    };

    const closeModalValidation = () => {
        setModalValidateOpen(false);
    }


    useEffect(() => {
        fetchData();
    }, [currentPage]);


    return (
        <TableContainer mt={"2%"}  >
            <PicturesPanelList
                total={total}
                currentPage={currentPage}
                profilePictures={profilePictures}
                setCurrentPage={setCurrentPage}
                totalPages={totalPages}
                handleValidation={handleValidation}
            />
            {modalValidateOpen && (
                <ValidationPictureModal
                    isOpen={modalValidateOpen}
                    user={selectUserValidation}
                    onClose={closeModalValidation}
                    fetchData={fetchData}
                />
            )}
        </TableContainer >

    );
}
export default PicturesPanelFunction; 