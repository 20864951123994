// Chakra imports
import {
  Modal, ModalOverlay, ModalContent, ModalBody, ModalHeader, ModalCloseButton,
  useToast, Text, Button, Flex, Input, useColorModeValue,
  Avatar, Box, Alert, AlertIcon,
} from '@chakra-ui/react';
// React imports
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import logo from "assets/img/dashboards/Logo Dark Blue-01-01.jpg";
import { IoMdPhotos, IoMdCheckmarkCircle } from 'react-icons/io';
import { FaCamera } from "react-icons/fa";


export default function UploadImage(props) {

  const { isOpen, onClose, fetchProfilePicture, imageUrl, user } = props;
  const borderColor = useColorModeValue(
    'white !important',
    '#111C44 !important',
  );
  const toast = useToast();


  const [imageFile, setImageFile] = useState(null);

  const removeImage = () => {
    setImageFile(null)
  };

  const handleSubmit = async () => {


    const formDataSend = new FormData();
    // Agrega el archivo de documento si existe
    if (imageFile) {
      formDataSend.append('image', imageFile?.file || '');
    }

    try {

      const response = await axios.post('/users/updatePicture', formDataSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      });
      toast({
        title: 'Profile picture updated successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      onClose();
      fetchProfilePicture();
    } catch (error) {
      toast({
        title: "Error updating the profile picture",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      console.error('Error creating schedule:', error);
    }
  };

  const handleImageChange = (e, imageFile = null) => {
    const file = e?.target?.files?.[0] || imageFile?.file;

    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setImageFile({
        file,
        previewUrl: imageUrl,
      });
    }
  };


  useEffect(() => {
    fetchProfilePicture();
  }, []);


  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'2xl'} closeOnOverlayClick={false} closeOnEsc={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} color={'#1c2147'} mt={'5%'} fontWeight={'bold'} fontSize={'40px'}>
          <span style={{ marginRight: '8px' }}>Profile Picture</span>
        </ModalHeader>
        <ModalCloseButton size={'20px'} mr={'3%'} mt={'2%'} />
        <ModalBody>
          <Flex direction={'column'} justifyContent={'center'} alignItems={'center'} mb={'10%'} mt={'5%'}>
            <Box
              position="relative"
              mx="auto"
              mt="-43px"
              cursor="pointer"
              _hover={{ ".hoverText": { display: "block" } }}
              as="label"
              htmlFor="document"
            >
              {imageUrl ? (
                <Avatar
                  src={imageUrl}
                  h="440px"
                  w="440px"
                  border="4px solid"
                  borderColor={borderColor}
                />
              ) : (
                <Avatar
                  src={logo}
                  h="440px"
                  w="440px"
                  border="4px solid"
                  borderColor={borderColor}
                />
              )}
              <Text
                className="hoverText"
                display="none"
                position="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                bg="rgba(0, 0, 0, 0.7)"
                color="white"
                p="2px 8px"
                borderRadius="4px"
              >
                <FaCamera size={'50px'} />
              </Text>
            </Box>
            <Input
              type="file"
              id="document"
              style={{ display: 'none' }}
              accept="image/*"
              onChange={(e) => handleImageChange(e, imageFile)}
            />
            {[1].includes(user.path_confirm) ? (
              <Alert status='warning' width={'50%'} mb={'5%'} fontWeight={'bold'} color={'#1c2147'}>
                <AlertIcon />
                You have a pending validation.
              </Alert>
            ) : null}
            {!imageFile ? (
              <Button
                as="label"
                htmlFor="document"
                cursor="pointer"
                bg={'linear-gradient(180deg, rgba(66, 78, 168, 1.0) 50%, rgba(101, 112, 199, 1.0) 50%)'}
                color={"white"}
                _hover={{ background: "#808080", color: 'white' }}
                width={'60%'}
              >
                <IoMdPhotos ml={2} size={'35px'} /> Add profile picture
              </Button>
            ) : (
              <Button
                onClick={handleSubmit}
                cursor="pointer"
                bg={'linear-gradient(180deg, rgba(0, 128, 0, 1.0) 50%, rgba(34, 139, 34, 1.0) 50%)'}
                color={"white"}
                _hover={{ background: "#808080", color: 'white' }}
                width={'60%'}
              >
                <IoMdCheckmarkCircle ml={2} size={'35px'} /> File Selected - Update profile picture
              </Button>
            )}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
