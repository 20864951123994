import React, { useState } from 'react';
import axios from 'axios';
import Select2 from 'react-select'
import {
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody,
    Button, Input, Box, Flex, Heading, SimpleGrid, FormControl, FormErrorMessage, Image, useToast, Select,Text
} from '@chakra-ui/react';
import avatar4 from 'assets/img/dashboards/grey_world.png';
import { CloseIcon, AddIcon } from '@chakra-ui/icons'

const MedicalNotesModal = ({ isOpen, onClose, customStyles, optionsUsers, filterOptions, fetchData }) => {
    const toast = useToast();
    const [formErrors, setFormErrors] = useState({});
    const [selectedOperator, setSelectedOperator] = useState([]);
    const [resetKey, setResetKey] = useState(0);
    const [formData, setFormData] = useState({
        duration: '',
        date_start: '',
        date_end: '',
        status: '',
        date_return: '',
    });

    const clearForm = () => {
        setFormData({
            duration: '',
            date_start: '',
            date_end: '',
            status: '',
            date_return: '',
        });
        setFormErrors({});
        setResetKey(resetKey + 1);
        setSelectedOperator([]);
    };

    const handleSubmit = async () => {
        // Obtener la fecha actual y formatearla como YYYY-MM-DD
        const getDate = () => {
            const today = new Date();
            const day = String(today.getDate()).padStart(2, '0');
            const month = String(today.getMonth() + 1).padStart(2, '0'); // Los meses comienzan en 0
            const year = today.getFullYear();

            return `${year}-${month}-${day}`;
        };

        const selectAgent = selectedOperator.value;

        const formSubmitData = {
            duration: formData.duration,
            date_start: formData.date_start,
            date_end: formData.date_end,
            status: formData.status,
            date_submitted: getDate(), // Asignar la fecha actual formateada
            id_user: selectAgent,
            date_return: formData.date_return
        };

        try {
            const response = await axios.post('/medicalNotes/create', formSubmitData);

            toast({
                title: 'Medical Note Created',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            fetchData();
            clearForm();
            onClose();
            setFormErrors({});
        } catch (error) {
            toast({
                title: "Error Creating a Medical Note",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            console.error('Error creating medical note:', error);
            setFormErrors(error.response.data.errors);
        }
    };
    const handleChange = e => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };
    return (
        <Modal size="lg" isOpen={isOpen} onClose={() => {
            onClose();
            clearForm();
        }}
            closeOnOverlayClick={false}
            closeOnEsc={false}
        >
            <ModalOverlay />
            <ModalContent mt={"5%"} background={"#f2f2f2"} borderRadius={"30px"}>
            <ModalHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} color={'#1c2147'} mt={'3%'} mb={'5%'} fontWeight={'bold'} fontSize={'35px'}>
                    <Flex direction={'column'}>
                        <Text textAlign={'center'}>
                        Add Medical Note
                        </Text>
                        
                    </Flex>
                </ModalHeader>
                <ModalBody>
                    <Box>
                        
                        <SimpleGrid
                            columns='1'
                            gap='40px'
                            maxW='100%'
                            w={{ base: "100%", md: "unset" }}
                        >
                            <FormControl> Select Agent
                                <Select2
                                    options={optionsUsers}
                                    styles={customStyles}
                                    value={selectedOperator}
                                    onChange={(selectedOption) => setSelectedOperator(selectedOption)}
                                    key={`userSelect-${resetKey}`}
                                    isSearchable
                                    filterOption={filterOptions}
                                />

                            </FormControl>
                            <FormControl isInvalid={!!formErrors.duration}> Duration
                                <Input

                                    type="number"
                                    placeholder="Enter Duration"
                                    name='duration'
                                    className='custom-placeholderInputs'
                                    background="white"
                                    defaultValue={formData.duration}
                                    onChange={handleChange}
                                />
                                {formErrors.duration && (
                                    <FormErrorMessage>This field is required.</FormErrorMessage>
                                )}
                            </FormControl>
                            <FormControl isInvalid={!!formErrors.date_start}>Start Date
                                <Input
                                    type="date"
                                    name='date_start'
                                    className='custom-placeholderInputs'
                                    background="white"
                                    defaultValue={formData.date_start}
                                    onChange={handleChange}
                                />
                                {formErrors.date_start && (
                                    <FormErrorMessage>This field is required.</FormErrorMessage>
                                )}
                            </FormControl>
                            <FormControl isInvalid={!!formErrors.date_end}>End Date
                                <Input
                                    type="date"
                                    name='date_end'
                                    className='custom-placeholderInputs'
                                    background="white"
                                    defaultValue={formData.date_end}
                                    onChange={handleChange}
                                />
                                {formErrors.date_end && (
                                    <FormErrorMessage>This field is required.</FormErrorMessage>
                                )}
                            </FormControl>
                            <FormControl isInvalid={!!formErrors.date_return}> Return Date
                                <Input
                                    type="date"
                                    name='date_return'
                                    className='custom-placeholderInputs'
                                    background="white"
                                    defaultValue={formData.date_return}
                                    onChange={handleChange}
                                />
                                {formErrors.date_return && (
                                    <FormErrorMessage>This field is required.</FormErrorMessage>
                                )}
                            </FormControl>
                            <FormControl isInvalid={!!formErrors.status}>
                                <Select
                                    w='460px'
                                    fontSize={'md'}
                                    borderRadius={'10px'}
                                    name="status"
                                    textAlign={"left"}
                                    background="white"
                                    value={formData.status}
                                    onChange={handleChange}
                                    h='44px'
                                    maxh='44px'
                                    errorBorderColor='#fc7466'
                                >
                                    <option value=''>Select status</option>
                                    <option value='1'>In review</option>
                                    <option value='2'>Proof of Call out</option>
                                    <option value='3'>Approved</option>
                                    <option value='4'>Nonvalid</option>
                                </Select>
                                {formErrors.status && (
                                    <FormErrorMessage>This field is required.</FormErrorMessage>
                                )}
                            </FormControl>
                        </SimpleGrid>
                    </Box>
                </ModalBody>
                <ModalFooter justifyContent="center" pt={"10%"} pb={"10%"}>
                    <Button class="buttonCreate" onClick={handleSubmit}>
                        Add Note <AddIcon ml={2} />
                    </Button>
                    <Button class="buttonCancel" onClick={() => {
                        onClose();
                        clearForm();
                    }}>
                        Cancel <CloseIcon ml={2} />
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
export default MedicalNotesModal;
