import { Flex } from "@chakra-ui/react";
import Card from "components/card/Card";
import SupervisorFunctions from "./items/function";
export default function SupervisorsOverview() { 
  return (
    <Flex direction='column' pt={{ sm: "125px", lg: "75px" }}>
      <Card px='100px'>
        <SupervisorFunctions/>
      </Card>
    </Flex>
  );
} 