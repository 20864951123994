import React from 'react';
import {
  Table, Thead, Tbody, Tr, Th, Td,
  Button, ButtonGroup, Text,
  Flex, Box, Badge,
} from '@chakra-ui/react'
import { BsCalendarCheck, BsStar, BsStarFill, BsCalendarCheckFill } from "react-icons/bs";
import { FaUsers } from "react-icons/fa";
import { BiCalendarStar } from "react-icons/bi";
import { AiOutlineEye, AiFillEye } from "react-icons/ai";
import { GoClockFill } from "react-icons/go";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import { FaNoteSticky } from "react-icons/fa6";
import WeeklyButtons from './buttons';

function WeeklyList({ userAuth, userAuthID, setCurrentPage, scheduleData, getRowBackground, getTextDay, getColorSchemeForStatusDay, getColorSchemeForTypeDaily, getColorSchemeForOption, handleInfo, handleAudit, handleNotes, deleteSchedule, handleUpdate, handleAP, handleAccept, handlePublic, handleCallOut, generateShift, total, totalSchedule, currentPage, totalPages, handleClockOut }) {

  return (
    <>
      <Flex
        direction='column'
        w='100%'
      >
        <Flex
          direction='column'
          w='100%'

          p={3}
          borderRadius="16px"
        >
        </Flex>
        <br />
        <Table size={'sm'}>
          <Thead bg={"white"}>
            <Tr bg={'white'}>
              <Th textAlign={'center'}>Agent</Th>
              <Th textAlign={'center'}>Schedules</Th>
            </Tr>
          </Thead>
          <Tbody>
            {scheduleData && scheduleData.length > 0 ? (
              scheduleData.map((item, index) => (
                <Tr key={item.id_operator} className={index % 2 === 0 ? 'white-row' : 'gray-row'} mb={'50px'}>
                  <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                    <Flex direction={'column'}>
                      <Text>
                        {item.name.split(' ')[0]} {item.last_name.split(' ')[0]}
                      </Text>
                      <Flex justifyContent={'center'}>
                        <Text mr={'1%'}>
                          {(() => {
                            switch (item.status_employee_id) {
                              case 1:
                                return "Office";
                              case 2:
                                return "Remote";
                              case 3:
                                return "Remote";
                              default:
                                return "Office";
                            }
                          })()}
                        </Text>
                        <Text>
                          {(() => {
                            switch (item.location_id) {
                              case 1:
                                return "MX";
                              case 2:
                                return "BZ";
                              case 3:
                                return "BZ";
                              case 4:
                                return "RD";
                              case 5:
                                return "COL";
                              case 6:
                                return "GTM";
                              default:
                                return "MX";
                            }
                          })()}
                        </Text>
                      </Flex>


                      {item.total_duration_final === null ?
                        null
                        :
                        <>
                          <Text mt={'5%'} color={'#808080'}>
                            Total duration:
                          </Text>
                          <Text mt={'1%'}>
                            {item.total_duration_final}
                          </Text>
                        </>
                      }

                      <Text mt={'1%'} color={'#808080'}>
                        Total expected:
                      </Text>
                      <Text mt={'1%'}>
                        {item.total_duration_expect}
                      </Text>
                    </Flex>
                  </Td>
                  {item.schedules.map((schedule) => (
                    <Box key={schedule.id} borderRadius={'5px'} display={'flex'} justifyContent={'center'}>
                      <Td textAlign={'center'} mb={2}>
                        <Box flexDirection="column">
                          <Box fontWeight={'bold'} textAlign={'center'} display="flex" justifyContent='space-evenly' alignItems="center" bg={getRowBackground(schedule)} height={'50px'} width={'920px'} borderRadius={'5px'}>
                            <Badge color={"white"} bg={'#424ea8'} width={'170px'} height={'22px'} fontSize='10px' ml={1} borderRadius={"5px"} mr={'1%'}>
                              {schedule.client}
                            </Badge>
                            {schedule.countNotes !== 0 ?
                              <>
                                <Flex cursor={'pointer'} onClick={() => handleNotes(schedule)}>
                                  <Box>
                                    <FaNoteSticky size={'20px'} color={'#1c2147'} title="Notes" />
                                  </Box>
                                  <Text>
                                    {schedule.countNotes}
                                  </Text>
                                </Flex>
                              </>
                              :
                              <Box cursor={'pointer'} onClick={() => handleNotes(schedule)}>
                                <FaNoteSticky size={'20px'} color={'#1c2147'} title="Notes" />
                              </Box>
                            }
                            {schedule.accept === 1 ?
                              <BsCalendarCheckFill size={'18px'} style={{ marginRight: '10px', marginLeft: '10px' }} color={'#1c2147'} title="Accepted" />
                              :
                              <BsCalendarCheck size={'18px'} style={{ marginRight: '10px', marginLeft: '10px' }} color={'#808080'} title="No accepted" />}
                            {schedule.post === 1 ?
                              <BsStarFill size={'18px'} style={{ marginRight: '10px', marginLeft: '10px' }} color={'#1c2147'} title="Published" /> :
                              <BsStar size={'18px'} style={{ marginRight: '10px', marginLeft: '10px' }} color={'#808080'} title="No published" />}
                            {schedule.read_confirmation === 1 ?
                              <AiFillEye size={'18px'} style={{ marginRight: '10px', marginLeft: '10px' }} color={'#1c2147'} title="Reading" /> :
                              <AiOutlineEye size={'18px'} style={{ marginRight: '10px', marginLeft: '10px' }} color={'#808080'} title="No reading" />}
                            <Text fontWeight={'bold'} color='#424ea8' textAlign={'center'} display="flex" justifyContent="space-between" alignItems="center" width={"35px"}>
                              <span style={{ flex: 1, textAlign: 'center' }}>{getTextDay(schedule.id_day)}</span>
                            </Text>
                            <Flex flexDirection={"row"} width={"300px"} justifyContent={"space-evenly"}>
                              <Text fontWeight={'bold'} color='#808080' textAlign={'center'}  >
                                {schedule.date}
                              </Text>
                              {([2, 4, 5, 6, 7, 8, 9, 10, 11, 13, 15].includes(schedule.status)) ? (
                                null
                              ) :
                                <>
                                  <Box display={"flex"} flexDirection={"row"} width={"170px"} justifyContent={"space-between"}>
                                    <GoClockFill color='#808080' size={'16px'} />
                                    <Text fontWeight={'bold'} color='#808080' textAlign={'center'} width={"70px"}>
                                      {schedule.status !== 2 && schedule.type_daily !== 6 ? schedule.start_time : '---'}
                                    </Text>
                                    <Text fontWeight={'bold'} color='#808080' textAlign={'center'} width={"70px"}>
                                      {schedule.end_time}
                                    </Text>
                                  </Box>
                                </>
                              }
                            </Flex>
                            {([2, 4, 5, 6, 7, 8, 9, 10, 11, 13, 15].includes(schedule.status)) ? (
                              <>
                                <Badge color={"white"} bg={getColorSchemeForStatusDay(schedule.status)} width={'210px'} height={'22px'} fontSize='10px' borderRadius={"5px"}>
                                  {(() => {
                                    switch (schedule.status) {
                                      case 1:
                                        return "Normal";
                                      case 2:
                                        return "Off";
                                      case 3:
                                        return "Holiday";
                                      case 4:
                                        return "Vacation";
                                      case 5:
                                        return "Maternity Leave";
                                      case 6:
                                        return "Permission Request";
                                      case 7:
                                        return "Wedding Leave";
                                      case 8:
                                        return "Medical Leave";
                                      case 9:
                                        return "Paternity Leave";
                                      case 10:
                                        return "Bereavement Leave";
                                      case 11:
                                        return "Surecall O/C";
                                      case 13:
                                        return "No longer with us";
                                      case 14:
                                        return "Stand by";
                                      case 15:
                                        return "Holiday Off";
                                      default:
                                        return "Normal";
                                    }
                                  })()}
                                </Badge>
                              </>
                            ) :
                              <>
                                <Badge color={"white"} bg={getColorSchemeForStatusDay(schedule.status)} width={'55px'} height={'22px'} fontSize='8px' ml={1} borderRadius={"5px"}>
                                  {(() => {
                                    switch (schedule.status) {
                                      case 1:
                                        return "Normal";
                                      case 2:
                                        return "Off";
                                      case 3:
                                        return "Holiday";
                                      case 4:
                                        return "Vacation";
                                      case 5:
                                        return "Maternity Leave";
                                      case 6:
                                        return "Permission Request";
                                      case 7:
                                        return "Wedding Leave";
                                      case 8:
                                        return "Medical Leave";
                                      case 9:
                                        return "Paternity Leave";
                                      case 10:
                                        return "Bereavement Leave";
                                      case 13:
                                        return "No longer with us";
                                      case 14:
                                        return "Stand by";
                                      case 15:
                                        return "Holiday Off";
                                      default:
                                        return "Normal";
                                    }
                                  })()}
                                </Badge>
                                <Badge color={"white"} bg={getColorSchemeForTypeDaily(schedule.type_daily)} width={'auto'} height={'22px'} fontSize='8px' borderRadius={"5px"}>
                                  {(() => {
                                    switch (schedule.type_daily) {
                                      case 1:
                                        return "Regular shift";
                                      case 2:
                                        return "Regular training";
                                      case 3:
                                        return "Additional hours";
                                      case 4:
                                        return "Regular coverage";
                                      case 5:
                                        return "Regular shadowing"
                                      case 6:
                                        return "No shift"
                                      case 7:
                                        return "Additional training"
                                      case 8:
                                        return "Additional coverage"
                                      case 9:
                                        return "Additional shadowing"
                                      default:
                                        return "Regular shift";
                                    }
                                  })()}
                                </Badge>
                                <Badge color={"white"} bg={getColorSchemeForOption(schedule.option)} width={'70px'} height={'22px'} fontSize='8px' borderRadius={"5px"}>
                                  {(() => {
                                    switch (schedule.option) {
                                      case 1:
                                        return "----";
                                      case 2:
                                        return "Start";
                                      case 3:
                                        return "Late";
                                      case 4:
                                        return "Clock out";
                                      case 5:
                                        return "Supervisor";
                                      case 6:
                                        return "Call out";
                                      default:
                                        return "----";
                                    }
                                  })()}
                                </Badge>
                              </>
                            }
                            <WeeklyButtons
                              schedule={schedule}
                              userAuth={userAuth}
                              userAuthID={userAuthID}
                              handleInfo={handleInfo}
                              handleAudit={handleAudit}
                              handleNotes={handleNotes}
                              deleteSchedule={deleteSchedule}
                              handleUpdate={handleUpdate}
                              handleAP={handleAP}
                              handleAccept={handleAccept}
                              handlePublic={handlePublic}
                              handleCallOut={handleCallOut}
                              generateShift={generateShift}
                              handleClockOut={handleClockOut}
                            />
                          </Box>
                        </Box>
                      </Td>
                    </Box>
                  ))}
                </Tr>
              ))
            ) : (
              <Tr>
                <Th colSpan={'9'} >
                  <Text
                    color='#1c2147'
                    fontSize={"25px"}
                    textAlign={"center"}
                    paddingTop={"15px"}
                    paddingBottom={"15px"}
                    bg={"#f2f2f2"}
                    textTransform={'none'}
                    fontFamily={'DM Sans'}
                    letterSpacing="-1px"
                  >
                    No data
                  </Text>
                </Th>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Flex >
      <Box width={"100%"} display={"flex"} mt={"30px"}>
        <Flex display={'flex'} justifyContent={'flex-start'} width={"max-content"} pb={"10px"} mt={"8px"}  >
          {total === 0 ? (
            <>
              <Flex bg={'#f2f2f2'} width={'250px'} display={'flex'} justifyContent={'center'}>
                <Box mt={'5px'} mr={2}>
                  <FaUsers color={"#1c2147"} fontWeight={'bold'} />
                </Box>
                <Text fontSize={'14px'} mt={1} color={"#1c2147"} fontWeight={'bold'}>
                  No users
                </Text>
                <Box mt={'5px'} mr={2} ml={4}>
                  <BiCalendarStar color={"#1c2147"} fontWeight={'bold'} />
                </Box>
                <Text fontSize={'14px'} mt={1} color={"#1c2147"} fontWeight={'bold'}>
                  No schedules
                </Text>
              </Flex>
            </>
          ) : (
            <>
              <Flex bg={'#f2f2f2'} width={'auto'} display={'flex'} justifyContent={'center'}>
                <Text fontSize={'14px'} mt={1} mr={3} color={"#1c2147"} fontWeight={'bold'} ml={'20px'}>
                  {total}
                </Text>
                <Box mt={'5px'} mr={1}>
                  <FaUsers color={"#1c2147"} fontWeight={'bold'} />
                </Box>
                <Text fontSize={'14px'} mt={1} color={"#1c2147"} fontWeight={'bold'} mr={3}>
                  {total === 1 ? 'User' : 'Users'}
                </Text>
                <Text fontSize={'14px'} mt={1} mr={3} color={"#1c2147"} fontWeight={'bold'}>
                  {totalSchedule}
                </Text>
                <Box mt={'5px'} mr={1}>
                  <BiCalendarStar color={"#1c2147"} fontWeight={'bold'} />
                </Box>
                <Text fontSize={'14px'} mt={1} color={"#1c2147"} fontWeight={'bold'} mr={'20px'}>
                  {totalSchedule === 1 ? 'Schedule' : 'Schedules'}
                </Text>
              </Flex>
            </>
          )}
        </Flex>
        <ButtonGroup isAttached variant="outline" ml={"26%"} justifyContent={'center'} display={'flex'} width={"max-content"} >
          <Button
            borderRadius={'0px'}
            onClick={() => setCurrentPage(currentPage - 1)}
            isDisabled={currentPage === 1}
            bg="#808080" color={"white"}
            _hover={{ background: "#808080", }}
            _active={{ background: "#808080", }}
          ><BsArrowLeftShort fontSize="20px" />
          </Button>
          <Text class="btnTextPages">{currentPage} of {totalPages}</Text>
          <Button
            borderRadius={'0px'} ml={4}
            onClick={() => setCurrentPage(currentPage + 1)}
            isDisabled={currentPage === totalPages}
            bg="#808080" color={"white"}
            _hover={{ background: "#808080", }}
            _active={{ background: "#808080", }}
          ><BsArrowRightShort fontSize="20px" />
          </Button>
        </ButtonGroup>
      </Box>
    </>
  );
}
export default WeeklyList; 