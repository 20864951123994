import {
  Box, Flex,
  Text,
  Badge, Button, ButtonGroup, Input
} from "@chakra-ui/react";
import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2'
import Messages from "views/admin/main/others/messages/components/Messages";
import EndIncident from "../../incidents/componentes/endIncident";
import { IoTicketOutline } from "react-icons/io5";
import { FaRegTrashAlt } from "react-icons/fa";
import { TbArrowsUpDown } from "react-icons/tb";
import ModalTransfer from "../../incidents/componentes/modalTransfer";
import Select2 from 'react-select'
import { RxTriangleLeft, RxTriangleRight } from "react-icons/rx";
import { ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons'
import TicketReason from "../../incidents/componentes/updateReason";
import UpdateAnydesk from "../../users/newUser/items/modals/updateAnydesk";
import EndToTransfer from "../../incidents/componentes/endToTransfer";



export default function Default() {

  const [showMessages, setShowMessages] = useState(false);
  const [selectedMessageId, setSelectedMessageId] = useState(null);
  const [userJoin, setUserJoin] = useState('');
  const [incidentData, setIncidentData] = useState([]);
  const [selectIncident, setSelectIncident] = useState(null);
  const [setting, setSetting] = useState(null);
  const [openCloseIncidentModal, setOpenCloseIncidentModal] = useState(false);
  const [totalTickets, setTotalTickets] = useState('');
  const [openModalTransfer, setOpenModalTransfer] = useState(false);
  const [ticketFilter, setTicketFilter] = useState('');
  const [ticket, setTicket] = useState([]);
  const [resetKey, setResetKey] = useState(0);
  const [agentsFilter, setAgentsFilter] = useState('');
  const [agent, setAgent] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [order, setOrder] = useState('desc');
  const [searchType, setSearchType] = useState('');
  const abortController = new AbortController();
  const signal = abortController.signal;
  const [user, setUser] = useState("");
  const [boxClicked, setBoxClicked] = useState({});
  const [claimFilter, setClaimFilter] = useState('');
  const [openTicketReasonModal, setOpenTicketReasonModal] = useState(false);
  const [openUpdateAnydesk, setOpenUpdateAnydesk] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [openEndToTransferModal, setOpenEndToTransferModal] = useState(false);



  const clearFilterID = () => {
    setSearchType('');
    setCurrentPage(1);
  }

  const clearFilterTicket = () => {
    setTicketFilter('');
    setResetKey(resetKey + 1);
    setCurrentPage(1);
  }

  const clearFilterClaim = () => {
    setClaimFilter('');
    setResetKey(resetKey + 1);
    setCurrentPage(1);
  }

  const clearFiltersAgents = () => {
    setAgentsFilter('');
    setResetKey(resetKey + 1);
    setCurrentPage(1);
  }

  const fetchData = async () => {
    try {
      const response = axios.get(`/user`).then((response) => {
        const user = response.data.user;
        setUser(user);
      });
    } catch (error) {
      console.error('Error al obtener datos del usuario:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const claimIncident = async () => {
    try {
      const response = await axios.get(
        `/incidentsClaim?page=${currentPage}`
        + `${searchType ? `&id=${searchType}` : ''}`
        + `${claimFilter ? `&claim=${encodeURIComponent(claimFilter)}` : ''}`
        + `${ticketFilter ? `&ticket=${ticketFilter}` : ''}`
        + `${agentsFilter ? `&agent=${encodeURIComponent(agentsFilter)}` : ''}`
        + `&order=${order}`,
        { signal }
      );
      setIncidentData(response.data.data.data);
      setTicket(response.data.tickets);
      setAgent(response.data.agents);
      setTotalTickets(response.data.data.total);
      setTotalPages(response.data.data.last_page);
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Solicitud abortada');
      } else {
        console.error(error);
      }
    }
  };


  const optionTickets = ticket.map(ticket => ({
    value: ticket.id,
    label: ticket.name,
  }));

  const optionAgents = agent.map(agents => ({
    value: agents.id,
    label: `${agents.name} ${agents.last_name}`,
  }));

  const claimedOptions = [
    { value: user.id, label: 'Claimed Tickets' }
  ]

  useEffect(() => {
    claimIncident();
    const shortIntervalUserIds = [30, 31, 32, 14, 15, 16, 17, 18, 19, 20, 21, 26, 27, 28, 29, 41, 42];
    const intervalDuration = shortIntervalUserIds.includes(user.type_user_id) ? 5000 : 600000;

    const intervalId = setInterval(() => {
      claimIncident();
    }, intervalDuration);

    return () => {
      clearInterval(intervalId);
    };

  }, [user, order, currentPage, ticketFilter, agentsFilter, searchType, claimFilter]);

  useEffect(() => {

    const storedItemId = localStorage.getItem('selectedItemId');
    if (storedItemId !== null) {
      setSearchType(storedItemId);
      localStorage.removeItem('selectedItemId');
    }
  }, []);

  const handleJoinClick = async (incident) => {
    try {
      setShowMessages(false);
      const response = await axios.post(`/chat/rooms/${incident.chat_room_id}/join`);
      const data = response.data;
      setUserJoin(data.user);
      setSelectedMessageId(incident);
      claimIncident();
      setShowMessages(true);

      if (incident.chat_room_id === 1 || incident.chat_room_id === 4) {
        handleClaimITOC(incident);
      } else {
        const newBoxClicked = {};
        Object.keys(boxClicked).forEach((key) => {
          newBoxClicked[key] = false;
        });

        newBoxClicked[incident.id] = true;
        setBoxClicked(newBoxClicked);
      }

    } catch (error) {
      console.error('Error al unirse a la sala de chat:', error);
    }
  };

  const toggleOrder = () => {
    setOrder(order === 'desc' ? 'asc' : 'desc');
  };


  const closeMessages = () => {
    setShowMessages(false);
    setSelectedMessageId(null);
    setBoxClicked(prevState => ({ ...prevState, [selectedMessageId.id]: false }));
  };

  const openMessages = () => {
    setShowMessages(false);
  };

  const handleUpdate = async (chatRoomId) => {
    try {
      const response = await axios.get(`/incidents/edit/${chatRoomId.id}`);
      const incident = response.data.incident;
      const setting = response.data.settings;
      setOpenCloseIncidentModal(true);
      setSelectIncident(incident);
      setSetting(setting);
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  const handleAddReason = async (chatRoomId) => {
    try {
      const response = await axios.get(`/incidents/edit/${chatRoomId.id}`);
      const incident = response.data.incident;
      const setting = response.data.settings;
      setOpenTicketReasonModal(true);
      setSelectIncident(incident);
      setSetting(setting);
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  const handleTransfer = async (chatRoomId) => {
    try {
      const response = await axios.get(`/incidents/edit/${chatRoomId.id}`);
      const incident = response.data.incident;
      const setting = response.data.settings;
      setOpenModalTransfer(true);
      setSelectIncident(incident);
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };


  const handleEndToTransfer = async (chatRoomId) => {
    try {
      const response = await axios.get(`/incidents/edit/${chatRoomId.id}`);
      const incident = response.data.incident;
      const setting = response.data.settings;
      setOpenEndToTransferModal(true);
      setSelectIncident(incident);
      setSetting(setting);
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  const handleClaim = async (event, incident) => {
    try {
      event.stopPropagation();
      const response = await axios.put(`/claim-incident/${incident.id}`);
      claimIncident();

      setTimeout(() => {
        setBoxClicked((prevState) => ({
          ...prevState,
          [incident.id]: false,
        }));
      }, 0);
    } catch (error) {
      console.error('Error al reclamar el incidente:', error);
    }
  };

  const handleClaimITOC = async (incident) => {
    try {
      const response = await axios.put(`/claim-incident/${incident.id}`);
      claimIncident();
      setBoxClicked(prevState => ({ ...prevState, [incident.id]: false }));
    } catch (error) {
      console.error('Error al reclamar el incidente:', error);
    }
  };

  const closeModalUpdate = () => {
    setOpenCloseIncidentModal(false);
  }

  const closeModalTransfer = () => {
    setOpenModalTransfer(false);
  }

  const closeModalEditAnydesk = () => {
    setOpenUpdateAnydesk(false);
  }

  const closeModalAddReason = () => {
    setOpenTicketReasonModal(false);
  }

  const getColorIncident = (chatRoom) => {
    const colorMap = {
      1: '#ff6f94',
      2: '#fabb1c',
      3: '#633cbd',
      4: '#fb9539',
      5: '#a2bd34',
      6: '#6bc2ed',
      7: '#7080fc',
      8: '#fc7466',
    };
    return colorMap[chatRoom] || '#ff6f94';
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: 'transparent',
      borderRadius: '5px',
      borderColor: '#808080',
      fontSize: '15px',
      height: 'max-content',
      width: '140px',
      '&:hover': {
        borderColor: '#808080',
      },
      '&:focus-visible': {
        outline: 'none',
        boxShadow: 'none'
      },
    }),
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: 'white',
      width: '200px'
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#808080' : state.isFocused ? 'white' : '#808080',
      backgroundColor: state.isSelected ? '#f2f2f2' : state.isFocused ? '#bcc3f3' : 'white',
    }),
  };

  const handleSelectChangeF = (componentName, selectedOption) => {
    switch (componentName) {
      case 'ticket':
        setTicketFilter(selectedOption.value);
        break;
      case 'agents':
        setAgentsFilter(selectedOption.value);
        break;
      case 'claim':
        setClaimFilter(selectedOption.value);
        break;
      default:
        break;
    }
  };

  const miRef = useRef(null);

  const filterOptions = (candidate, input) => {
    const words = input.toLowerCase().split(' ');
    return words.every(word =>
      candidate.label.toLowerCase().includes(word)
    );
  };

  const handleUpdateAD = async (user) => {

    try {
      const response = await axios.get(`/users/editAnydesk/${user}`);
      const userData = response.data;
      setSelectedUser(userData);
      setOpenUpdateAnydesk(true);

    } catch (error) {
      console.error('Error fetching anydesk details:', error);
    }
  };


  const getWhatsAppLink = (phoneNumber, locationId) => {
    const countryCodeMap = {
      1: '52',
      2: '501',
      3: '501',
      4: '1',
      5: '57'
    };

    const countryCode = countryCodeMap[locationId] || '';

    // Agregar el código de país a la URL de WhatsApp
    const fullPhoneNumber = `+${countryCode}${phoneNumber}`;

    // Reemplazar espacios con %20 en el número de teléfono (si hay alguno)
    const sanitizedPhoneNumber = fullPhoneNumber.replace(/\s/g, '%20');

    // Construir la URL de WhatsApp
    return `https://wa.me/${sanitizedPhoneNumber}`;
  }

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + '...';
    }
    return text;
  };

  const handleSweetAlert = (user) => {
    Swal.fire({
      title: 'Select an action',
      text: "What do you want to do?",
      icon: 'question',
      showCancelButton: true,
      showDenyButton: true,
      confirmButtonText: 'Update Anydesk',
      denyButtonText: 'Open Anydesk',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        handleUpdateAD(user.id_user);
      } else if (result.isDenied) {
        window.location.href = `anydesk:${user.anydesk}`;
      }
    });
  };


  return (
    <Box className="gridboxticket">
      <Box className="cardonetick" >
        <Box position={"relative"} ref={miRef}>
          <Box className="ticketfiltprev" >
            <Flex className="flextickfilts">
              <Flex className="boxtickfilts">
                <Input border={"1px"}
                  borderRadius={"5px"}
                  value={searchType}
                  width={"100px"}
                  type="text"
                  fontSize={"14px"}
                  placeholder={`Ticket #`}
                  onChange={(e) => {
                    setSearchType(e.target.value);
                    setCurrentPage(1);
                  }}
                  _placeholder={{
                    color: '#808080',
                  }}
                  _focusVisible={'none'}
                />
                <Button onClick={clearFilterID} bg={'transparent'} _hover={{ backgroundColor: 'transparent' }} color={'#808080'} padding={"0px"} title="Clear filters" >
                  <FaRegTrashAlt size={'22px'} />
                </Button>
              </Flex>
              <Flex>
                <Box className="boxfilts">
                  <Select2
                    options={claimedOptions}
                    value={claimedOptions.find(option => option.value === claimFilter)}
                    onChange={(selectedOption) => {
                      handleSelectChangeF('claim', selectedOption);
                      setCurrentPage(1);
                    }}
                    styles={customStyles}
                    placeholder="All tickets"
                    key={`claimSelect-${resetKey}`}
                  />
                </Box>
                <Flex className="filt1">
                  <Button onClick={clearFilterClaim} bg={'transparent'} _hover={{ backgroundColor: 'transparent' }} color={'#808080'} padding={"0px"} title="Clear filters" >
                    <FaRegTrashAlt size={'22px'} />
                  </Button>
                </Flex>
              </Flex>
              <Flex  >
                <Box className="boxfilts">
                  <Select2
                    options={optionTickets}
                    value={optionTickets.find(option => option.value === ticketFilter)}
                    onChange={(selectedOption) => {
                      handleSelectChangeF('ticket', selectedOption);
                      setCurrentPage(1);
                    }}
                    styles={customStyles}
                    placeholder="Department"
                    key={`ticketSelect-${resetKey}`}
                  />
                </Box>
                <Flex className="filt1">
                  <Button onClick={clearFilterTicket} bg={'transparent'} _hover={{ backgroundColor: 'transparent' }} color={'#808080'} padding={"0px"} title="Clear filters" >
                    <FaRegTrashAlt size={'22px'} />
                  </Button>
                </Flex>
              </Flex>
              <Flex  >
                <Box className="boxfilts">
                  <Select2
                    options={optionAgents}
                    value={optionAgents.find(option => option.value === agentsFilter)}
                    onChange={(selectedOption) => {
                      handleSelectChangeF('agents', selectedOption);
                      setCurrentPage(1);
                    }}
                    styles={customStyles}
                    placeholder="Agent"
                    key={`agentSelect-${resetKey}`}
                    isSearchable
                    filterOption={filterOptions}
                  />
                </Box>
                <Flex className="filt1">
                  <Button onClick={clearFiltersAgents} bg={'transparent'} _hover={{ backgroundColor: 'transparent' }} color={'#808080'} padding={"0px"} title="Clear filters" >
                    <FaRegTrashAlt size={'22px'} />
                  </Button>
                </Flex>
              </Flex>
            </Flex>
            <Flex className="btnfiltprev">
              <Text className="numticks">
                {totalTickets === 0 ? (
                  <>
                    <Flex width={'150px'} display={'flex'} justifyContent={'center'}>
                      <Box mt={'8px'} mr={2} ml={4}>
                        <IoTicketOutline color={"#1c2147"} fontWeight={'bold'} />
                      </Box>
                      <Text fontSize={'20px'} mt={1} color={"#1c2147"} fontWeight={'bold'}>
                        No tickets
                      </Text>
                    </Flex>
                  </>
                ) : (
                  <>
                    <Flex width={'max-content'} display={'flex'} justifyContent={'center'}>
                      <Box mt={'8px'} mr={1}>
                        <IoTicketOutline color={"#1c2147"} fontWeight={'bold'} />
                      </Box>
                      <Text fontSize={'18px'} mt={1} color={"#1c2147"} fontWeight={'bold'}>
                        {totalTickets} {totalTickets === 1 ? 'Ticket' : 'Tickets'}
                      </Text>
                    </Flex>
                  </>
                )}
              </Text>
              <ButtonGroup variant="outline" ml={"2%"} height={"max-content"} justifyContent={'center'} display={'flex'} width={"max-content"}>
                <Button
                  borderRadius={'0px'}
                  onClick={() => setCurrentPage(1)}
                  isDisabled={currentPage === 1}
                  bg="#f2f2f2" color={"#b3b3b3"}
                  _hover={{ background: "#808080", }}
                  _active={{ background: "#808080", }}
                ><ArrowLeftIcon fontSize="10px" />
                </Button>
                <Button
                  borderRadius={'0px'} ml={4}
                  onClick={() => setCurrentPage(currentPage - 1)}
                  isDisabled={currentPage === 1}
                  bg="#f2f2f2" color={"#b3b3b3"}
                  _hover={{ background: "#808080", }}
                  _active={{ background: "#808080", }}
                ><RxTriangleLeft fontSize="20px" />
                </Button>
                <Text p={2} width={"max-content"} fontSize={"14px"}>{currentPage} of {totalPages}</Text>
                <Button
                  borderRadius={'0px'}
                  onClick={() => setCurrentPage(currentPage + 1)}
                  isDisabled={currentPage === totalPages}
                  bg="#f2f2f2" color={"#b3b3b3"}
                  _hover={{ background: "#808080", }}
                  _active={{ background: "#808080", }}
                ><RxTriangleRight fontSize="20px" />
                </Button>
                <Button
                  borderRadius={'0px'} ml={4}
                  onClick={() => setCurrentPage(totalPages)}
                  isDisabled={currentPage === totalPages}
                  bg="#f2f2f2" color={"#b3b3b3"}
                  _hover={{ background: "#808080", }}
                  _active={{ background: "#808080", }}
                ><ArrowRightIcon fontSize="10px" />
                </Button>
              </ButtonGroup>
              <Box className="btnascdesc">
                <Button onClick={toggleOrder} color={"white"} width={"max-content"} bg={'#7a86e6'} ml={8} borderRadius={'5px'} _hover={{ background: "#808080", color: 'white' }}>
                  <Box mr={'10px'}>
                    <TbArrowsUpDown />
                  </Box>
                  {order === 'desc' ? 'Descending Order' : 'Ascending Order'}
                </Button>
              </Box>

            </Flex>
          </Box>
          <Box className="boxticketchat">
            {incidentData.map((incident) => {
              const truncatedMessage = truncateText(incident.first_message, 15);

              return (
                <Flex key={incident.id} position={"relative"}
                  onClick={() => handleJoinClick(incident)}
                  cursor={'pointer'}
                  borderRadius={'10px'}
                  border={'4px'}
                  borderColor={selectedMessageId && selectedMessageId.id === incident.id ? '#424ea8' : (incident.id_supervisor > 0 ? '#7a86e6' : '#b3b3b3')}
                  mt={4}
                  _hover={{ background: '#e6e6e6' }}
                >
                  <Box right={4} width={'40px'} height={"40px"} borderRadius={"15%"} bg={'#9ba4ec'} top={4} position="absolute"
                    display={selectedMessageId && selectedMessageId.id === incident.id ? 'none' : (incident.last_message_count > 0 ? 'block' : 'none')} >
                    <Text mt={"5px"} textAlign={'center'} fontSize={"20px"} color={'white'}>
                      {incident.last_message_count}
                    </Text>
                  </Box>
                  {(boxClicked[incident.id] && incident.id_supervisor === 0) && (
                    <Button bg={"#b9ce67"} color={"white"} borderRadius={"5px"}
                      position="absolute"
                      onClick={(e) => handleClaim(e, incident)}
                      right={4}
                      bottom={4} _hover={{ background: "#8aad01" }}
                    >
                      <Text fontSize="18px">Claim</Text>
                    </Button>
                  )}
                  <Flex flexDirection={"row"} w={'100%'}>
                    <Flex flexDirection={"column"}
                      pt='2%'
                      p={'4%'}
                      justifyContent='center'
                      w='50%'
                    >
                      <Text
                        color={selectedMessageId && selectedMessageId.id === incident.id ? '#2c3470' : '#b3b3b3'}
                        fontSize={'20px'}
                        width={"100px"}
                        whiteSpace={'pre-wrap'}
                        ml={'8%'}
                      >
                        #{incident.id}
                      </Text>
                      <Text
                        color={selectedMessageId && selectedMessageId.id === incident.id ? '#424ea8' : (incident.id_supervisor > 0 ? '#7a86e6' : '#b3b3b3')}
                        fontWeight={'bold'}
                        width={'80%'}
                        fontSize={'20px'}
                        ml={'8%'}
                      >
                        Claimed by:
                        {incident.id_supervisor > 0
                          ? ` ${incident.supervisor.split(' ')[0]} ${incident.supervisorL.split(' ')[0]}`
                          : `---`
                        }
                      </Text>
                      <Text color={'#1c2147'}
                        fontSize={'35px'}
                        fontWeight={'semibold'}
                        ml={'8%'}
                      >
                        {truncatedMessage}
                      </Text>
                      <Text
                        color={'#1c2147'}
                        fontSize={'20px'}
                        fontWeight='500'
                        ml={'8%'}
                      >
                        by: {incident.id_user === 1 ? "Dedicated Portal System" : `${incident.operator} ${incident.operatorL}`}
                      </Text>
                      {incident.chat_room_id === 6 ? (
                        <Text fontWeight='500' ml={'8%'} color={'#1c2147'} borderRadius={'5px'} mt={'10px'} fontSize={'20px'} textTransform='none'>
                          {incident.chat_room_id === 6 ? `ID: ${incident.mat_dp}` : ''}
                        </Text>
                      ) : null}
                      <Flex ml={'8%'} mt={'5%'}>
                        <Text
                          color={selectedMessageId && selectedMessageId.id === incident.id ? '#424ea8' : (incident.id_supervisor > 0 ? '#7a86e6' : '#b3b3b3')}
                          fontSize={"20px"}
                          fontWeight='bold'
                          whiteSpace={'pre-wrap'}
                          mr={'2%'}
                          mt={'1%'}
                        >
                          {incident.hour}
                        </Text>
                        <Text
                          color={'#1c2147'}
                          fontWeight='bold'
                          whiteSpace={'pre-wrap'}
                          fontSize={"16px"}
                          mt='2%'
                        >
                          {incident.date}
                        </Text>

                      </Flex>

                    </Flex>
                    <Flex direction='column' align='start' w={'50%'} bg={'#f2f2f2'} p={'7%'}>
                      <Badge bg={'#9ba4ec'} fontWeight='500' textAlign={'center'} color={'white'} borderRadius={'5px'} mr={2} mt={'10px'} fontSize={'18px'} textTransform='none'>
                        {incident.client}
                      </Badge>

                      <Flex width={'100%'}>
                        <Badge bg={'#dd67ae'} fontWeight='500' textAlign={'center'} color={'white'} borderRadius={'5px'} mr={2} mt={'10px'} fontSize={'15px'} textTransform='none'>
                          {incident.id_user === 1 ? "System" : `${incident.position}`}
                        </Badge>
                        <Badge bg={'#dd67ae'} fontWeight='500' textAlign={'center'} color={'white'} borderRadius={'5px'} mr={2} mt={'10px'} fontSize={'15px'} textTransform='none'>
                          {(() => {
                            switch (incident.location_id) {
                              case 1:
                                return "MX";
                              case 2:
                                return "BZ";
                              case 3:
                                return "BZ";
                              case 4:
                                return "RD";
                              case 5:
                                return "COL";
                              case 6:
                                return "GTM";
                              default:
                                return "MX";
                            }
                          })()}
                        </Badge>
                        <Badge bg={'#dd67ae'} fontWeight='500' textAlign={'center'} color={'white'} borderRadius={'5px'} mr={2} mt={'10px'} fontSize={'15px'} textTransform='none'>
                          {(() => {
                            switch (incident.status_employee_id) {
                              case 1:
                                return "Office";
                              case 2:
                                return "Remote";
                              case 3:
                                return "Remote";
                              default:
                                return "Office";
                            }
                          })()}
                        </Badge>
                      </Flex>

                      <Badge bg={getColorIncident(incident.chat_room_id)} fontWeight='500' textAlign={'center'} color={'white'} borderRadius={'5px'} mt={'10px'} mr={2} fontSize={'18px'} textTransform='none'>
                        {(() => {
                          switch (incident.chat_room_id) {
                            case 1:
                              return "IT request ";
                            case 2:
                              return "HR request ";
                            case 3:
                              return "Hiring request ";
                            case 4:
                              return "OnCall request ";
                            case 5:
                              return "Schedules request ";
                            case 6:
                              return "Finances request ";
                            case 7:
                              return "Calls request ";
                            case 8:
                              return "Supervisor task";
                            default:
                              return "IT request ";
                          }
                        })()}
                      </Badge>

                      {incident.chat_room_id === 1 && incident.id_setting !== 0 ? (
                        <Badge bg={'#b3b3b3'} fontWeight='500' textAlign={'center'} color={'white'} borderRadius={'5px'} mt={'10px'} fontSize={'18px'} textTransform='none'>
                          {(() => {
                            switch (incident.id_setting) {
                              case 14:
                                return "VPN issues";
                              case 15:
                                return "Remote Issues";
                              case 16:
                                return "System Issues";
                              case 17:
                                return "Password Issues";
                              case 18:
                                return "Credentials Issues";
                              case 19:
                                return "Lost Connection";
                              case 20:
                                return "Audio Issues";
                              case 21:
                                return "Internet Outage";
                              case 22:
                                return "Lost Power";
                              case 23:
                                return "PC Restarted";
                              case 24:
                                return "PC Updated";
                              case 25:
                                return "PC didnt Turn On";
                              case 26:
                                return "Error Displays";
                              case 27:
                                return "Headsets Issues";
                              case 28:
                                return "Keyboard Issues";
                              case 29:
                                return "Hardware Issues";
                              case 30:
                                return "Other";
                              default:
                                return "----";
                            }
                          })()}
                        </Badge>
                      ) : null}
                      {incident.chat_room_id === 5 && incident.schedule_error !== 0 ? (
                        <Badge bg={'#b3b3b3'} fontWeight='500' textAlign={'center'} color={'white'} borderRadius={'5px'} mt={'10px'} fontSize={'18px'} textTransform='none'>
                          Wrong Schedule
                        </Badge>
                      ) : null}
                      {incident.chat_room_id === 1 && incident.urgency !== null ? (
                        <Badge
                          bg={incident.urgency === 1 ? '#fc7466' : '#b3b3b3'}
                          fontWeight='500'
                          textAlign={'center'}
                          color={'white'}
                          borderRadius={'5px'}
                          mt={'10px'}
                          fontSize={'18px'}
                          textTransform='none'
                        >
                          {[22, 36, 43, 44].includes(incident.type_user_id) ? (
                            (() => {
                              switch (incident.urgency) {
                                case 1:
                                  return "Out of rotation.";
                                case 2:
                                  return "In rotation.";
                                default:
                                  return "----";
                              }
                            })()
                          ) : (
                            (() => {
                              switch (incident.urgency) {
                                case 1:
                                  return "Urgent";
                                case 2:
                                  return "Not Urgent";
                                default:
                                  return "----";
                              }
                            })()
                          )}
                        </Badge>
                      ) : null}
                    </Flex>
                  </Flex>
                </Flex>
              );
            })}
          </Box>
        </Box>
      </Box>
      <Box className="cardtwotick">
        {showMessages && <Messages
          status='active'
          chatRoomId={selectedMessageId}
          onClose={closeMessages}
          openModal={handleUpdate}
          transferModal={handleTransfer}
          claimIncident={claimIncident}
          addReason={handleAddReason}
          UpdateAnydesk={handleUpdateAD}
          getWhatsAppLink={getWhatsAppLink}
          handleSweetAlert={handleSweetAlert}
          handleEndToTransfer={handleEndToTransfer}
        />
        }
        {openCloseIncidentModal && <EndIncident
          isOpen={openCloseIncidentModal}
          onClose={closeModalUpdate}
          incident={selectIncident}
          setting={setting}
          onIncidentUpdated={claimIncident}
          closeMessages={closeMessages}
        />
        }
        {openModalTransfer && <ModalTransfer
          isOpen={openModalTransfer}
          onClose={closeModalTransfer}
          incident={selectIncident}
          onIncidentUpdated={claimIncident}
          closeMessages={closeMessages}
        />
        }
        {openTicketReasonModal && <TicketReason
          isOpen={openTicketReasonModal}
          onClose={closeModalAddReason}
          incident={selectIncident}
          setting={setting}
          onIncidentUpdated={claimIncident}
          closeMessages={closeMessages}
        />
        }
        {openUpdateAnydesk && <UpdateAnydesk
          isOpen={openUpdateAnydesk}
          onClose={closeModalEditAnydesk}
          user={selectedUser}
          onAnydeskUpdated={claimIncident}
          closeMessages={closeMessages}
          setFlashing={null}
        />
        }
        {openEndToTransferModal &&
          <EndToTransfer
            isOpen={openEndToTransferModal}
            onClose={() => setOpenEndToTransferModal(false)}
            incident={selectIncident}
            setting={setting}
            onIncidentUpdated={claimIncident}
            closeMessages={closeMessages}
          />
        }
      </Box>
    </Box>
  );
}