import React, { useState, useEffect } from 'react';
import {
    TableContainer,
} from '@chakra-ui/react'
import axios from 'axios';
import ReportsListTSE from './tableTSE';
import TimeSheetClientFilters from './FiltersTSE';
import { useUserData } from 'interceptors/userAuth';


function UseTSE() {

    const [reportsD, setReportsD] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState('');
    const [totalPages, setTotalPages] = useState(1);
    const [users, setUsers] = useState([]);
    const [resetKey, setResetKey] = useState(0);
    const [userTypeSearch, setUserTypeSearch] = useState('');
    const [startDateFilter, setStartDateFilter] = useState(null);
    const [endDateFilter, setEndDateFilter] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingBar, setIsLoadingBar] = useState(false);
    const { user, fetchData } = useUserData();

    useEffect(() => {
        fetchData();
    }, []);

    const optionsUsers = users.map(users => ({
        value: users.id_user,
        label: users.user ? `${users.user.name} ${users.user.last_name}` : '',
    }));

    const filterOptions = (candidate, input) => {
        const words = input.toLowerCase().split(' ');
        return words.every(word =>
            candidate.label.toLowerCase().includes(word)
        );
    };

    const clearFilters = () => {

        setUserTypeSearch(null);
        setStartDateFilter('');
        setEndDateFilter('');
        setResetKey(resetKey + 1);
        setCurrentPage(1);
    }

    const fetchDataTS = async () => {

        try {

            setIsLoading(false);
            setReportsD([]);

            const response = await axios.get(`/reports/timesheetsClient?page=${currentPage}` +
                `${userTypeSearch ? `&operator=${encodeURIComponent(userTypeSearch)}` : ''}` +
                `${startDateFilter ? `&startDate=${encodeURIComponent(startDateFilter)}` : ''}` +
                `${endDateFilter ? `&endDate=${encodeURIComponent(endDateFilter)}` : ''}`
            );
            const responseData = response.data;
            setReportsD(responseData.data.data);
            setUsers(responseData.operators);
            setTotalPages(responseData.data.last_page);
            setTotal(responseData.data.total)
            setIsLoading(true);
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchDataTS();
    }, [currentPage, userTypeSearch, startDateFilter, endDateFilter, resetKey]);

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: 'transparent',
            borderRadius: '5px',
            borderColor: '#808080',
            marginLeft: '20px',
            fontSize: '16px',
            height: '40px',
            width: '220px',
            '&:hover': {
                borderColor: '#808080',
            },
            '&:focus-visible': {
                outline: 'none',
                boxShadow: 'none'
            },
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: 'white',
            width: '265px'
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#808080' : state.isFocused ? 'white' : '#808080',
            backgroundColor: state.isSelected ? '#f2f2f2' : state.isFocused ? '#bcc3f3' : 'white',
        }),
    };

    const handleSelectChangeF = (componentName, selectedOption) => {
        switch (componentName) {
            case 'user':
                setUserTypeSearch(selectedOption.value);
                break;
            default:
                break;
        }
    };



    const handleDownloadExcel = async () => {
        try {
            // Construir los parámetros de la URL dinámicamente
            const params = new URLSearchParams();
            let  name = '';

            if (userTypeSearch) {
                params.append('operator', userTypeSearch);
            }
            if (user.type_user_id === 41) {
                params.append('client', 19);
                name = 'Endicott';
            } else if (user.type_user_id === 42) {
                params.append('client', 3);
                name = 'Anserve';
            }
            if (startDateFilter) {
                params.append('startDate', startDateFilter);
            }
            if (endDateFilter) {
                params.append('endDate', endDateFilter);
            }

            const startDate = startDateFilter || '';
            const endDate = endDateFilter || '';
            const url = `/reports/timesheetC?${params.toString()}`;

            setIsLoadingBar(true);

            // Realizar la petición utilizando async/await
            const response = await axios.get(url, { responseType: 'blob' });

            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', `Timesheet_${name}_${startDate}_${endDate}.xlsx`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(downloadUrl); // Liberar el objeto URL
            setIsLoadingBar(false);
        } catch (error) {
            console.error('Error al descargar el archivo Excel:', error);
        } finally {
            setIsLoadingBar(false);
        }
    };

    return (
        <TableContainer>
            <TimeSheetClientFilters
                optionsUsers={optionsUsers}
                userTypeSearch={userTypeSearch}
                handleSelectChangeF={handleSelectChangeF}
                setCurrentPage={setCurrentPage}
                customStyles={customStyles}
                resetKey={resetKey}
                filterOptions={filterOptions}
                startDateFilter={startDateFilter}
                setStartDateFilter={setStartDateFilter}
                endDateFilter={endDateFilter}
                setEndDateFilter={setEndDateFilter}
                clearFilters={clearFilters}
                isLoadingBar={isLoadingBar}
                handleDownloadExcel={handleDownloadExcel}
            />
            <ReportsListTSE
                reportsD={reportsD}
                isLoading={isLoading}
                total={total}
                currentPage={currentPage}
                totalPages={totalPages}
            />

        </TableContainer>

    )
}
export default UseTSE;
