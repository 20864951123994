import React, { useState, useEffect } from 'react';
import {
    TableContainer, Button, Flex
} from '@chakra-ui/react'
import axios from 'axios';
import QAsTable from './table';
import UpdateQAFormats from '../update';

function UseQA5_1({ navigate }) {
    const [QA, setQA] = useState([]);
    const [loading, setLoading] = useState(false);
    const [ModalUpdateIsOpen, setModalUpdateIsOpen] = useState(false);
    const [selectQA, setSelectQA] = useState(null);
    const [flashing, setFlashing] = useState(false);

    const fetchData = async () => {
        try {
            const response = await axios.get(`/indexQAFormats`);
            const responseData = response.data;
            setQA(responseData.data);
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchData();
        if (flashing) {
            const intervalId = setInterval(() => {
                setFlashing(false);
            }, 7000);

            return () => {
                clearInterval(intervalId);
            };
        }
    }, [flashing]);

    const handleUpdate = async (QA) => {
        setSelectQA(QA);
        setModalUpdateIsOpen(true);

    };
    const closeModalUpdate = () => {
        setModalUpdateIsOpen(false);
    }

    const getRowBackground = (QA) => {
        if (selectQA && selectQA.id === QA.id) {
            if (flashing) {
                return '#fcd677';
            }
            return QA.id % 2 === 0 ? '#f2f2f2' : 'white';
        } else {
            return QA.id % 2 === 0 ? '#f2f2f2' : 'white';
        }
    };
    return (
        <TableContainer>
            <Flex
                direction='column'
                w='100%'
            >
                <Flex
                    mt={'1%'}
                    direction='column'
                    w='100%'
                    borderRadius="16px"
                >
                    <Flex align="center" justifyContent="flex-end" >
                        <Button class="buttonCancel" onClick={() => navigate("QALayouts")}>
                            Back
                        </Button>
                    </Flex>
                </Flex>
                <QAsTable
                    QA={QA}
                    getRowBackground={getRowBackground}
                    loading={loading}
                    fetchData={fetchData}
                    selectQA={selectQA}
                    setFlashing={setFlashing}
                    handleUpdate={handleUpdate}
                    ModalUpdateIsOpen={ModalUpdateIsOpen}
                    closeModalUpdate={closeModalUpdate}
                    navigate={navigate}
                />
            </Flex>
            {ModalUpdateIsOpen && (
                <UpdateQAFormats isOpen={ModalUpdateIsOpen} onClose={closeModalUpdate} QA={selectQA} onQAFormatsUpdated={fetchData} setFlashing={setFlashing} />)}

        </TableContainer>
    );
}
export default UseQA5_1;