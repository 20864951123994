import React, { useState } from "react";
import { Box, Flex, Text, Input } from "@chakra-ui/react";
import { MdEditDocument } from "react-icons/md";

function QALayouts({ navigate }) {

    const boxData = [
        { label: "FORMAT 5 IN 1", component: "UseQA5_1", client: 1 },
        { label: "FORMAT A1", component: "UseQA5_1", client: 2 },
        { label: "FORMAT AB", component: "UseQA5_1", client: 2 },
        { label: "FORMAT ANSERVE", component: "UseQA5_1", client: 2 },
        { label: "FORMAT ANSWER EXCELLENCE", component: "UseQA5_1", client: 2 },
        { label: "FORMAT ANSWER UNITED", component: "UseQA5_1", client: 2 },
        { label: "FORMAT ANSWERONE", component: "UseQA5_1", client: 2 },
        { label: "FORMAT ANSWER PRO", component: "UseQA5_1", client: 2 },
        { label: "FORMAT ANSWERQUICK", component: "UseQA5_1", client: 2 },
        { label: "FORMAT ANSWERTEL", component: "UseQA5_1", client: 2 },
        { label: "FORMAT ANTIETAM", component: "UseQA5_1", client: 2 },
        { label: "FORMAT API", component: "UseQA5_1", client: 2 },
        { label: "FORMAT BAME", component: "UseQA5_1", client: 2 },
        { label: "FORMAT CALL 24", component: "UseQA5_1", client: 2 },
        { label: "FORMAT CALL MANAGEMENT RESOURCES", component: "UseQA5_1", client: 2 },
        { label: "FORMAT CCC", component: "UseQA5_1", client: 2 },
        { label: "FORMAT COMBINED", component: "UseQA5_1", client: 2 },
        { label: "FORMAT DIRECTLINE", component: "UseQA5_1", client: 2 },
        { label: "FORMAT EDWARDS AS", component: "UseQA5_1", client: 2 },
        { label: "FORMAT EMERALD", component: "UseQA5_1", client: 2 },
        { label: "FORMAT ENDICOTT", component: "UseQA5_1", client: 2 },
        { label: "FORMAT FOCUS", component: "UseQA5_1", client: 2 },
        { label: "FORMAT GLOBAL", component: "UseQA5_1", client: 2 },
        { label: "FORMAT HELLO INC", component: "UseQA5_1", client: 2 },
        { label: "FORMAT ICG", component: "UseQA5_1", client: 2 },
        { label: "FORMAT KEYSTONE", component: "UseQA5_1", client: 2 },
        { label: "FORMAT MEDCONNECT", component: "UseQA5_1", client: 2 },
        { label: "FORMAT METRO MSSG", component: "UseQA5_1", client: 2 },
        { label: "FORMAT NATIONWIDE", component: "UseQA5_1", client: 2 },
        { label: "FORMAT PAS", component: "UseQA5_1", client: 2 },
        { label: "FORMAT SOUTHWEST", component: "UseQA5_1", client: 2 },
        { label: "FORMAT SPEEDEZ", component: "UseQA5_1", client: 2 },
        { label: "FORMAT SUNSHINE", component: "UseQA5_1", client: 2 },
        { label: "FORMAT TEL US", component: "UseQA5_1", client: 2 },
        { label: "FORMAT TRICITIES", component: "UseQA5_1", client: 2 },
        { label: "FORMAT VOICENATION", component: "UseQA5_1", client: 2 },
    ];

    const [searchTerm, setSearchTerm] = useState("");
    const [filteredData, setFilteredData] = useState(boxData);

    const handleSearch = (e) => {
        const value = e.target.value.toLowerCase();
        setSearchTerm(value);
        setFilteredData(
            boxData.filter((item) =>
                item.label.toLowerCase().includes(value)
            )
        );
    };

    return (
        <Flex direction="column" mt={"2%"} width={"100%"} align="center">
            <Input
                placeholder="Search format..."
                value={searchTerm}
                onChange={handleSearch}
                mb={"2%"}
                width="80%"
                borderRadius="5px"
                bg="white"
                color="black"
                _placeholder={{ color: "gray.500" }}
            />
            
            {filteredData.map((item, index) => (
                <Box
                    key={index}
                    border={"1px"}
                    mb={"2%"}
                    p={"4"}
                    display="flex"
                    borderRadius={"5px"}
                    justifyContent="space-between"
                    alignItems="center"
                    bg={"#424ea8"}
                    color="white"
                    width="80%"
                >
                    <Text
                        fontSize={"110%"}
                        cursor="pointer"
                        _hover={{ textDecoration: "underline" }}
                        onClick={() => navigate(item.component, item.client)}
                    >
                        {item.label}
                    </Text>
                    <MdEditDocument size={"1.5rem"} />
                </Box>
            ))}
        </Flex>
    );


}

export default QALayouts;
