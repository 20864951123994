import React, { useState } from 'react';
import {
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter,
    Button, Flex, Box, Text, FormControl, useToast
} from '@chakra-ui/react';
import { EditIcon, CloseIcon } from '@chakra-ui/icons'
import { FaUser } from "react-icons/fa";
import axios from 'axios';
import Select2 from 'react-select'


const ClientSupervisors = ({ isOpen, onClose, user, onUserUpdate, clientsOptions, setFlashing }) => {

    const toast = useToast();

    const [loading, setLoading] = useState(false);

    const allClientsOption = {
        value: 'selectAll',
        label: 'Select All',
    };

    const userClientsOptions = Array.isArray(user.supervisor_clients)
        ? user.supervisor_clients.map(client => ({
            value: client.id_client,
            label: client.client.name,
        }))
        : [];

    const selectedClientIds = userClientsOptions.map(option => option.value);

    const isAllSelected = selectedClientIds.length === clientsOptions.length;
    const initialSelectedOptions = isAllSelected
        ? [allClientsOption, ...userClientsOptions]
        : userClientsOptions;

    const [selectClient, setSelectClient] = useState(initialSelectedOptions);

    const handleClientChange = selectedOptions => {
        if (selectedOptions.some(option => option.value === 'selectAll')) {
            setSelectClient([allClientsOption, ...clientsOptions]);
        } else {
            setSelectClient(selectedOptions);
        }
    };


    const handleUpdate = async (e) => {
        setLoading(true);
        e.preventDefault();

        const selectedIds = selectClient.map((option) => option.value);

        const formSubmitData = {
            data: selectedIds,
        };

        try {
            const response = await axios.post(
                `/supervisors/update/${user.id}`,
                formSubmitData
            );

            onUserUpdate();
            setFlashing(formSubmitData);
            toast({
                title: "Client Updated",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            console.error(error);

            toast({
                title: "Error Updating Client",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setLoading(false);
        }
    };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            border: '2px solid transparent',
            borderRadius: '5px',
            width: '280px',
            '&:hover': {
                border: '2px solid transparent',
            },
            '&:focus-visible': {
                outline: 'none',
                boxShadow: 'none'
            },
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: 'white',
            width: '265px'
        }),
        option: (provided, state) => ({
            ...provided,
            color: '#808080',
        }),
    };


    if (!user) {
        return null;
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'3xl'} isCentered closeOnOverlayClick={false}
            closeOnEsc={false}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} color={'#1c2147'} mt={'3%'} fontWeight={'bold'} fontSize={'35px'}>
                    <Flex direction={'column'}>
                        <Text textAlign={'center'}>
                            Add Client
                        </Text>
                        <Flex style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} color={'#7080fc'} fontWeight={'bold'} fontSize={'22px'}>
                            <FaUser size={'18px'} />
                            <span style={{ marginLeft: '8px' }}>
                                <span style={{ marginRight: '8px', color: '#7080fc' }}>
                                    {user.name.split(' ')[0]} {user.last_name.split(' ')[0]}
                                </span>
                            </span>
                        </Flex>
                    </Flex>
                </ModalHeader>

                <ModalBody>
                    <Flex flexDirection={"row"} justifyContent={"center"} alignItems={"center"}>
                        <FormControl isInvalid={!selectClient} width={"max-content"}  >
                            <Select2
                                isMulti
                                options={[allClientsOption, ...clientsOptions]}
                                value={isAllSelected ? [allClientsOption, ...clientsOptions] : selectClient}
                                onChange={handleClientChange}
                                styles={customStyles}
                            />
                        </FormControl>
                        <Button class="buttonUpUs" onClick={handleUpdate}>
                            <EditIcon />
                        </Button>
                    </Flex>
                </ModalBody>
                <ModalFooter justifyContent="center" pt={"5%"} pb={"10%"}>
                    <Button class="buttonCancel" onClick={onClose} >
                        Close <CloseIcon ml={2} />
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default ClientSupervisors;