import {
    Flex, Button, Text, Input, Box, Select
} from "@chakra-ui/react";
import React from 'react';
export default function ShiftSection({ schedule, formDataShift2, handleChangeShift2, handleUpdateShift2 }) {

    return (
        <Flex borderLeft={"4px"} borderRight={"4px"} height={"80%"} pl={'1'} ml={'0.5%'} mt={'3%'} width={"30%"} flexDirection={"column"} borderColor={"#f2f2f2"} alignItems={"center"} justifyContent={"center"}>
            <Flex>
                <Flex >
                <Flex direction={'column'} alignItems={'center'} bg={'#f2f2f2'} mb={'5%'} height={'600px'} width={'200px'} borderRadius={'20px'} >                        
                    <Flex display={'flex'} mt={'20%'} justifyContent={'center'} direction={'column'} >
                            <Text justifyContent={"center"} color={'#1c2147'} fontWeight={'bold'} fontSize={'20px'} bg={'white'} width={'110%'} textAlign={'center'} borderRadius={'10px'}>
                                Previous data!
                            </Text>
                            <Text color={'#1c2147'} fontWeight={'bold'} fontSize={'20px'} textAlign={'center'} mt={'35%'}>
                                Start time
                            </Text>
                            <Text color={'#808080'} fontWeight={'bold'} fontSize={'20px'} textAlign={'center'}  >
                                <span><span style={{ color: '#808080' }}>{schedule.date_start ? schedule.date_start : '---'}  </span></span>
                            </Text>
                            <Text color={'#1c2147'} fontWeight={'bold'} fontSize={'20px'} textAlign={'center'} mt={'35%'}>
                                End time
                            </Text>
                            <Text color={'#808080'} fontWeight={'bold'} fontSize={'20px'} textAlign={'center'} >
                                <span><span style={{ color: '#808080' }}>{schedule.date_end ? schedule.date_end : '---'}  </span></span>
                            </Text>
                            <Text color={'#1c2147'} fontWeight={'bold'} fontSize={'20px'} textAlign={'center'} mt={'35%'}>
                                Shift status
                            </Text>
                            <Text color={'#808080'} fontWeight={'bold'} fontSize={'20px'} textAlign={'center'}  >
                                {(() => {
                                    switch (schedule.option) {
                                        case 1:
                                            return "---";
                                        case 2:
                                            return "On time";
                                        case 3:
                                            return "Late";
                                        case 4:
                                            return "Clock out";
                                        case 5:
                                            return "Supervisor"
                                        case 6:
                                            return "Call out"
                                        default:
                                            return "---";;
                                    }
                                })()}
                            </Text>

                        </Flex>
                    </Flex>
                </Flex>
                <Flex direction={'column'} alignItems={'center'}  height={"max-content"} width={"215px"}>
                    <Flex display={'flex'} mt={'30px'} justifyContent={'center'}>
                        <Text color={'#1c2147'} fontWeight={'bold'} fontSize={'40px'} >
                            Shift
                        </Text>
                    </Flex>

                    <Flex display={'flex'}  >
                        <Flex display={'flex'} justifyContent={'center'} mt={'8px'} direction={'column'}>
                            {schedule.time !== null ? (
                                <>
                                    <Text textAlign={'center'} mb={'5px'} color={'#1c2147'} mt={"20px"} fontWeight={'bold'}>
                                        New start time
                                    </Text>
                                    <Input
                                        type='time'
                                        bg='#f2f2f2' borderColor={"white"}
                                        color={'#808080'}
                                        value={formDataShift2.date_start}
                                        name='date_start'
                                        mr={'10px'}
                                        onChange={handleChangeShift2}
                                    />
                                    <Text textAlign={'center'} mb={'5px'} color={'#1c2147'} fontWeight={'bold'} mt={'30px'}>
                                        New end time
                                    </Text>
                                    <Input
                                        type='time'
                                        bg='#f2f2f2' borderColor={"white"}
                                        color={'#808080'}
                                        value={formDataShift2.date_end}
                                        name='date_end'
                                        mr={'10px'}
                                        onChange={handleChangeShift2}
                                    />
                                    <Text textAlign={'center'} mb={'5px'} mt={'30px'} color={'#1c2147'} fontWeight={'bold'}>
                                        New shift status
                                    </Text>
                                    <Select bg='#f2f2f2' borderColor={"white"} width={'auto'} color={'#424ea8'} value={formDataShift2.option} onChange={handleChangeShift2} name='option' textAlign={'center'} fontWeight={'bold'}>
                                        <option value="">Status</option>
                                        <option value="1">---</option>
                                        <option value="2">On time</option>
                                        <option value="3">Late</option>
                                        <option value="4">Clock out</option>
                                        <option value="5">Supervisor</option>
                                        <option value="6">Call out</option>
                                    </Select>


                                </>
                            ) : (
                                <Box width={'200px'} >
                                    <Text bg={"#f2f2f2"} fontSize={"24px"} mt={"20px"} fontWeight={"bold"} textAlign={"center"}>
                                        No data
                                    </Text>
                                </Box>
                            )}
                        </Flex>
                    </Flex>

                    <Button
                        width={"max-content"}
                        p={4}
                        bg={'#b9ce67'}
                        borderRadius={'5px'}
                        _hover={{ background: "#8aad01", }}
                        color={'white'}
                        onClick={handleUpdateShift2}
                        mt={'20%'}

                    >
                        Update
                    </Button>
                </Flex>


            </Flex>


        </Flex>
    );
} 