import React from 'react';
import {
  Table, Thead, Tbody, Tr, Th, Td,
  Button, ButtonGroup, Text, Flex, Box, Badge,
  Menu, MenuItem, MenuList, MenuButton
} from '@chakra-ui/react'
import { SettingsIcon, EditIcon, DeleteIcon } from '@chakra-ui/icons'
import { FaNotesMedical } from "react-icons/fa";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import { FaNoteSticky } from "react-icons/fa6";

function MedicalNotesgList({ total, currentPage, medicalNotes, setCurrentPage, totalPages, handleUpdate, handleDelete, handleNotes, user }) {


  const getColorStatus = (option) => {
    const colorMap = {
      1: '#fabb1c',
      2: '#7080fc',
      3: '#a2bd34',
      4: '#fc7466'
    };
    return colorMap[option] || '#8a6dce';
  };


  return (
    <>
      <Table size={'sm'} mt={"2%"}>
        <Thead bg={"white"}>
          <Tr>
            <Th textAlign={'center'}>Name</Th>
            {[1, 2, 3, 4, 5, 6, 7].includes(user.type_user_id) ? (
              <Th textAlign={'center'}>Audits</Th>
            ) : null}
            <Th textAlign={'center'}>Client</Th>
            <Th textAlign={'center'}>Start Date</Th>
            <Th textAlign={'center'}>End Date</Th>
            <Th textAlign={'center'}>Status</Th>
            <Th textAlign={'center'}>Return Date</Th>
            <Th textAlign={'center'}>Notes</Th>
            <Th textAlign={'center'}>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {medicalNotes && medicalNotes.length > 0 ? (
            medicalNotes.map((item, index) => (
              <Tr key={item.id} className={index % 2 === 0 ? 'white-row' : 'gray-row'}>
                <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                  {item.users.name.split(' ')[0]} {item.users.last_name.split(' ')[0]}
                </Td>
                {[1, 2, 3, 4, 5, 6, 7].includes(user.type_user_id) ? (
                  <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                    <Flex direction={'column'}>
                      <Text>
                        Created at:
                      </Text>
                      <Text>
                        {item.created_at_formatted}
                      </Text>
                      <Text mt={'5%'}>
                        Updated at:
                      </Text>
                      <Text>
                        {item.updated_at_formatted}
                      </Text>
                    </Flex>
                  </Td>
                ) : null}
                <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                  <Badge color={"white"} bg={'#424ea8'} borderRadius={'5px'} width={'100px'} fontSize={'10px'} whiteSpace={'pre-wrap'}>
                    {item.clients.name}
                  </Badge>
                </Td>
                <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                  {item.date_start}
                </Td>
                <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                  {item.date_end}
                </Td>
                <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                  <Badge
                    color='white'
                    bg={getColorStatus(item.status)}
                    borderRadius={'5px'}
                    width={'120px'}
                    fontSize={'10px'}
                    whiteSpace={'pre-wrap'}
                  >

                    {(() => {
                      switch (item.status) {
                        case 1:
                          return "In review";
                        case 2:
                          return "Proof of Call out";
                        case 3:
                          return "Approved";
                        case 4:
                          return "Nonvalid";
                        default:
                          return "---";
                      }
                    })()}
                  </Badge>
                </Td>
                <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                  {item.date_return}
                </Td>
                <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                  <Flex justifyContent={'center'} cursor={'pointer'} onClick={() => handleNotes(item)}>

                    {(item.countNotes !== 0) ? (
                      <>
                        <Box>
                          <FaNoteSticky />
                        </Box>
                        <Text ml={'5%'}>
                          {item.countNotes}
                        </Text>
                      </>
                    ) :
                      <Box>
                        <FaNoteSticky />
                      </Box>
                    }

                  </Flex>
                </Td>
                <Td textAlign={'center'}>
                  <Menu>
                    <MenuButton borderRadius={"5px"} as={Button} _active={{ background: "#808080", color: 'white' }} bg="#1c2147" color={"white"} _hover={{ background: "#808080", color: 'white' }}>
                      <SettingsIcon pb={"2px"} />
                    </MenuButton>
                    <MenuList>
                      <MenuItem isDisabled>
                        <div style={{ fontWeight: 'bold', textAlign: 'center', width: "100%" }}>Actions</div>
                      </MenuItem>
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 37].includes(user.type_user_id) ? (
                        <MenuItem
                          display={"flex"}
                          justifyContent={"center"}
                          flexDirection={"column"}
                          alignItems="center">
                          <Button
                            borderRadius={"5px"}
                            bg={"#f2f2f2"}
                            color={"#1c2147"}
                            _hover={{ background: "#7080fc", color: 'white' }}
                            onClick={() => handleUpdate(item)}
                            width={"100%"}
                            justifyContent="space-between"
                            alignItems="center">
                            <Text flex={1} textAlign="center">
                              Edit
                            </Text>
                            <EditIcon ml={2} verticalAlign="middle" />
                          </Button>
                        </MenuItem>
                      ) : null}
                      <MenuItem>
                        <Button
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          bg="#f2f2f2"
                          _hover={{ background: "#ff9fb8", color: 'white' }}
                          color="#1c2147"
                          width="100%"
                          borderRadius={'5px'}
                          onClick={() => handleNotes(item)}
                        >
                          <Text
                            flex={1}
                            textAlign="center"
                          >
                            Notes
                          </Text>
                          <FaNoteSticky ml={2} />
                        </Button>
                      </MenuItem>
                      {[1, 2, 3, 4, 5, 6, 7, 37].includes(user.type_user_id) ? (
                        <MenuItem
                          display={"flex"}
                          justifyContent={"center"}
                          flexDirection={"column"}
                          alignItems="center">
                          <Button
                            borderRadius={"5px"}
                            bg={"#f2f2f2"}
                            color='#1c2147'
                            _hover={{ background: "#fc7466", color: 'white' }}
                            onClick={() => handleDelete(item)}
                            width={"100%"}
                            justifyContent="space-between"
                            alignItems="center">
                            <Text flex={1} textAlign="center">
                              Delete
                            </Text>
                            <DeleteIcon ml={2} verticalAlign="middle" />
                          </Button>
                        </MenuItem>
                      ) : null}
                    </MenuList>
                  </Menu>
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Th colSpan={'9'} >
                <Text
                  color='#1c2147'
                  fontSize={"25px"}
                  textAlign={"center"}
                  paddingTop={"15px"}
                  paddingBottom={"15px"}
                  bg={"#f2f2f2"}
                  textTransform={'none'}
                  fontFamily={'DM Sans'}
                  letterSpacing="-1px"
                >
                  No data
                </Text>
              </Th>
            </Tr>
          )}
        </Tbody>
      </Table>
      <Box width={"100%"} display={"flex"} mt={"30px"}>
        <Flex display={'flex'} justifyContent={'flex-start'} width={"max-content"} pb={"10px"} mt={"8px"}  >
          {total === 0 ? (
            <>
              <Flex bg={'#f2f2f2'} width={'250px'} display={'flex'} justifyContent={'center'}>
                <Box mt={'5px'} mr={2}>
                  <FaNotesMedical color={"#1c2147"} fontWeight={'bold'} />
                </Box>
                <Text fontSize={'14px'} mt={1} color={"#1c2147"} fontWeight={'bold'}>
                  No medical notes
                </Text>


              </Flex>
            </>
          ) : (
            <>
              <Flex bg={'#f2f2f2'} width={'auto'} display={'flex'} justifyContent={'center'}>
                <Text fontSize={'14px'} mt={1} mr={3} color={"#1c2147"} fontWeight={'bold'} ml={'20px'}>
                  {total}
                </Text>
                <Box mt={'5px'} mr={1}>
                  <FaNotesMedical color={"#1c2147"} fontWeight={'bold'} />
                </Box>
                <Text fontSize={'14px'} mt={1} color={"#1c2147"} fontWeight={'bold'} mr={3}>
                  {total === 1 ? 'Note' : 'Notes'}
                </Text>
              </Flex>
            </>
          )}
        </Flex>
        <ButtonGroup isAttached variant="outline" ml={"26%"} justifyContent={'center'} display={'flex'} width={"max-content"} >
          <Button
            borderRadius={'0px'}
            onClick={() => setCurrentPage(currentPage - 1)}
            isDisabled={currentPage === 1}
            bg="#808080" color={"white"}
            _hover={{ background: "#808080", }}
            _active={{ background: "#808080", }}
          ><BsArrowLeftShort fontSize="20px" />
          </Button>
          <Text class="btnTextPages">{currentPage} of {totalPages}</Text>
          <Button
            borderRadius={'0px'} ml={4}
            onClick={() => setCurrentPage(currentPage + 1)}
            isDisabled={currentPage === totalPages}
            bg="#808080" color={"white"}
            _hover={{ background: "#808080", }}
            _active={{ background: "#808080", }}
          ><BsArrowRightShort fontSize="20px" />
          </Button>
        </ButtonGroup>
      </Box>
    </>

  );
}
export default MedicalNotesgList; 