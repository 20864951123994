import axios from 'axios';
import React, { useEffect, useState, useRef } from "react";
import {
    Button, FormControl, Flex, useToast, Input, Text, Textarea,
    Box, FormErrorMessage, Modal, ModalBody, ModalOverlay, Heading,
    ModalContent
} from '@chakra-ui/react';
import { EditIcon, CloseIcon } from '@chakra-ui/icons'
import { ImAttachment } from "react-icons/im";
import Select2 from 'react-select'

function ChangeScheduleRequestModal({ isOpen, onClose, }) {

    const toast = useToast();
    const [showForm, setShowForm] = useState(false);
    const [showAdvice, setShowAdvice] = useState(true);
    const [userList, setUserList] = useState([]);
    const [client, setClient] = useState([]);
    const [resetKey, setResetKey] = useState(0);
    const [userSearch, setUserSearch] = useState('');
    const [clientSearch, setClientSearch] = useState('');
    const [typeRequestSearch, setTypeRequestSearch] = useState('');
    const [formErrors, setFormErrors] = useState({});
    const [showSpeedTest, setShowSpeedTest] = useState(false);
    const [imageFile, setImageFile] = useState(null);
    const fileInputRef = useRef(null);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        reason: '',
    });

    const clearForm = () => {
        setFormData({
            name: '',
            email: '',
            reason: '',
        });
        setFormErrors({});
        setUserSearch(null);
        setClientSearch(null);
        setTypeRequestSearch(null);
        setShowSpeedTest(false);
        setResetKey(resetKey + 1);
    };


    const showFormButton = () => {
        setShowForm(true);
        setShowAdvice(false);
    };

    const handleOptions = async () => {
        try {
            const response = await axios.get(`/settings/options`);
            const responseData = response.data;
            setUserList(responseData.userList);
            setClient(responseData.client);
        } catch (error) {
            console.error('Error fetching employee details:', error);
        }
    };

    useEffect(() => {
        handleOptions();
    }, []);

    const optionClients = client.map(client => ({
        value: client.name,
        label: client.name,
    }));

    const optionsUsers = userList.map(userList => ({
        value: `${userList.name} ${userList.last_name}`,
        label: `${userList.name} ${userList.last_name}`,
    }));

    const typeRequest = [
        { value: 'Relocation', label: 'Relocation' },
        { value: 'Availability - schedule mistake', label: 'Availability - schedule mistake' },
        { value: 'Home office request', label: 'Shift change request' },
        { value: 'Reduced hours', label: 'Reduced hours' },
    ];


    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: 'white',
            borderRadius: '5px',
            borderColor: '#808080',
            fontSize: '16px',
            height: '40px',
            width: '375px',
            '&:hover': {
                borderColor: '#808080',
            },
            '&:focus-visible': {
                outline: 'none',
                boxShadow: 'none'
            },
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: 'white',
            width: '265px'
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#808080' : state.isFocused ? 'white' : '#808080',
            backgroundColor: state.isSelected ? '#f2f2f2' : state.isFocused ? '#bcc3f3' : 'white',
        }),
    };

    const filterOptions = (candidate, input) => {
        const words = input.toLowerCase().split(' ');
        return words.every(word =>
            candidate.label.toLowerCase().includes(word)
        );
    };

    const handleSelectChangeF = (componentName, selectedOption) => {
        switch (componentName) {
            case 'client':
                setClientSearch(selectedOption.value);
                break;
            case 'user':
                setUserSearch(selectedOption.value);
                break;
            default:
                break;
        }
    };

    const handleTypeRequest = (selectedOption) => {
        setTypeRequestSearch(selectedOption);

        if (selectedOption && selectedOption.value === 'Relocation') {
            setShowSpeedTest(true);
        } else {
            setShowSpeedTest(false);
        }
    };

    const handleSubmit = async () => {

        const selectTypeRequest = typeRequestSearch.value;

        const formDataSend = new FormData();
        formDataSend.append('name', formData.name);
        formDataSend.append('typeRequest', selectTypeRequest);
        formDataSend.append('email', formData.email);
        formDataSend.append('reason', formData.reason);
        formDataSend.append('client', clientSearch);
        formDataSend.append('supervisor', userSearch);

        if (imageFile) {
            formDataSend.append('image', imageFile?.file || '');
        }

        try {
            const response = await axios.post('/changeScheduleRequests', formDataSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });

            toast({
                title: 'Change schedule request',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            clearForm();
            setFormErrors({});
            onClose();
        } catch (error) {
            toast({
                title: "Schedule request error",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            console.error('Error Request Send:', error);
            setFormErrors(error.response.data.errors);
        }
    };

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    const removeImage = () => {
        setImageFile(null)
        setFormErrors({});
    };

    const handleImageChange = (e, imageFile) => {
        const file = e?.target?.files?.[0] || imageFile?.file;

        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setImageFile({
                file,
                previewUrl: imageUrl,
            });
        }
    };



    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'5xl'} closeOnOverlayClick={false} closeOnEsc={false}>
            <ModalOverlay />
            <ModalContent mt={"5%"} background={"#f2f2f2"} borderRadius={"30px"}>
                <ModalBody>
                    <Heading style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} color={'#1c2147'} mt={'5%'} mb={'4%'} fontWeight={'bold'} fontSize={'35px'}>
                        Change Schedule Request
                    </Heading>
                    <Flex direction={'column'}>
                        {showAdvice && (
                            <Flex direction={'column'} alignItems={'center'} display={'flex'} width={'100%'} mb={'5%'}>
                                <Text fontWeight={'bold'} fontSize={'25px'} mb={'2%'}>
                                    Welcome
                                </Text>
                                <Text>
                                    Important: please be advised that the minimum time to get and update for your request is three (3)
                                    weeks.
                                </Text>
                                <Text>
                                    This allows us to effectively evaluate the request and the client's staffing needs before
                                    approving it.
                                </Text>
                                <Flex width={'50%'} justifyContent={'center'} mt={'2%'}>
                                    <Button class="buttonCreate" onClick={showFormButton} >
                                        I've read and i agree
                                    </Button>
                                </Flex>
                            </Flex>
                        )}
                        {showForm && (
                            <Flex mt={4} direction={'column'} width={'90%'} ml={'10%'}>
                                <Flex display={'flex'}>
                                    <Flex direction={'column'} width={'80%'} >
                                        <Text color={'gray.400'} mb={'1%'} fontWeight={'bold'}>
                                            Name
                                        </Text>
                                        <Text color={'gray.400'} mb={'1%'}>
                                            Please add your name and last name.
                                        </Text>
                                        <FormControl isInvalid={!!formErrors.name} w='380px' >
                                            <Input
                                                placeholder="Full Name"
                                                className='custom-placeholderInputs'
                                                background="white"
                                                value={formData.name}
                                                onChange={handleChange}
                                                name='name'
                                            />
                                            {formErrors.name && (
                                                <FormErrorMessage mb={"2%"}>{formErrors.name}</FormErrorMessage>
                                            )}
                                        </FormControl>
                                    </Flex>

                                    <Flex direction={'column'} width={'100%'}>
                                        <Text color={'gray.400'} mb={'1%'} fontWeight={'bold'}>
                                            Team:
                                        </Text>
                                        <Text color={'gray.400'} mb={'1%'}>
                                            If not found, please reach ot to HR.
                                        </Text>
                                        <FormControl isInvalid={!!formErrors.client} w='400px' >
                                            <Select2
                                                options={optionClients}
                                                value={optionClients.find(option => option.value === clientSearch)}
                                                onChange={(selectedOption) => {
                                                    handleSelectChangeF('client', selectedOption);
                                                }}
                                                styles={customStyles}
                                                placeholder="Select client"
                                                key={`clientSelect-${resetKey}`}
                                            />
                                            {formErrors.client && (
                                                <FormErrorMessage mb={"2%"}>{formErrors.client}</FormErrorMessage>
                                            )}
                                        </FormControl>
                                    </Flex>
                                </Flex>
                                <Flex>
                                    <Flex direction={'column'} width={'80%'} >
                                        <Text color={'gray.400'} mb={'1%'} fontWeight={'bold'}>
                                            Supervisor:
                                        </Text>
                                        <Text color={'gray.400'} mb={'1%'}>
                                            If not found, please reach ot to HR.
                                        </Text>
                                        <FormControl isInvalid={!!formErrors.supervisor} w='400px'>
                                            <Select2
                                                options={optionsUsers}
                                                value={optionsUsers.find(option => option.value === userSearch)}
                                                onChange={(selectedOption) => {
                                                    handleSelectChangeF('user', selectedOption);
                                                }}
                                                styles={customStyles}
                                                placeholder="Select supervisor"
                                                key={`agentSelect-${resetKey}`}
                                                isSearchable
                                                filterOption={filterOptions}
                                            />
                                            {formErrors.supervisor && (
                                                <FormErrorMessage mb={"2%"}>{formErrors.supervisor}</FormErrorMessage>
                                            )}
                                        </FormControl>
                                    </Flex>

                                    <Flex direction={'column'} width={'100%'}>
                                        <Text color={'gray.400'} mb={'1%'} fontWeight={'bold'} mt={'5.5%'}>
                                            Type of request:
                                        </Text>
                                        <FormControl isInvalid={!!formErrors.typeRequest} w='400px'>
                                            <Select2
                                                options={typeRequest}
                                                value={typeRequestSearch}
                                                onChange={handleTypeRequest}
                                                styles={customStyles}
                                                placeholder="Select type request"
                                                key={`typeRequestSelect-${resetKey}`}
                                            />
                                            {formErrors.typeRequest && (
                                                <FormErrorMessage mb={"2%"}>{formErrors.typeRequest}</FormErrorMessage>
                                            )}
                                        </FormControl>
                                    </Flex>
                                </Flex>
                                <Flex>
                                    <Flex direction={'column'} width={'90%'} mr={'1%'} mt={'1%'}>
                                        <Text color={'gray.400'} mb={'1%'} fontWeight={'bold'}>
                                            Email
                                        </Text>
                                        <Text color={'gray.400'} mb={'1%'}>
                                            Please provide your DP email.
                                            You will receive response to your request to this email, please make sure it is correct.
                                        </Text>
                                        <FormControl isInvalid={!!formErrors.email} w='400px'>
                                            <Input
                                                width={"200%"}
                                                placeholder="Dedicated people email"
                                                className='custom-placeholderInputs'
                                                background="white"
                                                value={formData.email}
                                                onChange={handleChange}
                                                name='email'
                                            />
                                            {formErrors.email && (
                                                <FormErrorMessage mb={"2%"}>{formErrors.email}</FormErrorMessage>
                                            )}
                                        </FormControl>
                                    </Flex>
                                </Flex>
                                <Flex>
                                    <Flex direction={'column'} width={'90%'}>
                                        <Text color={'gray.400'} mb={'1%'} fontWeight={'bold'} mt={'1%'}>
                                            Reason of request
                                        </Text>
                                        <FormControl isInvalid={!!formErrors.reason} w='400px'>
                                            <Textarea
                                                width={"200%"}
                                                background="white"
                                                value={formData.reason}
                                                onChange={handleChange}
                                                name='reason'
                                            />
                                            {formErrors.reason && (
                                                <FormErrorMessage mb={"2%"}>{formErrors.reason}</FormErrorMessage>
                                            )}
                                        </FormControl>
                                    </Flex>
                                </Flex>
                                {showSpeedTest && (
                                    <Flex>
                                        <Flex direction={'column'} width={'100%'}>
                                            <Text color={'gray.400'} mb={'1%'} fontWeight={'bold'} mt={'1%'}>
                                                Speed test
                                            </Text>
                                            <Text color={'gray.400'} mb={'1%'}>
                                                If you are making home office request is needed to attach one internet speed test
                                            </Text>
                                            <Input
                                                type="file"
                                                id="image"
                                                style={{ display: 'none' }}
                                                accept="image/*"
                                                onChange={(e) => handleImageChange(e, null)}
                                                ref={fileInputRef} />
                                            <Button as="label" htmlFor="image" cursor="pointer"
                                                bg={'linear-gradient(180deg, rgba(66, 78, 168, 1.0) 50%, rgba(101, 112, 199, 1.0) 50%)'}
                                                color={"white"} _hover={{ background: "#808080", color: 'white' }}
                                                width={"95%"}
                                            >
                                                <Text>
                                                    Attach the speed test
                                                </Text>
                                                <Box ml={2}>
                                                    <ImAttachment size={'20px'} />
                                                </Box>
                                            </Button>
                                        </Flex>
                                    </Flex>
                                )}

                                {imageFile && (
                                    <Flex
                                        display={'flex'}
                                        direction={'column'}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                        mr={'5%'} position={"relative"}
                                        mt={'2%'}
                                    >
                                        <img src={imageFile.previewUrl} alt="Preview" style={{ maxWidth: '100px', maxHeight: '100px' }} />
                                        <Text>
                                            {imageFile.file.name.length > 20
                                                ? `${imageFile.file.name.substring(0, 20)}…`
                                                : imageFile.file.name
                                            }
                                        </Text>
                                        <Button borderRadius={"5px"} cursor={"pointer"} px={2} onClick={removeImage} _hover={{ background: "transparent" }} position={"absolute"} top={-2} right={-4}><CloseIcon boxSize={"15px"} /></Button>
                                    </Flex>
                                )}
                                <Flex display={'flex'} justifyContent={'center'} mt={'2%'} marginBottom={'3%'}>
                                    <Button class="buttonCreate">  {/* onClick={handleSubmit} */}
                                        Submit <EditIcon ml={2} />
                                    </Button>
                                    <Button class="buttonCancel" onClick={() => {
                                        onClose();
                                        clearForm();
                                    }}>
                                        Cancel <CloseIcon ml={2} />
                                    </Button>
                                </Flex>
                            </Flex>
                        )}
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

export default ChangeScheduleRequestModal;
