import {
    Menu, MenuButton, MenuItem, MenuList,
    Button, Text,
} from '@chakra-ui/react'
import React from 'react';
import { InfoOutlineIcon, SettingsIcon, EditIcon, UnlockIcon, DeleteIcon } from '@chakra-ui/icons'
import { TbCalendarPlus } from "react-icons/tb";
import { MdRestore } from "react-icons/md";

const UserButtons = ({ userAuth, handleInfo, handleUpdate, handleUpdateAD, handleUpdateClient, handleUpdateTU, handleCreateSchedule, handleReset, handleDelete, loading, user, handleChangePassword, handleRestore }) => {

    return (
        <>
            <Menu>
                <MenuButton as={Button} bg="#1c2147" color={"white"} _hover={{
                    background: "#808080",
                }}
                    _active={{
                        background: "#808080",
                    }}
                    borderRadius={'5px'}
                >
                    <SettingsIcon pb={"2px"} />
                </MenuButton>
                <MenuList>
                    <MenuItem isDisabled>
                        <div style={{ fontWeight: 'bold', textAlign: 'center', width: '100%', margin: '0 auto !important' }}>Actions</div>
                    </MenuItem>
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 14, 17, 23, 37, 40].includes(userAuth) ? (
                        <MenuItem>
                            <Button
                                borderRadius={"5px"}
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                bg="#f2f2f2"
                                _hover={{ background: "#7080fc", color: 'white' }}
                                color="#1c2147"
                                width="100%"
                                onClick={() => handleInfo(user)}
                            >
                                <Text
                                    flex={1}
                                    textAlign="center"
                                >
                                    Info
                                </Text>
                                <InfoOutlineIcon ml={2} />
                            </Button>
                        </MenuItem>
                    ) : null}
                    {[1, 2, 3, 7, 8, 9, 37, 40].includes(userAuth) ? (
                        <MenuItem>
                            <Button
                                borderRadius={"5px"}
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                bg={'#f2f2f2'}
                                color='#1c2147'
                                _hover={{ background: "#ff9fb8", color: 'white' }}
                                width="100%"
                                onClick={() => handleUpdate(user)}
                            >
                                <Text
                                    flex={1}
                                    textAlign="center"
                                >Update user info
                                </Text>
                                <EditIcon ml={2} />
                            </Button>
                        </MenuItem>
                    ) : null}
                    {[1, 2, 3, 30, 31, 32, 37, 38, 40, 46].includes(userAuth) ? (
                        <MenuItem>

                            <Button
                                borderRadius={"5px"}
                                bg={"#f2f2f2"}
                                color={"#1c2147"}
                                _hover={{ background: "#988d77", color: 'white' }}
                                onClick={() => handleUpdateAD(user)}
                                width={"100%"}
                                justifyContent="space-between"
                                alignItems="center">
                                <Text flex={1} textAlign="center">
                                    Edit anydesk
                                </Text>
                                <EditIcon ml={2} verticalAlign="middle" />
                            </Button>
                        </MenuItem>
                    ) : null}
                    {[1, 2, 3, 7, 8, 9, 23, 24, 25, 37, 40].includes(userAuth) ? (
                        <MenuItem>
                            <Button
                                borderRadius={"5px"}
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                bg={'#f2f2f2'}
                                color='#1c2147'
                                _hover={{ background: "#fcd677", color: 'white' }}
                                width="100%"
                                onClick={() => handleUpdateClient(user)}
                            >
                                <Text
                                    flex={1}
                                    textAlign="center"
                                >
                                    Change client & user type
                                </Text>
                                <EditIcon ml={2} verticalAlign="middle" />
                            </Button>
                        </MenuItem>
                    ) : null}

                    {[1, 2, 3, 7, 37].includes(userAuth) ? (
                        <MenuItem>

                            <Button
                                borderRadius={"5px"}
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                bg={'#f2f2f2'}
                                color='#1c2147'
                                _hover={{ background: "#424ea8", color: 'white' }}
                                width="100%"
                                onClick={() => handleChangePassword(user)}
                            >
                                <Text
                                    flex={1}
                                    textAlign="center"
                                >
                                    Change Password
                                </Text>
                                <UnlockIcon ml={2} verticalAlign="middle" />
                            </Button>
                        </MenuItem>
                    ) : null}

                    {[1, 2, 3, 7, 37].includes(userAuth) ? (
                        <MenuItem>

                            <Button
                                borderRadius={"5px"}
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                bg={'#f2f2f2'}
                                color='#1c2147'
                                _hover={{ background: "#fca066", color: 'white' }}
                                width="100%"
                                onClick={() => handleUpdateTU(user)}
                            >
                                <Text
                                    flex={1}
                                    textAlign="center"
                                >
                                    Update location
                                </Text>
                                <EditIcon ml={2} verticalAlign="middle" />
                            </Button>
                        </MenuItem>
                    ) : null}
                    {[1, 2, 3, 7, 23, 24, 25, 37, 40].includes(userAuth) ? (
                        <MenuItem>
                            <Button
                                borderRadius={"5px"}
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                bg={'#f2f2f2'}
                                color='#1c2147'
                                _hover={{ background: "#8a6dce", color: 'white' }}
                                width="100%"
                                onClick={() => handleCreateSchedule(user)}
                            >
                                <Text
                                    flex={1}
                                    textAlign="center"
                                    mr={1}
                                >
                                    Weekly schedules
                                </Text>
                                <TbCalendarPlus ml={2} verticalAlign="middle" />
                            </Button>
                        </MenuItem>
                    ) : null}
                    {[1, 2, 3, 30, 31, 32, 37, 38, 40, 46].includes(userAuth) ? (
                        <MenuItem>

                            <Button
                                borderRadius={"5px"}
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                bg={'#f2f2f2'}
                                color='#1c2147'
                                _hover={{ background: "#b9ce67", color: 'white' }}
                                width="100%"
                                onClick={() => handleReset(user)}
                                isLoading={loading}
                            >
                                <Text
                                    flex={1}
                                    textAlign="center"
                                >
                                    Reset password
                                </Text>
                                <UnlockIcon ml={2} verticalAlign="middle" />
                            </Button>
                        </MenuItem>
                    ) : null}
                    {[1, 2, 3, 7, 8, 9, 37, 40].includes(userAuth) ? (
                        <MenuItem>
                            {(user.active === 1) ? (
                                <Button
                                    borderRadius={"5px"}
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    bg={'#f2f2f2'}
                                    color='#1c2147'
                                    _hover={{ background: "#fc7466", color: 'white' }}
                                    width="100%"
                                    onClick={() => handleDelete(user)}
                                    isLoading={loading}
                                >
                                    <Text
                                        flex={1}
                                        textAlign="center"
                                    >
                                        Delete user
                                    </Text>
                                    <DeleteIcon ml={2} verticalAlign="middle" />
                                </Button>
                            ) :
                                <Button
                                    borderRadius={"5px"}
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    bg={'#f2f2f2'}
                                    color='#1c2147'
                                    _hover={{ background: "#fc7466", color: 'white' }}
                                    width="100%"
                                    onClick={() => handleRestore(user)}
                                    isLoading={loading}
                                >
                                    <Text
                                        flex={1}
                                        textAlign="center"
                                    >
                                        Restore user
                                    </Text>
                                    <MdRestore ml={2} verticalAlign="middle" />
                                </Button>
                            }



                        </MenuItem>
                    ) : null}
                </MenuList>
            </Menu>
        </>
    );
}

export default UserButtons;
