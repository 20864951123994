import React, { useState } from 'react';
import axios from 'axios';
import {
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody,
    Button, Input, Select,
    Box, Flex, Heading, SimpleGrid,
    FormControl, FormErrorMessage,
    Image, Text,
    useToast,
} from '@chakra-ui/react';
import avatar4 from 'assets/img/dashboards/grey_world.png';
import { CloseIcon, DeleteIcon } from '@chakra-ui/icons'

const UpdateSetting = ({ isOpen, onClose, onSettingUpdated, setting, setFlashing }) => {
    const toast = useToast();
    const [formErrors, setFormErrors] = useState({});

    const clearForm = () => {
        setFormData({
            name: '',
            id_option: ''
        });
        setFormErrors({});
    };

    const [formData, setFormData] = useState({
        name: setting?.name || '',
        id_option: setting?.id_option || '',
    });

    const handleSubmit = async (id) => {
        try {
            const response = await axios.put(`/settings/update/${setting.id}`, formData);
            onSettingUpdated();
            setFlashing(formData);
            toast({
                title: 'Setting Edit Success',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            clearForm();
            onClose();
            setFormErrors({});
        } catch (error) {
            toast({
                title: "Error Editing Setting",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            console.error('Error Editing Setting:', error);
            setFormErrors(error.response.data.errors);
        }
    };

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    return (
        <Modal size="lg" isOpen={isOpen} onClose={() => {
            onClose();
        }}
            closeOnOverlayClick={false}
            closeOnEsc={false}
        >
            <ModalOverlay />
            <ModalContent mt={"8%"} background={"#f2f2f2"} borderRadius={"30px"}>
                <ModalHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} color={'#1c2147'} mt={'3%'} mb={'5%'} fontWeight={'bold'} fontSize={'35px'}>
                    <Flex direction={'column'}>
                        <Text textAlign={'center'}>
                            Edit Setting
                        </Text>
                        <Flex style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} color={'#7080fc'} fontWeight={'bold'} fontSize={'22px'}>
                            <span style={{ marginLeft: '8px' }}>
                                <span style={{ marginRight: '8px', color: '#7080fc' }}>
                                    {setting.name}
                                </span>
                            </span>
                        </Flex>
                    </Flex>
                </ModalHeader>
                <ModalBody>
                    <Box>

                        <SimpleGrid
                            columns='1'
                            gap='40px'
                            maxW='100%'
                            w={{ base: "100%", md: "unset" }}
                        >
                            <FormControl isInvalid={!!formErrors.name}>
                                <Input
                                    type="text"
                                    placeholder="Enter setting for edit"
                                    name='name'
                                    className='custom-placeholderInputs'
                                    background="white"
                                    value={formData.name}
                                    onChange={handleChange}
                                    errorBorderColor='#fc7466'
                                />
                                {formErrors.name && (
                                    <FormErrorMessage>This field is required.</FormErrorMessage>
                                )}
                            </FormControl>
                            <FormControl isInvalid={!!formErrors.id_option}>
                                <Select
                                    
                                    borderRadius={'10px'}
                                    name="id_option"
                                    textAlign={"center"}
                                    color={"#cccccc"}
                                    background="white"
                                    value={formData.id_option}
                                    onChange={handleChange}
                                    errorBorderColor='#fc7466'
                                >
                                    <option value=''>Select type setting</option>
                                    <option value='1'>IT</option>
                                    <option value='2'>HR</option>
                                    <option value='3'>Hiring</option>
                                    <option value='4'>OnCall</option>
                                    <option value='5'>Schedules</option>
                                    <option value='6'>Finances</option>
                                    <option value='7'>Calls</option>
                                </Select>
                                {formErrors.id_option && (
                                    <FormErrorMessage>This field is required.</FormErrorMessage>
                                )}
                            </FormControl>
                           
                        </SimpleGrid>
                    </Box>
                </ModalBody>
                <ModalFooter justifyContent="center" pt={"10%"} pb={"10%"}>
                    <Button class="buttonCreate" onClick={handleSubmit}>
                        Update setting
                    </Button>
                    <Button class="buttonCancel" onClick={() => {
                        onClose();
                    }}>
                        Cancel <CloseIcon ml={2} />
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
export default UpdateSetting; 