import {
  Box,
  Flex,
  Link,
  useColorModeValue
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import AdminNavbarLinks from 'components/navbar/NavbarLinksAdmin';
import HeaderLinksMobile from './NavbarLinksMobile';
import { useMediaQuery } from 'react-responsive';
import axios from 'axios';


export default function AdminNavbar(props) {
  const [scrolled, setScrolled] = useState(false);
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 1023 });
  const [userName, setUserName] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [generalInformation, setGeneralInformation] = useState({});
  const [userAuth, setUserAuth] = useState([]);
  const [shift, setShift] = useState("");
  const [breakS, setBreakS] = useState("");
  const [dataShift, setDataShift] = useState("");
  const [read, setRead] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notify, setNotify] = useState([]);
  const [numberNotify, setNumberNotify] = useState('');
  const [notifyUser, setNotifyUser] = useState([]);
  const [isOnBreak, setIsOnBreak] = useState(false);
  const [counter, setCounter] = useState(0);
  const [bonoEnable, setBonoEnable] = useState("");
  const [survey, setSurvey] = useState(false);
  const [supervisorPermission, setSupervisorPermission] = useState("");

  const fetchData = async () => {
    try {
      const response = axios.get("/user").then((response) => {

        const name = response.data.user.name;
        const lName = response.data.user.last_name;
        const fullName = name + ' ' + lName;
        const nameParts = name.split(' ');
        const lastNameParts = lName.split(' ');
        const decodedGeneralInformation = response.data.user_info;
        const shiftStatus = response.data.shift;
        const breakStatus = response.data.break;
        const dataShift = response.data.dataShift;
        const readStatus = response.data.noread;
        const numberNotify = response.data.messageCount;
        const notify = response.data.lastMessage;
        const notifyUser = response.data.lastMessageUser;
        const user = response.data.user;
        const bono = response.data.bonoEnable;
        const surveyStatus = response.data.surveyConfirm;
        const supPending = response.data.pendingPermission;

        if (nameParts.length >= 2) {
          const firstName = nameParts[0];
          const lastName = lastNameParts[0];
          setUserName(`${firstName} ${lastName}`);
          setGeneralInformation(decodedGeneralInformation);
        } else {
          setUserName(fullName);
          setGeneralInformation(decodedGeneralInformation);
        }

        setIsLoggedIn(true);
        setShift(shiftStatus);
        setBreakS(breakStatus);
        setDataShift(dataShift);
        setUserAuth(user);
        setNumberNotify(numberNotify);
        setNotify(notify);
        setNotifyUser(notifyUser);
        setBonoEnable(bono);


        if (readStatus > 0) {
          openModalRead();
        }

         if (surveyStatus === 0) {
           openModalSurvey();
         }

        // if (supPending === true) {
        //   openModalSupervisorPermission();
        // }

      });
    } catch (error) {
      console.error('Error al obtener datos del usuario:', error);
    } finally {
      setLoading(false);
    }
  };

  const openModalRead = () => {
    setRead(true);
  };

  const openModalSurvey = () => {
    setSurvey(true);
  };

  const openModalSupervisorPermission = () => {
    setSupervisorPermission(true);
  };

  useEffect(() => {
    fetchData();

    let interval;

    const startInterval = () => {
      interval = setInterval(() => {
        setCounter((prevCounter) => prevCounter + 1);
      }, 1000);
    };

    if (isOnBreak) {
      // Iniciar el contador solo si isOnBreak es true
      startInterval();
    } else {
      // Reiniciar el contador a cero si isOnBreak es false
      setCounter(0);
    }

    // Limpiar el intervalo cuando el componente se desmonta o cuando isOnBreak cambia a false
    return () => clearInterval(interval);
  }, [isOnBreak]);


  useEffect(() => {
    window.addEventListener('scroll', changeNavbar);

    return () => {
      window.removeEventListener('scroll', changeNavbar);
    };
  });

  const { secondary, brandText, mini, setMini, theme, setTheme, hovered } =
    props;

  // Here are all the props that may change depending on navbar's type or state.(secondary, variant, scrolled)
  let mainText = useColorModeValue('navy.700', 'white');
  let secondaryText = useColorModeValue('gray.700', 'white');
  let navbarPosition = 'fixed';
  let navbarFilter = 'none';
  let navbarBackdrop = '';
  let navbarShadow = 'none';
  let navbarBg = 'white';
  let navbarBorder = 'transparent';
  let secondaryMargin = '0px';
  let paddingX = '15px';
  let gap = '0px';
  const changeNavbar = () => {
    if (window.scrollY > 1) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  return (
    <Box
      position={navbarPosition}
      boxShadow={navbarShadow}
      bg={navbarBg}
      borderColor={navbarBorder}
      filter={navbarFilter}
      backdropFilter={'none'}
      backgroundPosition="center"
      backgroundSize="cover"
      borderRadius="16px"
      borderWidth="1.5px"
      borderStyle="solid"
      transitionDelay="0s, 0s, 0s, 0s"
      transitionDuration=" 0.25s, 0.25s, 0.25s, 0s"
      transition-property="box-shadow, background-color, filter, border"
      transitionTimingFunction="linear, linear, linear, linear"
      alignItems={{ xl: 'center' }}
      display={secondary ? 'block' : 'flex'}
      minH="75px"
      justifyContent={{ xl: 'center' }}
      lineHeight="25.6px"
      mx="auto"
      mt={secondaryMargin}
      pb="8px"
      right={{ base: '12px', md: '30px', lg: '30px', xl: '30px' }}
      px={{
        sm: paddingX,
        md: '10px',
      }}
      ps={{
        xl: '12px',
      }}
      pt="8px"
      top={{ base: '12px', md: '16px', xl: '18px' }}
      w={
        mini === false
          ? {
            base: 'calc(100vw - 6%)',
            md: 'calc(100vw - 8%)',
            lg: 'calc(100vw - 6%)',
            xl: 'calc(100vw - 350px)',
            '2xl': 'calc(100vw - 365px)',
          }
          : mini === true && hovered === true
            ? {
              base: 'calc(100vw - 6%)',
              md: 'calc(100vw - 8%)',
              lg: 'calc(100vw - 6%)',
              xl: 'calc(100vw - 350px)',
              '2xl': 'calc(100vw - 365px)',
            }
            : {
              base: 'calc(100vw - 6%)',
              md: 'calc(100vw - 8%)',
              lg: 'calc(100vw - 6%)',
              xl: 'calc(100vw - 185px)',
              '2xl': 'calc(100vw - 200px)',
            }
      }
    >
      <Flex
        w="100%"
        flexDirection={{
          sm: 'column',
          md: 'row',
        }}
        alignItems={{ xl: 'center' }}
        mb={gap}
      >
        <Box   >

          <Link
            color={mainText}
            href="#"
            bg="inherit"
            borderRadius="inherit"
            fontWeight="bold"
            fontSize="48px"
            _hover={{ color: { mainText } }}
            _active={{
              bg: 'inherit',
              transform: 'none',
              borderColor: 'transparent',
            }}
            _focus={{
              boxShadow: 'none',
            }}
          >
            {brandText}
          </Link>
        </Box>
        <Box ms="auto" w={{ sm: '100%', md: 'unset' }}>
          {isDesktop && (
            <AdminNavbarLinks
              mini={mini}
              setMini={setMini}
              theme={theme}
              setTheme={setTheme}
              secondary={props.secondary}
              fetchData={fetchData}
              setRead={setRead}
              dataShift={dataShift}
              setIsOnBreak={setIsOnBreak}
              isOnBreak={isOnBreak}
              shift={shift}
              breakS={breakS}
              userAuth={userAuth}
              numberNotify={numberNotify}
              notify={notify}
              read={read}
              notifyUser={notifyUser}
              bonoEnable={bonoEnable}
              survey={survey}
              setSurvey={setSurvey}
              supervisorPermission={supervisorPermission}
              setSupervisorPermission={setSupervisorPermission}
            />
          )}
          {isMobile && (
            <HeaderLinksMobile
              mini={mini}
              setMini={setMini}
              theme={theme}
              setTheme={setTheme}
              secondary={props.secondary}
            />
          )}
          {isMobile && userAuth.type_user_id === 37 && (
            <AdminNavbarLinks
              mini={mini}
              setMini={setMini}
              theme={theme}
              setTheme={setTheme}
              secondary={props.secondary}
              fetchData={fetchData}
              setRead={setRead}
              dataShift={dataShift}
              setIsOnBreak={setIsOnBreak}
              isOnBreak={isOnBreak}
              shift={shift}
              breakS={breakS}
              userAuth={userAuth}
              numberNotify={numberNotify}
              notify={notify}
              read={read}
              notifyUser={notifyUser}
              survey={survey}
              setSurvey={setSurvey}
              supervisorPermission={supervisorPermission}
              setSupervisorPermission={setSupervisorPermission}
            />
          )}
        </Box>
      </Flex>
    </Box>
  );
}
