import { Flex } from "@chakra-ui/react";
import Card from "components/card/Card";
import UseBZ from "./items/BZ/functions";

export default function PrenominalBZOverview() {
  return (
    <Flex direction='column' pt={{ sm: "125px", lg: "75px" }}>
      <Card>
        <UseBZ/>
      </Card>
    </Flex>
  );
} 