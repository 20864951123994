
import React, { useEffect, useState, useCallback } from "react";
import axios from 'axios';

import { Flex, Box, Text } from "@chakra-ui/react";

import OverallRevenue from "views/admin/dashboards/default/components/OverallRevenue";
import CarouselD from "./components/Carousel";
import Timeline from "views/admin/main/applications/calendar/components/Timeline";
import { useMediaQuery } from 'react-responsive';
import TimelineMobile from "./components/TimelineMobile";
import { ChevronUpIcon, ChevronDownIcon } from '@chakra-ui/icons'
import { FaStar } from "react-icons/fa";
import Protocols from "./components/protocols";
import ImageViewer from "react-simple-image-viewer";

//Employees perks
import OC_Coverage from "assets/img/home/OC_Coverage.jpeg";
import Lentek from "assets/img/employees_perks/Lentek C.png";
import Legacy from "assets/img/employees_perks/Legacy C.png";
import GR from "assets/img/employees_perks/GR c.png";
import Welcome from "assets/img/employees_perks/Welcome.png";
import KC from "assets/img/employees_perks/KC.png";

//Carrousel Two
import Car from "assets/img/home/Announcements 2.2.webp";
import Car2 from "assets/img/home/Announcements 2-1.webp";
import Car3 from "assets/img/home/Announcements 2.6.webp";
import Car4 from "assets/img/home/Announcements 2.3.webp";
import Car5 from "assets/img/home/Announcements 2.4.webp";
import Car6 from "assets/img/home/Announcements 2.5.png";
import Car7 from "assets/img/home/OBS_Audio.png";
import Reminder from "assets/img/home/Reminder_ClockIN.png";
import EmployeePerks from "./components/EmployeePerks";


export default function Default() {
  const [user, setUser] = useState("");
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 1023 });

  const [currentIndex, setCurrentIndex] = useState(0);

  const vids = [];
  for (let i = 0; i < 4; i++) {
    vids.push(<FaStar key={i} />);
  }

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? vids.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === vids.length - 1 ? 0 : prevIndex + 1));
  };


  const fetchData = async () => {
    try {
      const response = await axios.get('/user')
      const user = response.data.user;

      setUser(user);
    } catch (error) {
      console.error('Error al obtener datos del usuario:', error);
    }
  };


  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);


  const images = [
    Lentek,
    Legacy,
    GR,
    KC
  ];

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  useEffect(() => {
    fetchData();
  }, []);


  const data = [
    Car,
    Car2,
    Car3,
    Car4,
    Car5,
    Car6,
    Car7,
  ];

  const dataMG = [
    OC_Coverage,
    Car,
    Car2,
    Car3,
    Car4,
    Car5,
    Car6,
    Car7,
  ];

  const arrayData = [22, 36, 43, 44].includes(user.type_user_id) ? data : dataMG;


  const [isViewerOpenT, setIsViewerOpenT] = useState(false);
  const [currentImageT, setCurrentImageT] = useState(0);

  const openImageViewerT = (index) => {
    setCurrentImageT(index);
    setIsViewerOpenT(true);
  };

  const closeImageViewerT = () => {
    setIsViewerOpenT(false);
  };

  const reminder = [
    Reminder
  ];

  const [isViewerOpenP, setIsViewerOpenP] = useState(false);
  const [currentImageP, setCurrentImageP] = useState(0);

  const openImageViewerP = (index) => {
    setCurrentImageP(index);
    setIsViewerOpenP(true);
  };

  const closeImageViewerP = () => {
    setIsViewerOpenP(false);
  };


  return (

    <Flex
      justifyContent="center"
      mt={'5%'}
    >
      {isDesktop && (
        <>
          <Flex
            marginLeft={10}
            width="100%"
            mt={"2%"}
            direction={'row'}
            overflow={"hidden"}
          >
            <Flex minHeight={"40%"} flexDirection={"column"} overflow={"hidden"} position={"relative"} mr={'5%'} >
              <Box position={"absolute"} top={0} left={"50%"} zIndex={1000}
                width={"50px"}
                height={"max-content"}
                fontSize={"30px"}
                cursor={"pointer"}
                color={"#cccccc"}
                p={1}
                onClick={handlePrev}
                _hover={{ background: "#f2f2f2", color: "#808080" }}
                display={'none'}
              >
                {" "}
                <ChevronUpIcon fontSize={"40px"} />{" "}
              </Box>
              <Text className='announcements'>Announcements</Text>
              <Flex ml={0} display={"flex"} flexDirection={"column"} mt={1} width={"800px"} height={"1000px"} overflowY={"auto"} transform={`translateY(-${currentIndex * 18}%)`} transition="transform 0.5s ease-in-out">

                <OverallRevenue images={reminder} openImageViewer={openImageViewer} user={user} />
                <CarouselD data={arrayData} openImageViewer={openImageViewerT} user={user} />

                {user && user.location_id !== null && user.status_employee_id !== null &&
                  (user.location_id === 1 && [1, 2].includes(user.status_employee_id)) ? (
                  <EmployeePerks images={images} openImageViewer={openImageViewerP} user={user} />
                ) : null
                }

              </Flex>


            </Flex>
            <Box position={"absolute"} bottom={'auto'} zIndex={1000} left={"25%"}
              width={"50px"}
              height={"max-content"}
              fontSize={"30px"}
              cursor={"pointer"}
              color={"#cccccc"}
              p={1}
              onClick={handleNext}
              _hover={{ background: "#f2f2f2", color: "#808080" }}
              display={'none'}
            >
              {" "}
              <ChevronDownIcon fontSize={"40px"} />{" "}
            </Box>
            <Flex
              display={"flex"}
              flexDirection={"column"}
              height={'100%'}
              width={'45%'}
            >
              <Timeline />
              <Protocols user={user} />
            </Flex>
          </Flex>
        </>
      )}
      {isMobile && (
        <Flex style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
          <TimelineMobile />
        </Flex>
      )}

      {isViewerOpen && (
        <ImageViewer
          src={reminder}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)"
          }}
          closeOnClickOutside={true}
        />
      )}

      {isViewerOpenT && (
        <ImageViewer
          src={arrayData}
          currentIndex={currentImageT}
          onClose={closeImageViewerT}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: 'rgba(0,0,0,0.9)'
          }}
          closeOnClickOutside={true}
        />
      )}

      {isViewerOpenP && (
        <ImageViewer
          src={images}
          currentIndex={currentImageP}
          onClose={closeImageViewerP}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: 'rgba(0,0,0,0.9)'
          }}
          closeOnClickOutside={true}
        />
      )}
    </Flex>



  );
}
