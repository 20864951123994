import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody,
    Button, Input, Select,
    Box, Flex, SimpleGrid, Heading,
    FormControl, FormErrorMessage,
    Image, Text,
    useToast,
} from '@chakra-ui/react';
import avatar4 from 'assets/img/dashboards/grey_world.png';
import { CloseIcon, AddIcon } from '@chakra-ui/icons'
const SettingsCreate = ({ isOpen, onClose, onSettingCreated }) => {
    const toast = useToast();
    const [settings, setSettings] = useState([]);
    const [formErrors, setFormErrors] = useState({});

    const clearForm = () => {
        setFormData({
            name: '',
            id_option: ''
        });
        setFormErrors({});
    };
    useEffect(() => {
        let isMounted = true;
        axios.get('/options_settings')
            .then(response => {
                if (isMounted) {
                    setSettings(response.data);
                }
            })
            .catch(error => {
                console.error('Error fetching settings:', error);
            });
        return () => {
            isMounted = false;
        };
    }, []);

    const [formData, setFormData] = useState({
        name: '',
        id_option: '',
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formSubmitData = {
            name: formData.name,
            id_option: formData.id_option,
        };
        try {
            const response = await axios.post('/settings/create', formSubmitData);
            onSettingCreated();
            toast({
                title: 'Setting Created',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            clearForm();
            onClose();
            setFormErrors({});
        } catch (error) {
            toast({
                title: "Error Creating Setting",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            console.error('Error Creating Setting:', error);
            setFormErrors(error.response.data.errors);
        }
    };

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };
    return (
        <Modal size="lg" isOpen={isOpen} onClose={() => {
            onClose();
            clearForm();
        }}
            closeOnOverlayClick={false}
            closeOnEsc={false}
        >
            <ModalOverlay />
            <ModalContent class='modalContentAddSetting'>
                <ModalHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} color={'#1c2147'} mt={'3%'} mb={'5%'} fontWeight={'bold'} fontSize={'35px'}>
                    <Flex direction={'column'}>
                        <Text textAlign={'center'}>
                            Add Setting
                        </Text>
                    </Flex>
                </ModalHeader>
                <ModalBody>
                    <Box>
                        
                        <SimpleGrid
                            columns='1'
                            gap='40px'
                            maxW='100%'
                            w={{ base: "100%", md: "unset" }}
                        >
                            <FormControl isInvalid={!!formErrors.name}>
                                <Input
                                    type="text"
                                    placeholder="Enter Setting Name"
                                    name='name'
                                    className='custom-placeholderInputs'
                                    background="white"
                                    defaultValue={formData.name}
                                    onChange={handleChange}
                                    errorBorderColor='#fc7466'
                                />
                                {formErrors.name && (
                                    <FormErrorMessage>This field is required.</FormErrorMessage>
                                )}
                            </FormControl>
                            <FormControl isInvalid={!!formErrors.id_option}>
                                <Select
                                    w='460px'
                                    fontSize={'md'}
                                    borderRadius={'10px'}
                                    name="id_option"
                                    textAlign={"center"}
                                    background="white"
                                    value={formData.id_option}
                                    onChange={handleChange}
                                    h='44px'
                                    maxh='44px'
                                    errorBorderColor='#fc7466'
                                >
                                    <option value=''>Select type setting</option>
                                    <option value='1'>IT</option>
                                    <option value='2'>HR</option>
                                    <option value='3'>Hiring</option>
                                    <option value='4'>OnCall</option>
                                    <option value='5'>Schedules</option>
                                    <option value='6'>Finances</option>
                                    <option value='7'>Calls</option>
                                </Select>
                                {formErrors.id_option && (
                                    <FormErrorMessage>This field is required.</FormErrorMessage>
                                )}
                            </FormControl>
                        </SimpleGrid>
                    </Box>
                </ModalBody>
                <ModalFooter justifyContent="center" pt={"10%"} pb={"10%"}>
                    <Button class="buttonCreate" onClick={handleSubmit}>
                        Add Setting <AddIcon ml={2} />
                    </Button>
                    <Button class="buttonCancel" onClick={() => {
                        onClose();
                        clearForm();
                    }}>
                        Cancel <CloseIcon ml={2} />
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
export default SettingsCreate; 