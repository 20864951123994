import axios from 'axios';
import React, { useEffect, useState, useRef } from "react";
import {
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton,
    Text, Textarea, Button, Input,
    Box, Flex,
    FormControl, FormErrorMessage,
    useToast
}
    from '@chakra-ui/react';
import { BiChat } from "react-icons/bi";
import { CloseIcon } from '@chakra-ui/icons'
import { IoMdSend } from "react-icons/io";
import ModalChat from '../searchBar/ModalChat';
import { FaRegImage } from "react-icons/fa6";
import { ImAttachment } from "react-icons/im";
import { IoDocument } from "react-icons/io5";
import Select2 from 'react-select'

function TicketMedicalNote({ isOpen, onClose, selectedIncidentId, incidentID, openModalChat }) {

    const [joiningRoomId, setJoiningRoomId] = useState(null);
    const [incident, setIncident] = useState(null);
    const [modalChat, setModalChat] = useState(false);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [formErrors, setFormErrors] = useState({});
    const [selectedWorkType, setSelectedWorkType] = useState([]);
    const [errorMsg, setErrorMsg] = useState("");
    const [resetKey, setResetKey] = useState(0);
    const toast = useToast();
    const [showChat, setShowChat] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [documentFile, setDocumentFile] = useState(null);
    const [imageFile, setImageFile] = useState(null);
    const fileInputRef = useRef(null);


    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData((prevData) => {
            const updatedFormData = {
                ...prevData,
                [name]: value,
            };

            // Si ambas fechas están presentes
            if (updatedFormData.date_start && updatedFormData.date_end) {
                const startDate = new Date(updatedFormData.date_start);
                const endDate = new Date(updatedFormData.date_end);

                // Calcular la diferencia en días
                const timeDifference = endDate - startDate;
                const duration = Math.ceil(timeDifference / (1000 * 60 * 60 * 24)) + 1; // Sumar 1 para incluir el día final

                // Calcular la fecha de regreso
                const returnDate = new Date(endDate);
                returnDate.setDate(returnDate.getDate() + 1); // Sumar un día a la fecha de fin

                // Actualizar los campos de duración y fecha de regreso
                updatedFormData.duration = duration;
                updatedFormData.date_return = returnDate.toISOString().split('T')[0]; // Formato YYYY-MM-DD
            }

            return updatedFormData;
        });
    };


    const getDate = () => {
        const today = new Date();
        const day = String(today.getDate()).padStart(2, '0');
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Los meses comienzan en 0
        const year = today.getFullYear();

        return `${year}-${month}-${day}`;
    };


    const [formData, setFormData] = useState({
        date_submitted: getDate(),
        duration: '',
        date_start: '',
        date_end: '',
        date_return: '',
        ticked_id: incidentID,
    });

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            border: '2px solid transparent',
            borderRadius: '8px',
            backgroundColor: '#f2f2f2',
            marginRight: '20px',
            textAlign: 'center',
            width: '350px',
            '&:hover': {
                border: '2px solid transparent',
            },
            '&:focus-visible': {
                outline: 'none',
                boxShadow: 'none'
            },
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: '#f2f2f2',
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#808080' : state.isFocused ? 'white' : '#808080',
            backgroundColor: state.isSelected ? '#f2f2f2' : state.isFocused ? '#bcc3f3' : 'white',
        }),
    };

    const handleWorkTypeChange = (selectedOption) => {
        setSelectedWorkType(selectedOption);

        if (selectedOption && selectedOption.value === '1') {
            setShowChat(true);
            setShowForm(false);
        } else if (selectedOption && (selectedOption.value === '2')) {
            setShowForm(true);
            setShowChat(false);
        } else {
            setShowForm(false);
            setShowChat(false);
        }
    };

    const handleSubmit = async () => {


        const formDataSend = new FormData();
        formDataSend.append('date_submitted', formData.date_submitted);
        formDataSend.append('duration', formData.duration);
        formDataSend.append('date_start', formData.date_start);
        formDataSend.append('date_end', formData.date_end);
        formDataSend.append('date_return', formData.date_return);
        formDataSend.append('ticked_id', formData.ticked_id);


        if (documentFile) {
            formDataSend.append('document', documentFile?.file || '');
        }

        if (imageFile) {
            formDataSend.append('image', imageFile?.file || '');
        }

        try {

            const response = await axios.post('/medicalNoteTicket', formDataSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });
            if (response.data.msg) {
                setErrorMsg(response.data.msg);
            } else {
                toast({
                    title: 'Medical Note sent successfully',
                    status: 'success',
                    duration: 3000,
                    position: 'top',
                    isClosable: true,
                });
                onClose();
            }
        } catch (error) {
            toast({
                title: "Error sending a medical note",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            console.error('Error creating schedule:', error);
        }
    };

    const typeDaily = [
        { value: '1', label: 'Questions to HR department' },
        { value: '2', label: 'Send medical note' },
    ];


    const fetchData = async () => {
        try {
            const response = await axios.get(`/showTicket/${incidentID}`);
            setIncident(response.data);
        } catch (error) {
            console.error('Error fetching incident:', error);
        }
    };

    const handleJoinClick = async () => {
        if (selectedIncidentId !== null) {
            setJoiningRoomId(selectedIncidentId);
            try {
                await axios.post(`/chat/rooms/${selectedIncidentId}/joinUser`, { message: newMessage, chatRomId: selectedIncidentId, incident_id: incidentID })
                    .then(response => {
                        const newMessageData = response.data.message;
                        const user = response.data.user;
                        setMessages([...messages, { ...newMessageData, user }]);
                        setNewMessage('');
                        openModalChat();
                        onClose();
                        setFormErrors({});
                    })
                    .catch(error => {
                        console.error(error);
                        setFormErrors(error.response.data.errors);
                    });
            } finally {
                setJoiningRoomId(null);
            }
        }
    };

    useEffect(() => {
        if (incidentID) {
            fetchData();
        }
    }, [incidentID]);


    const getPlaceholderText = () => {
        switch (selectedIncidentId) {
            case 1:
                return 'Please specify the issue. Example: Internet issues.';
            case 2:
                return 'Please specify the issue. Example: Vacation request.';
            case 3:
                return 'Please specify the issue. Example: Hiring status.';
            case 4:
                return 'Please specify the issue. Example: Client issues.';
            case 5:
                return 'Please specify the issue. Example: Schedule error.';
            case 6:
                return 'Please specify the issue. Example: Payment issues.';
            case 7:
                return 'Please specify the issue. Example: Client feedback.';
            default:
                return 'Please specify the issue.';
        }
    };

    const cancelTicket = () => {
        axios.delete(`/incidents/delete/${incident.id}`)
            .then(response => {
                onClose();
            })
            .catch(error => {
                console.error(error);
            });
    };

    const removeFile = () => {
        setDocumentFile(null)
        setFormErrors({});
    };

    const removeImage = () => {
        setImageFile(null)
        setFormErrors({});
    };

    const handleDocumentChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            setDocumentFile({
                file,
                name: file.name,
            });
        }
    };

    const handleImageChange = (e, imageFile) => {
        const file = e?.target?.files?.[0] || imageFile?.file;

        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setImageFile({
                file,
                previewUrl: imageUrl,
            });
        }
    };


    return (
        <Flex>
            <Modal isOpen={isOpen} onClose={onClose} size={'6xl'} isCentered closeOnOverlayClick={false}
                closeOnEsc={false}>
                <ModalOverlay />
                <ModalContent borderRadius={'20px'}>
                    <ModalHeader textAlign="center" color={'#808080'} mt={'50px'} fontSize={'40px'}>
                        Ticket HR
                    </ModalHeader>
                    <ModalCloseButton onClick={cancelTicket} />
                    <ModalBody>

                        <Flex display={'flex'} justifyContent={'center'} mb={'40px'} mt={'20px'}>
                            <Text mt={'5px'} mr={'20px'} color={'#1c2147'} fontWeight={'bold'} >
                                HR option:
                            </Text>
                            <Select2
                                placeholder="Select issue"
                                options={typeDaily}
                                value={selectedWorkType}
                                onChange={handleWorkTypeChange}
                                styles={customStyles}
                                key={`typeDailySelect-${resetKey}`}
                            />

                        </Flex>
                        {showForm && (
                            <Flex direction={'column'}>
                                <Flex display='flex' justifyContent="center" mt={'50px'}>

                                    <Text mt={'5px'} mr={'20px'} color={'#1c2147'} fontWeight={'bold'}>
                                        Start date:
                                    </Text>
                                    <Input
                                        type='date'
                                        bg={'#f2f2f2'}
                                        width={'250px'}
                                        color={'#808080'}
                                        textAlign={'center'}
                                        value={formData.date_start}
                                        onChange={handleChange}
                                        name='date_start'
                                        mr={'5%'}
                                    />

                                    <Text mt={'5px'} mr={'20px'} color={'#1c2147'} fontWeight={'bold'}>
                                        End date :
                                    </Text>
                                    <Input
                                        type='date'
                                        bg={'#f2f2f2'}
                                        width={'250px'}
                                        color={'#808080'}
                                        textAlign={'center'}
                                        value={formData.date_end}
                                        onChange={handleChange}
                                        name='date_end'
                                        mr={'5%'}
                                    />
                                </Flex>

                                <Flex display='flex' justifyContent="center" mt={'50px'}>

                                    <Text mt={'5px'} mr={'20px'} color={'#1c2147'} fontWeight={'bold'}>
                                        Duration:
                                    </Text>
                                    <Input
                                        type='text'
                                        bg={'#f2f2f2'}
                                        width={'250px'}
                                        color={'#808080'}
                                        textAlign={'center'}
                                        value={formData.duration}
                                        onChange={handleChange}
                                        name='duration'
                                        mr={'5%'}
                                        readOnly
                                    />

                                    <Text mt={'5px'} mr={'20px'} color={'#1c2147'} fontWeight={'bold'}>
                                        Return date:
                                    </Text>
                                    <Input
                                        type='date'
                                        bg={'#f2f2f2'}
                                        width={'250px'}
                                        color={'#808080'}
                                        textAlign={'center'}
                                        value={formData.date_return}
                                        onChange={handleChange}
                                        name='date_return'
                                        readOnly
                                    />
                                    <Text mt={'5px'} color={'#1c2147'} fontWeight={'bold'} ml={'5%'} mr={'1%'}>
                                        Doctor note:
                                    </Text>

                                    <Flex mr={'1%'}>
                                        <Input type="file" id="document" style={{ display: 'none' }} onChange={handleDocumentChange}
                                            accept="application/pdf" />
                                        <Button as="label" htmlFor="document" cursor="pointer"
                                            bg={'linear-gradient(180deg, rgba(66, 78, 168, 1.0) 50%, rgba(101, 112, 199, 1.0) 50%)'}
                                            color={"white"} _hover={{ background: "#808080", color: 'white' }}
                                            borderRadius={'50%'} height={'50px'}
                                            width={"50px"}
                                        >
                                            <ImAttachment ml={2} size={'35px'} />
                                        </Button>
                                    </Flex>


                                    <Flex>
                                        <Input type="file" id="image" style={{ display: 'none' }} accept="image/*"
                                            onChange={(e) => handleImageChange(e, null)} ref={fileInputRef}
                                        />
                                        <Button as="label" htmlFor="image" cursor="pointer"
                                            bg={'linear-gradient(180deg, rgba(251, 149, 57, 1.0) 50%, rgba(252, 160, 102, 1.0) 50%)'}
                                            color={"white"} _hover={{ background: "#808080", color: 'white' }}
                                            borderRadius={'50%'}
                                            height={'50px'} width={"50px"}>
                                            <FaRegImage ml={2} size={'45px'} />
                                        </Button>
                                    </Flex>



                                </Flex>

                                <Flex display={'flex'} justifyContent={'right'}>
                                    {documentFile && (
                                        <Flex
                                            display={'flex'}
                                            direction={'column'}
                                            justifyContent={'center'}
                                            alignItems={'center'}
                                            mr={'5%'} position={"relative"}
                                            mt={'2%'}
                                        >
                                            <IoDocument ml={2} size={'45px'} color="#cccccc" />
                                            <Text>
                                                {documentFile.name.length > 20
                                                    ? `${documentFile.name.substring(0, 20)}…`
                                                    : documentFile.name
                                                }
                                            </Text>
                                            <Button borderRadius={"5px"} cursor={"pointer"} px={2} onClick={removeFile} _hover={{ background: "tr" }} position={"absolute"} top={-2} right={-2}><CloseIcon boxSize={"15px"} /></Button>
                                        </Flex>
                                    )}

                                    {imageFile && (
                                        <Flex
                                            display={'flex'}
                                            direction={'column'}
                                            justifyContent={'center'}
                                            alignItems={'center'}
                                            mr={'5%'} position={"relative"}
                                            mt={'2%'}
                                        >
                                            <img src={imageFile.previewUrl} alt="Preview" style={{ maxWidth: '100px', maxHeight: '100px' }} />
                                            <Text>
                                                {imageFile.file.name.length > 20
                                                    ? `${imageFile.file.name.substring(0, 20)}…`
                                                    : imageFile.file.name
                                                }
                                            </Text>
                                            <Button borderRadius={"5px"} cursor={"pointer"} px={2} onClick={removeImage} _hover={{ background: "transparent" }} position={"absolute"} top={-2} right={-4}><CloseIcon boxSize={"15px"} /></Button>
                                        </Flex>
                                    )}
                                </Flex>


                                <Flex display={'flex'} justifyContent={'center'} mt={'80px'} direction={'column'} textAlign={'center'}>
                                    <Text fontSize={'20px'} color={'#7080fc'} fontWeight={'bold'}>
                                        Note: You can attach a photo or a document.
                                    </Text>

                                    <Text fontSize={'20px'} color={'#7080fc'} fontWeight={'bold'}>
                                        Please verify that the information is correct.
                                    </Text>
                                </Flex>

                                <Flex display={'flex'} justifyContent={'center'} mt={'80px'} mb={'40px'}>
                                    <Button
                                        borderRadius={"5px"}
                                        bg={"#b9ce67"}
                                        color={"white"}
                                        _hover={{ background: "#8aad01", color: 'white' }}
                                        onClick={handleSubmit}
                                        mr={'30px'}
                                    >
                                        <Text mr={'5px'}>
                                            Send
                                        </Text>
                                        <IoMdSend />
                                    </Button>

                                    <Button bg={'#424ea8'} _hover={{ background: "#fc7466", color: 'white' }} color={'white'} mr={3} borderRadius='10px' fontWeight='bold' onClick={cancelTicket}>
                                        <CloseIcon mr={2} />  Cancel
                                    </Button>
                                </Flex>
                            </Flex>
                        )}



                        {showChat && (
                            <Box justifyContent={'center'} display={'flex'} mt={'20px'} textAlign={'center'} flexDirection={'column'} alignItems={'center'}>
                                <FormControl isInvalid={!!formErrors.message}>
                                    <Textarea
                                        width={'80%'}
                                        value={newMessage}
                                        onChange={e => setNewMessage(e.target.value)}
                                        placeholder={getPlaceholderText()}
                                    />
                                    {formErrors.message && (
                                        <FormErrorMessage display={'flex'} justifyContent={'center'}>{formErrors.message}</FormErrorMessage>
                                    )}
                                </FormControl>
                                <Text
                                    fontSize={'16px'}
                                    textAlign={'center'}
                                    color={'#808080'}
                                >
                                    <br />
                                    For support with your ticket,  <strong>join the chat.</strong>
                                </Text>
                                <Flex mt={'20px'} mb={'40px'}>
                                    <Button bg={'#b9ce67'} _hover={{ background: "#8aad01", color: 'white' }} color={'white'} mr={3} borderRadius='10px' fontWeight='bold' onClick={handleJoinClick}>
                                        <Box mr={2}>
                                            <BiChat size={'20px'} />
                                        </Box>
                                        <Text mr={1}>
                                            Join Chat
                                        </Text>
                                    </Button>
                                    <Button bg={'#424ea8'} _hover={{ background: "#fc7466", color: 'white' }} color={'white'} mr={3} borderRadius='10px' fontWeight='bold' onClick={cancelTicket}>
                                        <CloseIcon mr={2} />  Cancel
                                    </Button>
                                </Flex>
                            </Box>
                        )}
                    </ModalBody>
                </ModalContent>
            </Modal >
            {modalChat && (
                <ModalChat isOpen={modalChat} onClose={() => setModalChat(false)} selectedIncidentId={selectedIncidentId} />
            )}
        </Flex>
    );
}


export default TicketMedicalNote;
