import {
    TableContainer, Box,
} from '@chakra-ui/react'
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SupervisorSearch from './filters';
import SupervisorList from './table';
import ClientSupervisors from './clients';


const SupervisorFunctions = () => {

    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [loading, setLoading] = useState(false);
    const [clientModalOpen, setClientModalOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [total, setTotal] = useState('');
    const [locationSearch, setLocationSearch] = useState('');
    const [location, setLocation] = useState([]);
    const [clientSearch, setClientSearch] = useState('');
    const [clientS, setClientS] = useState([]);
    const [statusSearch, setStatusSearch] = useState('');
    const [flashing, setFlashing] = useState(false);
    const [resetKey, setResetKey] = useState(0);
    const [userSearch, setUserSearch] = useState('');
    const [userList, setUserList] = useState([]);
    const [usersClients, setUsersClients] = useState([]);


    const filterOptions = (candidate, input) => {
        const words = input.toLowerCase().split(' ');
        return words.every(word =>
            candidate.label.toLowerCase().includes(word)
        );
    };

    const clearFilters = () => {
        setClientSearch(null);
        setUserSearch(null);
        setStatusSearch('');
        setLocationSearch('');
        setResetKey(resetKey + 1);
        setCurrentPage(1);
    }


    const optionClients = clientS.map(clientS => ({
        value: clientS.id,
        label: clientS.name,
    }));

    const optionsUsers = userList.map(userList => ({
        value: userList.id,
        label: `${userList.name} ${userList.last_name}`,
    }));

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: 'transparent',
            borderRadius: '5px',
            borderColor: '#808080',
            marginLeft: '20px',
            fontSize: '16px',
            height: '40px',
            width: '220px',
            '&:hover': {
                borderColor: '#808080',
            },
            '&:focus-visible': {
                outline: 'none',
                boxShadow: 'none'
            },
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: 'white',
            width: '265px'
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#808080' : state.isFocused ? 'white' : '#808080',
            backgroundColor: state.isSelected ? '#f2f2f2' : state.isFocused ? '#bcc3f3' : 'white',
        }),
    };


    const fetchData = async () => {
        try {
            const response = await axios.get(
                `/supervisors?page=${currentPage}`
                + `${locationSearch ? `&location=${locationSearch.trim()}` : ''}`
                + `${clientSearch ? `&client=${encodeURIComponent(clientSearch)}` : ''}`
                + `${statusSearch ? `&status=${statusSearch.trim()}` : ''}`
                + `${userSearch ? `&name=${encodeURIComponent(userSearch)}` : ''}`

            );
            const responseData = response.data;
            setUsers(responseData.data.data);
            setLocation(responseData.location);
            setClientS(responseData.client);
            setUserList(responseData.userList);
            setTotalPages(responseData.data.last_page);
            setTotal(responseData.data.total)
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setLoading(false);
        }
    };



    const handleClientChange = (selectedOption) => {
        setClientSearch(selectedOption.value);

        if (selectedOption) {
            axios
                .get(`/userClient?id_client=${selectedOption.value}`)
                .then((response) => {
                    const userOptions = response.data.data.map((user) => ({
                        label: user.user ? `${user.user.name} ${user.user.last_name}` : '',
                        value: user.id_user,
                    }));
                    setUsersClients(userOptions);
                });
        } else {
            setUsersClients([]);
        }
    };

    useEffect(() => {
        fetchData();

        if (flashing) {
            const intervalId = setInterval(() => {
                setFlashing(false);
            }, 7000);

            return () => {
                clearInterval(intervalId);
            };
        }
    }, [currentPage, userSearch, locationSearch, clientSearch, statusSearch, flashing, resetKey]);

    const handleSelectChangeF = (componentName, selectedOption) => {
        switch (componentName) {
            case 'client':
                setClientSearch(selectedOption.value);
                break;
            case 'user':
                setUserSearch(selectedOption.value);
                break;
            default:
                break;
        }
    };



    const handleUpdateClient = async (user) => {
        setSelectedUser(user);
        setClientModalOpen(true);
    };




    const getColorSchemeForLocation = (locationId) => {
        const colorMap = {
            1: '#b9ce67',
            2: '#fca066',
            3: '#fca066',
            4: '#fc7466',
            5: '#fcd677',
            6: '#8a6dce',
        };

        return colorMap[locationId] || '#b9ce67';
    };




    const getRowBackground = (user) => {
        if (selectedUser && selectedUser.id === user.id) {
            if (flashing) {
                return '#fcd677';
            }
        }
    };



    return (
        <TableContainer>
            <SupervisorSearch
                customStyles={customStyles}
                usersClients={usersClients}
                clientSearch={clientSearch}
                optionsUsers={optionsUsers}
                handleSelectChangeF={handleSelectChangeF}
                setCurrentPage={setCurrentPage}
                filterOptions={filterOptions}
                resetKey={resetKey}
                optionClients={optionClients}
                handleClientChange={handleClientChange}
                statusSearch={statusSearch}
                setStatusSearch={setStatusSearch}
                locationSearch={locationSearch}
                setLocationSearch={setLocationSearch}
                clearFilters={clearFilters}
                location={location}
            />
            <SupervisorList
                customStyles={customStyles}
                users={users}
                getRowBackground={getRowBackground}
                getColorSchemeForLocation={getColorSchemeForLocation}
                setFlashing={setFlashing}
                loading={loading}
                total={total}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPages={totalPages}
                handleUpdateClient={handleUpdateClient}
            />
            {clientModalOpen && (
                <ClientSupervisors
                    isOpen={clientModalOpen}
                    onClose={() => setClientModalOpen(false)}
                    user={selectedUser}
                    onUserUpdate={fetchData}
                    clientsOptions={optionClients}
                    setFlashing={setFlashing}
                />
            )}

        </TableContainer>
    );
}

export default SupervisorFunctions;
