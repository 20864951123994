import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2'
import {
    useToast, TableContainer
} from '@chakra-ui/react'
import PermissionsList from './PermissionsTable';
import ModalNotesPermissions from './itemsNotesPermissions/notesPermissions';
import { useUserData } from 'interceptors/userAuth';

function PermissionsFunctions() {

    const { user, fetchData } = useUserData();
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [total, setTotal] = useState('');
    const [userSearch, setUserSearch] = useState(null);
    const [permissions, setpermissions] = useState([]);
    const [userD, setUser] = useState([]);
    const [resetKey, setResetKey] = useState(0);
    const [openNotes, setNotes] = useState(false);
    const [selectedMN, setSelectedMN] = useState(null);


    const clearFilters = () => {
        setUserSearch(null);
        setCurrentPage(1);
        setResetKey(resetKey + 1);
    }


    const optionsUsers = userD.map(user => ({
        value: user.id,
        label: `${user.name} ${user.last_name}`,
    }));


    const filterOptions = (candidate, input) => {
        const words = input.toLowerCase().split(' ');
        return words.every(word =>
            candidate.label.toLowerCase().includes(word)
        );
    };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: 'transparent',
            borderRadius: '5px',
            borderColor: '#808080',
            fontSize: '16px',
            marginRight: '3px',
            marginLeft: '10px',
            height: '40px',
            width: '250px',
            '&:hover': {
                borderColor: '#808080',
            },
            '&:focus-visible': {
                outline: 'none',
                boxShadow: 'none'
            },
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: 'white',
            width: '265px'
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#808080' : state.isFocused ? 'white' : '#808080',
            backgroundColor: state.isSelected ? '#f2f2f2' : state.isFocused ? '#bcc3f3' : 'white',
        }),
    };

    const fetchDataPermission = async () => {
        try {
            const response = await axios.get(
                `/permissions?page=${currentPage}`
                + `${userSearch ? `&users=${encodeURIComponent(userSearch)}` : ''}`
            );
            const responseData = response.data;
            setpermissions(responseData.data.data);
            setTotal(responseData.data.total)
            setTotalPages(responseData.data.last_page);
            setUser(responseData.user);
        } catch (error) {
            console.error('Error fetching Permissions:', error);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchDataPermission();
        fetchData();
    }, [currentPage, userSearch, resetKey]);


    const handleSelectChange = (componentName, selectedOption) => {
        switch (componentName) {
            case 'user':
                setUserSearch(selectedOption.value);
                break;
            default:
                break;
        }
    };

    const exportPermissions = () => {
        axios.get(
            `/permissionsP?
            ${userSearch ? `&users=${userSearch}` : ''}`, {
            responseType: 'blob'
        })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Permissions.xlsx');
                document.body.appendChild(link);
                link.click();
            })
            .catch(error => {
                console.error('Error al descargar el archivo Excel:', error);
            });
    };

    const handleStatus = async (permission, statusField) => {
        try {
            // Definir diferentes opciones según el valor de statusField y el estado de permission.status_Client
            const inputOptions = getInputOptions(statusField, permission);

            // Construir los elementos HTML para los radio buttons y el input text
            const radioOptionsHTML = Object.keys(inputOptions).map(key => `
                <div style="margin-top: 3%; margin-bottom: 5%; display: inline-block;">
                    <input type="radio" id="radio-${key}" name="statusRadio" value="${key}">
                    <label for="radio-${key}">${inputOptions[key]}</label>
                </div>
            `).join('');

            // Agregar el campo de texto
            const customHTML = `
                <div style="display: grid; grid-template-columns: repeat(2, 1fr); gap: 10px; text-align: center;">
                    <p><strong>Name:</strong> ${permission.user.name.split(' ')[0]} ${permission.user.last_name.split(' ')[0]}</p>
                    <p><strong>Type request:</strong> ${permission.type_request}</p>
                    <p><strong>Duration:</strong> ${permission.duration}</p>
                    <p><strong>Start date:</strong> ${permission.start_date}</p>
                    <p><strong>End date:</strong> ${permission.end_date}</p>
                    <p><strong>Reason request:</strong> ${permission.reason_request ? permission.reason_request : '---'}</p>
                    <p><strong>Coverage:</strong> ${permission.coverage}</p>
                </div>
                ${radioOptionsHTML}
                    <textarea id="commentText" placeholder="Add a note" rows="4" style="width: 100%; border: 1px solid;"></textarea>
            `;

            const { isConfirmed, value } = await Swal.fire({
                title: 'What do you want to do with this permission?',
                html: customHTML,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Submit',
                cancelButtonText: 'Cancel',
                preConfirm: () => {
                    const selectedRadio = Swal.getPopup().querySelector('input[name="statusRadio"]:checked');
                    const commentText = Swal.getPopup().querySelector('#commentText').value;

                    if (!selectedRadio) {
                        Swal.showValidationMessage('You need to choose an option!');
                        return false;
                    }

                    if (!commentText) {
                        Swal.showValidationMessage('The note is required');
                        return false;
                    }

                    return {
                        statusValue: parseInt(selectedRadio.value, 10),
                        description: commentText
                    };
                },
                customClass: {
                    popup: 'custom-swal-popup' // Clase CSS personalizada
                }
            });

            if (isConfirmed) {
                const { statusValue, description } = value;

                const formSubmitData = {
                    model: 'permissions',
                    id: permission.id,
                    statusField: statusField,
                    statusValue: statusValue,
                    description: description // Incluir el comentario en los datos a enviar
                };

                await axios.post(`/updateStatus`, formSubmitData);
                fetchDataPermission();
            }
        } catch (error) {
            console.error('Error updating status:', error);
        }
    };


    const styles = `
    .custom-swal-popup {
        width: auto !important; /* Aumentar el ancho */
        height: auto; /* Aumentar la altura si es necesario */
        font-size: 18px; /* Ajustar el tamaño de la fuente */
    }
`;

    // Añadir el CSS personalizado al documento
    const styleSheet = document.createElement("style");
    styleSheet.type = "text/css";
    styleSheet.innerText = styles;
    document.head.appendChild(styleSheet);

    // Función para definir las opciones según statusField y status_Client
    const getInputOptions = (statusField, permission) => {
        switch (statusField) {
            case 'status_HR':
                return {
                    2: 'Compliant',
                    3: 'Non-compliant',
                    4: 'Compliant(Exception)'
                };
            case 'status_Client':
                return {
                    2: 'Verified',
                };
            case 'status_MG':
                // Si el permiso tiene el status_Client igual a 2 (aprobado por el cliente)
                if (permission.status_Client === 2 || permission.status_Client === 3) {
                    return {
                        4: 'Approved',
                        5: 'Denied'
                    };
                } else {
                    // Opciones por defecto si status_Client no es igual a 2
                    return {
                        2: 'To verified',
                        5: 'Denied',
                    };
                }
            default:
                return {
                    2: 'Approved',
                    3: 'Denied'
                };
        }
    };


    const handleNotes = async (permissions) => {
        setNotes(true);
        setSelectedMN(permissions);
    }



    return (
        <TableContainer mt={"2%"}  >
            <PermissionsList
                optionsUsers={optionsUsers}
                userSearch={userSearch}
                total={total}
                currentPage={currentPage}
                permissions={permissions}
                handleSelectChange={handleSelectChange}
                setCurrentPage={setCurrentPage}
                customStyles={customStyles}
                totalPages={totalPages}
                filterOptions={filterOptions}
                resetKey={resetKey}
                clearFilters={clearFilters}
                loading={loading}
                exportPermissions={exportPermissions}
                handleStatus={handleStatus}
                handleNotes={handleNotes}
            />
            {openNotes && (
                <ModalNotesPermissions
                    isOpen={openNotes}
                    permission={selectedMN}
                    onClose={() => setNotes(false)}
                    userAuth={user}
                />
            )}

        </TableContainer >

    );
}
export default PermissionsFunctions; 