import { Flex } from "@chakra-ui/react";
import Card from "components/card/Card";
import UseSalaryMX from "./items/MX/functions";

export default function SalariesMXOverview() {
  return (
    <Flex direction='column' pt={{ sm: "125px", lg: "75px" }}>
      <Card>
        <UseSalaryMX/>
      </Card>
    </Flex>
  );
} 