import axios from 'axios';
import React, { useEffect, useState } from "react";
import Swal from 'sweetalert2'
import { Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Text, Box, Flex, Badge, useToast, Alert, AlertIcon, AlertDescription, Textarea, FormControl, FormErrorMessage, Input } from '@chakra-ui/react';
import { AiOutlineSchedule } from "react-icons/ai";
import { GoClockFill } from "react-icons/go";
import moment from 'moment';
import Select from 'react-select'

function ModalClock({ isOpen, onClose, data, userAuth, dataBreaks }) {

    const [dataDaily, setDataDaily] = useState([]);
    const [loading, setLoading] = useState(false);
    const [time, setTime] = useState(new Date());
    const [selectShift, setSelectShift] = useState(null);
    const [formErrors, setFormErrors] = useState({});
    const toast = useToast();
    const [error, setError] = useState(null);
    const [error2, setError2] = useState(null);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const shouldShowContent = [22, 35, 36, 43, 44].includes(userAuth.type_user_id);
    const [showForm, setShowForm] = useState(false);
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState([]);
    const [selectedOperator, setSelectedOperator] = useState([]);
    const [selectedCoverage, setSelectedCoverage] = useState([]);
    const [users, setUsers] = useState([]);
    const [userCoverage, setUserCoverage] = useState([]);
    const [selectedWorkType, setSelectedWorkType] = useState([]);
    const [userCoverageOptions, setUserCoverageOptions] = useState(userCoverage);
    const [showSecondSelect, setShowSecondSelect] = useState(false);
    const [resetKey, setResetKey] = useState(0);
    const [hiddenButton, setHiddenButton] = useState(true);
    const [loadingTextVisible, setLoadingTextVisible] = useState(false);
    const [formData, setFormData] = useState({
        description: '',
    });

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    const [formDataSCH, setFormDataSCH] = useState({
        description: '',
        time_start: '08:00:00',
        hours: 8,
        minutes: 30,
        time_end: '16:30:00',
    });



    const fetchData = async () => {
        try {
            const response = axios.get("/getDaily").then((response) => {

                const data = response.data;

                setDataDaily(data);

            });
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
        const interval = setInterval(() => {
            setTime(new Date());
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    const hours = time.getHours();
    const minutes = time.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    const formattedHours = hours % 12 || 12;


    const getColorSchemeForTypeDaily = (typeDaily) => {
        const colorMap = {
            1: '#7080fc',
            2: '#fcd677',
            3: '#8a6dce',
            4: '#dd67ae',
            5: '#fca066',
            7: '#fcd677',
            8: '#dd67ae',
            9: '#fca066'
        };
        return colorMap[typeDaily] || '#7080fc';
    };

    const getTextTypeDaily = (typeDaily) => {
        const colorMap = {
            1: 'Regular shift',
            2: 'Regular training',
            3: 'Additional hrs',
            4: 'Regular coverage',
            5: 'Regular shadowing',
            7: 'Additional training',
            8: 'Additional coverage',
            9: 'Additional shadowing'

        };
        return colorMap[typeDaily] || 'Workday';
    };

    const startShift = async (dataDaily) => {
        const confirmDelete = await Swal.fire({
            title: 'Do you want to Clock In?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#b9ce67',
            cancelButtonColor: '#fc7466',
            confirmButtonText: 'Yes, Clock it!'
        }).then((result) => {
            return result.isConfirmed
        });

        if (confirmDelete) {

            setLoading(true);
            setLoadingTextVisible(true);
            setButtonDisabled(true);

            setSelectShift(dataDaily);
            try {
                const response = await axios.get(`/startShift/${dataDaily.scheduleDetail.id}`);
                const status = response.data.status;



                if (response.status === 200) {
                    data();
                    dataBreaks();
                    toast({
                        title: "Clocked In!",
                        description: `You're clocked in at: ${formattedHours}:${minutes < 10 ? `0${minutes}` : minutes} ${ampm}, you are ${status}`,
                        status: "success",
                        position: 'top',
                        containerStyle: {
                            width: '800px',
                            maxWidth: '100%',
                        },
                        duration: 8000,
                        isClosable: true,
                    });

                    onClose();
                }

            } catch (error) {
                console.error(error);
                const errorMessage = error.response?.data?.errors?.erro;
                setError(errorMessage);
                setLoadingTextVisible(false);
                setButtonDisabled(false);
            } finally {
                setLoading(false);
            }
        }
    };

    const shiftDefault = async () => {


        const confirmDelete = await Swal.fire({
            title: 'Do you want to Clock In?',
            html: "<p style='color: #f8ba85;'><strong>Please remember to notify your supervisor.</strong></p>",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#b9ce67',
            cancelButtonColor: '#fc7466',
            confirmButtonText: 'Yes, Clock in!'
        }).then((result) => {
            return result.isConfirmed;
        });

        if (confirmDelete) {

            setLoading(true);
            setLoadingTextVisible(true);
            setButtonDisabled(true);


            const formSubmitData = {
                description: formData.description
            };

            try {
                const response = await axios.post(`/schedule/createAlways`, formSubmitData);



                if (response.status === 200) {
                    data();
                    dataBreaks();
                    toast({
                        title: "Clocked In!",
                        description: `You're clocked in.`,
                        status: "success",
                        position: 'top',
                        containerStyle: {
                            width: '800px',
                            maxWidth: '100%',
                        },
                        duration: 8000,
                        isClosable: true,
                    });

                    onClose();
                    setFormErrors({});
                }
            } catch (error) {
                console.error(error);
                setFormErrors(error.response.data.errors);
                setError2(
                    <Alert status="error" width={'80%'} mt={'10px'}>
                        An error occurred. Please try again.
                    </Alert>
                );
            } finally {
                setLoading(false);
                setButtonDisabled(false);
            }
        }
    };

    const handleShowForm = () => {
        setShowForm(true);
        setHiddenButton(false);
    };


    const handleChangeSCH = e => {
        const { name, value } = e.target;

        if (name === 'hours') {
            const isValidHours = /^\d+$/.test(value) && parseInt(value, 10) >= 0 && parseInt(value, 10) <= 23;

            if (isValidHours || value === '') {
                setFormDataSCH((prevData) => ({
                    ...prevData,
                    [name]: value,
                }));
            }
        }
        else if (name === 'minutes') {
            const isValidMinutes = /^\d+$/.test(value) && parseInt(value, 10) >= 0 && parseInt(value, 10) <= 59;

            if (isValidMinutes || value === '') {
                setFormDataSCH((prevData) => ({
                    ...prevData,
                    [name]: value,
                }));
            }
        } else {
            setFormDataSCH((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    const calculateTimeEnd = () => {
        const { time_start, hours, minutes } = formDataSCH;

        if (time_start && hours && minutes) {
            const startTime = moment(`2000-01-01 ${time_start}`, 'YYYY-MM-DD HH:mm');

            const endTime = startTime.add(hours, 'hours').add(minutes, 'minutes');

            const formattedTimeEnd = endTime.format('HH:mm');
            setFormDataSCH((prevData) => ({
                ...prevData,
                time_end: formattedTimeEnd,
            }));
        }
    };


    const fetchDataClient = async () => {
        try {
            const response = axios.get("/userClient").then((response) => {
                const clientOptions = response.data.client.map((client) => ({
                    label: client.name,
                    value: client.id,
                }));
                const coverageOptions = response.data.user.map((user) => ({
                    label: user.user ? `${user.user.name} ${user.user.last_name}` : '',
                    value: user.id,
                }));
                setClients(clientOptions);
                setUserCoverage(coverageOptions);
            });
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setLoading(false);
        }
    };



    useEffect(() => {
        fetchDataClient();
        calculateTimeEnd();
    }, [resetKey, formDataSCH.time_start, formDataSCH.hours, formDataSCH.minutes]);


    const handleClientChange = (selectedOption) => {
        setSelectedClient(selectedOption);

        if (selectedOption) {
            axios
                .get(`/userClient?id_client=${selectedOption.value}`)
                .then((response) => {
                    const userOptions = response.data.data.map((user) => ({
                        label: user.user ? `${user.user.name} ${user.user.last_name}` : '',
                        value: user.id_user,
                    }));
                    setUsers(userOptions);
                });
        } else {
            setUsers([]);
        }
    };

    const typeDaily = [

        { value: '', label: 'Work Type' },
        { value: '1', label: 'Regular shift' },
        { value: '2', label: 'Regular training' },
        { value: '4', label: 'Regular coverage' },
        { value: '5', label: 'Regular shadowing' },
        { value: '3', label: 'Additional hours' },
        { value: '7', label: 'Additional training' },
        { value: '8', label: 'Additional coverage' },
        { value: '9', label: 'Additional shadowing' },


    ];

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            border: '2px solid transparent',
            borderRadius: '8px',
            backgroundColor: '#f2f2f2',
            marginRight: '20px',
            textAlign: 'center',
            width: '250px',
            '&:hover': {
                border: '2px solid transparent',
            },
            '&:focus-visible': {
                outline: 'none',
                boxShadow: 'none'
            },
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: '#f2f2f2',
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#808080' : state.isFocused ? 'white' : '#808080',
            backgroundColor: state.isSelected ? '#f2f2f2' : state.isFocused ? '#bcc3f3' : 'white',
        }),
    };

    const handleWorkTypeChange = (selectedOption) => {
        setSelectedWorkType(selectedOption);

        if (selectedOption && selectedOption.value === '4' || selectedOption && selectedOption.value === '5' || selectedOption && selectedOption.value === '7' || selectedOption && selectedOption.value === '9') {
            setShowSecondSelect(true);
            setUserCoverageOptions(userCoverage);
        } else {
            setShowSecondSelect(false);
            setUserCoverageOptions([]);
        }
    };


    const handleSubmit = async () => {

        const confirmDelete = await Swal.fire({
            title: 'Do you want to Clock In?',
            html: "<p style='color: #f8ba85;'><strong>Please check your shift information before clock in.</strong></p>",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#b9ce67',
            cancelButtonColor: '#fc7466',
            confirmButtonText: 'Yes, Clock in!'
        }).then((result) => {
            return result.isConfirmed;
        });

        if (confirmDelete) {

            setLoading(true);

            setLoadingTextVisible(true);
            setButtonDisabled(true);

            // await new Promise(resolve => setTimeout(resolve, 3000));

            const selectOperator = selectedOperator.value;
            const selectCoverage = selectedCoverage.value;
            const selectClient = selectedClient.value;
            const selectTypeDaily = selectedWorkType.value;

            const formSubmitData = {
                id_operator_coverage: selectCoverage,
                id_client: selectClient,
                time_start: formDataSCH.time_start,
                time_end: formDataSCH.time_end,
                durationH: formDataSCH.hours,
                durationM: formDataSCH.minutes,
                type_daily: selectTypeDaily,
                description: formDataSCH.description
            };

            try {
                const response = await axios.post('/schedule/createAlwaysMG', formSubmitData);

                if (response.data && response.data.original.message === "An error occurred while, please try again.") {
                    toast({
                        title: "Error!",
                        description: `An error has occurred, please try again.`,
                        status: "error",
                        position: 'top',
                        containerStyle: {
                            width: '800px',
                            maxWidth: '100%',
                        },
                        duration: 8000,
                        isClosable: true,
                    });
                    setLoadingTextVisible(false);
                } else {
                    data();
                    dataBreaks();
                    toast({
                        title: "Clocked In!",
                        description: `You're clocked in.`,
                        status: "success",
                        position: 'top',
                        containerStyle: {
                            width: '800px',
                            maxWidth: '100%',
                        },
                        duration: 8000,
                        isClosable: true,
                    });

                    onClose();
                    setFormErrors({});
                }


            } catch (error) {
                console.error(error);
                setFormErrors(error.response.data.errors);
                setError2(
                    <Alert status="error" width={'80%'} mt={'10px'}>
                        An error occurred. Please try again.
                    </Alert>
                );
            } finally {
                setLoading(false);
                setButtonDisabled(false);
            }
        }
    };

    const filterOptions = (candidate, input) => {
        const words = input.toLowerCase().split(' ');
        return words.every(word =>
            candidate.label.toLowerCase().includes(word)
        );
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'3xl'} closeOnOverlayClick={false}
            closeOnEsc={false}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} color={'#1c2147'} mt={'70px'} fontWeight={'bold'} fontSize={'40px'}>
                    <span style={{ marginRight: '8px' }}>Today's Schedule</span>
                    <AiOutlineSchedule size={'45px'} />
                </ModalHeader>
                <ModalBody>

                    {dataDaily.length > 0 ? (
                        dataDaily.map(dataDaily => (
                            <Flex key={dataDaily.id} display={'flex'} direction={'column'} justifyContent={'center'} alignItems={'center'}>
                                <Flex flexDirection={'row'} mt={'30px'} fontWeight={'bold'} fontSize={'20px'}>
                                    <Text mr={'5px'}>
                                        Your
                                    </Text>
                                    <Text mr={'5px'} color={getColorSchemeForTypeDaily(dataDaily.scheduleDetail.type_daily)}>
                                        {getTextTypeDaily(dataDaily.scheduleDetail.type_daily)}
                                    </Text>
                                    <Text mr={'5px'}>
                                        shift begins at
                                    </Text>
                                    <Text mr={'5px'} color={getColorSchemeForTypeDaily(dataDaily.scheduleDetail.type_daily)}>
                                        {dataDaily.time_start}
                                    </Text>
                                </Flex>

                                <Flex fontWeight={'bold'} fontSize={'20px'}>
                                    <Text mr={'5px'} color={getColorSchemeForTypeDaily(dataDaily.scheduleDetail.type_daily)}>
                                        {dataDaily.scheduleDetail.dateFormatted}
                                    </Text>
                                </Flex>

                                <Box display="flex" justifyContent="space-between" alignItems="center" height="60px" bg={'#f2f2f2'} mt={'3%'} mb={'10px'}>

                                    <Text fontWeight={'bold'} color={'#808080'} ml={'20px'}>
                                        Clock in at {formattedHours}:{minutes < 10 ? `0${minutes}` : minutes} {ampm}
                                    </Text>

                                    <Flex>
                                        <Badge color={"white"} bg={getColorSchemeForTypeDaily(dataDaily.scheduleDetail.type_daily)} fontSize='12px' borderRadius={"5px"} ml={'20px'} textAlign={'center'} height={'25px'} mr={'20px'}>
                                            {(() => {
                                                switch (dataDaily.scheduleDetail.type_daily) {
                                                    case 1:
                                                        return "Regular shift";
                                                    case 2:
                                                        return "Regular training";
                                                    case 3:
                                                        return "Additional hours";
                                                    case 4:
                                                        return "Regular coverage";
                                                    case 5:
                                                        return "Regular shadowing"
                                                    case 6:
                                                        return "No shift"
                                                    case 7:
                                                        return "Additional training"
                                                    case 8:
                                                        return "Additional coverage"
                                                    case 9:
                                                        return "Additional shadowing"
                                                    default:
                                                        return "Regular shift";
                                                }
                                            })()}
                                        </Badge>

                                    </Flex>
                                </Box>
                                <Button
                                    mt={'20px'}
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    size={'sm'}
                                    color={"white"}
                                    bg={"#b9ce67"}
                                    borderRadius={'5px'}
                                    _hover={{ background: "#8aad01", color: 'white' }}
                                    onClick={() => startShift(dataDaily)}
                                    isDisabled={buttonDisabled}
                                >
                                    <Text flex={1} textAlign='center' mr={2}>
                                        Clock In
                                    </Text>
                                    <GoClockFill color='white' size={'20px'} />
                                </Button>
                                {error && (
                                    <Alert status="error" width={'80%'} mt={'10px'}>
                                        <AlertIcon />
                                        <AlertDescription fontSize={'15px'}>{error}</AlertDescription>
                                    </Alert>
                                )}
                                {shouldShowContent ? (
                                    <Flex direction={'column'} alignItems={'center'} mt={'50px'}>
                                        <Box display="flex" justifyContent="center" alignItems="center" height="60px" width={'80%'}>
                                            <Text fontSize={'18px'} color={'#b9ce67'} fontWeight={'bold'} mr={1} textAlign={'center'}>
                                                CLOCK-IN BUTTON
                                                <Text as="span" color={'#b3b3b3'}> is for scheduled shifts. Use the </Text>
                                                <Text as="span" color={'#fcd677'}>FORCED CLOCK IN BUTTON </Text>
                                                <Text as="span" color={'#b3b3b3'}>below for unscheduled hours or extra shifts with
                                                    supervisor approval. </Text>
                                            </Text>
                                        </Box>
                                        <Flex direction={'column'} display={'flex'} justifyContent={'center'} alignItems={'center'} bg={'#f2f2f2'} mt={'30px'}>
                                            <Box display="flex" justifyContent="center" alignItems="center" height="60px" width={'80%'} flexDirection={'column'} mt={'40px'}>
                                                <Text
                                                    fontSize={'20px'}
                                                    color={'#1c2147'}
                                                    fontWeight={'bold'}
                                                    textAlign={'center'}
                                                    mr={1}
                                                    mt={'80px'}
                                                >
                                                    Use the Forced clock-in button below ONLY if you
                                                    don’t have a shift or are working extra hours
                                                </Text>
                                                <Text
                                                    fontSize={'16px'}
                                                    color={'#b3b3b3'}
                                                    fontWeight={'bold'}
                                                    mr={1}
                                                    mt='20px'
                                                >
                                                    Forced clocked in auto-creates today’s schedule as shifts are assigned.
                                                </Text>

                                            </Box>
                                            <Box display="flex" justifyContent="center" alignItems="center" height="60px" width={'50%'} mt={'100px'}>
                                                <FormControl isInvalid={!!formErrors.description}>
                                                    <Textarea
                                                        fontSize={'14px'}
                                                        placeholder='Please, specify the reason why you forced your clock in.'
                                                        value={formData.description}
                                                        onChange={handleChange}
                                                        bg={'white'}
                                                        name='description'
                                                    />
                                                    {formErrors.description && (
                                                        <FormErrorMessage>This field is required. Max 200 characters. Min 8 characters.</FormErrorMessage>
                                                    )}
                                                </FormControl>
                                            </Box>

                                            <Flex display={'flex'} justifyContent={'center'} mt={'50px'} mb={'80px'}>

                                                <Text color={"#424ea8"} textAlign={"center"} fontSize={"22px"} style={{ display: loadingTextVisible ? 'block' : 'none' }} mb={'20px'} fontWeight={'bold'} >Loading, please wait...</Text>

                                                <Button
                                                    display="flex"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    color={"white"}
                                                    bg={"#fcd677"}
                                                    borderRadius={'5px'}
                                                    mr={3}
                                                    _hover={{ background: "#8aad01", color: 'white' }}
                                                    onClick={shiftDefault}
                                                    isDisabled={buttonDisabled}
                                                >
                                                    <Text flex={1} textAlign='center' mr={2}>
                                                        Forced Clock In
                                                    </Text>
                                                    <GoClockFill color='white' size={'20px'} />
                                                </Button>
                                            </Flex>
                                        </Flex>

                                    </Flex>
                                ) : (
                                    <>
                                        {hiddenButton && (
                                            <>
                                                <Flex direction={'column'} alignItems={'center'} mt={'50px'}>
                                                    <Box display="flex" justifyContent="center" alignItems="center" height="60px" bg={'#f2f2f2'} width={'90%'}>
                                                        <Text fontSize={'22px'} color={'#7080fc'} fontWeight={'bold'} mr={1}>
                                                            No shifts today!
                                                        </Text>
                                                        <Text fontSize={'22px'} color={'#808080'} fontWeight={'bold'} mr={1}>
                                                            But you can still
                                                        </Text>
                                                        <Text fontSize={'22px'} color={'#7080fc'} fontWeight={'bold'}>
                                                            clock in.
                                                        </Text>
                                                    </Box>
                                                    <Box display="flex" justifyContent="center" alignItems="center" height="60px" width={'100%'}>
                                                        <Text
                                                            fontSize={'16px'}
                                                            color={'#b9ce67'}
                                                            fontWeight={'bold'}
                                                            mr={1}
                                                        >
                                                            Forced clocked in

                                                        </Text>
                                                        <Text color={'#cccccc'} fontWeight={'bold'} fontSize={'16px'}
                                                        >
                                                            auto-creates today's schedule as shifts are assigned.
                                                        </Text>
                                                    </Box>


                                                </Flex>
                                                <Text color={"#424ea8"} textAlign={"center"} fontSize={"22px"} style={{ display: loadingTextVisible ? 'block' : 'none' }} mt={'20px'} fontWeight={'bold'} >Loading, please wait...</Text>
                                                <Flex display={'flex'} justifyContent={'center'} mt={'50px'} mb={'80px'}>
                                                    <Button
                                                        display="flex"
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                        color={"white"}
                                                        bg={"#b9ce67"}
                                                        borderRadius={'5px'}
                                                        mr={3}
                                                        _hover={{ background: "#8aad01", color: 'white' }}
                                                        onClick={handleShowForm}
                                                    >
                                                        <Text flex={1} textAlign='center' mr={2}>
                                                            Forced Clock In
                                                        </Text>
                                                        <GoClockFill color='white' size={'20px'} />
                                                    </Button>
                                                    <Button
                                                        size={'md'}
                                                        color={"white"}
                                                        bg={"#7080fc"}
                                                        borderRadius={'5px'}
                                                        _hover={{ background: "#fc7466", color: 'white' }}
                                                        onClick={onClose}
                                                    >
                                                        Close
                                                    </Button>
                                                </Flex>
                                            </>
                                        )}

                                        {showForm && (
                                            <>
                                                <Flex display='flex' justifyContent='space-around' mt={'50px'}>
                                                    <Text fontSize={'25px'} color={'#1c2147'} fontWeight={'bold'} display="flex"
                                                    >
                                                        What type of shift?
                                                    </Text>

                                                    <Text fontSize={'25px'} color={'#1c2147'} fontWeight={'bold'} display="flex"
                                                    >
                                                        What client?
                                                    </Text>
                                                </Flex>
                                                <Flex display='flex' justifyContent='space-around' mt={'50px'}>
                                                    <Select
                                                        options={typeDaily}
                                                        value={selectedWorkType}
                                                        onChange={handleWorkTypeChange}
                                                        styles={customStyles}
                                                        key={`typeDailySelect-${resetKey}`}
                                                    />
                                                    <Select
                                                        options={clients}
                                                        value={selectedClient}
                                                        onChange={handleClientChange}
                                                        styles={customStyles}
                                                        key={`clientSelect-${resetKey}`}
                                                    />
                                                </Flex>


                                                <Flex display='flex' justifyContent="center" mt={'50px'}>
                                                    {showSecondSelect && (
                                                        <>
                                                            <Text fontSize={'25px'} color={'#1c2147'} fontWeight={'bold'} display="flex" mr={'20px'}>
                                                                Who's this agent covering for?
                                                            </Text>
                                                            <Select
                                                                options={userCoverageOptions}
                                                                styles={customStyles}
                                                                value={selectedCoverage}
                                                                onChange={(selectedOption) => setSelectedCoverage(selectedOption)}
                                                                key={`CoverageSelect-${resetKey}`}
                                                            />
                                                        </>
                                                    )}
                                                </Flex>

                                                <Flex display={'flex'} justifyContent={'center'} mt={'60px'}>
                                                    <Text fontSize={'25px'} color={'#1c2147'} fontWeight={'bold'} display="flex"
                                                        justifyContent="space-between">

                                                        <Box mt={1.5} mr={2}>
                                                            <GoClockFill />
                                                        </Box>
                                                        What time?
                                                    </Text>
                                                </Flex>

                                                <Flex display='flex' justifyContent="center" mt={'50px'}>
                                                    <FormControl isInvalid={!!formErrors.time_start}>
                                                        <Input type='time' bg={'#f2f2f2'} color={'#808080'} textAlign={'center'} value={formDataSCH.time_start} onChange={handleChangeSCH} name='time_start' />
                                                        {formErrors.time_start && (
                                                            <FormErrorMessage>This field is required</FormErrorMessage>
                                                        )}
                                                    </FormControl>
                                                    <FormControl isInvalid={!!formErrors.hours} width={'150px'} ml={'20px'} mr={'10px'} >
                                                        <Input type='text' bg={'#f2f2f2'} color={'#808080'} textAlign={'center'} value={formDataSCH.hours} onChange={handleChangeSCH} name='hours' />
                                                        {formErrors.hours && (
                                                            <FormErrorMessage>This field is required</FormErrorMessage>
                                                        )}
                                                    </FormControl>
                                                    <Text mt={'6px'} mr={'10px'} color={'#808080'}>
                                                        :
                                                    </Text>
                                                    <FormControl isInvalid={!!formErrors.minutes} width={'150px'} mr={'20px'}>
                                                        <Input type='text' bg={'#f2f2f2'} color={'#808080'} textAlign={'center'} value={formDataSCH.minutes} onChange={handleChangeSCH} name='minutes' />
                                                        {formErrors.minutes && (
                                                            <FormErrorMessage>This field is required</FormErrorMessage>
                                                        )}
                                                    </FormControl>
                                                    <FormControl isInvalid={!!formErrors.time_end}>
                                                        <Input type='time' bg={'#f2f2f2'} color={'#808080'} textAlign={'center'} value={formDataSCH.time_end} readOnly name='time_end' />
                                                        {formErrors.time_end && (
                                                            <FormErrorMessage>This field is required</FormErrorMessage>
                                                        )}
                                                    </FormControl>
                                                </Flex>

                                                <Flex display='flex' justifyContent='space-around' mt={'50px'}>
                                                    <Text fontSize={'25px'} color={'#1c2147'} fontWeight={'bold'} display="flex"
                                                    >
                                                        Please, specify the reason why you are clocking in.
                                                    </Text>
                                                </Flex>

                                                <Flex display='flex' justifyContent="center" mt={'50px'}>
                                                    <FormControl isInvalid={!!formErrors.description}>
                                                        <Textarea bg={'white'} mr={4} placeholder='Description' value={formDataSCH.description} onChange={handleChangeSCH} name='description' />
                                                        {formErrors.description && (
                                                            <FormErrorMessage>This field is required. Max 200 characters. Min 8 characters.</FormErrorMessage>
                                                        )}
                                                    </FormControl>
                                                </Flex>
                                                <Flex display={'flex'} justifyContent={'center'} mt={'50px'} mb={'80px'} flexDirection={"column"} >
                                                    <Text color={"#424ea8"} textAlign={"center"} fontSize={"22px"} style={{ display: loadingTextVisible ? 'block' : 'none' }} mb={'20px'} >Loading, please wait...</Text>
                                                    <Flex flexDirection={"row"} width={"100%"} justifyContent={'center'}>
                                                        <Button
                                                            display="flex"
                                                            justifyContent="space-between"
                                                            alignItems="center"
                                                            color={"white"}
                                                            bg={"#b9ce67"}
                                                            borderRadius={'5px'}
                                                            mr={3}
                                                            _hover={{ background: "#8aad01", color: 'white' }}
                                                            onClick={handleSubmit}
                                                            isDisabled={buttonDisabled}
                                                        >
                                                            <Text flex={1} textAlign='center' mr={2}>
                                                                Clock In
                                                            </Text>
                                                            <GoClockFill color='white' size={'20px'} />
                                                        </Button>
                                                        <Button
                                                            size={'md'}
                                                            color={"white"}
                                                            bg={"#7080fc"}
                                                            borderRadius={'5px'}
                                                            _hover={{ background: "#fc7466", color: 'white' }}
                                                            onClick={onClose}
                                                            isDisabled={buttonDisabled}
                                                        >
                                                            Close
                                                        </Button>
                                                    </Flex>
                                                </Flex>
                                            </>
                                        )}
                                    </>
                                )}

                                <Button
                                    mt='20px'
                                    mb={'50px'}
                                    size={'md'}
                                    color={"white"}
                                    bg={"#7080fc"}
                                    borderRadius={'5px'}
                                    _hover={{ background: "#fc7466", color: 'white' }}
                                    onClick={onClose}
                                    isDisabled={buttonDisabled}
                                >
                                    Close
                                </Button>
                            </Flex>

                        ))
                    ) : (
                        shouldShowContent ? (
                            <Flex direction={'column'} alignItems={'center'}>
                                <Box display="flex" justifyContent="center" alignItems="center" height="60px" bg={'#f2f2f2'} width={'80%'}>
                                    <Text fontSize={'22px'} color={'#7080fc'} fontWeight={'bold'} mr={1}>
                                        No shifts today!
                                    </Text>
                                    <Text fontSize={'22px'} color={'#808080'} fontWeight={'bold'} mr={1}>
                                        But you can still
                                    </Text>
                                    <Text fontSize={'22px'} color={'#7080fc'} fontWeight={'bold'}>
                                        clock in.
                                    </Text>
                                </Box>
                                <Box display="flex" justifyContent="center" alignItems="center" height="60px" width={'100%'}>
                                    <Text
                                        fontSize={'16px'}
                                        color={'#b9ce67'}
                                        fontWeight={'bold'}
                                        mr={1}
                                    >
                                        Forced clocked in

                                    </Text>
                                    <Text color={'#cccccc'} fontWeight={'bold'} fontSize={'16px'}
                                    >
                                        auto-creates today's schedule as shifts are assigned.
                                    </Text>
                                </Box>
                                <Box display="flex" justifyContent="center" alignItems="center" height="60px" width={'50%'} mt={'20px'}>
                                    <FormControl isInvalid={!!formErrors.description}>
                                        <Textarea
                                            fontSize={'14px'}
                                            placeholder='Please, specify the reason why you forced your clock in.'
                                            value={formData.description}
                                            onChange={handleChange}
                                            name='description'
                                        />
                                        {formErrors.description && (
                                            <FormErrorMessage>This field is required. Max 200 characters. Min 8 characters.</FormErrorMessage>
                                        )}
                                    </FormControl>
                                </Box>
                                <Text color={"#424ea8"} textAlign={"center"} fontSize={"22px"} style={{ display: loadingTextVisible ? 'block' : 'none' }} mt={'20px'} fontWeight={'bold'} >Loading, please wait...</Text>
                                <Flex display={'flex'} justifyContent={'center'} mt={'50px'} mb={'80px'}>
                                    <Button
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        color={"white"}
                                        bg={"#b9ce67"}
                                        borderRadius={'5px'}
                                        mr={3}
                                        _hover={{ background: "#8aad01", color: 'white' }}
                                        onClick={shiftDefault}
                                        isDisabled={buttonDisabled}
                                    >
                                        <Text flex={1} textAlign='center' mr={2}>
                                            Forced Clock In
                                        </Text>
                                        <GoClockFill color='white' size={'20px'} />
                                    </Button>
                                    <Button
                                        size={'md'}
                                        color={"white"}
                                        bg={"#7080fc"}
                                        borderRadius={'5px'}
                                        _hover={{ background: "#fc7466", color: 'white' }}
                                        onClick={onClose}
                                        isDisabled={buttonDisabled}
                                    >
                                        Close
                                    </Button>
                                </Flex>
                            </Flex>
                        ) :
                            <>
                                {hiddenButton && (
                                    <>
                                        <Flex direction={'column'} alignItems={'center'}>


                                            <Box display="flex" justifyContent="center" alignItems="center" height="60px" bg={'#f2f2f2'} width={'80%'}>
                                                <Text fontSize={'22px'} color={'#7080fc'} fontWeight={'bold'} mr={1}>
                                                    No shifts today!
                                                </Text>
                                                <Text fontSize={'22px'} color={'#808080'} fontWeight={'bold'} mr={1}>
                                                    But you can still
                                                </Text>
                                                <Text fontSize={'22px'} color={'#7080fc'} fontWeight={'bold'}>
                                                    clock in.
                                                </Text>
                                            </Box>
                                            <Box display="flex" justifyContent="center" alignItems="center" height="60px" width={'100%'}>
                                                <Text
                                                    fontSize={'16px'}
                                                    color={'#b9ce67'}
                                                    fontWeight={'bold'}
                                                    mr={1}
                                                >
                                                    Forced clocked in

                                                </Text>
                                                <Text color={'#cccccc'} fontWeight={'bold'} fontSize={'16px'}
                                                >
                                                    auto-creates today's schedule as shifts are assigned.
                                                </Text>
                                            </Box>


                                        </Flex>
                                        <Text color={"#424ea8"} textAlign={"center"} fontSize={"22px"} style={{ display: loadingTextVisible ? 'block' : 'none' }} mt={'20px'} fontWeight={'bold'} >Loading, please wait...</Text>
                                        <Flex display={'flex'} justifyContent={'center'} mt={'50px'} mb={'80px'}>
                                            <Button
                                                display="flex"
                                                justifyContent="space-between"
                                                alignItems="center"
                                                color={"white"}
                                                bg={"#b9ce67"}
                                                borderRadius={'5px'}
                                                mr={3}
                                                _hover={{ background: "#8aad01", color: 'white' }}
                                                onClick={handleShowForm}
                                            >
                                                <Text flex={1} textAlign='center' mr={2}>
                                                    Forced Clock In
                                                </Text>
                                                <GoClockFill color='white' size={'20px'} />
                                            </Button>
                                            <Button
                                                size={'md'}
                                                color={"white"}
                                                bg={"#7080fc"}
                                                borderRadius={'5px'}
                                                _hover={{ background: "#fc7466", color: 'white' }}
                                                onClick={onClose}
                                            >
                                                Close
                                            </Button>
                                        </Flex>
                                    </>
                                )}

                                {showForm && (
                                    <>
                                        <Flex display='flex' justifyContent='space-around' mt={'50px'}>
                                            <Text fontSize={'25px'} color={'#1c2147'} fontWeight={'bold'} display="flex"
                                            >
                                                What type of shift?
                                            </Text>

                                            <Text fontSize={'25px'} color={'#1c2147'} fontWeight={'bold'} display="flex"
                                            >
                                                What client?
                                            </Text>
                                        </Flex>
                                        <Flex display='flex' justifyContent='space-around' mt={'50px'}>
                                            <Select
                                                options={typeDaily}
                                                value={selectedWorkType}
                                                onChange={handleWorkTypeChange}
                                                styles={customStyles}
                                                key={`typeDailySelect-${resetKey}`}
                                            />
                                            <Select
                                                options={clients}
                                                value={selectedClient}
                                                onChange={handleClientChange}
                                                styles={customStyles}
                                                key={`clientSelect-${resetKey}`}
                                            />
                                        </Flex>


                                        <Flex display='flex' justifyContent="center" mt={'50px'}>
                                            {showSecondSelect && (
                                                <>
                                                    <Text fontSize={'25px'} color={'#1c2147'} fontWeight={'bold'} display="flex" mr={'20px'}>
                                                        Who's this agent covering for?
                                                    </Text>
                                                    <Select
                                                        options={userCoverageOptions}
                                                        styles={customStyles}
                                                        value={selectedCoverage}
                                                        onChange={(selectedOption) => setSelectedCoverage(selectedOption)}
                                                        key={`CoverageSelect-${resetKey}`}
                                                        isSearchable
                                                        filterOption={filterOptions}
                                                    />
                                                </>
                                            )}
                                        </Flex>

                                        <Flex display={'flex'} justifyContent={'center'} mt={'60px'}>
                                            <Text fontSize={'25px'} color={'#1c2147'} fontWeight={'bold'} display="flex"
                                                justifyContent="space-between">

                                                <Box mt={1.5} mr={2}>
                                                    <GoClockFill />
                                                </Box>
                                                What time?
                                            </Text>
                                        </Flex>

                                        <Flex display='flex' justifyContent="center" mt={'50px'}>
                                            <FormControl isInvalid={!!formErrors.time_start}>
                                                <Input type='time' bg={'#f2f2f2'} color={'#808080'} textAlign={'center'} value={formDataSCH.time_start} onChange={handleChangeSCH} name='time_start' />
                                                {formErrors.time_start && (
                                                    <FormErrorMessage>This field is required</FormErrorMessage>
                                                )}
                                            </FormControl>
                                            <FormControl isInvalid={!!formErrors.hours} width={'150px'} ml={'20px'} mr={'10px'} >
                                                <Input type='text' bg={'#f2f2f2'} color={'#808080'} textAlign={'center'} value={formDataSCH.hours} onChange={handleChangeSCH} name='hours' />
                                                {formErrors.hours && (
                                                    <FormErrorMessage>This field is required</FormErrorMessage>
                                                )}
                                            </FormControl>
                                            <Text mt={'6px'} mr={'10px'} color={'#808080'}>
                                                :
                                            </Text>
                                            <FormControl isInvalid={!!formErrors.minutes} width={'150px'} mr={'20px'}>
                                                <Input type='text' bg={'#f2f2f2'} color={'#808080'} textAlign={'center'} value={formDataSCH.minutes} onChange={handleChangeSCH} name='minutes' />
                                                {formErrors.minutes && (
                                                    <FormErrorMessage>This field is required</FormErrorMessage>
                                                )}
                                            </FormControl>
                                            <FormControl isInvalid={!!formErrors.time_end}>
                                                <Input type='time' bg={'#f2f2f2'} color={'#808080'} textAlign={'center'} value={formDataSCH.time_end} readOnly name='time_end' />
                                                {formErrors.time_end && (
                                                    <FormErrorMessage>This field is required</FormErrorMessage>
                                                )}
                                            </FormControl>
                                        </Flex>

                                        <Flex display='flex' justifyContent='space-around' mt={'50px'}>
                                            <Text fontSize={'25px'} color={'#1c2147'} fontWeight={'bold'} display="flex"
                                            >
                                                Please, specify the reason why you are clocking in.
                                            </Text>
                                        </Flex>

                                        <Flex display='flex' justifyContent="center" mt={'50px'}>
                                            <FormControl isInvalid={!!formErrors.description}>
                                                <Textarea bg={'white'} mr={4} placeholder='Description' value={formDataSCH.description} onChange={handleChangeSCH} name='description' />
                                                {formErrors.description && (
                                                    <FormErrorMessage>This field is required. Max 200 characters. Min 8 characters.</FormErrorMessage>
                                                )}
                                            </FormControl>
                                        </Flex>
                                        <Flex display={'flex'} justifyContent={'center'} mt={'50px'} mb={'80px'} flexDirection={"column"} >
                                            <Text color={"#424ea8"} textAlign={"center"} fontSize={"22px"} style={{ display: loadingTextVisible ? 'block' : 'none' }} mb={'20px'} fontWeight={'bold'} >Loading, please wait...</Text>
                                            <Flex flexDirection={"row"} width={"100%"} justifyContent={'center'}>
                                                <Button
                                                    display="flex"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    color={"white"}
                                                    bg={"#b9ce67"}
                                                    borderRadius={'5px'}
                                                    mr={3}
                                                    _hover={{ background: "#8aad01", color: 'white' }}
                                                    onClick={handleSubmit}
                                                    isDisabled={buttonDisabled}
                                                >
                                                    <Text flex={1} textAlign='center' mr={2}>
                                                        Clock In
                                                    </Text>
                                                    <GoClockFill color='white' size={'20px'} />
                                                </Button>
                                                <Button
                                                    size={'md'}
                                                    color={"white"}
                                                    bg={"#7080fc"}
                                                    borderRadius={'5px'}
                                                    _hover={{ background: "#fc7466", color: 'white' }}
                                                    onClick={onClose}
                                                    isDisabled={buttonDisabled}
                                                >
                                                    Close
                                                </Button>
                                            </Flex>
                                        </Flex>
                                    </>
                                )}
                            </>
                    )}


                </ModalBody>
            </ModalContent>
        </Modal>
    );
}



export default ModalClock;
