import React, { useState } from "react";
import { Flex } from "@chakra-ui/react";
import Card from "components/card/Card";
import QALayouts from "./components/formats";
import UseQA5_1 from "./components/items5_1/Functions";

export default function QAOverview() {
  const [activeComponent, setActiveComponent] = useState("QALayouts");
  const [client, setClient] = useState(null);

  // Función para manejar la navegación y asignar el cliente
  const navigate = (component, clientValue = null) => {
    setClient(clientValue); // Actualizamos el valor del cliente
    setActiveComponent(component); // Cambiamos el componente activo
  };

  // Renderizamos el componente según el estado actual
  const renderComponent = () => {
    switch (activeComponent) {
      case "QALayouts":
        return <QALayouts navigate={navigate} />; // Pasamos navigate para manejar la navegación
      case "UseQA5_1":
        return <UseQA5_1 navigate={navigate} client={client} />; // Pasamos client al componente
      default:
        return <QALayouts navigate={navigate} />;
    }
  };

  return (
    <Flex direction="column" pt={{ sm: "125px", lg: "75px" }}>
      <Card px="100px">
        {renderComponent()} {/* Renderiza el componente activo */}
      </Card>
    </Flex>
  );
}
