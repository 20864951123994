// Chakra imports
import { SimpleGrid, Text, useColorModeValue, Flex } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import React from "react";
import Information from "./Information";
import CardProfile from "components/card/cardProfile";

// Assets
export default function GeneralInformation(props) {

  const { user, ...rest } = props;
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");

  const personal_email = user.profile ? user.profile.personal_email : '---';
  const phone = user.profile ? user.profile.phone : '---';
  const address = user.profile && user.profile.current_address ? user.profile.current_address : '---';
  const date_of_birth = user.profile && user.profile.date_of_birth ? user.profile.date_of_birth : '---';

  const emergency_contact = user.emergency_info && user.emergency_info.emergency_contact ? user.emergency_info.emergency_contact : '---'
  const emergency_contact_number = user.emergency_info && user.emergency_info.emergency_contact_number ? user.emergency_info.emergency_contact_number : '---'
  const relationship = user.emergency_info && user.emergency_info.relationship ? user.emergency_info.relationship : '---'


  return (
    <CardProfile  {...rest} >
      <Flex>
        <Card {...rest}>
        <Flex display={'flex'} direction={'column'} mr={'2%'}>
          <Text
            color={textColorPrimary}
            fontWeight='bold'
            fontSize='2xl'
            mt='10px'
            mb='4px'>
            General Information
          </Text>
          <SimpleGrid columns='2' gap='4%'>
            <Information title='Personal email' value={personal_email} />
            <Information title='Phone' value={phone} />
            <Information title='Address' value={address} />
            <Information title='Date of birth' value={date_of_birth} />
          </SimpleGrid>
        </Flex>

        <Flex display={'flex'} direction={'column'}>
          <Text
            color={textColorPrimary}
            fontWeight='bold'
            fontSize='2xl'
            mt='10px'
            mb='4px'>
            Emergency Conctact Information
          </Text>
          <SimpleGrid columns='2' gap='20px'>
            <Information title='Emergency Contact' value={emergency_contact} />
            <Information title='Phone' value={emergency_contact_number} />
            <Information title='Relationship' value={relationship} />
          </SimpleGrid>
        </Flex>
        </Card>

      </Flex>


    </CardProfile>
  );
}
