import axios from 'axios';
import React, { useState } from "react";
import {
    Button, FormControl, Flex, useToast, Input, Text, Textarea, Select,
    FormErrorMessage, Modal, ModalBody, ModalOverlay, Heading,
    ModalContent, Alert, AlertIcon
} from '@chakra-ui/react';
import { EditIcon, CloseIcon } from '@chakra-ui/icons';
import RadioGroup from './itemsPermission/RadioGroup';

function PermissionRequestModal({ isOpen, onClose }) {

    const [formErrors, setFormErrors] = useState({});
    const [total, setTotal] = useState('');
    const toast = useToast();
    const [formData, setFormData] = useState({
        name: '',
        type_of_request: '',
        start_date: '',
        end_date: '',
        reason_of_request: '',
        coverage: '',
        duration: '',
    });


    const clearForm = () => {
        setFormData({
            name: '',
            type_of_request: '',
            start_date: '',
            end_date: '',
            reason_of_request: '',
            coverage: '',
        });
        setFormErrors({});
    };

    const handleSubmit = async () => {
        const formSubmitData = {
            name: formData.name,
            type_of_request: formData.type_of_request,
            start_date: formData.start_date,
            end_date: formData.end_date,
            reason_of_request: formData.reason_of_request,
            coverage: formData.coverage,
            duration: formData.duration,
        };

        try {
            const response = await axios.post('/permissionRequests', formSubmitData);

            toast({
                title: 'Request Sending',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            clearForm();
            onClose();
            setFormErrors({});
        } catch (error) {

            toast({
                title: "Error Request Send",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            console.error('Error Request Send:', error);
            setFormErrors(error.response.data.errors);
        }
    };

    const handleChange = async (e) => {
        const { name, value } = e.target;

        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));

        if (name === 'start_date' || name === 'end_date') {
            const updatedFormData = {
                ...formData,
                [name]: value,
            };

            if (updatedFormData.start_date && updatedFormData.end_date) {

                try {
                    const response = await axios.get('/permissionValidate', {
                        params: {
                            start_date: updatedFormData.start_date,
                            end_date: updatedFormData.end_date,
                        }
                    });

                    const responseData = response.data;
                    setTotal(responseData);

                } catch (error) {
                    console.error('Error validating vacations:', error);
                }
            }
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'5xl'} closeOnOverlayClick={false} closeOnEsc={false}>
            <ModalOverlay />
            <ModalContent mt={"5%"} background={"#f2f2f2"} borderRadius={"30px"}>
                <ModalBody>
                    <Heading style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} color={'#1c2147'} mt={'5%'} mb={'4%'} fontWeight={'bold'} fontSize={'35px'}>
                        Permission Request
                    </Heading>
                    <Flex mt={4} ml={10} mr={10} direction={'column'}>
                        <Flex display={'flex'}>
                            <Flex direction={'column'} width={'100%'} mr={'1%'}>
                                <Text color={'gray.400'} mb={'1%'} fontWeight={'bold'}>
                                    Name
                                </Text>
                                <Text color={'gray.400'} mb={'1%'}>
                                    Please add your name and last name.
                                </Text>
                                <FormControl isInvalid={!!formErrors.name} w='400px' >
                                    <Input
                                        placeholder="Full Name"
                                        className='custom-placeholderInputs'
                                        background="white"
                                        value={formData.name}
                                        onChange={handleChange}
                                        name='name'
                                        errorBorderColor='red.300'
                                    />
                                    {formErrors.name && (
                                        <FormErrorMessage mb={"2%"}>{formErrors.name}</FormErrorMessage>
                                    )}
                                </FormControl>
                            </Flex>

                            <Flex direction={'column'} width={'100%'}>
                                <Text color={'gray.400'} mb={'1%'} fontWeight={'bold'} mt={'1%'}>
                                    Type of request:
                                </Text>
                                <FormControl isInvalid={!!formErrors.type_of_request} w='400px'>
                                    <Select
                                        fontSize={'md'}
                                        borderRadius={'5px'}
                                        color={'#CCCCCC'}
                                        bg={"white"}
                                        textAlign={"center"}
                                        mt={'6%'}
                                        placeholder='Select type request'
                                        value={formData.type_of_request}
                                        onChange={handleChange}
                                        name='type_of_request'
                                    >
                                        <option value='Covered day (day off)'>Covered day (day off)</option>
                                        <option value='Leave early'>Leave early</option>
                                        <option value='Late start'>Late start</option>
                                        <option value='Switching shifts'>Switching shifts</option>
                                        <option value='Long break'>Long break</option>
                                        <option value='Other'>Other</option>
                                        <option value='Bereavement leave'>Bereavement leave</option>
                                        <option value='Maternity leave'>Maternity leave</option>
                                        <option value='Paternity leave'>Paternity leave</option>
                                        <option value='Funeral service leave'>Funeral service leave</option>
                                        <option value='Marriage leave'>Marriage leave</option>
                                        <option value='Day off'>Day off</option>
                                    </Select>
                                    {formErrors.type_of_request && (
                                        <FormErrorMessage mb={"2%"}>{formErrors.type_of_request}</FormErrorMessage>
                                    )}
                                </FormControl>
                            </Flex>
                        </Flex>
                        <Flex mt={'3%'}>
                            <Flex direction={'column'} width={'100%'} mr={'1%'}>
                                <Text color={'gray.400'} mb={'1%'} fontWeight={'bold'}>
                                    Start Date
                                </Text>
                                <Text color={'gray.400'} mb={'1%'}>
                                    Enter dates requested by the agent, if only one day is requested, put the same date on both.
                                </Text>
                                <FormControl isInvalid={!!formErrors.start_date} w='400px' >
                                    <Input
                                        className='custom-placeholderInputs'
                                        background="white"
                                        type='date'
                                        value={formData.start_date}
                                        onChange={handleChange}
                                        name='start_date'
                                    />
                                    {formErrors.start_date && (
                                        <FormErrorMessage mb={"2%"}>{formErrors.start_date}</FormErrorMessage>
                                    )}
                                </FormControl>
                            </Flex>

                            <Flex direction={'column'} width={'100%'}>
                                <Text color={'gray.400'} mb={'1%'} fontWeight={'bold'} mt={'1%'}>
                                    End Date:
                                </Text>
                                <FormControl isInvalid={!!formErrors.end_date} w='400px' >
                                    <Input
                                        className='custom-placeholderInputs'
                                        background="white"
                                        type='date'
                                        mt='12%'
                                        value={formData.end_date}
                                        onChange={handleChange}
                                        name='end_date'
                                    />
                                    {formErrors.end_date && (
                                        <FormErrorMessage mb={"2%"}>{formErrors.end_date}</FormErrorMessage>
                                    )}
                                </FormControl>
                            </Flex>
                        </Flex>

                        <Flex direction="column" width="100%" mt={4}>
                            <Text color="gray.400" mb="1%" fontWeight="bold">
                                Duration:
                            </Text>
                            <FormControl isInvalid={!!formErrors.duration} w='400px' >
                                <RadioGroup setFormData={setFormData} />
                                {formErrors.duration && (
                                    <FormErrorMessage mb={"2%"}>{formErrors.duration}</FormErrorMessage>
                                )}
                            </FormControl>
                        </Flex>

                        <Flex>
                            <Flex direction={'column'} width={'90%'}>
                                <Text color={'gray.400'} mb={'1%'} marginTop={'2%'} fontWeight={'bold'}>
                                    Reason of request
                                </Text>
                                <Text color={'gray.400'} mb={'1%'}>
                                    Please add a detailed description for the reason of this request.
                                    If you do not provide enough details your request is subject to be denied
                                </Text>
                                <FormControl isInvalid={!!formErrors.reason_of_request} w='400px' >
                                    <Textarea
                                        width={"215%"}
                                        background="white"
                                        value={formData.reason_of_request}
                                        onChange={handleChange}
                                        name='reason_of_request'
                                    />
                                    {formErrors.reason_of_request && (
                                        <FormErrorMessage mb={"2%"}>{formErrors.reason_of_request}</FormErrorMessage>
                                    )}
                                </FormControl>
                            </Flex>
                        </Flex>

                        <Flex>
                            <Flex direction={'column'} width={'90%'}>
                                <Text color={'gray.400'} mb={'1%'} fontWeight={'bold'}>
                                    Coverage
                                </Text>
                                <Text color={'gray.400'} mb={'1%'}>
                                    Remember you are required to find your own coverage or swap.
                                    Please state the details of said coverage or swap below.
                                </Text>
                                <FormControl isInvalid={!!formErrors.coverage} w='400px' >
                                    <Input
                                        width={"215%"}
                                        className='custom-placeholderInputs'
                                        background="white"
                                        value={formData.coverage}
                                        onChange={handleChange}
                                        name='coverage'
                                        marginBottom={'4%'}
                                    />
                                    {formErrors.coverage && (
                                        <FormErrorMessage mb={"2%"}>{formErrors.coverage}</FormErrorMessage>
                                    )}
                                </FormControl>
                            </Flex>
                        </Flex>

                        {total > 0 && (
                            <Flex mt={'3%'} width={'95%'}>
                                <Alert status='warning'>
                                    <AlertIcon />
                                    This absence overlaps with {total} other people in the company.
                                </Alert>
                            </Flex>
                        )}

                        <Flex display={'flex'} justifyContent={'center'} mt={'1%'} marginBottom={'3%'}>
                            <Button class="buttonCreate">  {/*  onClick={handleSubmit}*/}
                                Submit <EditIcon ml={2} />
                            </Button>
                            <Button class="buttonCancel" onClick={() => {
                                onClose();
                                clearForm();
                            }}>
                                Cancel <CloseIcon ml={2} />
                            </Button>
                        </Flex>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

export default PermissionRequestModal;
