import {
  Box, Flex, Button, Text
} from "@chakra-ui/react";
import CardProfile from "components/card/cardProfile";
import { TriangleDownIcon } from '@chakra-ui/icons'

export default function Requests(props) {
  const { user, openModalVacations, openModalPermissions, openModalChangeSchedule, ...rest } = props;

  return (
    <CardProfile {...rest} >
      <Flex direction={'column'}>
        <Text
          color={'#1c2147'}
          fontWeight='bold'
          fontSize='2xl'
          mb='2%'
          ml={'2%'}
        >
          Requests
        </Text>

        <Button _hover={{ bg: '#424ea8', color: 'white' }} onClick={openModalVacations} borderRadius={'5px'} >
          <Box as='span' flex='1' textAlign='left'>
            Vacations
          </Box>
          <TriangleDownIcon />
        </Button>


        <Button _hover={{ bg: '#fb9539', color: 'white' }} onClick={openModalPermissions} borderRadius={'5px'}>
          <Box as='span' flex='1' textAlign='left'>
            Permission
          </Box>
          <TriangleDownIcon />
        </Button>

        <Button _hover={{ bg: '#ff6f94', color: 'white' }} onClick={openModalChangeSchedule} borderRadius={'5px'}>
          <Box as='span' flex='1' textAlign='left'>
            Change schedule
          </Box>
          <TriangleDownIcon />
        </Button>





      </Flex>
    </CardProfile>
  );
}
