import axios from 'axios';
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import {
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton,
    Text, Button, Box, Flex, Badge,
    TableContainer, Table, Thead, Tbody, Tr, Th, Td
} from '@chakra-ui/react';
import { MdOutlinePendingActions } from "react-icons/md";

function SupervisorPermission({ isOpen, onClose }) {

    const [permissions, setPermissions] = useState([]);
    const navigate = useNavigate();

    const fetchData = async () => {
        try {
            const response = await axios.get('/getPermissions')
            setPermissions(response.data);
        } catch (error) {
            console.error('Error al obtener datos del usuario:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const getColorStatusHR = (option) => {
        const colorMap = {
            1: '#fabb1c',
            2: '#a2bd34',
            3: '#fc7466',
            4: '#8a6dce'
        };
        return colorMap[option] || '#808080';
    };

    const getColorStatusMG = (option) => {
        const colorMap = {
            1: '#fabb1c',
            2: '#8a6dce',
            3: '#7080fc',
            4: '#a2bd34',
            5: '#fc7466',
            6: '#fc7466'
        };
        return colorMap[option] || '#808080';
    };

    const getColorStatusClient = (option) => {
        const colorMap = {
            1: '#fabb1c',
            2: '#a2bd34',
            3: '#fc7466'
        };
        return colorMap[option] || '#808080';
    };

    const handlePermissions = async () => {
        navigate('/admin/permissions');
        onClose();
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'6xl'} closeOnOverlayClick={false} closeOnEsc={false}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} color={'#1c2147'} mt={'70px'} fontWeight={'bold'} fontSize={'40px'}>
                    <span style={{ marginRight: '8px' }}>Permission To Check</span>
                    <MdOutlinePendingActions size={'45px'} />
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <TableContainer>
                        <Table size={'sm'} mt={"2%"}>
                            <Thead bg={"white"}>
                                <Tr>
                                    <Th textAlign={'center'}>Name</Th>
                                    <Th textAlign={'center'}>HR Status</Th>
                                    <Th textAlign={'center'}>Call Center Manager Status</Th>
                                    <Th textAlign={'center'}>Client Status</Th>
                                    <Th textAlign={'center'}>Start Date</Th>
                                    <Th textAlign={'center'}>End Date</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {permissions && permissions.length > 0 ? (
                                    permissions.map((permissions, index) => (
                                        <Tr key={permissions.id} className={index % 2 === 0 ? 'white-row' : 'gray-row'}>
                                            <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                                                {permissions.user.name.split(' ')[0]} {permissions.user.last_name.split(' ')[0]}
                                            </Td>
                                            <Td textAlign={'center'} fontWeight={"bold"}>
                                                <Badge
                                                    color='white'
                                                    bg={getColorStatusHR(permissions.status_HR)}
                                                    borderRadius={'5px'}
                                                    width={'auto'}
                                                    fontSize={'10px'}
                                                    whiteSpace={'pre-wrap'}
                                                >

                                                    {(() => {
                                                        switch (permissions.status_HR) {
                                                            case 1:
                                                                return "In process";
                                                            case 2:
                                                                return "Compliant";
                                                            case 3:
                                                                return "Non-compliant";
                                                            case 4:
                                                                return "Compliant(Exception)";
                                                            default:
                                                                return "---";
                                                        }
                                                    })()}
                                                </Badge>

                                            </Td>
                                            <Td textAlign={'center'} fontWeight={"bold"}>
                                                <Badge
                                                    color='white'
                                                    bg={getColorStatusMG(permissions.status_MG)}
                                                    borderRadius={'5px'}
                                                    width={'100px'}
                                                    fontSize={'10px'}
                                                    whiteSpace={'pre-wrap'}
                                                >

                                                    {(() => {
                                                        switch (permissions.status_MG) {
                                                            case 1:
                                                                return "Compliant";
                                                            case 2:
                                                                return "To verify";
                                                            case 3:
                                                                return "Verified";
                                                            case 4:
                                                                return "Approved";
                                                            case 5:
                                                                return "Denied";
                                                            case 6:
                                                                return "Non-verified";
                                                            default:
                                                                return "---";
                                                        }
                                                    })()}
                                                </Badge>

                                            </Td>

                                            <Td textAlign={'center'} fontWeight={"bold"}>
                                                <Badge
                                                    color='white'
                                                    bg={getColorStatusClient(permissions.status_Client)}
                                                    borderRadius={'5px'}
                                                    width={'100px'}
                                                    fontSize={'10px'}
                                                    whiteSpace={'pre-wrap'}
                                                >

                                                    {(() => {
                                                        switch (permissions.status_Client) {
                                                            case 1:
                                                                return "Pending";
                                                            case 2:
                                                                return "Verified";
                                                            case 3:
                                                                return "Non-verified";
                                                            default:
                                                                return "---";
                                                        }
                                                    })()}
                                                </Badge>

                                            </Td>
                                            <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                                                {permissions.start_date}
                                            </Td>
                                            <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                                                {permissions.end_date}
                                            </Td>
                                        </Tr>
                                    ))
                                ) : (
                                    <Tr>
                                        <Th colSpan={'12'} >
                                            <Text
                                                color='#1c2147'
                                                fontSize={"25px"}
                                                textAlign={"center"}
                                                paddingTop={"15px"}
                                                paddingBottom={"15px"}
                                                bg={"#f2f2f2"}
                                                textTransform={'none'}
                                                fontFamily={'DM Sans'}
                                                letterSpacing="-1px"
                                            >
                                                No data
                                            </Text>
                                        </Th>
                                    </Tr>
                                )}
                            </Tbody>
                        </Table>
                    </TableContainer>
                    <Flex display={'flex'} justifyContent={'center'}>
                        <Button
                            mt={'70px'}
                            mb={'50px'}
                            size={'md'}
                            color={"white"}
                            bg={"#b9ce67"}
                            borderRadius={'5px'}
                            _hover={{ background: "#8aad01", color: 'white' }}
                            onClick={handlePermissions}
                        >
                            Go to Permissions
                        </Button>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

export default SupervisorPermission;
