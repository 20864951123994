import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Table, Thead, Tbody, Tr, Th, Td, TableContainer,
  Button, ButtonGroup,
  Select, Text, Input,
  Flex, Box, Badge,
} from '@chakra-ui/react'
import { RepeatIcon } from '@chakra-ui/icons'
import { FaRegStickyNote } from "react-icons/fa";
import { BiCalendarStar } from "react-icons/bi";
import { AiOutlineClear } from "react-icons/ai";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import ModalNotesUser from './modalNotes';

function MySchedules() {

  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [total, setTotal] = useState('');
  const [totalSchedule, setTotalSchedule] = useState('');
  const [totalBreaks, setTotalBreaks] = useState('');
  const [daySearch, setDaySearch] = useState('');
  const [filterDate, setFilterDate] = useState('');
  const [filterDateEnd, setFilterDateEnd] = useState('');
  const [scheduleData, setScheduleData] = useState([]);
  const [resetKey, setResetKey] = useState(0);
  const [selectSchedule, setSelectSchedule] = useState(null);
  const [statusSearch, setStatusSearch] = useState(1);
  const [dailySearch, setDailySearch] = useState('');
  const [openNotes, setNotes] = useState(false);
  const [bono, setBono] = useState('');

  const [userAuth, setUserAuth] = useState([]);

  const fetchDataAuth = async () => {
    try {
      const response = await axios.get("/user");
      const user = (response.data.user)
      setUserAuth(user);
    } catch (error) {
      console.error('Error al obtener datos del usuario:', error);
    }
  };

  useEffect(() => {
    fetchDataAuth();
  }, []);



  const clearFilters = () => {
    setDaySearch('');
    setFilterDate('');
    setFilterDateEnd('');
    setCurrentPage(1);
    setStatusSearch(1);
    setDailySearch('');
    setResetKey(resetKey + 1);
  }

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `/indexMySchedule?page=${currentPage}`
        + `${daySearch ? `&day=${daySearch}` : ''}`
        + `${filterDate ? `&startDate=${filterDate.trim()}` : ''}`
        + `${filterDateEnd ? `&endDate=${filterDateEnd.trim()}` : ''}`
        + `${statusSearch ? `&status=${statusSearch}` : ''}`
        + `${dailySearch ? `&type_daily=${dailySearch.trim()}` : ''}`
      );

      const responseData = response.data;
      setScheduleData(responseData.data.data);
      setTotal(responseData.data.total);
      setTotalPages(responseData.data.last_page);

      // Verifica que `total_durations` y el primer elemento estén presentes
      const totalDifference = responseData.total_durations && responseData.total_durations[0]
        ? responseData.total_durations[0].total_difference
        : '00:00:00'; // Valor por defecto si no hay datos

      setTotalSchedule(totalDifference);
    } catch (error) {
      console.error('Error fetching schedules:', error);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    fetchData();
  }, [currentPage, daySearch, , filterDate, filterDateEnd, resetKey, dailySearch, statusSearch]);

  const getColorSchemeForTypeDaily = (typeDaily) => {
    const colorMap = {
      1: '#7080fc',
      2: '#fcd677',
      3: '#8a6dce',
      4: '#dd67ae',
      5: '#fca066',
      6: '#fc7466',
      7: '#fcd677',
      8: '#dd67ae',
      9: '#fca066'
    };
    return colorMap[typeDaily] || 'green';
  };


  const handleNotes = async (item) => {
    try {
      const response = await axios.get(`/schedule/edit/${item.id}`);
      const { schedule: detail, user } = response.data;
      setSelectSchedule({ ...item, user });
      setNotes(true);
    } catch (error) {
      console.error('Error fetching employee details:', error);
    }
  };

  const closeNotesModal = () => {
    setNotes(false);
  };

  const isWeeklyBonusEligible = (userAuth) => {
    const eligibleCombinations = [
      { status_employee_id: 1, location_id: 1, type_user_id: 22 },
      { status_employee_id: 2, location_id: 1, type_user_id: 22 },
      { status_employee_id: 1, location_id: 2, type_user_id: 22 },
      { status_employee_id: 2, location_id: 2, type_user_id: 22 },
      { status_employee_id: 1, location_id: 3, type_user_id: 22 },
      { status_employee_id: 2, location_id: 3, type_user_id: 22 },
      { status_employee_id: 1, location_id: 1, type_user_id: 36 },
      { status_employee_id: 2, location_id: 1, type_user_id: 36 },
      { status_employee_id: 1, location_id: 2, type_user_id: 36 },
      { status_employee_id: 2, location_id: 2, type_user_id: 36 },
      { status_employee_id: 1, location_id: 3, type_user_id: 36 },
      { status_employee_id: 2, location_id: 3, type_user_id: 36 },
      { status_employee_id: 1, location_id: 1, type_user_id: 43 },
      { status_employee_id: 2, location_id: 1, type_user_id: 43 },
      { status_employee_id: 1, location_id: 2, type_user_id: 43 },
      { status_employee_id: 2, location_id: 2, type_user_id: 43 },
      { status_employee_id: 1, location_id: 3, type_user_id: 43 },
      { status_employee_id: 2, location_id: 3, type_user_id: 43 },
      { status_employee_id: 1, location_id: 1, type_user_id: 44 },
      { status_employee_id: 2, location_id: 1, type_user_id: 44 },
      { status_employee_id: 1, location_id: 2, type_user_id: 44 },
      { status_employee_id: 2, location_id: 2, type_user_id: 44 },
      { status_employee_id: 1, location_id: 3, type_user_id: 44 },
      { status_employee_id: 2, location_id: 3, type_user_id: 44 },
    ];

    return eligibleCombinations.some(combination =>
      combination.status_employee_id === userAuth.status_employee_id &&
      combination.location_id === userAuth.location_id &&
      combination.type_user_id === userAuth.type_user_id
    );
  };


  return (
    <TableContainer>
      <Flex
        direction='column'
        w='100%'
      >
        <Flex
          direction='column'
          w='100%'

          p={3}
          borderRadius="16px"
        >
        </Flex>
        <Flex
          direction='column'
          w='auto'
          bg="gray.200"
          p={2}
          borderRadius="16px"

        >
          <Flex align="center">
            <Select
              value={dailySearch}
              width={'auto'}
              borderColor={'#808080'}
              ml={4}
              fontSize={"sm"}
              color={'#808080'}
              onChange={(e) => {
                setDailySearch(e.target.value);
                setCurrentPage(1);
              }}
              _focusVisible={'none'}
              _hover={{
                borderColor: "#808080",
              }}
            >
              <option value="">Work type</option>
              <option value="1">Regular shift</option>
              <option value="2">Regular Training</option>
              <option value="4">Regular Coverage</option>
              <option value="5">Regular shadowing</option>
              <option value="3">Additional hours</option>
              <option value="7">Additional training</option>
              <option value="8">Additional coverage</option>
              <option value="9">Additional shadowing</option>
            </Select>
            <Select
              value={statusSearch}
              width={'auto'}
              borderColor={'#808080'}
              ml={4}
              fontSize={"sm"}
              color={'#808080'}
              onChange={(e) => {
                setStatusSearch(e.target.value);
                setCurrentPage(1);
              }}
              _focusVisible={'none'}
              _hover={{
                borderColor: "#808080",
              }}
            >
              <option value="">Status</option>
              <option value="1">Normal</option>
              <option value="2">Off</option>
              <option value="3">Holiday</option>
            </Select>
            <Flex
              border={'1px solid #808080'}
              display={'flex'}
              borderRadius={'4px'}
              ml={4} mr={4}
            >
              <Input
                width={'auto'}
                border={'0px'}
                type="date"
                fontSize={"sm"}
                value={filterDate}
                onChange={(e) => {
                  setFilterDate(e.target.value);
                  setCurrentPage(1);
                }}
                _focusVisible={'none'}
                color={'#808080'}
              />
              <Button bg={'none'} color={'#808080'} onClick={() => setFilterDate('')}>
                <RepeatIcon />
              </Button>
            </Flex>
            <Flex
              border={'1px solid #808080'}
              display={'flex'}
              borderRadius={'4px'}
              ml={4} mr={4}
            >
              <Input
                width={'auto'}
                border={'0px'}
                type="date"
                fontSize={"sm"}
                value={filterDateEnd}
                onChange={(e) => {
                  setFilterDateEnd(e.target.value);
                  setCurrentPage(1);
                }}
                _focusVisible={'none'}
                color={'#808080'}
              />
              <Button bg={'none'} color={'#808080'} onClick={() => setFilterDateEnd('')}>
                <RepeatIcon />
              </Button>
            </Flex>
            <Button onClick={clearFilters} bg={'transparent'} _hover={{ backgroundColor: 'transparent' }} color={'#808080'} padding={"0px"} title="Clear filters" >
              <AiOutlineClear size={'26px'} />
            </Button>
          </Flex>
        </Flex>
        <br />
        <Table>
          <Thead bg={"white"}>
            <Tr bg={'white'}>
              <Th textAlign={'center'}>Date</Th>
              <Th textAlign={'center'}>Time</Th>
              <Th textAlign={'center'}>Work type</Th>
              <Th textAlign={'center'}>Client</Th>
              <Th textAlign={'center'}>Shift</Th>
              <Th textAlign={'center'}>Lunch </Th>
              <Th textAlign={'center'}>Notes</Th>
            </Tr>
          </Thead>
          <Tbody>
            {scheduleData && scheduleData.length > 0 ? (
              scheduleData.map((item, index) => (
                <Tr key={item.id} className={index % 2 === 0 ? 'white-row' : 'gray-row'} mb={'50px'}>
                  <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>{item.date}</Td>
                  <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>{item.time_start_12hr}-{item.time_end_12hr}</Td>
                  <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                    {(() => {
                      switch (item.status) {
                        case 1:
                          return (
                            <Badge color={"white"} bg={getColorSchemeForTypeDaily(item.type_daily)} borderRadius={"5px"}>
                              {(() => {
                                switch (item.type_daily) {
                                  case 1:
                                    return "Regular shift";
                                  case 2:
                                    return "Regular training";
                                  case 3:
                                    return "Additional hours";
                                  case 4:
                                    return "Regular coverage";
                                  case 5:
                                    return "Regular shadowing"
                                  case 6:
                                    return "No shift"
                                  case 7:
                                    return "Additional training"
                                  case 8:
                                    return "Additional coverage"
                                  case 9:
                                    return "Additional shadowing"
                                  default:
                                    return "Regular shift";
                                }
                              })()}
                            </Badge>
                          );
                        case 2:
                          return (
                            <Badge color={"white"} bg={'#808080'} borderRadius={"5px"}>
                              Off
                            </Badge>
                          );
                        case 3:
                          return (
                            <Badge color={"white"} bg={'#fca066'} borderRadius={"5px"}>
                              Holiday
                            </Badge>
                          );
                        default:
                          return null;
                      }
                    })()}
                  </Td>
                  <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                    <Badge color={"white"} bg={'#424ea8'} borderRadius={"5px"}>
                      {item.client}
                    </Badge>
                  </Td>
                  <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>{item.shift === "0" ? '----' : item.shift}</Td>
                  <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>{item.lunch === "0" ? '----' : item.lunch}</Td>
                  <Td textAlign={"center"} color={"#1c2147"}>
                    <Button marginX="auto"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      bg="#e6e6e6"
                      _hover={{ background: "#7080fc", color: 'white' }}
                      color="#1c2147"
                      width="max-content"
                      borderRadius={'5px'}
                      onClick={() => handleNotes(item)}
                    >  <FaRegStickyNote ml={2} />
                      <Text ml={2}
                        flex={1}
                        textAlign="center"
                      >
                        {(item.countNotes !== 0) ? (
                          <>
                            {item.countNotes} Notes
                          </>
                        ) :
                          <>
                            Notes
                          </>
                        }
                      </Text>

                    </Button>
                  </Td>
                </Tr>
              ))
            ) : (
              <Tr>
                <Th colSpan={'9'} >
                  <Text
                    color='#1c2147'
                    fontSize={"25px"}
                    textAlign={"center"}
                    paddingTop={"15px"}
                    paddingBottom={"15px"}
                    bg={"#f2f2f2"}
                    textTransform={'none'}
                    fontFamily={'DM Sans'}
                    letterSpacing="-1px"
                  >
                    No data
                  </Text>
                </Th>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Flex >
      <Box width={"100%"} display={"flex"} mt={"30px"} flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"}>
        <Flex display={'flex'} justifyContent={'flex-start'} width={"max-content"} height={"max-content"}    >
          {total === 0 ? (
            <>
              <Flex bg={'#f2f2f2'} width={'auto'} display={'flex'} justifyContent={'center'}  >
                <Box mt={'5px'} mr={2} ml={4}>
                  <BiCalendarStar color={"#1c2147"} fontWeight={'bold'} />
                </Box>
                <Text fontSize={'14px'} mt={1} color={"#1c2147"} fontWeight={'bold'} mr={'20px'}>
                  No schedules
                </Text>
              </Flex>
            </>
          ) : (
            <>
              <Flex bg={'#f2f2f2'} width={'auto'} display={'flex'} justifyContent={'center'}>
                <Text fontSize={'14px'} mt={1} mr={3} color={"#1c2147"} fontWeight={'bold'} ml={'20px'}>
                  {total}
                </Text>
                <Box mt={'5px'} mr={1}>
                  <BiCalendarStar color={"#1c2147"} fontWeight={'bold'} />
                </Box>
                <Text fontSize={'14px'} mt={1} color={"#1c2147"} fontWeight={'bold'} mr={'20px'}>
                  {total === 1 ? 'Schedule' : 'Schedules'}
                </Text>
              </Flex>
            </>
          )}
        </Flex>
        <ButtonGroup isAttached variant="outline" justifyContent={'center'} display={'flex'} width={"max-content"} height={"max-content"}   >
          <Button
            borderRadius={'0px'}
            onClick={() => setCurrentPage(currentPage - 1)}
            isDisabled={currentPage === 1}
            bg="#808080" color={"white"}
            _hover={{ background: "#808080", }}
            _active={{ background: "#808080", }}
          ><BsArrowLeftShort fontSize="20px" />
          </Button>
          <Text class="btnTextPages">{currentPage} of {totalPages}</Text>
          <Button
            borderRadius={'0px'} ml={4}
            onClick={() => setCurrentPage(currentPage + 1)}
            isDisabled={currentPage === totalPages}
            bg="#808080" color={"white"}
            _hover={{ background: "#808080", }}
            _active={{ background: "#808080", }}
          ><BsArrowRightShort fontSize="20px" />
          </Button>
        </ButtonGroup>
        <Flex display={'flex'} justifyContent={'flex-start'} width={"max-content"} height={"max-content"}   >
          {totalSchedule === 0 ? (
            null
          ) : (
            <>
              <Flex bg={'#f2f2f2'} width={'auto'} display={'flex'} justifyContent={'center'} height={"max-content"}  >
                <Text fontSize={'18px'} mt={1} mr={3} color={"#1c2147"} fontWeight={'bold'} ml={'20px'}>
                  Total: {totalSchedule}
                </Text>
              </Flex>
            </>
          )}

          {/* {isWeeklyBonusEligible(userAuth) && (
            bono <= 0 ? (
              <Flex bg={'#f2f2f2'} width={'auto'} display={'flex'} justifyContent={'center'} height={"max-content"}>
                <Text fontSize={'18px'} mt={1} mr={3} color={"#1c2147"} fontWeight={'bold'} ml={'20px'}>
                  Weekly Bono: 0
                </Text>
              </Flex>
            ) : (
              <Flex bg={'#f2f2f2'} width={'auto'} display={'flex'} justifyContent={'center'} height={"max-content"}>
                <Text fontSize={'18px'} mt={1} mr={3} color={"#1c2147"} fontWeight={'bold'} ml={'20px'}>
                  Weekly Bonus: {bono}
                </Text>
              </Flex>
            )
          )} */}

        </Flex>
      </Box>
      {openNotes && (
        <ModalNotesUser
          isOpen={openNotes}
          onClose={closeNotesModal}
          schedule={selectSchedule}
          data={fetchData}
          isWeeklyBonusEligible={isWeeklyBonusEligible}
          userAuth={userAuth}
        />
      )
      }
    </TableContainer >
  );
}
export default MySchedules; 