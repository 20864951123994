import React from 'react';
//Icons
import { Icon } from '@chakra-ui/react';
import { MdDashboard, MdHome, } from 'react-icons/md';
import { BsFillFilePersonFill } from "react-icons/bs";
import { FaRegCalendar, FaUsers } from "react-icons/fa6";
import { IoTicketSharp } from "react-icons/io5";
import { TbReportAnalytics } from "react-icons/tb";
import { IoMdSettings } from "react-icons/io";
import { FaMoneyBillWave } from "react-icons/fa";
import { ImProfile } from "react-icons/im";

import DashboardsDefault from 'views/admin/dashboards/default';
import UsersSchedulesOverview from 'views/admin/main/schedules/weeklyschedule';
import UsersOverview from 'views/admin/main/users/newUser/items/userDashboard';
import ClientsOverview from 'views/admin/main/clients';
import DailyOverview from 'views/admin/main/schedules/dailyschedule';
import ReportsOverview from 'views/admin/main/reports';
import IncidentsOverview from 'views/admin/main/incidents';
import DefaultUser from 'views/admin/main/others/messages/indexUser';
import Default from 'views/admin/main/others/messages';
import ReportsSupOverview from 'views/admin/main/reports/indexSUP';

import MyScheduleOverview from 'views/admin/main/schedules/myschedule';
import OverviewProfile from 'views/admin/main/profile';

import ReportsPROverview from 'views/admin/main/reports/indexPR';
import IncidentsAgentOverview from 'views/admin/main/incidents/indexAgent';
import MedicalNotesOverview from 'views/admin/main/Medical_Notes';
import ReportsExpOverview from 'views/admin/main/reports/indexExp';
import IndexTask from 'views/admin/main/others/messages/indexTask';
import ReportsBonoOverview from 'views/admin/main/reports/indesBono';
import ReportsATZOverview from 'views/admin/main/reports/indexATZ';

//Finances
import PrenominalMXRUOverview from 'views/admin/main/prenominals/indexMXRU';
import PrenominalGTMOverview from 'views/admin/main/prenominals/indexGTM';
import PrenominalRDOverview from 'views/admin/main/prenominals/indexRD';
import PrenominalBZOverview from 'views/admin/main/prenominals/indexBZ';
import SalariesBZOverview from 'views/admin/main/finances/indexBZ';
import SalariesMXOverview from 'views/admin/main/finances/indexMX';

const routesFinanceManager = [
  // --- Dashboards ---
  {
    name: 'Home',
    layout: '/admin',
    path: '/home',
    icon: <Icon as={MdHome} width="30px" height="30px" color="inherit" />,
    component: <DashboardsDefault />,
  },
  {
    name: 'Profile',
    path: '/profile',
    icon: <Icon as={ImProfile} width="30px" height="30px" color="inherit" />,
    layout: '/admin',
    component: <OverviewProfile />,
  },
  {
    name: 'Dashboard',
    layout: '/admin',
    path: '/dashboards',
    icon: <Icon as={MdDashboard} width="30px" height="30px" color="inherit" />,
    component: <DailyOverview />,
  },
  {
    name: 'Schedules',
    icon: <Icon as={FaRegCalendar} width="30px" height="30px" color="inherit" />,
    path: '/schedules',
    collapse: true,
    items: [
      {
        name: 'Scheduler',
        layout: '/admin',
        path: '/schedules',
        exact: false,
        component: <UsersSchedulesOverview />,
      },
      {
        name: 'My schedule',
        path: '/my-schedule',
        layout: '/admin',
        component: <MyScheduleOverview />,
      },
    ],
  },
  {
    name: 'Tickets',
    icon: <Icon as={IoTicketSharp} width="30px" height="30px" color="inherit" />,
    path: '/Ticket',
    collapse: true,
    items: [
      {
        name: 'Requests',
        layout: '/admin',
        path: '/ticket/chat',
        exact: false,
        component: <Default />,
      },
      {
        name: 'Completed',
        layout: '/admin',
        path: '/ticket/dashboard',
        exact: false,
        component: <IncidentsOverview />,
      },
      {
        name: 'Ongoing',
        layout: '/admin',
        path: '/ticketU/chat',
        exact: false,
        component: <DefaultUser />,
      },
      {
        name: 'My Completed',
        layout: '/admin',
        path: '/ticketU/finish',
        exact: false,
        component: <IncidentsAgentOverview />,
      },
      {
        name: 'Pending tasks',
        layout: '/admin',
        path: '/ticket/tasks',
        exact: false,
        component: <IndexTask />,
      },
    ],
  },
  {
    name: 'Reports',
    path: '/reports',
    icon: <Icon as={TbReportAnalytics} width="30px" height="30px" color="inherit" />,
    collapse: true,
    items: [
      {
        name: 'Billing',
        layout: '/admin',
        path: '/reports/overview',
        exact: false,
        component: <ReportsOverview />,
      },
      {
        name: 'Timesheet',
        layout: '/admin',
        path: '/reports/timesheet',
        exact: false,
        component: <ReportsSupOverview />,
      },
      {
        name: 'Payroll',
        layout: '/admin',
        path: '/reports/payroll',
        exact: false,
        component: <ReportsPROverview />,
      },
      {
        name: 'Bonos',
        layout: '/admin',
        path: '/reports/bonos',
        exact: false,
        component: <ReportsBonoOverview />,
      },
      {
        name: 'Attendance Zoom',
        layout: '/admin',
        path: '/reports/attendanceZoom',
        exact: false,
        component: <ReportsATZOverview />,
      },
      {
        name: 'Exports',
        layout: '/admin',
        path: '/reports/exports',
        exact: false,
        component: <ReportsExpOverview />,
      },
    ],
  },
  {
    name: 'Human Resources',
    icon: <Icon as={FaUsers} width="30px" height="30px" color="inherit" />,
    path: '/hr',
    collapse: true,
    items: [
      {
        name: 'Medical Notes',
        path: '/MedicalNotes',
        layout: '/admin',
        component: <MedicalNotesOverview />,
      },
    ],
  },
  {
    name: 'Finances',
    path: '/finances',
    icon: <Icon as={FaMoneyBillWave} width="30px" height="30px" color="inherit" />,
    collapse: true,
    items: [
      {
        name: 'Prepayroll',
        path: '/prepayroll',
        collapse: true,
        items: [
          // {
          //   name: 'Mexico(MID)',
          //   path: '/mxmid',
          //   layout: '/admin',
          //   component: <MedicalNotesOverview />,
          // },
          {
            name: 'Mexico(RU)',
            path: '/mxru',
            layout: '/admin',
            component: <PrenominalMXRUOverview />,
          },
          {
            name: 'Belize',
            path: '/bz',
            layout: '/admin',
            component: <PrenominalBZOverview />,
          },
          {
            name: 'Dominican Republic',
            layout: '/admin',
            path: '/rd',
            exact: false,
            component: <PrenominalRDOverview />,
          },
          {
            name: 'Guatemala',
            layout: '/admin',
            path: '/gtm',
            exact: false,
            component: <PrenominalGTMOverview />,
          },
          // {
          //   name: 'Colombia',
          //   layout: '/admin',
          //   path: '/col',
          //   exact: false,
          //   component: <OverviewChangeSchedules />,
          // },
        ],
      },
      {
        name: 'Salaries',
        path: '/salaries',
        collapse: true,
        items: [
          {
            name: 'Mexico',
            path: '/salariesmx',
            layout: '/admin',
            component: <SalariesMXOverview />,
          },
          {
            name: 'Belize',
            path: '/salariesbz',
            layout: '/admin',
            component: <SalariesBZOverview />,
          },
        ],
      },
    ],
  },
  {
    name: 'Users',
    path: '/users',
    layout: '/admin',
    icon: <Icon as={BsFillFilePersonFill} width="30px" height="30px" color="inherit" />,
    collapse: false,
    component: <UsersOverview />,

  },
  {
    name: 'Others',
    path: '/others',
    icon: <Icon as={IoMdSettings} width="30px" height="30px" color="inherit" />,
    collapse: true,
    items: [
      {
        name: 'Clients',
        path: '/clients',
        component: <ClientsOverview />,
        layout: '/admin',
      },
    ],
  },
];

export default routesFinanceManager;
