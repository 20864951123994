import {
    Flex,
    Button, Text, Input, Select,
    FormControl, FormErrorMessage
} from "@chakra-ui/react";
import React from 'react';
import Select2 from 'react-select'
export default function SchedulesSections({ handleChange, formData, errorMsg, optionsUsers, customStyles, handleCoverageChange, resetKey, selectedCoverage, optionClients, filterOptions, handleClientChange, selectedClient, handleUpdate }) {

    return (
        <Flex direction={'column'} p={'1%'}>
            <Flex display={'flex'} mt={'3%'} justifyContent={'center'}>
                <Text color={'#1c2147'} fontWeight={'bold'} fontSize={'250%'}>
                    Schedule
                </Text>
            </Flex>
            <Flex display={'flex'} mt={'5%'} justifyContent={'space-between'} width={'27%'} marginLeft={'35%'}   >
                <Text color={'#1c2147'} fontWeight={'bold'} fontSize={'120%'} marginLeft={'4%'}>
                    Hrs
                </Text>
                <Text color={'#1c2147'} fontWeight={'bold'} fontSize={'120%'}  >
                    Min
                </Text>

            </Flex>
            <Flex display={'flex'} justifyContent={'space-around'}  >
                <Input type='text' bg='#f2f2f2' borderColor={"white"} marginLeft={'30%'} width={'20%'} fontSize={'90%'} color={'#808080'} value={formData.hours} onChange={handleChange} name='hours' fontWeight={'bold'} textAlign={'center'} />
                <Text mt={'0.7%'} color={'#808080'} mr={'2%'} ml={'2%'}>
                    :
                </Text>
                <Input type='text' bg='#f2f2f2' borderColor={"white"} marginRight={'30%'} width={'20%'} fontSize={'90%'} color={'#808080'} value={formData.minutes} onChange={handleChange} name='minutes' fontWeight={'bold'} textAlign={'center'} />

            </Flex>

            <Flex display={'flex'} mt={'5%'} justifyContent={'space-between'} width={'100%'}    >

                <Text color={'#1c2147'} fontWeight={'bold'} fontSize={'125%'} ml={"10%"} >
                    Start time
                </Text>
                <Text color={'#1c2147'} fontWeight={'bold'} fontSize={'125%'} ml={"10%"} mr={"15%"}>
                    End time
                </Text>
            </Flex>
            <Flex display={'flex'} justifyContent={'space-around'}  >
                <FormControl isInvalid={errorMsg} width={'50%'} >
                    <Input type='time' bg='#f2f2f2' width={'100%'} borderColor={"white"} color={'#424ea8'} value={formData.time_start} onChange={handleChange} name='time_start' fontWeight={'bold'} textAlign={'center'} />
                    {errorMsg && <FormErrorMessage>{errorMsg}</FormErrorMessage>}
                </FormControl>
                <FormControl isInvalid={errorMsg} width={'50%'}>
                    <Input type='time' bg='#f2f2f2' width={'100%'} borderColor={"white"} color={'#424ea8'} value={formData.time_end} readOnly name='time_end' fontWeight={'bold'} textAlign={'center'} />
                    {errorMsg && <FormErrorMessage>{errorMsg}</FormErrorMessage>}
                </FormControl>
            </Flex>


            <Flex display={'flex'} mt={'10%'} justifyContent={'center'}  >
                <Flex direction={'column'} alignItems={'center'} mr={'1%'}>
                    <Text color={'#1c2147'} fontWeight={'bold'} fontSize={'120%'} mb={'5%'}>
                        Type of day
                    </Text>
                    <Select bg='#f2f2f2' textAlign={"center"} borderColor={"white"} width={"90%"} mr={'1%'} color={'#424ea8'} defaultValue={formData.status} onChange={handleChange} name='status' fontWeight={'bold'}>
                        <option value="">Status</option>
                        <option value="1">Normal</option>
                        <option value="12">Agent overnight</option>
                        <option value="11">Surecall O/C</option>
                        <option value='14'>Stand by</option>
                        <option value="2">Off</option>
                        <option value="3">Holiday</option>
                        <option value='15'>Holiday Off</option>
                        <option value='4'>Vacation</option>
                        <option value='5'>Maternity Leave</option>
                        <option value='6'>Permission request</option>
                        <option value='7'>Wedding Leave</option>
                        <option value='8'>Medical Leave</option>
                        <option value='9'>Paternity Leave</option>
                        <option value='10'>Bereavement Leave</option>
                        <option value='13'>No longer with us</option>
                    </Select>
                </Flex>

                <Flex direction={'column'} alignItems={'center'}>
                    <Text color={'#1c2147'} fontWeight={'bold'} fontSize={'120%'} mb={'5%'}>
                        Type of shift
                    </Text>
                    <Select bg='#f2f2f2' textAlign={"center"} borderColor={"white"} width={"90%"} color={'#424ea8'} defaultValue={formData.type_daily} onChange={handleChange} name='type_daily' fontWeight={'bold'}>
                        <option value="">Work type</option>
                        <option value="1">Regular shift</option>
                        <option value="2">Regular Training</option>
                        <option value="4">Regular Coverage</option>
                        <option value="5">Regular shadowing</option>
                        <option value="3">Additional hours</option>
                        <option value="7">Additional training</option>
                        <option value="8">Additional coverage</option>
                        <option value="9">Additional shadowing</option>
                        <option value="6">No shift</option>
                    </Select>
                </Flex>
            </Flex>
            <Flex display={'flex'} justifyContent={'center'} mt={'3%'}>
                <Flex direction={'column'} alignItems={'center'}>

                    {formData.type_daily === '4' || formData.type_daily === '5' || formData.type_daily === "8" || formData.type_daily === "9" ? (
                        <>
                            <Text color={'#1c2147'} fontWeight={'bold'} fontSize={'120%'}>
                                User Coverage
                            </Text>
                            <Select2
                                options={optionsUsers}
                                styles={customStyles}
                                onChange={handleCoverageChange}
                                placeholder="Select User"
                                key={`userSelect-${resetKey}`}
                                value={optionsUsers.find(option => option.value === selectedCoverage)}
                                isSearchable
                                filterOption={filterOptions}
                            />
                        </>
                    ) : (
                        null
                    )}
                </Flex>
            </Flex>

            <Flex alignItems={'center'} direction={'column'}>
                <Flex display={'flex'} mt={'10%'}>
                    <Flex direction={'column'}  >
                        <Text color={'#1c2147'} fontWeight={'bold'} fontSize={'110%'} mb={'1%'}>
                            Client
                        </Text>
                        <Select2
                            options={optionClients}
                            styles={customStyles}
                            onChange={handleClientChange}
                            placeholder="Select client"
                            key={`clientSelect-${resetKey}`}
                            value={optionClients.find(option => option.value === selectedClient)}
                        />
                        <Text color={'#1c2147'} fontWeight={'bold'} fontSize={'110%'} mb={'1%'} mt={'10%'}>
                            Arrival
                        </Text>
                        <Select textAlign={"center"} bg='#f2f2f2' borderColor={"white"} color={'#424ea8'} defaultValue={formData.option} onChange={handleChange} name='option' fontWeight={'bold'}>
                            <option value="">Status</option>
                            <option value="1">---</option>
                            <option value="2">On time</option>
                            <option value="3">Late</option>
                            <option value="4">Clock out</option>
                            <option value="5">Supervisor</option>
                            <option value="6">Call out</option>
                        </Select>
                    </Flex>
                </Flex>
            </Flex>

            <Flex mt='10%' marginLeft={'40%'}>
                <Button
                    bg={'#b9ce67'}
                    borderRadius={'5px'}
                    _hover={{ background: "#8aad01", }}
                    color={'white'}
                    onClick={handleUpdate}
                >
                    Update
                </Button>
            </Flex>
        </Flex>
    );
} 