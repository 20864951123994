import axios from 'axios';
import React, { useEffect, useState } from "react";
import {
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton,
    Text, Button, Flex, Input,
    FormControl, FormErrorMessage
}
    from '@chakra-ui/react';
import ModalChat from '../searchBar/ModalChat';
import Ticket_Gray from "assets/img/dashboards/Ticket_Gray.svg"
import Select2 from 'react-select'

function ModalIT({ isOpen, onClose, modalContent, selectedIncidentId, incidentID, openModalChat }) {
    const [joiningRoomId, setJoiningRoomId] = useState(null);
    const [incident, setIncident] = useState(null);
    const [modalChat, setModalChat] = useState(false);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [formErrors, setFormErrors] = useState({});
    const [userAuth, setUserAuth] = useState([]);
    const [selectedUrgent, setSelectedUrgent] = useState([]);

    const fetchDataAuth = async () => {
        try {
            const response = await axios.get("/user");
            const user = parseInt(response.data.user.type_user_id, 10);
            setUserAuth(user);
        } catch (error) {
            console.error('Error al obtener datos del usuario:', error);
        }
    };

    const fetchData = async () => {
        try {
            const response = await axios.get(`/showTicket/${incidentID}`);
            setIncident(response.data);
        } catch (error) {
            console.error('Error fetching incident:', error);
        }
    };

    const clearText = () => {
        setSelectedUrgent([]);
        setFormErrors({});
    }


    const handleJoinClick = async () => {
        if (selectedIncidentId !== null) {
            setJoiningRoomId(selectedIncidentId);
            const selectUrgency = selectedUrgent.value;
            try {
                await axios.post(`/chat/rooms/${selectedIncidentId}/joinUser`, { message: newMessage, chatRomId: selectedIncidentId, incident_id: incidentID, urgency: selectUrgency })
                    .then(response => {
                        const newMessageData = response.data.message;
                        const user = response.data.user;
                        setMessages([...messages, { ...newMessageData, user }]);
                        setNewMessage('');
                        openModalChat();
                        onClose();
                        setFormErrors({});
                    })
                    .catch(error => {
                        console.error(error);
                        setFormErrors(error.response.data.errors);
                    });
            } finally {
                setJoiningRoomId(null);
            }
        }
    };

    useEffect(() => {
        if (incidentID) {
            fetchData();
        }
        fetchDataAuth();
        clearText();
    }, [incidentID]);

    const getText = () => {
        switch (selectedIncidentId) {
            case 1:
                return ' Internet issues.';
            case 2:
                return ' Vacation request.';
            case 3:
                return ' Hiring status.';
            case 4:
                return ' Client issues.';
            case 5:
                return ' Adding extra hours.';
            case 6:
                return ' Payment issues.';
            case 7:
                return ' Client feedback.';
            case 8:
                return 'Supervisors task';
            default:
                return 'Please specify the issue.';
        }
    };

    const cancelTicket = () => {
        axios.delete(`/incidents/delete/${incident.id}`)
            .then(response => {
                onClose();
            })
            .catch(error => {
                console.error(error);
            });
    };

    const getColorIncident = (selectedIncidentId) => {
        const colorMap = {
            1: '#ff6f94',
            2: '#fabb1c',
            3: '#633cbd',
            4: '#fb9539',
            5: '#a2bd34',
            6: '#6bc2ed',
            7: '#7080fc',
            8: '#fc7466',
        };
        return colorMap[selectedIncidentId] || '#ff6f94';
    };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            border: '2px solid transparent',
            borderRadius: '8px',
            backgroundColor: '#f2f2f2',
            marginRight: '1%',
            textAlign: 'center',
            width: '300px',
            '&:hover': {
                border: '2px solid transparent',
            },
            '&:focus-visible': {
                outline: 'none',
                boxShadow: 'none'
            },
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: '#f2f2f2',
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#808080' : state.isFocused ? 'white' : '#808080',
            backgroundColor: state.isSelected ? '#f2f2f2' : state.isFocused ? '#bcc3f3' : 'white',
        }),
    };


    const urgents = [
        { value: 1, label: 'Yes' },
        { value: 2, label: 'No' }
    ];


    const handleSelectChange = (selectedOption) => {
        setSelectedUrgent(selectedOption);
    };


    return (
        <Flex>
            <Modal isOpen={isOpen} onClose={onClose} size={'xl'} isCentered closeOnOverlayClick={false}
                closeOnEsc={false}>
                <ModalOverlay />
                <ModalContent
                    style={{
                        backgroundImage: `url(${Ticket_Gray})`,
                        backgroundSize: '180%',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'left',
                        backgroundPositionX: '-350px',
                        backgroundPositionY: '-120px'
                    }}
                    borderRadius={'20px'}
                    width={"500px"}
                    height={"600px"}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                >
                    <ModalHeader
                        width={"max-content"}
                        textAlign="center"
                        color={'#1c2147'}
                        fontSize={"40px"}
                        mt={'5%'}

                    >
                        Incident Request
                    </ModalHeader>

                    <Text
                        borderRadius={"10px"}
                        width={"150px"}
                        pt={1}
                        fontSize={"22px"}
                        color={"white"}
                        textAlign={"center"}
                        height={"42px"}
                        bg={getColorIncident(selectedIncidentId)}
                        mt={"2%"}
                    >
                        {(() => {
                            switch (selectedIncidentId) {
                                case 1:
                                    return "IT";
                                case 2:
                                    return "HR";
                                case 3:
                                    return "Hiring";
                                case 4:
                                    return "OnCall";
                                case 5:
                                    return "Schedules";
                                case 6:
                                    return "Finances";
                                case 7:
                                    return "Calls";
                                case 8:
                                    return "Supervisors";
                                default:
                                    return "IT";
                            }
                        })()}
                    </Text>
                    <Text
                        mt={"3%"}
                        fontSize={'18px'}
                        textAlign={'center'}
                        color={'#1c2147'}
                        fontWeight={"bold"}
                        width={"max-content"}
                    >
                        <br />
                        Write a brief description of the issue, <br /> like this: <Text as="span" color={'#7a86e6'}>{getText()}</Text>
                    </Text>
                    <Text mt={4} mb={4} fontWeight={"bold"} width={"max-content"} color={"#1c2147"} >
                        For support with your incident, <span style={{ color: '#b9ce67' }}> join the chat.</span>
                    </Text>

                    <FormControl width={"70%"} mb={6} isInvalid={!!formErrors.urgency}>
                        <Flex display={'flex'} justifyContent={'center'} mb={'2%'} mt={'1%'} direction={'column'} alignItems={'center'}>
                            <Text mt={'1%'} mr={'20px'} color={'#1c2147'} fontWeight={'bold'} mb={'5%'} >
                                Are you having trouble taking calls?
                            </Text>
                            <Select2
                                placeholder="Select"
                                options={urgents}
                                value={selectedUrgent}
                                styles={customStyles}
                                onChange={handleSelectChange}
                            />
                        </Flex>
                        {formErrors.urgency && (
                            <FormErrorMessage
                                display={'flex'}
                                justifyContent={'center'}
                            >
                                The problem field is required.
                            </FormErrorMessage>
                        )}
                    </FormControl>

                    <FormControl width={"60%"} mb={6} isInvalid={!!formErrors.message}>
                        <Input width={"100%"}
                            textAlign={"center"}
                            bg={"#f2f2f2"}
                            borderColor={"#f2f2f2"}
                            value={newMessage}
                            onChange={(e) => setNewMessage(e.target.value)}
                            placeholder='Type a message'
                        />
                        {formErrors.message && (
                            <FormErrorMessage display={'flex'} justifyContent={'center'}>{formErrors.message}</FormErrorMessage>
                        )}
                    </FormControl>
                    <ModalFooter width={"max-content"} mb={'30px'}>
                        <Button
                            bg={'#b9ce67'}
                            _hover={{ background: "#8aad01", color: 'white' }}
                            color={'white'}
                            mr={3}
                            borderRadius='10px'
                            fontWeight='bold'
                            onClick={handleJoinClick}
                        >
                            <Text mr={1}>
                                Join Chat
                            </Text>
                        </Button>
                        <Button
                            bg={'#424ea8'}
                            _hover={{ background: "#fc7466", color: 'white' }}
                            color={'white'}
                            mr={3}
                            borderRadius='10px'
                            fontWeight='bold'
                            onClick={cancelTicket}
                        >
                            Cancel Ticket
                        </Button>
                    </ModalFooter>
                </ModalContent>

            </Modal >
            {modalChat && (
                <ModalChat isOpen={modalChat} onClose={() => setModalChat(false)} selectedIncidentId={selectedIncidentId} />
            )}

        </Flex>
    );
}

export default ModalIT;
