import axios from 'axios';
import React, { useEffect, useState } from "react";
import Swal from 'sweetalert2'
import {
    Modal, ModalOverlay, ModalContent, ModalFooter, ModalBody, ModalCloseButton,
    Box, Flex,
    Text,
    Button,
    Textarea, Select, useToast, FormControl, FormErrorMessage, VStack
} from '@chakra-ui/react';

import bgMundochat from "assets/img/dashboards/Mundo_chat_2.png";
import { FaRegTrashAlt } from "react-icons/fa";
import { EditIcon } from '@chakra-ui/icons'


function ModalNotes({ isOpen, onClose, schedule, userAuth, reOpenUpdate, handleUpdate, setReOpenUpdate }) {

    const toast = useToast();
    const [formErrors, setFormErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectNote, setSelectNote] = useState(null);
    const [buttonText, setButtonText] = useState("Add");
    const [buttonTextUpdate, setButtonTextUpdate] = useState("Update");
    const [showUpdate, setShowUpdate] = useState(false);
    const [notes, setNotes] = useState([]);
    const [formData, setFormData] = useState({
        description: '',
        option: '',
    });

    const clearForm = () => {
        setFormData({
            description: '',
            option: '',
        });
    };

    const fetchData = async (schedules) => {
        try {
            const response = await axios.get(`/notes/${schedule.id}`);
            setNotes(response.data);
        } catch (error) {
            console.error('Error fetching Notes:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const deleteNote = async (note) => {
        const confirmDelete = await Swal.fire({
            title: 'Do you want to delete this note?',
            text: 'The note will be deleted.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#b9ce67',
            cancelButtonColor: '#fc7466',
            confirmButtonText: "Delete",
            cancelButtonText: "Cancel",
            closeOnConfirm: true,
            closeOnCancel: false
        }).then((result) => {
            return result.isConfirmed
        });

        if (confirmDelete) {
            try {
                const response = await axios.delete(`/notes/delete/${note.id}`);
                toast({
                    title: " Note deleted",
                    status: "success",
                    position: 'top',
                    duration: 3000,
                    isClosable: true,
                });
                fetchData();
            } catch (error) {
                console.error(error);
                toast({
                    title: "Error deleting Note",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            }
        }
    };

    const handleSubmit = async () => {
        setIsSubmitting(true);
        setButtonText("Submitting...");

        const formSubmitData = {
            description: formData.description,
            option: formData.option,
            schedule_id: schedule.id,
        };
        try {
            const response = await axios.post(`/notes/create`, formSubmitData);
            toast({
                title: 'Note created successfully',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            clearForm();
            fetchData();
            setFormErrors({});

            if (reOpenUpdate) {
                handleUpdate(schedule);
                setReOpenUpdate(false);
                onClose();
            }
            setButtonText("Add");
        } catch (error) {
            toast({
                title: "Error Creating a note",
                status: "error",
                duration: 1000,
                isClosable: true,
            });
            console.error('Error creating employee:', error);
            setFormErrors(error.response.data.errors);
            setButtonText("Add");
        } finally {
            setIsSubmitting(false);
        }
    };


    useEffect(() => {
        fetchData();
    }, []);

    const getColorSchemeOption = (option) => {
        const colorMap = {
            1: '#fcd677', //Late
            2: '#8a6dce', //Call out
            3: '#fca066', //Leave early
            4: '#ff9fb8', //No call, no show
            5: '#6bc2ed', //Cell phone use
            6: '#fc7466', //Adherence (Camera, OBS, Browsing)
            7: '#bf957e', //Other and clock in description
            8: '#a2bd34', //Permission with client 
            9: '#7080fc', //Disciplinary actions
            10: '#424ea8', //Vacations
            11: '#fcd677', //Late from lunch
            12: '#fcd677', //Late from break
            14: '#fc7466', //Missed punches

        };
        return colorMap[option] || '#b3b3b3'; //default
    };

    const handleNote = async (note) => {
        setSelectNote(note);
        setShowUpdate(true);
    }

    const handleInputChangeUpdate = (e) => {
        setSelectNote((prevNote) => ({
            ...prevNote,
            description: e.target.value,
        }));
    };

    const handleUpdateNote = async (note) => {
        setIsSubmitting(true);
        setButtonTextUpdate("Submitting...");

        try {
            const response = await axios.post(`/notes/update/${note.id}`, {
                description: note.description,
            });
            toast({
                title: 'Note update successfully',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            fetchData();
            setFormErrors({});
            setShowUpdate(false);
            setButtonTextUpdate("Update");
        } catch (error) {
            toast({
                title: "Error Creating a note",
                status: "error",
                duration: 1000,
                isClosable: true,
            });
            console.error('Error creating employee:', error);
            setFormErrors(error.response.data.errors);
            setButtonTextUpdate("Update");
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'full'} isCentered
            closeOnOverlayClick={false}
            closeOnEsc={false}>
            <ModalOverlay />
            <ModalContent borderRadius={'20px'} width={"75%"} bg={"#f2f2f2"}>

                <ModalCloseButton bg={"#cccccc"} _hover={{ background: '#fc7466' }} />
                <ModalBody display={"flex"} flexDirection={"row"}>
                    <Flex width={"100%"} justify={"space-evenly"} mt={"8%"}>

                        <Box borderRadius={"30px"} bg={"white"}
                            width={"600px"}
                            height={"800px"}
                            overflow='hidden'
                            px={{ base: "10px", md: "40px" }}
                            position='static'
                            style={{
                                overflowY: 'auto',
                                backgroundImage: `url(${bgMundochat})`,
                                backgroundSize: 'auto',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                            }}
                        >
                            <Text mt={'40px'} fontSize={"50px"} color={'#1c2147'} fontWeight={"bold"} textAlign={'left'}>
                                Notes
                            </Text>

                            <Box fontSize={"16px"} color={'#b3b3b3'}   >
                                <Flex style={{ display: 'flex' }} flexDirection={"column"} width={"max-content"} color={'#7080fc'} fontSize={'24px'}  >

                                    <span style={{ marginRight: '8px', color: '#7080fc', fontWeight: 'bold' }}>{schedule.operator} {schedule.operatorL}</span>
                                    <span style={{ marginRight: '8px', color: '#808080', marginTop: '10px', fontStyle: 'italic' }}>{schedule.date}</span>
                                </Flex>
                            </Box>

                            <Flex direction={'column'} height={"560px"} overflowY={"auto"}>
                                {notes && notes.length > 0 ? (
                                    notes.map((note, index) => (
                                        <Flex p={1}
                                            key={note.id}
                                            boxSizing='border-box'
                                            direction={'column'}
                                        >
                                            <VStack spacing={3}>
                                                <Flex flexDirection={"column"} width={"100%"}  >
                                                    <Box width={"100%"} mt={2} mb={4} height={"4px"} bg={"#e6e6e6"}></Box>
                                                    <Flex justifyContent={'left'} width={'80%'} ml={'8%'}>
                                                        <Text color={'#1c2147'} fontSize={'15px'} textAlign={'center'} mr={"4px"} fontWeight={'bold'}>
                                                            {(() => {
                                                                const optionValue = note.option !== null ? note.option : 7;

                                                                switch (optionValue) {
                                                                    case 1:
                                                                        return "Late";
                                                                    case 2:
                                                                        return "Call out";
                                                                    case 3:
                                                                        return "Leave early";
                                                                    case 4:
                                                                        return "No call, no show";
                                                                    case 5:
                                                                        return "Cell phone use";
                                                                    case 6:
                                                                        return "Adherence (Camera, OBS, Browsing)";
                                                                    case 7:
                                                                        return "Other";
                                                                    case 8:
                                                                        return "Permission with client";
                                                                    case 9:
                                                                        return "Disciplinary actions";
                                                                    case 10:
                                                                        return "Vacations";
                                                                    case 11:
                                                                        return "Late from lunch";
                                                                    case 12:
                                                                        return "Late from break";
                                                                    case 14:
                                                                        return "Missed punches";
                                                                    default:
                                                                        return "Other";
                                                                }
                                                            })()}
                                                        </Text>
                                                    </Flex>
                                                    <Flex mt={'1%'} >
                                                        <Box width={"22px"} background={getColorSchemeOption(note.option)} borderRadius='5px' height={"auto"} ></Box>

                                                        <Box textAlign={'center'} color={'#b3b3b3'} textColor={"#b3b3b3"} fontWeight='medium' bg={"white"} borderRadius={"10px"} ml={4} >
                                                            <Text
                                                                width={"380px"}
                                                                textAlign={"left"}
                                                                fontSize={'14px'}
                                                                p={2} textColor={"black"}
                                                                whiteSpace={'pre-wrap'}
                                                            >
                                                                {note.description}
                                                            </Text>
                                                        </Box>
                                                        {[1, 2, 3, 4, 5, 6, 7, 37].includes(userAuth) ? (
                                                            <Button
                                                                ml={2}
                                                                onClick={() => deleteNote(note)}
                                                                bg={"transparent"}
                                                                _hover={{ background: "transparent" }}
                                                            >
                                                                <FaRegTrashAlt size={"22px"} />
                                                            </Button>
                                                        ) : null}
                                                        {[1, 2, 3, 4, 5, 6, 7, 37, 30, 46].includes(userAuth) ? (
                                                            <Button
                                                                ml={2}
                                                                onClick={() => handleNote(note)}
                                                                bg={"transparent"}
                                                                _hover={{ background: "transparent" }}
                                                            >
                                                                <EditIcon />
                                                            </Button>
                                                        ) : null}
                                                    </Flex>
                                                    <Flex ml={12} mt={2} justifyContent={'left'}>
                                                        <Text color={'#808080'} fontSize={'11px'} textAlign={'center'} mr={"4px"}>
                                                            {note.user_id === 1 ? 'Dedicated portal system' : `${note.user.name} ${note.user.last_name}`}
                                                        </Text>
                                                        <Text color={'#808080'} fontSize={'11px'} textAlign={'center'} mr={"4px"}>
                                                            {note.created}
                                                        </Text>
                                                        <span style={{ color: '#808080', fontSize: '12px' }}></span>
                                                    </Flex>
                                                </Flex>


                                            </VStack>
                                            <span style={{ borderTop: '4px', borderColor: '#808080', width: '100%' }}></span>
                                        </Flex>
                                    ))
                                ) : (
                                    <Box>
                                        <Text mt={"50%"} bg={"#f2f2f2"} fontSize={"24px"} fontWeight={"bold"} textAlign={"center"}>
                                            No notes
                                        </Text>
                                    </Box>
                                )}
                            </Flex>
                        </Box>
                        <Flex direction={'column'}>
                            <Box mr={"5px"} width={"550px"} height={"max-content"} p={10} borderRadius={"30px"} bg={"white"} >
                                <FormControl mt='40px' isInvalid={!!formErrors.option}>
                                    <Select
                                        w='100%'
                                        fontSize={'md'}
                                        borderRadius={'5px'}
                                        name='option'
                                        value={formData.option}
                                        onChange={handleInputChange}
                                        color={'#cccccc'}
                                        background={"white"}
                                        textAlign={"center"}
                                        h='44px'
                                        borderColor={"#b3b3b3"}
                                        errorBorderColor='#fc7466'
                                        maxh='44px'
                                        placeholder='Select Option'>
                                        <option value='2'>Call out</option>
                                        <option value='1'>Late</option>
                                        <option value='11'>Late from lunch</option>
                                        <option value='12'>Late from break</option>
                                        <option value='3'>Leave early</option>
                                        <option value='4'>No call, no show</option>
                                        <option value='5'>Cell phone use</option>
                                        <option value='6'>Adherence (Camera, OBS, Browsing)</option>
                                        <option value='14'>Missed punches</option>
                                        <option value='8'>Permission with client</option>
                                        <option value='9'>Disciplinary actions</option>
                                        <option value='10'>Vacations</option>
                                        <option value='7'>Other</option>
                                    </Select>
                                    {formErrors.option && (
                                        <FormErrorMessage>This field is required.</FormErrorMessage>
                                    )}
                                </FormControl>
                                <FormControl isInvalid={!!formErrors.description}>
                                    <Textarea borderColor={"#b3b3b3"}
                                        placeholder="Add a description here..."
                                        mt={'40px'}
                                        name="description"
                                        minHeight={"200px"}
                                        maxHeight={"510px"}
                                        className='custom-placeholderInputs'
                                        background="white"
                                        value={formData.description}
                                        onChange={handleInputChange}
                                        errorBorderColor='#fc7466'
                                    >
                                    </Textarea>
                                    {formErrors.description && (
                                        <FormErrorMessage>This field is required.</FormErrorMessage>
                                    )}
                                </FormControl>
                                <Box mt={'10%'} display={'flex'} alignContent={"center"} justifyContent={"center"}>
                                    <Button
                                        bg={'#5868e0'}
                                        _hover={{ background: "#8aad01", color: 'white' }}
                                        width={"120px"}
                                        color={'white'}
                                        mr={3}
                                        borderRadius='8px'
                                        fontWeight='bold'
                                        onClick={handleSubmit}
                                        isDisabled={isSubmitting}
                                    >
                                        <Text mr={1}>
                                            {buttonText}
                                        </Text>
                                    </Button>
                                    <Button bg="#fc7466" color={'white'} _hover={{ background: "#808080", color: 'white' }} width={"120px"} mr={3} borderRadius='8px' fontWeight='bold' onClick={onClose}>

                                        <Text mr={1}>
                                            Exit notes
                                        </Text>
                                    </Button>
                                </Box>

                            </Box>
                            {
                                showUpdate && (
                                    <Box mr={"5px"} width={"550px"} height={"max-content"} p={10} borderRadius={"30px"} bg={"white"} mt={'5%'} >
                                        <FormControl>
                                            <Textarea borderColor={"#b3b3b3"}
                                                placeholder="Add a description here..."
                                                mt={'40px'}
                                                name="description"
                                                minHeight={"200px"}
                                                maxHeight={"510px"}
                                                className='custom-placeholderInputs'
                                                background="white"
                                                value={selectNote?.description || ''}
                                                onChange={handleInputChangeUpdate}
                                                errorBorderColor='#fc7466'
                                            >
                                            </Textarea>
                                        </FormControl>
                                        <Box mt={'10%'} display={'flex'} alignContent={"center"} justifyContent={"center"}>
                                            <Button
                                                bg={'#5868e0'}
                                                _hover={{ background: "#8aad01", color: 'white' }}
                                                width={"120px"}
                                                color={'white'}
                                                mr={3}
                                                borderRadius='8px'
                                                fontWeight='bold'
                                                onClick={() => handleUpdateNote(selectNote)}
                                                isDisabled={isSubmitting}
                                            >
                                                <Text mr={1}>
                                                    {buttonTextUpdate}
                                                </Text>
                                            </Button>
                                        </Box>
                                    </Box>
                                )
                            }
                        </Flex>


                    </Flex>
                </ModalBody >

            </ModalContent >
        </Modal >
    );
}



export default ModalNotes;
