import {
    Table, Thead, Tbody, Tr, Th, Td,
    Menu, MenuButton, MenuItem, MenuList,
    Button, ButtonGroup,
    Badge, Flex, Box, Text
} from '@chakra-ui/react'
import React from "react";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import { SettingsIcon, EditIcon } from '@chakra-ui/icons'
import { FaUsers } from "react-icons/fa";

const SupervisorList = ({ setCurrentPage, users, getRowBackground, getColorSchemeForLocation, total, currentPage, totalPages, handleUpdateClient }) => {

    return (
        <>
            <Flex alignItems={"auto"}
                direction='column'
                width={'auto'}
                mt={5}
            >
                <Table>
                    <Thead bg={'white'}  >
                        <Tr>
                            <Th color='#808080' textAlign={'center'}>ID</Th>
                            <Th color='#808080' textAlign={'center'}>Full Name</Th>
                            <Th color='#808080' textAlign={'center'}>Dedicated People Email</Th>
                            <Th color='#808080' textAlign={'center'}>Type Of User</Th>
                            <Th color='#808080' textAlign={'center'}>Client</Th>
                            <Th color='#808080' textAlign={'center'}>Status & Location</Th>
                            <Th color='#808080' textAlign={'center'}>Actions</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {users && users.length > 0 ? (
                            users.map((user, index) => (
                                <Tr key={user.id} bg={getRowBackground(user)} className={index % 2 === 0 ? 'white-row' : 'gray-row'}>

                                    <Td color='#808080' textAlign={'center'}>{user.profile.mat_dp}</Td>
                                    <Td color='#1c2147' fontWeight='bold' textAlign={'center'}>
                                        {user.name} {user.last_name}
                                    </Td>
                                    <Td color='#1c2147' fontWeight='bold' textAlign={'center'}>{user.email}</Td>
                                    <Td textAlign={'center'}>
                                        <Badge
                                            backgroundColor={'#7080fc'}
                                            color='white'
                                            width={'180px'}
                                            fontSize={'10px'}
                                            borderRadius={'5px'}
                                        >
                                            {user.user_type.name}
                                        </Badge>
                                    </Td>
                                    <Td textAlign={"center"}>
                                        {user.supervisor_clients && user.supervisor_clients.length > 0 ? (
                                            user.supervisor_clients.length === 1 ? (
                                                <Badge
                                                    backgroundColor={'#dd67ae'}
                                                    color="white"
                                                    mt={1}
                                                    width={'158px'}
                                                    fontSize={'10px'}
                                                    borderRadius={'5px'}
                                                    textAlign={'center'}
                                                >
                                                    {user.supervisor_clients[0].client.name}
                                                </Badge>
                                            ) : (

                                                <Menu>
                                                    <MenuButton
                                                        as={Button}
                                                        backgroundColor={'#dd67ae'}
                                                        color="white" size="sm"
                                                        width={'158px'}
                                                        fontSize={'11px'}
                                                        borderRadius={'5px'}
                                                        mt={1}
                                                        _hover={{ background: "#dd67ae", }}
                                                        _active={{ background: "#dd67ae", }}
                                                        height={'25px'}
                                                        title='Show Clients'
                                                    >
                                                        {`${user.supervisor_clients.length} Clients`}
                                                    </MenuButton>
                                                    <MenuList maxH="200px" overflowY="auto">
                                                        {user.supervisor_clients.map((client, index) => (
                                                            <MenuItem key={index} justifyContent={'center'} color={'#808080'} width={'80%'} ml={'20px'} mt={'10px'}>
                                                                <Text fontWeight={'bold'}>
                                                                    {client.client.name}
                                                                </Text>
                                                            </MenuItem>
                                                        ))}
                                                    </MenuList>
                                                </Menu>
                                            )
                                        ) : (
                                            <Badge
                                                backgroundColor={'#dd67ae'}
                                                color="white"
                                                mt={1}
                                                width={'180px'}
                                                fontSize={'10px'}
                                                borderRadius={'5px'}
                                                textAlign={'center'}
                                            >
                                                No Client
                                            </Badge>
                                        )}
                                    </Td>
                                    <Td textAlign={'center'}>
                                        <Box display={"flex"} justifyContent={'space-between'}>
                                            <Badge bg={user.status_employee_id === 1 ? '#ff9fb8' : '#8a6dce'} width={"88px"} color={"white"} borderRadius={'5px'} >
                                                {user.status ? user.status.name : 'No Status'}
                                            </Badge>
                                            <Text
                                                flex={'1'}
                                                textAlign={'center'}
                                            >
                                                <Badge bg={getColorSchemeForLocation(user.location_id)} width={"50px"} color={"white"} borderRadius={'5px'}>
                                                    {(() => {
                                                        switch (user.location_id) {
                                                            case 1:
                                                                return "MX";
                                                            case 2:
                                                                return "BZ";
                                                            case 3:
                                                                return "BZ";
                                                            case 4:
                                                                return "RD";
                                                            case 5:
                                                                return "COL";
                                                            case 6:
                                                                return "GTM";
                                                            default:
                                                                return "MX";
                                                        }
                                                    })()}
                                                </Badge>
                                            </Text>

                                        </Box>
                                    </Td>
                                    <Td textAlign={'center'}>
                                        <Menu>
                                            <MenuButton borderRadius={"5px"} as={Button} _active={{ background: "#808080", color: 'white' }} bg="#1c2147" color={"white"} _hover={{ background: "#808080", color: 'white' }}>
                                                <SettingsIcon pb={"2px"} />
                                            </MenuButton>
                                            <MenuList>
                                                <MenuItem isDisabled>
                                                    <div style={{ fontWeight: 'bold', textAlign: 'center', width: "100%" }}>Actions</div>
                                                </MenuItem>
                                                <MenuItem>
                                                    <Button
                                                        borderRadius={"5px"}
                                                        display="flex"
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                        bg={'#f2f2f2'}
                                                        color='#1c2147'
                                                        _hover={{ background: "#fcd677", color: 'white' }}
                                                        width="100%"
                                                        onClick={() => handleUpdateClient(user)}
                                                    >
                                                        <Text
                                                            flex={1}
                                                            textAlign="center"
                                                        >
                                                            Add or Update Client
                                                        </Text>
                                                        <EditIcon ml={2} verticalAlign="middle" />
                                                    </Button>
                                                </MenuItem>
                                            </MenuList>
                                        </Menu>
                                    </Td>
                                </Tr>
                            ))
                        ) : (
                            <Tr>
                                <Th colSpan={'9'} >
                                    <Text
                                        color='#1c2147'
                                        fontSize={"25px"}
                                        textAlign={"center"}
                                        paddingTop={"15px"}
                                        paddingBottom={"15px"}
                                        bg={"#f2f2f2"}
                                        textTransform={'none'}
                                        fontFamily={'DM Sans'}
                                        letterSpacing="-1px"
                                    >
                                        No data
                                    </Text>
                                </Th>
                            </Tr>
                        )}
                    </Tbody>
                </Table>
                <Box width={"100%"} display={"flex"} mt={"30px"}>
                    <Flex display={'flex'} justifyContent={'flex-start'} width={"max-content"} pb={"10px"} mt={"8px"}  >
                        {total === 0 ? (
                            <>
                                <Flex bg={'#f2f2f2'} width={'150px'} display={'flex'} justifyContent={'center'}>
                                    <Box mt={'5px'} mr={2} ml={4}>
                                        <FaUsers color={"#1c2147"} fontWeight={'bold'} />
                                    </Box>
                                    <Text fontSize={'14px'} mt={1} color={"#1c2147"} fontWeight={'bold'}>
                                        No users
                                    </Text>
                                </Flex>
                            </>
                        ) : (
                            <>
                                <Flex bg={'#f2f2f2'} width={'150px'} display={'flex'} justifyContent={'center'}>
                                    <Text fontSize={'14px'} mt={1} mr={3} color={"#1c2147"} fontWeight={'bold'}>
                                        {total}
                                    </Text>
                                    <Box mt={'5px'} mr={1}>
                                        <FaUsers color={"#1c2147"} fontWeight={'bold'} />
                                    </Box>
                                    <Text fontSize={'14px'} mt={1} color={"#1c2147"} fontWeight={'bold'}>
                                        {total === 1 ? 'User' : 'Users'}
                                    </Text>
                                </Flex>
                            </>
                        )}
                    </Flex>
                    <ButtonGroup isAttached variant="outline" ml={"34%"} justifyContent={'center'} display={'flex'} width={"max-content"} >
                        <Button
                            borderRadius={'0px'}
                            onClick={() => setCurrentPage(currentPage - 1)}
                            isDisabled={currentPage === 1}
                            bg="#808080" color={"white"}
                            _hover={{ background: "#808080", }}
                            _active={{ background: "#808080", }}
                        ><BsArrowLeftShort fontSize="20px" />
                        </Button>
                        <Text class="btnTextPages">{currentPage} of {totalPages}</Text>
                        <Button
                            borderRadius={'0px'} ml={4}
                            onClick={() => setCurrentPage(currentPage + 1)}
                            isDisabled={currentPage === totalPages}
                            bg="#808080" color={"white"}
                            _hover={{ background: "#808080", }}
                            _active={{ background: "#808080", }}
                        ><BsArrowRightShort fontSize="20px" />
                        </Button>
                    </ButtonGroup>
                </Box>
            </Flex>

        </>
    );
}

export default SupervisorList;
