import { Text, Box, Flex, Button } from '@chakra-ui/react';
import React from "react";
import { FaUmbrellaBeach, FaNotesMedical, FaExchangeAlt } from "react-icons/fa";
import { RiHomeOfficeFill } from "react-icons/ri";
import { BsFillPatchCheckFill } from "react-icons/bs";
import { IoRibbonOutline } from "react-icons/io5";
import { RiPsychotherapyLine } from "react-icons/ri";
import { GiClothes } from "react-icons/gi";


//Vacations
import BelizeVacation from "assets/protocols/vacations/Belize_vacation_request_protocol.pdf";
import ColombiaVacation from "assets/protocols/vacations/Colombia_vacation_request_protocol.pdf";
import RDVacation from "assets/protocols/vacations/DominicanRep_vacation_request_protocol.pdf";
import GuatemalaVacation from "assets/protocols/vacations/Guatemala_vacation_request_protocol.pdf";
import MexicoMIDVacation from "assets/protocols/vacations/Mexico_MID_vacation_request_protocol.pdf";
import MexicoRemoteVacation from "assets/protocols/vacations/Mexico_remote_vacation_request_protocol.pdf";

//Medical Notes
import BelizeMedicalNotes from "assets/protocols/medical_note/belize_medical_note_protocol.pdf";
import MexicoMIDMedicalNotes from "assets/protocols/medical_note/Mexico_MID_medical_note_protocol.pdf"

//Permissions
import RemotePermission from "assets/protocols/permissions/Remote_permission_protocol.pdf";
import BelizeMexMIDPermission from "assets/protocols/permissions/Belize_Mex_MID_permission_protocol.pdf";

//Home office & Shift Change
import BelizeMexMIDHomeOffice from "assets/protocols/home_office_shift_change/Belize_Mex_MID_home_office_request_protocol.pdf";
import BelizeMexMIDPermanentShift from "assets/protocols/home_office_shift_change/Belize_Mex_MID_permanent_shift_change_request_protocol.pdf";
import RemotePermanentShift from "assets/protocols/home_office_shift_change/Remote_permanent_shift_change_request_protocol.pdf";

//Bonus
import WeeklyBonusMexico from "assets/protocols/bonus/Weekly bonus Mexico.pdf"
import WeeklyBonusBelice from "assets/protocols/bonus/Weekly bonus Belize.pdf"

//Psychology
import Psychology from "assets/protocols/psychology/Psychological_WEB.pdf"

//Dresscode
import Dresscode from "assets/protocols/dresscode/dress_code.pdf"

export default function Protocols({ user }) {


    const handleVacationPDF = () => {
        let pdfUrl;

        switch (true) {
            case user.location_id === 1 && user.status_employee_id === 1:
                pdfUrl = MexicoMIDVacation;
                break;
            case user.location_id === 2:
                pdfUrl = BelizeVacation;
                break;
            case user.location_id === 3:
                pdfUrl = BelizeVacation;
                break;
            case user.location_id === 4 && user.status_employee_id === 2:
                pdfUrl = RDVacation;
                break;
            case user.location_id === 5 && user.status_employee_id === 2:
                pdfUrl = ColombiaVacation;
                break;
            case user.location_id === 6 && user.status_employee_id === 2:
                pdfUrl = GuatemalaVacation;
                break;
            case user.location_id === 1 && user.status_employee_id === 2:
                pdfUrl = MexicoMIDVacation;
                break;
            case user.location_id === 1 && user.status_employee_id === 3:
                pdfUrl = MexicoRemoteVacation;
                break;
            default:
                pdfUrl = MexicoMIDVacation;
                break;
        }

        window.open(pdfUrl, '_blank');
    };


    const handleMedicalNotePDF = () => {
        let pdfUrl;

        switch (true) {
            case user.location_id === 1:
                pdfUrl = MexicoMIDMedicalNotes;
                break;
            case user.location_id === 2:
                pdfUrl = BelizeMedicalNotes;
                break;
            case user.location_id === 3:
                pdfUrl = BelizeMedicalNotes;
                break;
            default:
                pdfUrl = MexicoMIDMedicalNotes;
                break;
        }

        window.open(pdfUrl, '_blank');
    };

    const handlePermissionsPDF = () => {
        let pdfUrl;

        switch (true) {
            case user.status_employee_id === 1 && user.location_id === 1:
                pdfUrl = BelizeMexMIDPermission;
                break;
            case user.status_employee_id === 2 && user.location_id === 1:
                pdfUrl = BelizeMexMIDPermission;
                break;
            case user.status_employee_id === 3 && user.location_id === 1:
                pdfUrl = RemotePermission;
                break;
            case user.status_employee_id === 1 && user.location_id === 2:
                pdfUrl = BelizeMexMIDPermission;
                break;
            case user.status_employee_id === 2 && user.location_id === 2:
                pdfUrl = BelizeMexMIDPermission;
                break;
            case user.status_employee_id === 1 && user.location_id === 3:
                pdfUrl = BelizeMexMIDPermission;
                break;
            case user.status_employee_id === 2 && user.location_id === 3:
                pdfUrl = BelizeMexMIDPermission;
                break;
            case user.status_employee_id === 2 && user.location_id === 4:
                pdfUrl = RemotePermission;
                break;
            case user.status_employee_id === 2 && user.location_id === 5:
                pdfUrl = RemotePermission;
                break;
            case user.status_employee_id === 2 && user.location_id === 6:
                pdfUrl = RemotePermission;
                break;
            default:
                pdfUrl = BelizeMexMIDPermission;
                break;
        }

        window.open(pdfUrl, '_blank');
    };


    const handlePermanentShiftPDF = () => {
        let pdfUrl;

        switch (true) {
            case user.status_employee_id === 1 && user.location_id === 1:
                pdfUrl = BelizeMexMIDPermanentShift;
                break;
            case user.status_employee_id === 2 && user.location_id === 1:
                pdfUrl = BelizeMexMIDPermanentShift;
                break;
            case user.status_employee_id === 3 && user.location_id === 1:
                pdfUrl = RemotePermanentShift;
                break;
            case user.status_employee_id === 1 && user.location_id === 2:
                pdfUrl = BelizeMexMIDPermanentShift;
                break;
            case user.status_employee_id === 2 && user.location_id === 2:
                pdfUrl = BelizeMexMIDPermanentShift;
                break;
            case user.status_employee_id === 1 && user.location_id === 3:
                pdfUrl = BelizeMexMIDPermanentShift;
                break;
            case user.status_employee_id === 2 && user.location_id === 3:
                pdfUrl = BelizeMexMIDPermanentShift;
                break;
            case user.status_employee_id === 2 && user.location_id === 4:
                pdfUrl = RemotePermanentShift;
                break;
            case user.status_employee_id === 2 && user.location_id === 5:
                pdfUrl = RemotePermanentShift;
                break;
            case user.status_employee_id === 2 && user.location_id === 6:
                pdfUrl = RemotePermanentShift;
                break;
            default:
                pdfUrl = BelizeMexMIDPermanentShift;
                break;
        }

        window.open(pdfUrl, '_blank');
    };

    const handleHomeOfficePDF = () => {

        const pdfUrl = BelizeMexMIDHomeOffice;

        window.open(pdfUrl, '_blank');
    };


    const handleWeeklyBonus = () => {
        let pdfUrl;

        switch (true) {
            case user.status_employee_id === 1 && user.location_id === 1:
                pdfUrl = WeeklyBonusMexico;
                break;
            case user.status_employee_id === 2 && user.location_id === 1:
                pdfUrl = WeeklyBonusMexico;
                break;
            case user.status_employee_id === 1 && user.location_id === 2:
                pdfUrl = WeeklyBonusBelice;
                break;
            case user.status_employee_id === 2 && user.location_id === 2:
                pdfUrl = WeeklyBonusBelice;
                break;
            case user.status_employee_id === 1 && user.location_id === 3:
                pdfUrl = WeeklyBonusBelice;
                break;
            case user.status_employee_id === 2 && user.location_id === 3:
                pdfUrl = WeeklyBonusBelice;
                break;
            default:
                pdfUrl = WeeklyBonusMexico;
                break;
        }

        window.open(pdfUrl, '_blank');
    };

    const isWeeklyBonusEligible = (user) => {
        const eligibleCombinations = [
            { status_employee_id: 1, location_id: 1 },
            { status_employee_id: 2, location_id: 1 },
            { status_employee_id: 1, location_id: 2 },
            { status_employee_id: 2, location_id: 2 },
            { status_employee_id: 1, location_id: 3 },
            { status_employee_id: 2, location_id: 3 }
        ];

        return eligibleCombinations.some(combination =>
            combination.status_employee_id === user.status_employee_id &&
            combination.location_id === user.location_id
        );
    };

    const handlePsychology = () => {

        const pdfUrl = Psychology;

        window.open(pdfUrl, '_blank');
    };

    const isMedicalNote = (user) => {
        const eligibleCombinations = [
            { status_employee_id: 1, location_id: 1 },
            { status_employee_id: 2, location_id: 1 },
            { status_employee_id: 1, location_id: 2 },
            { status_employee_id: 2, location_id: 2 },
            { status_employee_id: 1, location_id: 3 },
            { status_employee_id: 2, location_id: 3 }
        ];

        return eligibleCombinations.some(combination =>
            combination.status_employee_id === user.status_employee_id &&
            combination.location_id === user.location_id
        );
    };


    const handleDresscode = () => {

        const pdfUrl = Dresscode;

        window.open(pdfUrl, '_blank');
    };


    return (
        <Flex className='protocols'>
            <Flex p={'5'} direction={'column'} width={"100%"}>
                <Flex  >
                    <Text fontSize="27px" fontWeight="700" mb={"1%"} color={'#1c2147'}>
                        View the protocols and free services
                    </Text>

                </Flex>

                <Text fontWeight={"bold"} mb={"5%"} color={'#b3b3b3'}  >
                    Clicking the box will view the pdf file.
                </Text>

                <Box position="relative">
                    <Flex
                        mr="5%"
                        mb="5%"
                        alignItems="center"
                        width="95%"
                    >
                        <Button
                            height="50px"
                            borderRadius="5px"
                            bg="#f2f2f2"
                            onClick={handlePsychology}
                            fontSize="20px"
                            display="flex"
                            width="100%"
                        >
                            <Box mr="10%">
                                <RiPsychotherapyLine color="#424ea8" fontSize="30px" />
                            </Box>
                            <Text color="#1c2147" fontWeight="bold">
                                Psychology
                            </Text>
                        </Button>
                    </Flex>
                </Box>


                <Flex direction={'row'}  >


                    <Flex mr={'5%'} mb={'5%'} alignItems={'center'} width={'80%'}>
                        <Button height='50px' borderRadius='5px' bg={'#f2f2f2'} onClick={handleVacationPDF} fontSize={'20px'} justifyContent={'space-evenly'} display={'flex'} width={'100%'}>
                            <Box >
                                <FaUmbrellaBeach color='#424ea8' fontSize={'30px'} />
                            </Box>
                            <Text color={'#1c2147'} fontWeight={'bold'}  >
                                Vacation
                            </Text>

                        </Button>
                    </Flex>

                    <Flex mr={'5%'} mb={'5%'} alignItems={'center'} width={'80%'}>
                        <Button height='50px' borderRadius='5px' bg={'#f2f2f2'} onClick={handleDresscode} fontSize={'20px'} justifyContent={'space-evenly'} display={'flex'} width={'100%'}>
                            <Box >
                                <GiClothes color='#424ea8' fontSize={'30px'} />
                            </Box>
                            <Text color={'#1c2147'} fontWeight={'bold'}  >
                                Dresscode
                            </Text>

                        </Button>
                    </Flex>

                    


                </Flex>

                <Flex direction={'row'}   >
                    <Flex mr={'5%'} mb={'5%'} alignItems={'center'} width={'45%'}>
                        <Button height='50px' borderRadius='5px' bg={'#f2f2f2'} onClick={handlePermanentShiftPDF} fontSize={'20px'} justifyContent={'space-evenly'} display={'flex'} width={'100%'}>
                            <Box>
                                <FaExchangeAlt fontSize={'25px'} color='#424ea8' />
                            </Box>
                            <Text color={'#1c2147'} fontWeight={'bold'}  >
                                Permanent Shift Change
                            </Text>

                        </Button>
                    </Flex>

                    <Flex mr={'5%'} mb={'5%'} alignItems={'center'} width={'45%'}>
                        <Button height='50px' borderRadius='5px' bg={'#f2f2f2'} onClick={handlePermissionsPDF} fontSize={'20px'} justifyContent={'space-evenly'} display={'flex'} width={'100%'}>
                            <Box  >
                                <BsFillPatchCheckFill color='#424ea8' fontSize={'30px'} />
                            </Box>
                            <Text color={'#1c2147'} fontWeight={'bold'}  >
                                Permission
                            </Text>

                        </Button>
                    </Flex>


                    {/* <Flex mr={'5%'} mb={'5%'} alignItems={'center'} width={'80%'}>
                        {(user.status_employee_id === 1) ? (
                            <Button height='50px' borderRadius='5px' bg={'#f2f2f2'} onClick={handleHomeOfficePDF} fontSize={'20px'} justifyContent={'space-evenly'} display={'flex'} width={'100%'}>
                                <Box mr={'5%'}>
                                    <RiHomeOfficeFill color='#424ea8' fontSize={'30px'} />
                                </Box>
                                <Text color={'#1c2147'} fontWeight={'bold'}  >
                                    Home Office
                                </Text>

                            </Button>
                        ) : null}
                    </Flex> */}

                    



                </Flex>

                <Flex direction={'row'}   >



                    <Flex mr={'5%'} mb={'5%'} alignItems={'center'} width={'45%'}>
                        {isWeeklyBonusEligible(user) && (
                            <Button height='50px' borderRadius='5px' bg={'#f2f2f2'} onClick={handleMedicalNotePDF} fontSize={'20px'} justifyContent={'space-evenly'} display={'flex'} width={'100%'}>
                                <Box >
                                    <FaNotesMedical color='#424ea8' fontSize={'30px'} />
                                </Box>
                                <Text color={'#1c2147'} fontWeight={'bold'}  >
                                    Medical Note
                                </Text>
                            </Button>
                        )}
                    </Flex>

                    <Flex mr={'5%'} mb={'5%'} alignItems={'center'} width={'45%'}>
                        {isMedicalNote(user) && (
                            <Button height='50px' borderRadius='5px' bg={'#f2f2f2'} onClick={handleWeeklyBonus} fontSize={'20px'} justifyContent={'space-evenly'} display={'flex'} width={'100%'}>
                                <Box>
                                    <IoRibbonOutline color='#424ea8' fontSize={'30px'} />
                                </Box>
                                <Text color={'#1c2147'} fontWeight={'bold'}  >
                                    Weekly Bonus
                                </Text>
                            </Button>
                        )}
                    </Flex>

                    
                    

                </Flex>

            </Flex>

        </Flex>

    );
}
