import React, { useState, useEffect, useRef } from 'react';
import {
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Heading,
  Button,
  Flex, Box,
  Image,
  Select, Input,Text,
  FormControl, FormErrorMessage,
  useToast,
} from '@chakra-ui/react';
import { EditIcon, CloseIcon } from '@chakra-ui/icons'
import avatar4 from 'assets/img/dashboards/grey_world.png';
import axios from 'axios';
import { FaUser } from "react-icons/fa";


const ModalCUT = ({ isOpen, onClose, user, onUserUpdate, timezone, setFlashing, location }) => {

  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const clearForm = () => {
    setFormData({
      location_id: '',
      id_time_zone: '',
      status_employee_id: '',
    });
    setFormErrors({});
  };

  const [formData, setFormData] = useState({
    location_id: user?.location_id || '',
    id_time_zone: user?.id_time_zone || '',
    status_employee_id: user?.status_employee_id || '',
  });

  const handleUpdate = async () => {

    const formSubmitData = {
      location_id: formData.location_id,
      id_time_zone: formData.id_time_zone,
      status_employee_id: formData.status_employee_id
    };

    try {
      const response = await axios.post(`/users/location/${user.id}`, formSubmitData);
      if (response.status === 200) {
        onUserUpdate();
        setFlashing(formSubmitData);
        toast({
          title: "Location Updated",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        clearForm();
        onClose();
      }
    } catch (error) {
      toast({
        title: "Error Updating Location",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      console.error('Error Updating Location:', error);
    }
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  if (!user) {
    return null;
  }

  return (
    <Modal size="lg" isOpen={isOpen} onClose={() => {
      onClose();
    }}
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <ModalOverlay />
      <ModalContent mt={"15%"} background={"#f2f2f2"} borderRadius={"30px"}>
      <ModalHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} color={'#1c2147'} mt={'3%'} fontWeight={'bold'} fontSize={'35px'}>
                    <Flex direction={'column'}>
                        <Text textAlign={'center'}>
                            Update Location
                        </Text>
                        <Flex style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} color={'#7080fc'} fontWeight={'bold'} fontSize={'22px'}>
                            <FaUser size={'18px'} />
                            <span style={{ marginLeft: '8px' }}>
                                <span style={{ marginRight: '8px', color: '#7080fc' }}>
                                    {user.name} {user.last_name}
                                </span>
                            </span>
                        </Flex>
                    </Flex>
                </ModalHeader>
        <ModalBody display={'flex'} justifyContent={'center'}>
          <Box>
            <FormControl width={"max-content"} >
              
              <FormControl mb={'20px'}>
                <Select
                  w='255px'
                  fontSize={'md'}
                  borderRadius={'10px'}
                  background="white"
                  textAlign={"center"}
                  name='location_id'
                  color={'#cccccc'}
                  defaultValue={formData.location_id}
                  onChange={handleChange}
                  h='44px'
                  maxh='44px'
                >
                  <option value=''>Select</option>
                  {location.map((c) => (
                    <option key={c.id} value={c.id}>
                      {c.country} - {c.estate}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <FormControl mb={'20px'}>
                <Select
                  w='255px'
                  fontSize={'md'}
                  borderRadius={'10px'}
                  background="white"
                  textAlign={"center"}
                  name='status_employee_id'
                  color={'#cccccc'}
                  defaultValue={formData.status_employee_id}
                  onChange={handleChange}
                  h='44px'
                  maxh='44px'
                >
                  <option value="">Status</option>
                  <option value="1">In office</option>
                  <option value="2">Remote</option>
                  <option value="3">Remote MX</option>
                </Select>
              </FormControl>
              <FormControl>
                <Select
                  w='255px'
                  fontSize={'md'}
                  borderRadius={'10px'}
                  background="white"
                  textAlign={"center"}
                  name='id_time_zone'
                  color={'#cccccc'}
                  defaultValue={formData.id_time_zone}
                  onChange={handleChange}
                  h='44px'
                  maxh='44px'
                >
                  <option value=''>Select</option>
                  {timezone.map((c) => (
                    <option key={c.id} value={c.id}>
                      {c.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </FormControl>
          </Box>
        </ModalBody>
        <ModalFooter justifyContent="center" pt={"10%"} pb={"10%"}>
          <Button onClick={handleUpdate} class="buttonCreate">
            Update <EditIcon ml={2} />
          </Button>
          <Button class="buttonCancel" onClick={onClose}>
            Close <CloseIcon ml={2} />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalCUT;
