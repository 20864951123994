import {
    Table, Thead, Tbody, Tr, Th, Td,
    Button, ButtonGroup, useColorModeValue,
    Text, Flex, Box, Badge, Input,
} from '@chakra-ui/react'
import { RepeatIcon } from '@chakra-ui/icons'
import React, { useEffect, useState } from "react";
import axios from 'axios';
import CardProfile from 'components/card/cardProfile';
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";

import { AiOutlineClear } from "react-icons/ai";
import { GrStatusInfo } from "react-icons/gr";

export default function UserRequests(props) {

    const { ...rest } = props;
    const [requests, setRequests] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState('');
    const [totalPages, setTotalPages] = useState(1);
    const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
    const [filterDate, setFilterDate] = useState('');
    const [filterDateEnd, setFilterDateEnd] = useState('');

    const clearFilters = () => {
        setFilterDate('');
        setFilterDateEnd('');
        setCurrentPage(1);
    }

    const fechDataRequests = async () => {

        try {
            const response = await axios.get(
                `/viewRequest?page=${currentPage}`
                + `${filterDate ? `&startDate=${filterDate.trim()}` : ''}`
                + `${filterDateEnd ? `&endDate=${filterDateEnd.trim()}` : ''}`
            );

            const responseData = response.data;
            setRequests(responseData.data);
            setTotalPages(responseData.last_page);
            setTotal(responseData.total)

        } catch (error) {

            console.error('Error al obtener datos del usuario:', error);

        }
    };

    useEffect(() => {
        fechDataRequests();
    }, [currentPage, filterDate, filterDateEnd]);

    const getColorStatus = (status_Client, status_HR) => {
        if (status_HR === 2 && status_Client === 2) {
            return '#a2bd34'; // Accepted (verde)
        }

        if (status_HR === 1 || status_HR === 2) {
            if (status_Client === 1 || status_Client === 2) {
                return '#fabb1c'; // Pending (amarillo)
            }
        }

        if (
            (status_Client === 1 && status_HR === 3) ||
            (status_Client === 3 && (status_HR === 1 || status_HR === 2)) ||
            (status_Client === 2 && status_HR === 3) ||
            (status_Client === 3 && status_HR === 3)
        ) {
            return '#fc7466'; // Rejected (rojo)
        }

        return '#8a6dce'; // Default (morado)
    };


    return (
        <CardProfile  {...rest} >
            <Text
                color={textColorPrimary}
                fontWeight='bold'
                fontSize='2xl'
                mt='10px'
                mb='4px'
                ml={'2%'}
            >
                Status Requests
            </Text>
            <Flex
                direction='column' justify={"space-evenly"}
                w='100%'
                p={'2%'}
                borderRadius="16px"
            >

                <Flex flexDirection={"column"} bg="gray.200" p={'2%'} borderRadius={"16px"} >
                    <Flex align="center"    >

                        <Flex
                            border={'1px solid #808080'}
                            display={'flex'}
                            borderRadius={'4px'}
                            ml={'1%'} mr={'1%'}
                        >
                            <Input

                                border={'0px'}
                                type="date"
                                fontSize={"sm"}
                                value={filterDate}
                                onChange={(e) => {
                                    setFilterDate(e.target.value);
                                    setCurrentPage(1);
                                }}
                                _focusVisible={'none'}
                                color={'#808080'}
                            />
                            <Button bg={'none'} color={'#808080'} onClick={() => setFilterDate('')}>
                                <RepeatIcon />
                            </Button>
                        </Flex>
                        <Flex
                            border={'1px solid #808080'}
                            display={'flex'}
                            borderRadius={'4px'}
                            ml={'1%'} mr={'1px'}
                        >
                            <Input

                                border={'0px'}
                                type="date"
                                fontSize={"sm"}
                                value={filterDateEnd}
                                onChange={(e) => {
                                    setFilterDateEnd(e.target.value);
                                    setCurrentPage(1);
                                }}
                                _focusVisible={'none'}
                                color={'#808080'}
                            />
                            <Button bg={'none'} color={'#808080'} onClick={() => setFilterDateEnd('')}>
                                <RepeatIcon />
                            </Button>
                        </Flex>

                        <Box height={"max-content"} width={"max-content"} >
                            <Button onClick={clearFilters} bg={'transparent'} _hover={{ backgroundColor: 'transparent' }} color={'#808080'} padding={"0px"} title="Clear filters" >
                                <AiOutlineClear size={'65%'} />
                            </Button>
                        </Box>
                    </Flex>

                </Flex>

            </Flex>
            <Table size={'sm'} mt={"2%"}>
                <Thead bg={"white"}>
                    <Tr>
                        <Th textAlign={'center'}>Requests</Th>
                        <Th textAlign={'center'}>Start Date</Th>
                        <Th textAlign={'center'}>End Date</Th>
                        <Th textAlign={'center'}>Status</Th>
                    </Tr>
                </Thead>
                <Tbody fontWeight={"bold"}>
                    {requests && requests.length > 0 ? (
                        requests.map((requests, index) => (
                            <Tr key={requests.id} className={index % 2 === 0 ? 'white-row' : 'gray-row'}>
                                <Td textAlign={'center'} color={"#1b2559"} >
                                    {requests.Requests}
                                </Td>
                                <Td textAlign={'center'} color={"#1b2559"} >
                                    {requests.start_date}
                                </Td>
                                <Td textAlign={'center'} color={"#1b2559"} >
                                    {requests.end_date}
                                </Td>

                                <Td textAlign={'center'}>
                                    <Badge
                                        color='white'
                                        bg={getColorStatus(requests.status_Client, requests.status_HR)}
                                        borderRadius={'5px'}
                                        width={'100%'}
                                        fontSize={'10px'}
                                        whiteSpace={'pre-wrap'}
                                    >
                                        {(() => {
                                            if (requests.status_HR === 2 && requests.status_Client === 2) {
                                                return "Accepted";
                                            }

                                            if (requests.status_HR === 1 || requests.status_HR === 2) {
                                                if (requests.status_Client === 1 || requests.status_Client === 2) {
                                                    return "Pending";
                                                }
                                            }

                                            if (
                                                (requests.status_Client === 1 && requests.status_HR === 3) ||
                                                (requests.status_Client === 3 && (requests.status_HR === 1 || requests.status_HR === 2)) ||
                                                (requests.status_Client === 2 && requests.status_HR === 3) ||
                                                (requests.status_Client === 3 && requests.status_HR === 3)
                                            ) {
                                                return "Rejected";
                                            }

                                            return "---";
                                        })()}
                                    </Badge>
                                </Td>


                            </Tr>
                        ))
                    ) : (
                        <Tr>
                            <Th colSpan={'12'} >
                                <Text
                                    color='#1b2559'
                                    fontSize={"25px"}
                                    textAlign={"center"}
                                    paddingTop={"15px"}
                                    paddingBottom={"15px"}
                                    bg={"#f2f2f2"}
                                    textTransform={'none'}
                                    fontFamily={'DM Sans'}
                                    letterSpacing="-1px"
                                >
                                    No data
                                </Text>
                            </Th>
                        </Tr>
                    )}
                </Tbody>
            </Table>
            <Box width={"100%"} display={"flex"} mt={"30px"}>
                <Flex display={'flex'} justifyContent={'flex-start'} width={"max-content"} pb={"10px"} mt={"8px"}  >
                    {total === 0 ? (
                        <>
                            <Flex bg={'#f2f2f2'} width={'150px'} display={'flex'} justifyContent={'center'}>
                                <Box mt={'5px'} mr={2} ml={4}>
                                    <GrStatusInfo color={"#1c2147"} fontWeight={'bold'} />
                                </Box>
                                <Text fontSize={'14px'} mt={1} color={"#1c2147"} fontWeight={'bold'}>
                                    No request
                                </Text>
                            </Flex>
                        </>
                    ) : (
                        <>
                            <Flex bg={'#f2f2f2'} width={'150px'} display={'flex'} justifyContent={'center'}>
                                <Text fontSize={'14px'} mt={1} mr={3} color={"#1c2147"} fontWeight={'bold'}>
                                    {total}
                                </Text>
                                <Box mt={'5px'} mr={1}>
                                    <GrStatusInfo color={"#1c2147"} fontWeight={'bold'} />
                                </Box>
                                <Text fontSize={'14px'} mt={1} color={"#1c2147"} fontWeight={'bold'}>
                                    {total === 1 ? 'Request' : 'Requests'}
                                </Text>
                            </Flex>
                        </>
                    )}
                </Flex>
                <ButtonGroup isAttached variant="outline" ml={"15%"} justifyContent={'center'} display={'flex'} width={"max-content"} >
                    <Button
                        borderRadius={'0px'}
                        onClick={() => setCurrentPage(currentPage - 1)}
                        isDisabled={currentPage === 1}
                        bg="#808080" color={"white"}
                        _hover={{ background: "#808080", }}
                        _active={{ background: "#808080", }}
                    ><BsArrowLeftShort fontSize="20px" />
                    </Button>
                    <Text class="btnTextPages">{currentPage} of {totalPages}</Text>
                    <Button
                        borderRadius={'0px'} ml={4}
                        onClick={() => setCurrentPage(currentPage + 1)}
                        isDisabled={currentPage === totalPages}
                        bg="#808080" color={"white"}
                        _hover={{ background: "#808080", }}
                        _active={{ background: "#808080", }}
                    ><BsArrowRightShort fontSize="20px" />
                    </Button>
                </ButtonGroup>
            </Box>
        </CardProfile>
    );
}
