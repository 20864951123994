import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2'
import {
    TableContainer, useToast, Flex,
} from '@chakra-ui/react'
import ShowSchedule from './Modals/modalshow';
import ScheduleUpdateModal from './Modals/modalupdate';
import DetailAudits from './Modals/modalAudit';
import CallOutModal from './Modals/modalCallOut';
import ModalNotes from './Modals/modalNotes';
import WeeklyList from './table';
import WeeklySearch from './search';
import ScheduleWeeklyUpdate from './Modals/modalupdateWeekly';
import ShowWeeklModal from './Modals/modalshowWeekly';
import ClockOutModal from './Modals/modalClockOut';

function WeeklySchedulesFunctions({ userAuth, userAuthID, setClientSearch, setStatusDSearch, setStatusSearch, setUserSearch, userSearch, setDailySearch, dailySearch, clientSearch, setAcceptSearch, acceptSearch, setPostSearch, setDaySearch, daySearch, setFilterDate, setFilterDateEnd, filterDate, filterDateEnd, postSearch, statusSearch, statusDSearch, }) {
    const [loading, setLoading] = useState(false);
    const toast = useToast();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [total, setTotal] = useState('');
    const [totalSchedule, setTotalSchedule] = useState('');
    const [scheduleData, setScheduleData] = useState([]);
    const [user, setUser] = useState([]);
    const [client, setClient] = useState([]);
    const [flashing, setFlashing] = useState(false);
    const [selectSchedule, setSelectSchedule] = useState(null);
    const [openShow, setOpenShow] = useState(false);
    const [resetKey, setResetKey] = useState(0);
    const [openUpdate, setOpenUpdate] = useState(false);
    const [openAudit, setOpenAudit] = useState(false);
    const [openCallOut, setCallOut] = useState(false);
    const [usersClients, setUsersClients] = useState([]);
    const [openNotes, setNotes] = useState(false);
    const [reOpenUpdate, setReOpenUpdate] = useState(false);
    const [openClockOut, setopenClockOut] = useState(false);

    const clearFilters = () => {
        setClientSearch(null);
        setStatusSearch('');
        setStatusDSearch('');
        setUserSearch(null);
        setDailySearch('');
        setAcceptSearch('');
        setPostSearch('');
        setDaySearch('');
        setFilterDate('');
        setFilterDateEnd('');
        setCurrentPage(1);
        setResetKey(resetKey + 1);
    }
    const filterOptions = (candidate, input) => {
        const words = input.toLowerCase().split(' ');
        return words.every(word =>
            candidate.label.toLowerCase().includes(word)
        );
    };

    const optionsUsers = user.map(user => ({
        value: user.id,
        label: `${user.name} ${user.last_name}`,
    }));

    const optionClients = client.map(client => ({
        value: client.id,
        label: client.name,
    }));

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: 'transparent',
            borderRadius: '5px',
            borderColor: '#808080',
            fontSize: '16px',
            marginRight: '3px',
            marginLeft: '10px',
            height: '40px',
            width: '250px',
            '&:hover': {
                borderColor: '#808080',
            },
            '&:focus-visible': {
                outline: 'none',
                boxShadow: 'none'
            },
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: 'white',
            width: '265px'
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#808080' : state.isFocused ? 'white' : '#808080',
            backgroundColor: state.isSelected ? '#f2f2f2' : state.isFocused ? '#bcc3f3' : 'white',
        }),
    };

    const fetchData = async () => {
        try {
            setScheduleData([]);
            const response = await axios.get(
                `/schedule-weekly?page=${currentPage}`
                + `${userSearch ? `&id_operator=${encodeURIComponent(userSearch)}` : ''}`
                + `${clientSearch ? `&client=${encodeURIComponent(clientSearch)}` : ''}`
                + `${postSearch ? `&post=${postSearch.trim()}` : ''}`
                + `${acceptSearch ? `&accept=${acceptSearch.trim()}` : ''}`
                + `${statusSearch ? `&status=${statusSearch.trim()}` : ''}`
                + `${dailySearch ? `&type_daily=${dailySearch.trim()}` : ''}`
                + `${daySearch ? `&day=${daySearch.trim()}` : ''}`
                + `${filterDate ? `&startDate=${filterDate.trim()}` : ''}`
                + `${filterDateEnd ? `&endDate=${filterDateEnd.trim()}` : ''}`
                + `${statusDSearch ? `&statusD=${statusDSearch.trim()}` : ''}`
            );
            const responseData = response.data;
            setScheduleData(responseData.data);
            setTotal(responseData.total)
            setTotalPages(responseData.last_page);
            setTotalSchedule(responseData.total_schedule_count)
            setUser(responseData.users);
            setClient(responseData.clients);
        } catch (error) {
            console.error('Error fetching schedules:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchDataClient = async () => {
        try {
            const response = axios.get("/userClient").then((response) => {
            });
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleClientChange = (selectedOption) => {

        setClientSearch(selectedOption.value);

        if (selectedOption) {
            axios
                .get(`/userClient?id_client=${selectedOption.value}`)
                .then((response) => {
                    const userOptions = response.data.data.map((user) => ({
                        label: user.user ? `${user.user.name} ${user.user.last_name}` : '',
                        value: user.id_user,
                    }));
                    setUsersClients(userOptions);
                });
        }
    };

    useEffect(() => {
        fetchData();
        fetchDataClient();
        if (flashing) {
            const intervalId = setInterval(() => {
                setFlashing(false);
            }, 7000);

            return () => {
                clearInterval(intervalId);
            };
        }
    }, [currentPage, userSearch, clientSearch, postSearch, acceptSearch, statusSearch, dailySearch, daySearch, statusDSearch, filterDate, filterDateEnd, flashing, resetKey]);

    const handleSelectChange = (componentName, selectedOption) => {
        switch (componentName) {
            case 'user':
                setUserSearch(selectedOption.value);
                break;
            case 'client':
                setClientSearch(selectedOption.value);
                break;
            default:
                break;
        }
    };

    const getTextDay = (idDay) => {
        const textDay = {
            1: 'Mon',
            2: 'Tue',
            3: 'Wed',
            4: 'Thu',
            5: 'Fri',
            6: 'Sat',
            7: 'Sun',
        };
        return textDay[idDay] || 'Mon';
    };
    const getColorSchemeForTypeDaily = (typeDaily) => {
        const colorMap = {
            1: '#7080fc',
            2: '#fcd677',
            3: '#8a6dce',
            4: '#dd67ae',
            5: '#fca066',
            6: '#fc7466',
            7: '#fcd677',
            8: '#dd67ae',
            9: '#fca066'
        };
        return colorMap[typeDaily] || '#7080fc';
    };
    const handleInfo = async (item) => {
        try {
            const response = await axios.get(`/schedule-weekly/show/${item.id}`);
            const { data, user, userC, clients, breaks, time_clock } = response.data;
            setSelectSchedule({ ...data, user, userC, clients, breaks, time_clock });
            setOpenShow(true);
        } catch (error) {
            console.error('Error fetching employee details:', error);
        }
    };
    const closeInfoModal = () => {
        setOpenShow(false);
    };

    const handleAP = async (schedule) => {
        
        const msg = schedule.accept === 0 && schedule.post === 0 ? 'Accept & Publish' : 'Unaccept & Unpublish';

        // SweetAlert con inputs de fecha
        const { value: formValues } = await Swal.fire({
            title: `Do you want to ${msg} all week?`,
            html:
                `
                <div style="display: flex; align-items: center; gap: 10px;">
                    <label for="startDate" style="width: 200px;">Start Date</label>
                    <input id="startDate" type="date" class="swal2-input" style="width: auto;">
                </div>
                <div style="display: flex; align-items: center; gap: 10px; margin-top: 10px;">
                    <label for="endDate" style="width: 200px;">End Date</label>
                    <input id="endDate" type="date" class="swal2-input" style="width: auto;">
                </div>
            `,
            focusConfirm: false,
            showCancelButton: true,
            confirmButtonColor: '#2C5282',
            cancelButtonColor: '#E53E3E',
            confirmButtonText: `${msg}`,
            preConfirm: () => {
                const startDate = document.getElementById('startDate').value;
                const endDate = document.getElementById('endDate').value;
                if (!startDate || !endDate) {
                    Swal.showValidationMessage('Both dates are required');
                }
                return { startDate, endDate };
            }
        });

        if (formValues) {
            setLoading(true);
            try {
                // Realiza la solicitud a la API pasando los valores de las fechas como datos
                const response = await axios.post(`/acceptAndPublicated/${schedule.id}`, {
                    startDate: formValues.startDate,
                    endDate: formValues.endDate
                });

                // Muestra mensaje de éxito
                toast({
                    title: `Schedule Week ${msg}`,
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
                setFlashing(schedule);
                fetchData();
            } catch (error) {
                console.error(error);
                toast({
                    title: "Error Accepting & Publishing",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            } finally {
                setLoading(false);
            }
        }
    };
    const handleAccept = async (schedule) => {
        const msg = schedule.accept == 0 ? 'accept' : 'unaccept';
        const confirmDelete = await Swal.fire({
            title: `Do you want to ${msg} this schedule?`,
            text: `The schedule week will be ${msg}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2C5282',
            cancelButtonColor: '#E53E3E',
            confirmButtonText: `${msg}`
        }).then((result) => {
            return result.isConfirmed
        });
        if (confirmDelete) {
            setLoading(true);
            setSelectSchedule(schedule);
            try {
                const response = await axios.post(`/accept/${schedule.id}`);
                toast({
                    title: `Schedule ${msg}`,
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
                setFlashing(schedule);
                fetchData();
            } catch (error) {
                console.error(error);
                toast({
                    title: "Error Accepting the schedule",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            } finally {
                setLoading(false);
            }
        }
    };
    const handlePublic = async (schedule) => {
        const msg = schedule.post == 0 ? 'publish' : 'unpublish';
        const confirmDelete = await Swal.fire({
            title: `Do you want to ${msg} this schedule?`,
            text: `The schedule week will be ${msg}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2C5282',
            cancelButtonColor: '#E53E3E',
            confirmButtonText: `${msg}`
        }).then((result) => {
            return result.isConfirmed
        });
        if (confirmDelete) {
            setLoading(true);
            setSelectSchedule(schedule);
            try {
                const response = await axios.post(`/post/${schedule.id}`);
                toast({
                    title: `Schedule ${msg}`,
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
                setFlashing(schedule);
                fetchData();
            } catch (error) {
                console.error(error);
                toast({
                    title: "Error Published the schedule",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            } finally {
                setLoading(false);
            }
        }
    };

    const deleteSchedule = async (schedule) => {
        const confirmDelete = await Swal.fire({
            title: 'Do you want to delete this schedule?',
            text: "The Schedule will be permanently removed",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#b9ce67',
            cancelButtonColor: '#fc7466',
            confirmButtonText: "Delete",
            cancelButtonText: "Cancel",
            closeOnConfirm: true,
            closeOnCancel: false
        }).then((result) => {
            return result.isConfirmed
        });

        if (confirmDelete) {
            setLoading(true);
            try {
                const response = await axios.post(`/schedule/delete/${schedule.id}`);
                toast({
                    title: "Schedule Delete it!",
                    status: "success",
                    position: 'top',
                    duration: 3000,
                    isClosable: true,
                });
                fetchData();
            } catch (error) {
                console.error(error);

            } finally {
                setLoading(false);
            }
        }
    };

    const getRowBackground = (schedule) => {
        if (selectSchedule && selectSchedule.id === schedule.id) {
            if (flashing) {
                return '#fcd677';
            }
            return 'white';
        } else {
            return 'white';
        }
    };

    const closeUpdateModal = () => {
        setOpenUpdate(false);
    };

    const handleUpdate = async (schedule) => {
        try {
            const response = await axios.get(`/schedule/edit/${schedule.id}`);
            const { schedule: detail, user, time, breaks, audits, incidents } = response.data;
            setSelectSchedule({ ...schedule, user, time, breaks, audits, incidents });
            setOpenUpdate(true);
        } catch (error) {
            console.error('Error fetching employee details:', error);
        }
    };

    const handleAudit = async (schedule) => {
        try {
            const response = await axios.get(`/schedule-weekly/show/${schedule.id}`);
            const { schedule: detail, user, userC, clients } = response.data;
            setSelectSchedule({ ...schedule, user, userC, clients });
            setOpenAudit(true);
        } catch (error) {
            console.error('Error fetching employee details:', error);
        }
    };
    const closeAuditModal = () => {
        setOpenAudit(false);
    };

    const handleCallOut = async (schedule) => {
        try {
            const response = await axios.get(`/schedule/edit/${schedule.id}`);
            const { schedule: detail, user } = response.data;
            setSelectSchedule({ ...schedule, user });
            setSelectSchedule(schedule);
            setCallOut(true);
        } catch (error) {
            console.error('Error fetching employee details:', error);
        }
    };

    const closeCallOutModal = () => {
        setCallOut(false);
    };

    const handleNotes = async (schedules) => {
        try {
            const response = await axios.get(`/schedule/edit/${schedules.id}`);
            const { schedule: detail, user } = response.data;
            setSelectSchedule({ ...schedules, user });
            setSelectSchedule(schedules);
            setNotes(true);

            if (openUpdate) {
                setOpenUpdate(false);
                setReOpenUpdate(true);
            }

        } catch (error) {
            console.error('Error fetching employee details:', error);
        }
    };

    const closeNotesModal = () => {
        setNotes(false);
        setReOpenUpdate(false);
    };

    const generateShift = async (schedule) => {
        const confirmDelete = await Swal.fire({
            title: 'Do you want to generate this shift?',
            text: "The shift will be generated",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#b9ce67',
            cancelButtonColor: '#fc7466',
            confirmButtonText: "Generate",
            cancelButtonText: "Cancel",
            closeOnConfirm: true,
            closeOnCancel: false
        }).then((result) => {
            return result.isConfirmed
        });

        if (confirmDelete) {
            setLoading(true);
            try {
                const response = await axios.post(`/schedule/timeClock/${schedule.id}`);
                toast({
                    title: "Shift Generated",
                    status: "success",
                    position: 'top',
                    duration: 3000,
                    isClosable: true,
                });
                fetchData();
            } catch (error) {

                console.error(error);
                const errorMessage = error.response?.data?.errors?.erro || "An error occurred";

                Swal.fire({
                    title: "Error",
                    text: errorMessage,
                    icon: "error",
                    confirmButtonColor: "#fc7466"
                });

            } finally {
                setLoading(false);
            }
        }
    };

    const getColorSchemeForOption = (option) => {
        const colorMap = {
            1: '#a7a9ac',
            2: '#b9ce67',
            3: '#fcd677',
            4: '#988d77',
            5: '#6bc2ed',
            6: '#fc7466',
        };
        return colorMap[option] || '#a7a9ac';
    };

    const getColorSchemeForStatusDay = (status) => {
        const colorMap = {
            1: '#424ea8',
            2: '#808080',
            3: '#fca066',
            13: '#fc7466'
        };
        return colorMap[status] || '#424ea8';
    };

    const handleClockOut = async (schedule) => {
        setSelectSchedule(schedule);
        setopenClockOut(true);
    };

    return (
        <TableContainer>
            <Flex
                direction='column'
                w='100%'
            >
                <Flex
                    direction='column'
                    w='100%'

                    p={3}
                    borderRadius="16px"
                >
                </Flex>
                <WeeklySearch
                    clientSearch={clientSearch}
                    usersClients={usersClients}
                    optionsUsers={optionsUsers}
                    userSearch={userSearch}
                    handleSelectChange={handleSelectChange}
                    setCurrentPage={setCurrentPage}
                    customStyles={customStyles}
                    resetKey={resetKey}
                    filterOptions={filterOptions}
                    optionClients={optionClients}
                    handleClientChange={handleClientChange}
                    dailySearch={dailySearch}
                    setDailySearch={setDailySearch}
                    filterDate={filterDate}
                    setFilterDate={setFilterDate}
                    acceptSearch={acceptSearch}
                    setAcceptSearch={setAcceptSearch}
                    filterDateEnd={filterDateEnd}
                    setFilterDateEnd={setFilterDateEnd}
                    postSearch={postSearch}
                    setPostSearch={setPostSearch}
                    statusDSearch={statusDSearch}
                    setStatusDSearch={setStatusDSearch}
                    daySearch={daySearch}
                    setDaySearch={setDaySearch}
                    statusSearch={statusSearch}
                    setStatusSearch={setStatusSearch}
                    clearFilters={clearFilters}
                />
                <br />
                <WeeklyList
                    userAuth={userAuth}
                    userAuthID={userAuthID}
                    setCurrentPage={setCurrentPage}
                    scheduleData={scheduleData}
                    getRowBackground={getRowBackground}
                    getTextDay={getTextDay}
                    getColorSchemeForStatusDay={getColorSchemeForStatusDay}
                    getColorSchemeForTypeDaily={getColorSchemeForTypeDaily}
                    getColorSchemeForOption={getColorSchemeForOption}
                    handleInfo={handleInfo}
                    handleAudit={handleAudit}
                    handleNotes={handleNotes}
                    deleteSchedule={deleteSchedule}
                    handleUpdate={handleUpdate}
                    handleAP={handleAP}
                    handleAccept={handleAccept}
                    handlePublic={handlePublic}
                    handleCallOut={handleCallOut}
                    generateShift={generateShift}
                    total={total}
                    totalSchedule={totalSchedule}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    handleClockOut={handleClockOut}
                />
            </Flex >
            {
                openShow && (
                    <ShowWeeklModal
                        isOpen={openShow}
                        onClose={closeInfoModal}
                        schedule={selectSchedule}
                    />
                )
            }
            {
                openUpdate && (
                    <ScheduleWeeklyUpdate
                        isOpen={openUpdate}
                        onClose={closeUpdateModal}
                        schedule={selectSchedule}
                        user={user}
                        client={client}
                        data={handleUpdate}
                        setFlashing={setFlashing}
                        handleNotes={handleNotes}
                        userAuth={userAuthID}
                    />
                )
            }
            {
                openAudit && (
                    <DetailAudits
                        isOpen={openAudit}
                        onClose={closeAuditModal}
                        schedule={selectSchedule}
                        user={user}
                        data={fetchData}
                    />
                )
            }
            {
                openCallOut && (
                    <CallOutModal
                        isOpen={openCallOut}
                        onClose={closeCallOutModal}
                        schedule={selectSchedule}
                        user={user}
                        data={fetchData}
                    />
                )
            }
            {
                openNotes && (
                    <ModalNotes
                        isOpen={openNotes}
                        onClose={closeNotesModal}
                        schedule={selectSchedule}
                        data={fetchData}
                        userAuth={userAuth}
                        reOpenUpdate={reOpenUpdate}
                        handleUpdate={handleUpdate}
                        setReOpenUpdate={setReOpenUpdate}
                    />
                )
            }
            {openClockOut && (
                <ClockOutModal
                    isOpen={openClockOut}
                    onClose={() => setopenClockOut(false)}
                    schedule={selectSchedule}
                    data={fetchData}
                />
            )}


        </TableContainer >
    );
}
export default WeeklySchedulesFunctions; 