import { Box, Flex, Text, Image, Button } from '@chakra-ui/react';
import React, { useState, useEffect } from "react";

const Carousel = ({ images, openImageViewer, user }) => {

  const [currentSlide, setCurrentSlide] = useState(0);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" height={"320px"} width={"100%"} mb={'5%'} justifyContent="center" position="relative">
      <Box
        display="flex"
        overflow="hidden"
        justifyContent="center"
        flexWrap="nowrap"
        width="800px"
        height="max-content"
      >
        {images.map((src, index) => (
          <Box
            key={index}
            flex="none"
            onClick={() => openImageViewer(index)}
            cursor="pointer"
            margin="0 5px"
            width="95%"
            height="100%"
            style={{
              display: index === currentSlide ? 'block' : 'none',
              transition: 'opacity 0.5s ease-in-out'
            }}
          >
            <Image
              src={src}
              alt={`Image ${index + 1}`}
              objectFit="contain"
              width="100%"
              height="100%"
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Carousel;
