import React from "react";
import {
    useRadioGroup, HStack,
} from '@chakra-ui/react';
import RadioCard from "components/fields/RadioInput";

function RadioGroup({ setFormData }) {
    const options = ['Hours', 'Midday', 'Days'];

    const { getRootProps, getRadioProps } = useRadioGroup({
        name: 'duration',
        onChange: (value) => {
            setFormData(prevData => ({
                ...prevData,
                duration: value,
            }));
        },
    });

    const group = getRootProps();

    return (
        <HStack {...group}>
            {options.map((value) => {
                const radio = getRadioProps({ value });
                return (
                    <RadioCard key={value} {...radio}>
                        {value}
                    </RadioCard>
                );
            })}
        </HStack>
    );
}

export default RadioGroup;