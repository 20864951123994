import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import {
    Button, ButtonGroup, Input,
    Flex, Box,
    Text, Select,
} from '@chakra-ui/react'
import { CalendarIcon } from '@chakra-ui/icons'
import { BiCalendarStar } from "react-icons/bi";
import { BiSync } from "react-icons/bi";
import DatePicker from 'react-datepicker';
import { AiOutlineClear } from "react-icons/ai";
import 'react-datepicker/dist/react-datepicker.css';
import Select2 from 'react-select'
import { MdChevronLeft, MdChevronRight } from "react-icons/md";

function SearchDashboard({
    operatorSearch,
    startDateFilter,
    setStartDateFilter,
    clientSearch,
    usersClients,
    optionsUsers,
    handleSelectChange,
    setCurrentPage,
    customStyles,
    resetKey,
    filterOptions,
    optionClients,
    handleClientChange,
    dailySearch,
    setDailySearch,
    optionSearch,
    setOptionSearch,
    statusSearch,
    setStatusSearch,
    locationSearch,
    setLocationSearch,
    timeSearch,
    setTimeSearch,
    setDateFilter,
    clearFilters,
    total,
    formattedDate,
    handleRefresh,
    currentPage,
    totalPages,
    optionTypeU,
    typeUSearch,
    toggleOptionSearch,
    toggleButton, 
    zoomSearch,
    setZoomSearch,
    toggleZoomSearch,
    toggleButtonZoom
}) {


    return (
        <>
            <Flex
                direction='row'
                w='100%'
                bg="#f2f2f2"
                p={2}
                borderRadius="16px"
            >
                <Select2
                    options={clientSearch > 0 ? usersClients : optionsUsers}
                    value={clientSearch > 0 ? usersClients.find(option => option.value === operatorSearch) : optionsUsers.find(option => option.value === operatorSearch)}
                    onChange={(selectedOption) => {
                        handleSelectChange('user', selectedOption);
                        setCurrentPage(1);
                    }}
                    styles={customStyles}
                    placeholder="Select operator"
                    key={`userSelect-${resetKey}`}
                    isSearchable
                    filterOption={filterOptions}
                />
                <Select2
                    options={optionClients}
                    value={optionClients.find(option => option.value === clientSearch)}
                    onChange={(selectedOption) => {
                        handleClientChange(selectedOption);
                        setCurrentPage(1);
                    }}
                    styles={customStyles}
                    placeholder="Select client"
                    key={`clientSelect-${resetKey}`}
                />
                <Select2
                    options={optionTypeU}
                    value={optionTypeU.find(option => option.value === typeUSearch)}
                    onChange={(selectedOption) => {
                        handleSelectChange('typeU', selectedOption);
                        setCurrentPage(1);
                    }}
                    styles={customStyles}
                    placeholder="Select type user"
                    key={`typeUSelect-${resetKey}`}
                />
                <Select
                    value={dailySearch}
                    width={'130px'}
                    borderColor={'#808080'}
                    mr={4} ml={4}
                    color={'#808080'}
                    onChange={(e) => {
                        setDailySearch(e.target.value);
                        setCurrentPage(1);
                    }}
                    _focusVisible={'none'}
                    _hover={{
                        borderColor: "#808080",
                    }}
                >
                    <option value="">Work type</option>
                    <option value="1">Regular shift</option>
                    <option value="2">Regular Training</option>
                    <option value="4">Regular Coverage</option>
                    <option value="5">Regular shadowing</option>
                    <option value="3">Additional hours</option>
                    <option value="7">Additional training</option>
                    <option value="8">Additional coverage</option>
                    <option value="9">Additional shadowing</option>
                    <option value="6">No shift</option>
                </Select>
                <Select
                    value={optionSearch}
                    width={'110px'}
                    mr={4}
                    borderColor={'#808080'}
                    color={'#808080'}
                    onChange={(e) => {
                        setOptionSearch(e.target.value);
                        setCurrentPage(1);
                    }}
                    _focusVisible={'none'}
                    _hover={{
                        borderColor: "#808080",
                    }}
                >
                    <option value="">Option</option>
                    <option value="1">No option</option>
                    <option value="2">Start</option>
                    <option value="3">Late</option>
                    <option value="4">Clock out</option>
                    <option value="5">Supervisor</option>
                    <option value="6">Call out</option>
                </Select>
                <Select
                    value={zoomSearch}
                    width={'110px'}
                    mr={4}
                    borderColor={'#808080'}
                    color={'#808080'}
                    onChange={(e) => {
                        setZoomSearch(e.target.value);
                        setCurrentPage(1);
                    }}
                    _focusVisible={'none'}
                    _hover={{
                        borderColor: "#808080",
                    }}
                >
                    <option value="">Zoom</option>
                    <option value="1">On time</option>
                    <option value="0">Late</option>
                </Select>
                <Select
                    value={statusSearch}
                    width={'max-content'}
                    mr={4}
                    borderColor={'#808080'}
                    color={'#808080'}
                    onChange={(e) => {
                        setStatusSearch(e.target.value);
                        setCurrentPage(1);
                    }}
                    _focusVisible={'none'}
                    _hover={{
                        borderColor: "#808080",
                    }}
                >
                    <option value="">Status</option>
                    <option value="1">Normal</option>
                    <option value="12">Agent Overnight</option>
                    <option value="11">Surecall O/C</option>
                    <option value="3">Holiday</option>
                    <option value="15">Holiday Off</option>
                    <option value="4">Vacation</option>
                    <option value="5">Maternity Leave</option>
                    <option value="6">Permission Request</option>
                    <option value="7">Wedding Leave</option>
                    <option value="8">Medical Leave</option>
                    <option value="9">Paternity Leave</option>
                    <option value="10">Bereavement Leave</option>
                    <option value='13'>No longer with us</option>
                </Select>
                <Select
                    value={locationSearch}
                    width={'120px'}
                    mr={4}
                    borderColor={'#808080'}
                    color={'#808080'}
                    onChange={(e) => {
                        setLocationSearch(e.target.value);
                        setCurrentPage(1);
                    }}
                    _focusVisible={'none'}
                    _hover={{
                        borderColor: "#808080",
                    }}
                >
                    <option value="">Location</option>
                    <option value="1">México</option>
                    <option value="2">Belize</option>
                    <option value="2">San Ignacio</option>
                    <option value="4">Dominican Republic</option>
                    <option value="5">Colombia</option>
                    <option value="6">Guatemala</option>
                </Select>
                <Flex
                    border={'1px solid #808080'} title='Set hour'
                    display={'flex'}
                    borderRadius={'4px'}
                    mr={4}
                >
                    <Input
                        width={'auto'}
                        border={'0px'}
                        type="time"
                        fontSize={"sm"}
                        value={timeSearch}
                        onChange={(e) => {
                            setTimeSearch(e.target.value);
                            setCurrentPage(1);
                        }}
                        _focusVisible={'none'}
                        color={'#808080'}
                    />
                </Flex>
                <Flex width={"150px"}
                    border={'1px solid #808080'}
                    display={'flex'}
                    borderRadius={'5px'}
                    mr={4}
                    height={'40px'}
                >
                    <Box mt={2} width={"150px"}>
                        <DatePicker
                            selected={startDateFilter}
                            onChange={(date) => {
                                setStartDateFilter(date);
                                setCurrentPage(1);
                                setDateFilter(date);
                            }}
                            placeholderText="Search date"
                            customInput={
                                <input
                                    className="custom-placeholder"
                                    style={{
                                        backgroundColor: 'transparent',
                                        textAlign: 'left',
                                        paddingLeft: '20px',
                                        outline: 'none',
                                        color: '#808080'
                                    }}
                                />
                            }
                        />
                        <CalendarIcon mr={4} ml={"-48%"} mt={"-3%"} color={'#808080'} />
                    </Box>

                </Flex>
                <Button onClick={clearFilters} bg={'transparent'} _hover={{ backgroundColor: 'transparent' }} color={'#808080'} padding={"0px"} title="Clear filters" >
                    <AiOutlineClear size={'26px'} />
                </Button>
            </Flex>
            <Flex
                direction='column'
                w='100%'
                p={3}
                borderRadius="16px"
                mt={'2%'}
                mb={'2%'}
            >
                <Flex align="center" justifyContent="space-between"  >
                    <Flex width={"max-content"}>
                        <Text
                            className='datedash'
                        >
                            {formattedDate}
                        </Text>
                        <Flex display={'flex'} justifyContent={'flex-start'} width={"max-content"} pb={"10px"} mt={"8px"}  >
                            {total === 0 ? (
                                <>
                                    <Flex bg={'#f2f2f2'} borderRadius={"10px"} ml={4} width={'150px'} display={'flex'} justifyContent={'center'}>
                                        <Box mt={'5px'} mr={2} ml={4}>
                                            <BiCalendarStar color={"#1c2147"} fontWeight={'bold'} />
                                        </Box>
                                        <Text fontSize={'14px'} mt={1} color={"#1c2147"} fontWeight={'bold'}>
                                            No schedules
                                        </Text>
                                    </Flex>
                                </>
                            ) : (
                                <>
                                    <Flex bg={'#f2f2f2'} borderRadius={"10px"} ml={16} width={'150px'} display={'flex'} justifyContent={'center'}>
                                        <Text fontSize={'14px'} mt={2} mr={3} color={"#1c2147"} fontWeight={'bold'}>
                                            {total}
                                        </Text>
                                        <Box mt={'5px'} pt={1} mr={1}>
                                            <BiCalendarStar color={"#1c2147"} fontWeight={'bold'} />
                                        </Box>
                                        <Text fontSize={'14px'} mt={2} color={"#1c2147"} fontWeight={'bold'}>
                                            {total === 1 ? 'Schedule' : 'Schedules'}
                                        </Text>
                                    </Flex>
                                </>
                            )}
                        </Flex>

                        <Flex display={'flex'} justifyContent={'flex-start'} width={"max-content"} pb={"10px"} mt={"8px"} ml={'10%'} >
                            <Button
                                mr={4}
                                title='Refresh'
                                bg={toggleButton === true ? '#1c2147' : '#b9ce67'}
                                color={"white"}
                                _hover={{ background: "#808080", color: 'white' }}
                                borderRadius={'5px'}
                                onClick={toggleOptionSearch}
                            >
                                {toggleButton === true ? 'Show all' : 'On Shift'}
                            </Button>
                        </Flex>

                        <Flex display={'flex'} justifyContent={'flex-start'} width={"max-content"} pb={"10px"} mt={"8px"} ml={'10%'} >
                            <Button
                                mr={4}
                                title='Refresh'
                                bg={toggleButtonZoom === true ? '#1c2147' : '#3e4a9e'}
                                color={"white"}
                                _hover={{ background: "#808080", color: 'white' }}
                                borderRadius={'5px'}
                                onClick={toggleZoomSearch}
                            >
                                {toggleButtonZoom === true ? 'Show all' : 'On Zoom'}
                            </Button>
                        </Flex>

                    </Flex>
                    <Box display={"flex"} mt={"5px"}  >
                        <Button mr={4} title='Refresh'
                            bg={"#1c2147"}
                            color={"white"}
                            _hover={{ background: "#808080", color: 'white' }}
                            borderRadius={'5px'}
                            onClick={handleRefresh}
                        >
                            <BiSync size={'25px'} />
                        </Button>
                        <ButtonGroup variant="outline" justifyContent={'center'} display={'flex'} width={"max-content"}  >
                            <Button borderRadius={'10px'}
                                onClick={() => setCurrentPage(currentPage - 1)}
                                isDisabled={currentPage === 1}
                                bg="#cccccc" color={"white"}
                                _hover={{ background: "#808080", }}
                                _active={{ background: "#808080", }}
                            ><MdChevronLeft fontSize="25px" />
                            </Button>
                            <Text class="btnTextPages">{currentPage} of {totalPages}</Text>
                            <Button
                                borderRadius={'10px'} ml={4}
                                onClick={() => setCurrentPage(currentPage + 1)}
                                isDisabled={currentPage === totalPages}
                                bg="#cccccc" color={"white"}
                                _hover={{ background: "#808080", }}
                                _active={{ background: "#808080", }}
                            ><MdChevronRight fontSize="25px" />
                            </Button>

                        </ButtonGroup>

                    </Box>

                </Flex>
            </Flex>
        </>
    );
}
export default SearchDashboard; 