import React from 'react';
import {
    Table, Thead, Tbody, Tr, Th, Td, Button, ButtonGroup, Flex, Box, Text, Spinner
} from '@chakra-ui/react'
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import { TbReportSearch } from "react-icons/tb";

function ReportsPR({ setCurrentPage, reportsD, isLoading, total, currentPage, totalPages }) {


    return (
        <>
            <Flex
                direction='column'
                w='100%'>
                <Table id='ticketsTable' size={'sm'}>
                    <Thead bg={"white"} >
                        <Tr>
                            <Th color='#808080' textAlign={'center'}>ID</Th>
                            <Th color='#808080' textAlign={'center'}>Full Name</Th>
                            <Th color='#808080' textAlign={'center'}>Schedule start time</Th>
                            <Th color='#808080' textAlign={'center'}>Schedule end time</Th>
                            <Th color='#808080' textAlign={'center'}>Date</Th>
                            <Th color='#808080' textAlign={'center'}>Start time</Th>
                            <Th color='#808080' textAlign={'center'}>End time</Th>
                            <Th color='#808080' textAlign={'center'}>Duration</Th>
                            <Th color='#808080' textAlign={'center'}>Lunch</Th>
                            <Th color='#808080' textAlign={'center'}>Breaks</Th>
                            <Th color='#808080' textAlign={'center'}>Incidents IT</Th>
                            <Th color='#808080' textAlign={'center'}>Incidents Schedulers</Th>
                            <Th color='#808080' textAlign={'center'}>Expect</Th>
                            <Th color='#808080' textAlign={'center'}>Schedule</Th>
                            <Th color='#808080' textAlign={'center'}>Job site</Th>
                            <Th color='#808080' textAlign={'center'}>Position</Th>
                            <Th color='#808080' textAlign={'center'}>Notes</Th>
                        </Tr>
                    </Thead>
                    <Tbody fontSize={'14px'} color={"#1c2147"} fontWeight={"bold"}>
                        {reportsD && reportsD.length > 0 ? (
                            reportsD.map((reportsD, index) => (
                                <Tr className={index % 2 === 0 ? 'white-row' : 'gray-row'} key={index}>
                                    <Td textAlign={'center'}>
                                        {reportsD.mat_dp ? (
                                            reportsD.mat_dp
                                        ) : (
                                            "MATP"
                                        )}
                                    </Td>
                                    <Td textAlign={'center'}>
                                        {reportsD.name} {reportsD.last_name}
                                    </Td>
                                    <Td textAlign={'center'}>
                                        {reportsD.status >= 4 && reportsD.status <= 10
                                            ? '---'
                                            : reportsD.start_time
                                        }
                                    </Td>
                                    <Td textAlign={'center'}>
                                        {reportsD.status >= 4 && reportsD.status <= 10
                                            ? '---'
                                            : reportsD.end_time
                                        }
                                    </Td>
                                    <Td textAlign={'center'}>
                                        {reportsD.date}
                                    </Td>
                                    <Td textAlign={'center'}>
                                        {reportsD.status >= 4 && reportsD.status <= 10
                                            ? '---'
                                            : reportsD.date_start_format
                                        }
                                    </Td>
                                    <Td textAlign={'center'}>
                                        {reportsD.status >= 4 && reportsD.status <= 10
                                            ? '---'
                                            : reportsD.date_end_format
                                        }
                                    </Td>
                                    <Td textAlign={'center'}>
                                        {Number(reportsD.duration).toFixed(3)}
                                    </Td>
                                    <Td textAlign={'center'}>
                                        {Number(reportsD.lunch_duration).toFixed(3)}
                                    </Td>
                                    <Td textAlign={'center'}>
                                        {Number(reportsD.break_duration).toFixed(3)}
                                    </Td>
                                    <Td textAlign={'center'}>
                                        {Number(reportsD.incident_duration).toFixed(3)}
                                    </Td>
                                    <Td textAlign={'center'}>
                                        {Number(reportsD.incident_duration_schedulers).toFixed(3)}
                                    </Td>
                                    <Td textAlign={'center'}>
                                        {reportsD.expected}
                                    </Td>
                                    <Td textAlign={'center'}>
                                        {reportsD.client}
                                    </Td>
                                    <Td textAlign={'center'}>
                                        {(() => {
                                            switch (reportsD.status) {
                                                case 4:
                                                    return 'Vacation';
                                                case 5:
                                                    return 'Maternity Leave';
                                                case 6:
                                                    return 'Permission Request';
                                                case 7:
                                                    return 'Wedding Leave';
                                                case 8:
                                                    return 'Medical Leave';
                                                case 9:
                                                    return 'Paternity Leave';
                                                case 10:
                                                    return 'Bereavement Leave';
                                                case 11:
                                                    return 'Surecall O/C';
                                                case 15:
                                                    return 'Holiday Off';
                                                default:
                                                    switch (reportsD.type_daily) {
                                                        case 1:
                                                            return "Regular shift";
                                                        case 2:
                                                            return "Regular training";
                                                        case 3:
                                                            return "Additional hours";
                                                        case 4:
                                                            return "Regular covering";
                                                        case 5:
                                                            return "Reguar shadowing"
                                                        case 6:
                                                            return "Regular shift"
                                                        case 7:
                                                            return "Additional coverage"
                                                        case 8:
                                                            return "Additional training"
                                                        case 9:
                                                            return "Additional shadowing"
                                                        default:
                                                            return "Regular hours";
                                                    }
                                            }
                                        })()}
                                    </Td>
                                    <Td textAlign={'center'}>
                                        {reportsD.status === 12 ? `${reportsD.position} Overnight` : reportsD.position}
                                    </Td>
                                    <Td textAlign={'center'}>
                                        {reportsD.notes && Array.isArray(reportsD.notes) && reportsD.notes.length > 0 && Array.isArray(reportsD.notes[0]) ? (
                                            reportsD.notes[0].filter(nota => nota !== null).map((nota, notaIndex) => (
                                                <Flex key={notaIndex} display={'flex'} justifyContent={'center'} width={'250px'} direction={'column'}>
                                                    <Text whiteSpace={'pre-wrap'} color={'#808080'}>
                                                        {(() => {
                                                            const optionValue = nota.option ? nota.option : 7;

                                                            switch (optionValue) {
                                                                case 1:
                                                                    return "Late";
                                                                case 2:
                                                                    return "Call out";
                                                                case 3:
                                                                    return "Leave early";
                                                                case 4:
                                                                    return "No call, no show";
                                                                case 5:
                                                                    return "Cell phone use";
                                                                case 6:
                                                                    return "Adherence (Camera, OBS Browsing)";
                                                                case 7:
                                                                    return "Other";
                                                                case 8:
                                                                    return "Permission with client";
                                                                case 9:
                                                                    return "Disciplinary actions";
                                                                case 10:
                                                                    return "Vacations";
                                                                case 11:
                                                                    return "Late from lunch";
                                                                case 12:
                                                                    return "Late from break";
                                                                case 14:
                                                                    return "Missed punches";
                                                                default:
                                                                    return "Other";
                                                            }
                                                        })()}
                                                    </Text>
                                                    <Text whiteSpace={'pre-wrap'}>
                                                        {nota.description ? nota.description : 'No description'}
                                                    </Text>
                                                </Flex>
                                            ))
                                        ) : (
                                            <Text>No notes</Text>
                                        )}
                                    </Td>
                                </Tr>
                            ))
                        ) : (
                            <Tr>
                                {!isLoading ? (
                                    <Th colSpan={'20'}>
                                        <Flex display={'flex'} justifyContent={'center'} alignItems={'center'} bg={"#f2f2f2"}>
                                            <Text
                                                color='#1c2147'
                                                fontSize={"25px"}
                                                textAlign={"center"}
                                                paddingTop={"15px"}
                                                paddingBottom={"15px"}
                                                textTransform={'none'}
                                                fontFamily={'DM Sans'}
                                                letterSpacing="-1px"
                                                mr={'20px'}
                                            >
                                                Loading...
                                            </Text>
                                            <Spinner color='blue.500' />
                                        </Flex>
                                    </Th>
                                ) : (
                                    <Th colSpan={'20'}>
                                        <Text
                                            color='#1c2147'
                                            fontSize={"25px"}
                                            textAlign={"center"}
                                            paddingTop={"15px"}
                                            paddingBottom={"15px"}
                                            bg={"#f2f2f2"}
                                            textTransform={'none'}
                                            fontFamily={'DM Sans'}
                                            letterSpacing="-1px"
                                        >
                                            No data
                                        </Text>
                                    </Th>
                                )}
                            </Tr>
                        )}
                    </Tbody>
                </Table>
            </Flex>
            <Box width={"100%"} display={"flex"} mt={"30px"}>
                <Flex display={'flex'} justifyContent={'flex-start'} width={"max-content"} pb={"10px"} mt={"8px"}  >
                    {total === 0 ? (
                        <>
                            <Flex bg={'#f2f2f2'} width={'150px'} display={'flex'} justifyContent={'center'}>
                                <Box mt={'5px'} mr={2} ml={4}>
                                    <TbReportSearch color={"#1c2147"} fontWeight={'bold'} />
                                </Box>
                                <Text fontSize={'14px'} mt={1} color={"#1c2147"} fontWeight={'bold'}>
                                    No reports
                                </Text>
                            </Flex>
                        </>
                    ) : (
                        <>
                            <Flex bg={'#f2f2f2'} width={'150px'} display={'flex'} justifyContent={'center'}>
                                <Text fontSize={'14px'} mt={1} mr={3} color={"#1c2147"} fontWeight={'bold'}>
                                    {total}
                                </Text>
                                <Box mt={'5px'} mr={1}>
                                    <TbReportSearch color={"#1c2147"} fontWeight={'bold'} />
                                </Box>
                                <Text fontSize={'14px'} mt={1} color={"#1c2147"} fontWeight={'bold'}>
                                    {total === 1 ? 'Report' : 'Reports'}
                                </Text>
                            </Flex>
                        </>
                    )}
                </Flex>
                <ButtonGroup isAttached variant="outline" ml={"34%"} justifyContent={'center'} width={"max-content"}>
                    <Button
                        borderRadius={'0px'}
                        onClick={() => setCurrentPage(currentPage - 1)}
                        isDisabled={currentPage === 1}
                        bg="#808080" color={"white"}
                        _hover={{ background: "#808080", }}
                        _active={{ background: "#808080", }}
                    ><BsArrowLeftShort fontSize="20px" />
                    </Button>
                    <Text class="btnTextPages">{currentPage} of {totalPages}</Text>
                    <Button
                        borderRadius={'0px'} ml={4}
                        onClick={() => setCurrentPage(currentPage + 1)}
                        isDisabled={currentPage === totalPages}
                        bg="#808080" color={"white"}
                        _hover={{ background: "#808080", }}
                        _active={{ background: "#808080", }}
                    ><BsArrowRightShort fontSize="20px" />
                    </Button>
                </ButtonGroup>
            </Box>
        </>
    );
}
export default ReportsPR; 