import React from 'react';
import axios from 'axios';
import Swal from 'sweetalert2'
import {
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton,
    Button, Flex, Text, useToast, Avatar
} from '@chakra-ui/react';
import logo from "assets/img/dashboards/Logo Dark Blue-01-01.jpg";
import { FaUser } from "react-icons/fa";
const ValidationPictureModal = ({ isOpen, onClose, fetchData, user }) => {

    const toast = useToast();

    const renderUserImage = (user) => {
        const imageSrc = user.base64Image && user.imageMimeType
            ? `data:${user.imageMimeType};base64,${user.base64Image}`
            : logo;

        return (
            <Flex width="100%" height="100%" justifyContent="center" alignItems="center">
                <Avatar
                    src={imageSrc}
                    h="400px"
                    w="400px"
                    borderRadius="full"
                />
            </Flex>
        );
    };

    const handleSubmit = async (value) => {
        const msg = value === 2 ? 'approved' : 'rejected';
        const confirmDelete = await Swal.fire({
            title: `Do you want to ${msg} this profile picture?`,
            text: `The profile picture will be ${msg}...`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2C5282',
            cancelButtonColor: '#E53E3E',
            confirmButtonText: `Yes, ${msg} it!`
        }).then((result) => {
            return result.isConfirmed
        });
        if (confirmDelete) {
            try {
                const response = await axios.post(`/validateProfilePicture/${user.id}`, { path_confirm: value });
                toast({
                    title: "Status Update",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
                fetchData();
                onClose();
            } catch (error) {
                console.error(error);
                toast({
                    title: "Error Validating Profile Picture",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            }
        }
    };


    return (
        <Modal size={'3xl'} isOpen={isOpen} onClose={() => {
            onClose();
        }}
            closeOnOverlayClick={false}
            closeOnEsc={false}
            isCentered
        >
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton />
                <ModalHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} color={'#1c2147'} mt={'5%'} fontWeight={'bold'} fontSize={'35px'}>
                    <Flex direction={'column'}>
                        <Text textAlign={'center'}>
                            Profile Picture Validation
                        </Text>
                        <Flex style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} color={'#7080fc'} fontWeight={'bold'} fontSize={'22px'}>
                            <FaUser size={'18px'} />
                            <span style={{ marginLeft: '8px' }}><span style={{ marginRight: '8px', color: '#7080fc' }}>{user.name} {user.last_name}</span></span>
                        </Flex>
                    </Flex>
                </ModalHeader>
                <ModalBody p={'10%'}>
                    {renderUserImage(user)}
                </ModalBody>
                <ModalFooter justifyContent="center"  pb={"5%"}>
                    <Button
                        bg={'#b9ce67'}
                        color={'white'}
                        mr={'2%'}
                        borderRadius={'5px'}
                        display={'flex'}
                        justifyContent={'space-between'}
                        onClick={() => handleSubmit(2)}
                        alignItems={'center'}
                        _hover={{ background: "#8aad01", color: 'white' }}
                    >
                        Approved
                    </Button>
                    <Button
                        bg={'#fc7466'}
                        color={'white'}
                        mr={'2%'}
                        borderRadius={'5px'}
                        display={'flex'}
                        justifyContent={'space-between'}
                        onClick={() => handleSubmit(3)}
                        alignItems={'center'}
                        _hover={{ background: "#de5b5b", color: 'white' }}
                    >
                        Rejected
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
export default ValidationPictureModal; 