import React from 'react';
import { Icon } from '@chakra-ui/react';
import { MdHome } from 'react-icons/md';
import { BsFillFilePersonFill } from "react-icons/bs";
import { FaRegCalendar, FaUsers } from "react-icons/fa6";
import { MdDashboard } from "react-icons/md";
import { TbReportAnalytics } from "react-icons/tb";
import { IoTicketSharp } from "react-icons/io5";

import DashboardsDefault from 'views/admin/dashboards/default';
import UsersSchedulesOverview from 'views/admin/main/schedules/weeklyschedule';
import UsersOverview from 'views/admin/main/users/newUser/items/userDashboard';
import DailyOverview from 'views/admin/main/schedules/dailyschedule';
import IncidentsOverview from 'views/admin/main/incidents';
import DefaultUser from 'views/admin/main/others/messages/indexUser';
import Default from 'views/admin/main/others/messages';
import ReportsSupOverview from 'views/admin/main/reports/indexSUP';

import MyScheduleOverview from 'views/admin/main/schedules/myschedule';
import ReportsPROverview from 'views/admin/main/reports/indexPR';
import IncidentsAgentOverview from 'views/admin/main/incidents/indexAgent';
import MedicalNotesOverview from 'views/admin/main/Medical_Notes';
import IndexTask from 'views/admin/main/others/messages/indexTask';




const routesOperations = [
  // --- Dashboards ---
  {
    name: 'Home',
    layout: '/admin',
    path: '/home',
    icon: <Icon as={MdHome} width="30px" height="30px" color="inherit" />,
    component: <DashboardsDefault />,
  },
  //{
    //name: 'Profile',
    //path: '/profile',
    //icon: <Icon as={ImProfile} width="30px" height="30px" color="inherit" />,
    //layout: '/admin',
    //component: <OverviewProfile />,
  //},
  {
    name: 'Dashboard',
    layout: '/admin',
    path: '/dashboards',
    icon: <Icon as={MdDashboard} width="30px" height="30px" color="inherit" />,
    component: <DailyOverview />,
  },
  {
    name: 'Schedules',
    icon: <Icon as={FaRegCalendar} width="30px" height="30px" color="inherit" />,
    path: '/schedules',
    collapse: true,
    items: [
      {
        name: 'Scheduler',
        layout: '/admin',
        path: '/schedules',
        exact: false,
        component: <UsersSchedulesOverview />,
      },
      {
        name: 'My schedule',
        path: '/my-schedule',
        layout: '/admin',
        component: <MyScheduleOverview />,
      },
    ],
  },
  {
    name: 'Tickets',
    icon: <Icon as={IoTicketSharp} width="30px" height="30px" color="inherit" />,
    path: '/Ticket',
    collapse: true,
    items: [
      {
        name: 'Requests',
        layout: '/admin',
        path: '/ticket/chat',
        exact: false,
        component: <Default />,
      },
      {
        name: 'Completed',
        layout: '/admin',
        path: '/ticket/dashboard',
        exact: false,
        component: <IncidentsOverview />,
      },
      {
        name: 'Ongoing',
        layout: '/admin',
        path: '/ticketU/chat',
        exact: false,
        component: <DefaultUser />,
      },
      {
        name: 'My Completed',
        layout: '/admin',
        path: '/ticketU/finish',
        exact: false,
        component: <IncidentsAgentOverview />,
      },
      {
        name: 'Pending tasks',
        layout: '/admin',
        path: '/ticket/tasks',
        exact: false,
        component: <IndexTask />,
      },
    ],
  },
  {
    name: 'Reports',
    path: '/reports',
    icon: <Icon as={TbReportAnalytics} width="30px" height="30px" color="inherit" />,
    collapse: true,
    items: [
      
      {
        name: 'Timesheet',
        layout: '/admin',
        path: '/reports/timesheet',
        exact: false,
        component: <ReportsSupOverview />,
      },
      {
        name: 'Payroll',
        layout: '/admin',
        path: '/reports/payroll',
        exact: false,
        component: <ReportsPROverview />,
      },
      
    ],
  },
  {
    name: 'Human Resources',
    icon: <Icon as={FaUsers} width="30px" height="30px" color="inherit" />,
    path: '/hr',
    collapse: true,
    items: [
      {
        name: 'Medical Notes',
        path: '/MedicalNotes',
        layout: '/admin',
        component: <MedicalNotesOverview />,
      },
    ],
  },
  {
    name: 'Users',
    path: '/users',
    layout: '/admin',
    icon: <Icon as={BsFillFilePersonFill} width="30px" height="30px" color="inherit" />,
    collapse: false,
    component: <UsersOverview />,
    
  },
  
];

export default routesOperations;
