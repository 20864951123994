import axios from 'axios';
import React, { useEffect, useState } from "react";
import {
    Button, Modal, ModalOverlay, ModalContent, ModalHeader, Text, ModalFooter, ModalBody, Box, FormControl,  Select, Flex, SimpleGrid, useToast, Input
} from '@chakra-ui/react';
import { EditIcon, CloseIcon } from '@chakra-ui/icons'

function UpdateModalInfo({ isOpen, onClose, user_info, fetchData }) {

    const [loading, setLoading] = useState(false);
    const toast = useToast();
    const [updatedInfo, setUpdatedInfo] = useState({
        personal_email: user_info.profile?.personal_email || '',
        phone: user_info.profile?.phone || '',
        current_address: user_info.profile?.current_address || '',
        emergency_contact: user_info.emergency_info?.emergency_contact || '',
        emergency_contact_number: user_info.emergency_info?.emergency_contact_number || '',
        relationship: user_info.emergency_info?.relationship || '',
        allergies: user_info.emergency_info?.allergies || '',
        favorite_food: user_info.emergency_info?.favorite_food || '',
        marital_status: user_info.emergency_info?.marital_status || '',
        children: user_info.emergency_info?.children || '',
        children_number: user_info.emergency_info?.children_number || '',
        gender_children: user_info.emergency_info?.gender_children || '',
        age_children: user_info.emergency_info?.age_children || '',

    });

    const handleUpdate = async () => {
        setLoading(true);

        try {
            const response = await axios.put(
                `/users/updateProfile`,
                updatedInfo
            );
            toast({
                title: "Employee Updated",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
            fetchData();
            onClose();
        } catch (error) {
            console.error(error);

            toast({
                title: "Error Updating Employee",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);


    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'4xl'} isCentered closeOnOverlayClick={false}
            closeOnEsc={false}>
            <ModalOverlay />
            <ModalContent bg={"#f2f2f2"} >
            <ModalHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} color={'#1c2147'} mt={'3%'} mb={'2%'} fontWeight={'bold'} fontSize={'35px'}>
                    <Flex direction={'column'}>
                        <Text textAlign={'center'}>
                            Emergency Contact Information
                        </Text>
                        
                    </Flex>
                </ModalHeader>
                <ModalBody mt={4} ml={10} mr={10}>
                    <Box>
                        
                        <SimpleGrid
                            columns='3'
                            gap='40px'
                            maxW='100%'
                            w={{ base: "100%", md: "unset" }}
                        >
                            <Input
                                mb="4"
                                w='255px'
                                value={updatedInfo.emergency_contact}
                                onChange={(e) =>
                                    setUpdatedInfo({ ...updatedInfo, emergency_contact: e.target.value })
                                }
                                placeholder="Emergency Contact Name"
                                className='custom-placeholderInputs'
                                background="white"
                            />
                            <Input
                                mb="4"
                                w='255px'
                                value={updatedInfo.emergency_contact_number}
                                onChange={(e) =>
                                    setUpdatedInfo({ ...updatedInfo, emergency_contact_number: e.target.value })
                                }
                                placeholder="Emergency Contact Phone"
                                className='custom-placeholderInputs'
                                background="white"
                            />
                            <Input
                                mb="4"
                                w='255px'
                                value={updatedInfo.relationship}
                                onChange={(e) =>
                                    setUpdatedInfo({ ...updatedInfo, relationship: e.target.value })
                                }
                                placeholder="Relationship"
                                className='custom-placeholderInputs'
                                background="white"
                            />
                        </SimpleGrid>
                    </Box>
                    <Box>
                        <Flex style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} color={'#1c2147'} mt={'3%'} mb={'2%'} fontWeight={'bold'} fontSize={'35px'}>
                    <Flex direction={'column'}>
                        <Text textAlign={'center'}>
                            Personal Information
                        </Text>
                        
                    </Flex>
                        </Flex>
                        <SimpleGrid
                            columns='2'
                            gap='40px'
                            maxW='100%'
                            w={{ base: "100%", md: "unset" }}
                        >
                            <Input
                                value={updatedInfo.personal_email}
                                onChange={(e) =>
                                    setUpdatedInfo({ ...updatedInfo, personal_email: e.target.value })
                                }
                                placeholder="email@email.com"
                                className='custom-placeholderInputs'
                                background="white"
                            />
                            <Input
                                value={updatedInfo.phone}
                                onChange={(e) =>
                                    setUpdatedInfo({ ...updatedInfo, phone: e.target.value })
                                }
                                placeholder="999999999"
                                className='custom-placeholderInputs'
                                background="white"
                            />
                            <Input
                                value={updatedInfo.favorite_food}
                                onChange={(e) =>
                                    setUpdatedInfo({ ...updatedInfo, favorite_food: e.target.value })
                                }
                                placeholder="Enter your favorite food"
                                className='custom-placeholderInputs'
                                background="white"
                            />
                            <FormControl>
                                <Select
                                    fontSize={'md'}
                                    borderRadius={'10px'}
                                    name='marital_status'
                                    color={'#CCCCCC'}
                                    bg={"white"}
                                    textAlign={"center"}
                                    defaultValue={updatedInfo.marital_status}
                                    onChange={(e) =>
                                        setUpdatedInfo({ ...updatedInfo, marital_status: e.target.value })
                                    }
                                    placeholder='Select your marital status'>
                                    <option value='Married'>Married</option>
                                    <option value='Single'>Single</option>
                                    <option value='Divorced'>Divorced</option>
                                    <option value='Widow/Widower'>Widow/Widower</option>
                                </Select>
                            </FormControl>
                        </SimpleGrid>
                        <SimpleGrid
                            mt={'5%'}
                            columns='1'
                            gap='40px'
                            maxW='100%'
                            w={{ base: "100%", md: "unset" }}
                        >
                            <Input
                                w='100%'
                                value={updatedInfo.current_address}
                                onChange={(e) =>
                                    setUpdatedInfo({ ...updatedInfo, current_address: e.target.value })
                                }
                                placeholder="Calle XX #xxx Col. Example Zipcode XXXX"
                                className='custom-placeholderInputs'
                                background="white"
                            />
                            <Input
                                w='100%'
                                value={updatedInfo.allergies}
                                onChange={(e) =>
                                    setUpdatedInfo({ ...updatedInfo, allergies: e.target.value })
                                }
                                placeholder="Enter your allergies or if you don't have, enter N/A"
                                className='custom-placeholderInputs'
                                background="white"
                            />
                        </SimpleGrid>
                    </Box>
                    <Box>
                    <Flex style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} color={'#1c2147'} mt={'3%'} mb={'2%'} fontWeight={'bold'} fontSize={'35px'}>
                    <Flex direction={'column'}>
                        <Text textAlign={'center'}>
                            Children Information
                        </Text>
                        
                    </Flex>
                        </Flex>
                        <SimpleGrid
                            columns='3'
                            gap='40px'
                            maxW='100%'
                            w={{ base: "100%", md: "unset" }}
                        >
                            <FormControl>
                                <Select
                                    fontSize={'md'}
                                    borderRadius={'10px'}
                                    name='children'
                                    color={'#CCCCCC'}
                                    bg={"white"}
                                    textAlign={"center"}
                                    defaultValue={updatedInfo.children}
                                    onChange={(e) =>
                                        setUpdatedInfo({ ...updatedInfo, children: e.target.value })
                                    }
                                    placeholder='Do you have children?'>
                                    <option value='Yes'>Yes</option>
                                    <option value='No'>No</option>
                                </Select>
                            </FormControl>
                            <Input
                                mb="4"
                                w='255px'
                                value={updatedInfo.children_number}
                                placeholder='How many children?'
                                className='custom-placeholderInputs'
                                background="white"
                                onChange={(e) =>
                                    setUpdatedInfo({ ...updatedInfo, children_number: e.target.value })
                                }
                            />
                            <Input
                                mb="4"
                                w='255px'
                                value={updatedInfo.gender_children}
                                placeholder='How many boys and girls?'
                                className='custom-placeholderInputs'
                                background="white"
                                onChange={(e) =>
                                    setUpdatedInfo({ ...updatedInfo, gender_children: e.target.value })
                                }
                            />
                            <Input
                                mb="4"
                                w='255px'
                                value={updatedInfo.age_children}
                                placeholder='How old are they?'
                                className='custom-placeholderInputs'
                                background="white"
                                onChange={(e) =>
                                    setUpdatedInfo({ ...updatedInfo, age_children: e.target.value })
                                }
                            />

                        </SimpleGrid>
                    </Box>
                </ModalBody>
                <ModalFooter justifyContent="center">
                    <Button class="buttonCreate" onClick={handleUpdate}>
                        Update <EditIcon ml={2} />
                    </Button>
                    <Button class="buttonCancel" onClick={onClose}>
                        Close <CloseIcon ml={2} />
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default UpdateModalInfo;
