import { Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Text, Flex, Box } from '@chakra-ui/react';
import React, { useEffect, useState } from "react";

function ModalOC({ isOpen, onClose, error, startIncident }) {

    const makeTicketOC = () => {
        startIncident(4);
        onClose();
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered closeOnOverlayClick={false} closeOnEsc={false} >
            <ModalOverlay />
            <ModalContent >
                <ModalHeader className='timermodalheader'>
                    <ModalCloseButton />
                    <Text className='textmodaltimerFS' > Attention </Text>
                </ModalHeader>
                <ModalBody className='modalAttention'>
                    <Text className='textError'>
                        You can't clock out before the end of your shift! Please contact the On Call.
                    </Text>
                    <Button onClick={makeTicketOC} class='btnMakeTicket'>
                        <Text>Contact the On Call</Text>
                    </Button>
                </ModalBody>

            </ModalContent>
        </Modal>
    );
}

export default ModalOC;
