import React, { useState } from 'react';
import axios from 'axios';
import {
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody,
    Button, Input, Box,
    FormControl, FormErrorMessage,
    Image, Text, 
    Flex, Heading, SimpleGrid,
    useToast
} from '@chakra-ui/react';
import avatar4 from 'assets/img/dashboards/grey_world.png';
import { CloseIcon, DeleteIcon } from '@chakra-ui/icons'


const UpdateQAFormats = ({ isOpen, onClose, onQAFormatsUpdated, QA, setFlashing }) => {
    const toast = useToast();
    const [formErrors, setFormErrors] = useState({});
    const [formData, setFormData] = useState({
        question: QA?.question || '',
        score: QA?.score || '',
    });

    const clearForm = () => {
        setFormData({
            question: '',
            score: ''
        });
        setFormErrors({});
    };


    const handleSubmit = async (id) => {
        try {
            const response = await axios.post(`/updateQAFormats/${QA.id}`, formData);

            onQAFormatsUpdated();
            setFlashing(formData);
            toast({
                title: ' Edit Success',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            onClose();
            setFormErrors({});
        } catch (error) {
            toast({
                title: "Error Updating the ",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            console.error('Error updating the ', error);
            setFormErrors(error.response.data.errors);
        }
    };

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    return (
        <Modal size="lg" isOpen={isOpen} onClose={() => {
            onClose();
        }}
            closeOnOverlayClick={false}
            closeOnEsc={false}
        >
            <ModalOverlay />
            <ModalContent mt={"5%"} background={"#f2f2f2"} borderRadius={"30px"}>
            <ModalHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} color={'#1c2147'} mt={'3%'} mb={'5%'} fontWeight={'bold'} fontSize={'35px'}>
                    <Flex direction={'column'}>
                        <Text textAlign={'center'}>
                            Edit Question
                        </Text>
                        
                    </Flex>
                </ModalHeader>
                <ModalBody>
                    <Box>
                        
                        <SimpleGrid
                            columns='1'
                            gap='40px'
                            maxW='100%'
                            w={{ base: "100%", md: "unset" }}
                        >
                            <FormControl isInvalid={!!formErrors.question}>
                                <Input
                                    type="text"
                                    placeholder="Enter client rol for edit"
                                    name='question'
                                    className='custom-placeholderInputs'
                                    background="white"
                                    value={formData.question}
                                    onChange={handleChange}
                                    errorBorderColor='#fc7466'
                                />
                                {formErrors.question && (
                                    <FormErrorMessage>This field is required.</FormErrorMessage>
                                )}
                            </FormControl>
                             <FormControl isInvalid={!!formErrors.score}>
                                <Input
                                    type="text"
                                    placeholder="Enter a score"
                                    name='score'
                                    className='custom-placeholderInputs'
                                    background="white"
                                    value={formData.score}
                                    onChange={handleChange}
                                />
                                {formErrors.score && (
                                    <FormErrorMessage>This field is required.</FormErrorMessage>
                                )}
                            </FormControl>
                          

                        </SimpleGrid>
                    </Box>
                </ModalBody>
                <ModalFooter justifyContent="center" pt={"10%"} pb={"10%"}>
                    <Button class="buttonCreate" onClick={handleSubmit}>
                        Update Client
                    </Button>
                    <Button class="buttonCancel" onClick={() => {
                        onClose();
                    }}>
                        Cancel <CloseIcon ml={2} />
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
export default UpdateQAFormats;