import React, { useState } from 'react';
import axios from 'axios';
import {
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody,
    Button, Input,
    Box, Image, Flex, Heading, SimpleGrid,
    useToast, Text,
    FormErrorMessage, FormControl,
} from '@chakra-ui/react';
import avatar4 from 'assets/img/dashboards/grey_world.png';
import { CloseIcon, AddIcon } from '@chakra-ui/icons'
const CreateUserType = ({ isOpen, onClose, onUserTypeCreated }) => {
    const toast = useToast();

    const [formErrors, setFormErrors] = useState({});
    const [formData, setFormData] = useState({
        name: ''
    });
    const clearForm = () => {
        setFormData({
            name: ''
        });
        setFormErrors({});
    };
    const handleSubmit = async () => {
        try {
            const response = await axios.post('/usertype/create', formData);
            onUserTypeCreated();
            toast({
                title: 'Type of user created',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            clearForm();
            onClose();
            setFormErrors({});
        } catch (error) {
            toast({
                title: "Error Creating Type of User",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            console.error('Error Creating Type of User:', error);
            setFormErrors(error.response.data.errors);
        }
    };

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };
    return (
        <Modal size="lg" isOpen={isOpen} onClose={() => {
            onClose();
            clearForm();
        }}
            closeOnOverlayClick={false}
            closeOnEsc={false}
        >
            <ModalOverlay />
            <ModalContent class='modalContenAddUserType'>
            <ModalHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} color={'#1c2147'} mt={'3%'} mb={'5%'} fontWeight={'bold'} fontSize={'35px'}>
                    <Flex direction={'column'}>
                        <Text textAlign={'center'}>
                        Add user type
                        </Text>
                        
                    </Flex>
                </ModalHeader>
                <ModalBody>
                    <Box>
                      
                        <SimpleGrid
                            columns='1'
                            gap='40px'
                            maxW='100%'
                            w={{ base: "100%", md: "unset" }}
                        >
                            <FormControl isInvalid={!!formErrors.name}>
                                <Input
                                    type="text"
                                    placeholder="Enter type User"
                                    name='name'
                                    className='custom-placeholderInputs'
                                    background="white"
                                    defaultValue={formData.name}
                                    onChange={handleChange}
                                />
                                {formErrors.name && (
                                    <FormErrorMessage>This field is required.</FormErrorMessage>
                                )}
                            </FormControl>
                        </SimpleGrid>
                    </Box>
                </ModalBody>
                <ModalFooter justifyContent="center" pt={"10%"} pb={"10%"}>
                    <Button class="buttonCreate" onClick={handleSubmit}>
                        Add user type <AddIcon ml={2} />
                    </Button>
                    <Button class="buttonCancel" ml={3} onClick={() => {
                        onClose();
                        clearForm();
                    }}>
                        Cancel <CloseIcon ml={2} />
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
export default CreateUserType; 