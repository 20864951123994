import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Table, Thead, Tbody, Tr, Th, Td, TableContainer,
    Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, Flex, Text, Box, Badge
} from '@chakra-ui/react';
import { TimeIcon } from '@chakra-ui/icons'
import { AiOutlineUser } from "react-icons/ai";
function DetailAudits({ isOpen, onClose, schedule }) {

    const [audit, setAudit] = useState([]);

    const fetchData = async () => {
        try {
            const response = await axios.get(`/auditdetail/${schedule.id}`);
            const responseData = response.data;
            setAudit(responseData);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };
    useEffect(() => {
        fetchData();
    }, []);

    const getColorSchemeForTypeDaily = (typeDaily) => {
        const colorMap = {
            1: '#7080fc',
            2: '#fcd677',
            3: '#8a6dce',
            4: '#dd67ae',
            5: '#fca066',
            6: '#a7a9ac',
            7: '#fcd677',
            8: '#dd67ae',
            9: '#fca066',
            "1": '#7080fc',
            "2": '#fcd677',
            "3": '#8a6dce',
            "4": '#dd67ae',
            "5": '#fca066',
            "6": '#a7a9ac',
            "7": '#fcd677',
            "8": '#dd67ae',
            "9": '#fca066',
        };
        return colorMap[typeDaily] || '#7080fc';
    };

    const getColorSchemeForTypeDailyNew = (typeDaily) => {
        const colorMap = {
            1: '#7080fc',
            2: '#fcd677',
            3: '#8a6dce',
            4: '#dd67ae',
            5: '#fca066',
            6: '#a7a9ac',
            7: '#fcd677',
            8: '#dd67ae',
            9: '#fca066',
            "1": '#7080fc',
            "2": '#fcd677',
            "3": '#8a6dce',
            "4": '#dd67ae',
            "5": '#fca066',
            "6": '#a7a9ac',
            "7": '#fcd677',
            "8": '#dd67ae',
            "9": '#fca066',
        };
        return colorMap[typeDaily] || '#7080fc';
    };

    const getColorSchemeForOption = (option) => {
        const colorMap = {
            1: 'none',
            2: '#b9ce67',
            3: '#b9ce67',
            4: '#988d77',
            5: '#6bc2ed',
            6: '#fc7466',
            "1": 'none',
            "2": '#b9ce67',
            "3": '#b9ce67',
            "4": '#988d77',
            "5": '#6bc2ed',
            "6": '#fc7466',
        };
        return colorMap[option] || '#a7a9ac';
    };


    const getColorSchemeForStatus = (status) => {
        const colorMap = {
            "1": '#424ea8',
            "2": '#808080',
            "3": '#424ea8',
            "4": '#808080',
            "5": '#424ea8',
            "6": '#808080',
            "7": '#424ea8',
            "8": '#808080',
            "9": '#424ea8',
            "10": '#808080',
            "11": '#424ea8',
            "12": '#808080',
            "13": '#424ea8',
            "14": '#808080',
            "15": '#808080',
            1: '#424ea8',
            2: '#808080',
            3: '#424ea8',
            4: '#808080',
            5: '#424ea8',
            6: '#808080',
            7: '#424ea8',
            8: '#808080',
            9: '#424ea8',
            10: '#808080',
            11: '#424ea8',
            12: '#808080',
            13: '#424ea8',
            14: '#808080',
            15: '#808080',
        };
        return colorMap[status] || 'transparent';
    };

    const getColorSchemeForEvent = (event) => {
        const colorMap = {
            "Created": '#3644a9',
            "Updated": '#bc9ae9',
            "Deleted": '#fc7466',
        };
        return colorMap[event] || '#3644a9';
    };


    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'6xl'} isCentered closeOnOverlayClick={false}
            closeOnEsc={false}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader mt={'70px'} display={'flex'} justifyContent={'center'}>
                    <Flex direction={'column'}>
                        <Text fontSize={'35px'} color={'#1c2147'} display={'flex'} justifyContent={'center'} ml={'40px'}>
                            <Box mr={2} mb={1}>
                                <TimeIcon />
                            </Box>
                            Audit History
                        </Text>
                        <Text color={'#808080'} display={'flex'} justifyContent={'center'} mb={'30px'}>
                            <Box mr={2}>
                                <AiOutlineUser size={'25px'} />
                            </Box>
                            <Text mr={'20px'}>
                                {schedule.user.name} {schedule.user.last_name}
                            </Text>
                            |
                            <Text ml={'20px'}>
                                {schedule.date}
                            </Text>
                        </Text>
                    </Flex>
                </ModalHeader>
                <ModalBody>
                    <TableContainer>
                        <Table>
                            <Thead bg={"white"}>
                                <Tr bg={'white'}>
                                    <Th textAlign={'center'}>User</Th>
                                    <Th textAlign={'center'}>Date Modified</Th>
                                    <Th textAlign={'center'}>Event</Th>
                                    <Th textAlign={'center'}>Original Values</Th>
                                    <Th textAlign={'center'}>Modified Values</Th>

                                </Tr>
                            </Thead>
                            <Tbody fontSize={'14px'} color={"#1c2147"} fontWeight={"bold"} overflowY="auto">
                                {audit && audit.length > 0 ? (
                                    audit.map((auditItem, index) => (
                                        <Tr key={auditItem.id} className={index % 2 === 0 ? 'white-row' : 'gray-row'} mb={'50px'}>
                                            <Td textAlign={'center'}>
                                                {auditItem.name}
                                            </Td>
                                            <Td textAlign={'center'}>
                                                <Flex direction={'column'} display={'flex'} alignItems={'center'}>
                                                    <Flex color={'#808080'}>
                                                        {auditItem.created_date}
                                                    </Flex>
                                                    <Flex color={'#808080'}>
                                                        {auditItem.created_time}
                                                    </Flex>
                                                </Flex>
                                            </Td>
                                            <Td textAlign={'center'}>
                                                <Badge color={"white"} bg={getColorSchemeForEvent(auditItem.event)} borderRadius={"5px"}>
                                                    {auditItem.event}
                                                </Badge>
                                            </Td>
                                            <Td>
                                                <Flex display={'flex'} direction={'column'} alignItems={'center'}>
                                                    {auditItem.old.option && (

                                                        <Flex mb={'5px'}>
                                                            <>
                                                                <Badge color={auditItem.old.option === 1 ? '#1c2147' : 'white'} bg={getColorSchemeForOption(auditItem.old.option)} borderRadius={"5px"}>
                                                                    {(() => {
                                                                        switch (auditItem.old.option) {
                                                                            case 1:
                                                                                return "---";
                                                                            case 2:
                                                                                return "Clock In";
                                                                            case 3:
                                                                                return "Late";
                                                                            case 4:
                                                                                return "Clock out";
                                                                            case 5:
                                                                                return "Supervisor";
                                                                            case 6:
                                                                                return "Call out";
                                                                            case "1":
                                                                                return "---";
                                                                            case "2":
                                                                                return "Clock In";
                                                                            case "3":
                                                                                return "Late";
                                                                            case "4":
                                                                                return "Clock out";
                                                                            case "5":
                                                                                return "Supervisor";
                                                                            case "6":
                                                                                return "Supervisor";
                                                                            default:
                                                                                return "";
                                                                        }
                                                                    })()}
                                                                </Badge>
                                                            </>
                                                        </Flex>
                                                    )}
                                                    {auditItem.old.status !== undefined && auditItem.old.status !== null && (
                                                        <Flex mb={'5px'}>
                                                            <>
                                                                <Badge color={"white"} bg={getColorSchemeForStatus(auditItem.old.status)} borderRadius={"5px"}>
                                                                    {(() => {
                                                                        switch (auditItem.old.status) {
                                                                            case "1":
                                                                                return "Normal";
                                                                            case "2":
                                                                                return "Off";
                                                                            case "3":
                                                                                return "Holiday";
                                                                            case "4":
                                                                                return "Vacation";
                                                                            case "5":
                                                                                return "Maternity Leave";
                                                                            case "6":
                                                                                return "Permission request";
                                                                            case "7":
                                                                                return "Wedding Leave";
                                                                            case "8":
                                                                                return "Medical Leave";
                                                                            case "9":
                                                                                return "Paternity Leave";
                                                                            case "10":
                                                                                return "Bereavement Leave";
                                                                            case "11":
                                                                                return "Surecall O/C";
                                                                            case "12":
                                                                                return "Agent overnight";
                                                                            case "13":
                                                                                return "No longer with us";
                                                                            case "14":
                                                                                return "Stand by";
                                                                            case "15":
                                                                                return "Holiday Off";
                                                                            case 1:
                                                                                return "Normal";
                                                                            case 2:
                                                                                return "Off";
                                                                            case 3:
                                                                                return "Holiday";
                                                                            case 4:
                                                                                return "Vacation";
                                                                            case 5:
                                                                                return "Maternity Leave";
                                                                            case 6:
                                                                                return "Permission request";
                                                                            case 7:
                                                                                return "Wedding Leave";
                                                                            case 8:
                                                                                return "Medical Leave";
                                                                            case 9:
                                                                                return "Paternity Leave";
                                                                            case 10:
                                                                                return "Bereavement Leave";
                                                                            case 11:
                                                                                return "Surecall O/C";
                                                                            case 12:
                                                                                return "Agent overnight";
                                                                            case 13:
                                                                                return "No longer with us";
                                                                            case 14:
                                                                                return "Stand by";
                                                                            case 15:
                                                                                return "Holiday Off";
                                                                            default:
                                                                                return "Clock out";
                                                                        }
                                                                    })()}
                                                                </Badge>
                                                            </>
                                                        </Flex>
                                                    )}
                                                    {auditItem.old.time_start && (

                                                        <Flex mb={'5px'}>
                                                            <>
                                                                <span style={{ color: '#999999', marginRight: '5px' }}>ST:</span> {auditItem.old.time_start}
                                                            </>
                                                        </Flex>
                                                    )}
                                                    {auditItem.old.time_end && (
                                                        <Flex mb={'5px'}>
                                                            <>
                                                                <span style={{ color: '#999999', marginRight: '5px' }}>ET:</span> {auditItem.old.time_end}
                                                            </>
                                                        </Flex>
                                                    )}
                                                    {auditItem.old.post !== undefined && auditItem.old.post !== null && (
                                                        <Flex mb={'5px'}>
                                                            <Badge color={auditItem.old.post === 0 ? '#1c2147' : 'white'} bg={auditItem.old.post === 0 ? 'none' : '#fabb1c'} borderRadius={"5px"}>
                                                                {(() => {
                                                                    switch (auditItem.old.post) {
                                                                        case 1:
                                                                            return "Published";
                                                                        case 0:
                                                                            return "---";
                                                                        case "1":
                                                                            return "Published";
                                                                        case "0":
                                                                            return "---";
                                                                        default:
                                                                            return "";
                                                                    }
                                                                })()}
                                                            </Badge>
                                                        </Flex>
                                                    )}
                                                    {auditItem.old.accept !== undefined && auditItem.old.accept !== null && (

                                                        <Flex mb={'5px'}>
                                                            <Badge color={auditItem.old.accept === 0 ? '#1c2147' : 'white'} bg={auditItem.old.accept === 0 ? 'none' : '#fabb1c'} borderRadius={"5px"}>
                                                                {(() => {
                                                                    switch (auditItem.old.accept) {
                                                                        case 1:
                                                                            return "Accepted";
                                                                        case 0:
                                                                            return "---";
                                                                        case "1":
                                                                            return "Accepted";
                                                                        case "0":
                                                                            return "---";
                                                                        default:
                                                                            return "";
                                                                    }
                                                                })()}
                                                            </Badge>
                                                        </Flex>
                                                    )}
                                                    {auditItem.old.type_daily && (

                                                        <Flex mb={'5px'}>
                                                            <>
                                                                <Badge color={"white"} bg={getColorSchemeForTypeDaily(auditItem.old.type_daily)} borderRadius={"5px"}>
                                                                    {(() => {
                                                                        switch (auditItem.old.type_daily) {
                                                                            case 1:
                                                                                return "Regular shift";
                                                                            case 2:
                                                                                return "Regular training";
                                                                            case 3:
                                                                                return "Additional hours";
                                                                            case 4:
                                                                                return "Regular coverage";
                                                                            case 5:
                                                                                return "Regular shadowing"
                                                                            case 6:
                                                                                return "No shift"
                                                                            case 7:
                                                                                return "Additional training"
                                                                            case 8:
                                                                                return "Additional coverage"
                                                                            case 9:
                                                                                return "Additional shadowing"
                                                                            case "1":
                                                                                return "Regular shift";
                                                                            case "2":
                                                                                return "Regular training";
                                                                            case "3":
                                                                                return "Additional hours";
                                                                            case "4":
                                                                                return "Regular coverage";
                                                                            case "5":
                                                                                return "Regular shadowing"
                                                                            case "6":
                                                                                return "No shift"
                                                                            case "7":
                                                                                return "Additional training"
                                                                            case "8":
                                                                                return "Additional coverage"
                                                                            case "9":
                                                                                return "Additional shadowing"
                                                                            default:
                                                                                return "Regular shift";
                                                                        }
                                                                    })()}
                                                                </Badge>
                                                            </>
                                                        </Flex>
                                                    )}
                                                    {auditItem.old.id_operator_coverage !== undefined && (

                                                        <Flex mb={'5px'}>
                                                            <>
                                                                {auditItem.old.id_operator_coverage === 0 ? '---' : null}
                                                            </>
                                                        </Flex>
                                                    )}

                                                    {auditItem.old.hours && (

                                                        <Flex mb={'5px'}>
                                                            <>
                                                                <span style={{ color: '#999999', marginRight: '5px' }}>H:</span> {auditItem.old.hours}hr
                                                            </>
                                                        </Flex>
                                                    )}
                                                    {auditItem.old.minutes && (
                                                        <Flex mb={'5px'}>

                                                            <>
                                                                <span style={{ color: '#999999', marginRight: '5px' }}>M:</span> {auditItem.old.minutes}min
                                                            </>
                                                        </Flex>
                                                    )}
                                                    {auditItem.old.date_end && (
                                                        <Flex mb={'5px'} direction={'column'}>
                                                            <>
                                                                <Text textAlign={'center'} color={'#808080'}>
                                                                    End date:
                                                                </Text>
                                                                <Text textAlign={'center'}>
                                                                    {auditItem.old.date_end}
                                                                </Text>
                                                            </>
                                                        </Flex>
                                                    )}
                                                    {auditItem.old.duration && (
                                                        <Flex mb={'5px'} direction={'column'}>
                                                            <>
                                                                <Text textAlign={'center'} color={'#808080'}>
                                                                    Duration:
                                                                </Text>
                                                                <Text textAlign={'center'}>
                                                                    {auditItem.old.duration}
                                                                </Text>
                                                            </>
                                                        </Flex>
                                                    )}
                                                    {auditItem.old.date_start && (
                                                        <Flex mb={'5px'} direction={'column'}>
                                                            <>
                                                                <Text textAlign={'center'} color={'#808080'}>
                                                                    Start date:
                                                                </Text>
                                                                <Text textAlign={'center'}>
                                                                    {auditItem.old.date_start}
                                                                </Text>
                                                            </>
                                                        </Flex>
                                                    )}
                                                </Flex>
                                            </Td>
                                            <Td>
                                                <Flex display={'flex'} direction={'column'} alignItems={'center'}>
                                                    {auditItem.new.option && (
                                                        <Flex mb={'5px'}>
                                                            <>
                                                                <Badge color={auditItem.new.option === 1 ? '#1c2147' : 'white'} bg={getColorSchemeForOption(auditItem.new.option)} borderRadius={"5px"}>
                                                                    {(() => {
                                                                        switch (auditItem.new.option) {
                                                                            case 1:
                                                                                return "---";
                                                                            case 2:
                                                                                return "Clock In";
                                                                            case 3:
                                                                                return "Late";
                                                                            case 4:
                                                                                return "Clock out";
                                                                            case 5:
                                                                                return "Supervisor";
                                                                            case 6:
                                                                                return "Call out";
                                                                            case "1":
                                                                                return "---";
                                                                            case "2":
                                                                                return "Clock In";
                                                                            case "3":
                                                                                return "Late";
                                                                            case "4":
                                                                                return "Clock out";
                                                                            case "5":
                                                                                return "Supervisor";
                                                                            case "6":
                                                                                return "Call out";
                                                                            default:
                                                                                return "";
                                                                        }
                                                                    })()}
                                                                </Badge>
                                                            </>
                                                        </Flex>
                                                    )}
                                                    {auditItem.new.status !== undefined && auditItem.new.status !== null && (
                                                        <Flex mb={'5px'}>
                                                            <>
                                                                <Badge color={"white"} bg={getColorSchemeForStatus(auditItem.new.status)} borderRadius={"5px"}>
                                                                    {(() => {
                                                                        switch (auditItem.new.status) {
                                                                            case "1":
                                                                                return "Normal";
                                                                            case "2":
                                                                                return "Off";
                                                                            case "3":
                                                                                return "Holiday";
                                                                            case "4":
                                                                                return "Vacation";
                                                                            case "5":
                                                                                return "Maternity Leave";
                                                                            case "6":
                                                                                return "Permission request";
                                                                            case "7":
                                                                                return "Wedding Leave";
                                                                            case "8":
                                                                                return "Medical Leave";
                                                                            case "9":
                                                                                return "Paternity Leave";
                                                                            case "10":
                                                                                return "Bereavement Leave";
                                                                            case "11":
                                                                                return "Surecall O/C";
                                                                            case "12":
                                                                                return "Agent overnight";
                                                                            case "13":
                                                                                return "No longer with us";
                                                                            case "14":
                                                                                return "Stand by";
                                                                            case "15":
                                                                                return "Holiday Off";
                                                                            case 1:
                                                                                return "Normal";
                                                                            case 2:
                                                                                return "Off";
                                                                            case 3:
                                                                                return "Holiday";
                                                                            case 4:
                                                                                return "Vacation";
                                                                            case 5:
                                                                                return "Maternity Leave";
                                                                            case 6:
                                                                                return "Permission request";
                                                                            case 7:
                                                                                return "Wedding Leave";
                                                                            case 8:
                                                                                return "Medical Leave";
                                                                            case 9:
                                                                                return "Paternity Leave";
                                                                            case 10:
                                                                                return "Bereavement Leave";
                                                                            case 11:
                                                                                return "Surecall O/C";
                                                                            case 12:
                                                                                return "Agent overnight";
                                                                            case 13:
                                                                                return "No longer with us";
                                                                            case 14:
                                                                                return "Stand by";
                                                                            case 15:
                                                                                return "Holiday Off";
                                                                            default:
                                                                                return "Clock out";
                                                                        }
                                                                    })()}
                                                                </Badge>
                                                            </>
                                                        </Flex>
                                                    )}
                                                    {auditItem.new.time_start && (
                                                        <Flex mb={'5px'}>
                                                            <>
                                                                <span style={{ color: '#999999', marginRight: '5px' }}>ST:</span> {auditItem.new.time_start}
                                                            </>
                                                        </Flex>
                                                    )}
                                                    {auditItem.new.time_end && (
                                                        <Flex mb={'5px'}>
                                                            <>
                                                                <span style={{ color: '#999999', marginRight: '5px' }}>ET:</span> {auditItem.new.time_end}
                                                            </>
                                                        </Flex>
                                                    )}
                                                    {auditItem.new.post !== undefined && auditItem.new.post !== null && (
                                                        <Flex mb={'5px'}>
                                                            <Badge color={auditItem.new.post === 0 ? '#1c2147' : 'white'} bg={auditItem.new.post === 0 ? 'none' : '#fabb1c'} borderRadius={"5px"}>
                                                                {(() => {
                                                                    switch (auditItem.new.post) {
                                                                        case 1:
                                                                            return "Published";
                                                                        case 0:
                                                                            return "---";
                                                                        case "1":
                                                                            return "Published";
                                                                        case "0":
                                                                            return "---";
                                                                        default:
                                                                            return "";
                                                                    }
                                                                })()}
                                                            </Badge>
                                                        </Flex>
                                                    )}
                                                    {auditItem.new.accept !== undefined && auditItem.new.accept !== null && (
                                                        <Flex mb={'5px'}>
                                                            <Badge color={auditItem.new.accept === 0 ? '#1c2147' : 'white'} bg={auditItem.new.accept === 0 ? 'none' : '#fabb1c'} borderRadius={"5px"}>
                                                                {(() => {
                                                                    switch (auditItem.new.accept) {
                                                                        case 1:
                                                                            return "Accepted";
                                                                        case 0:
                                                                            return "---";
                                                                        case "1":
                                                                            return "Accepted";
                                                                        case "0":
                                                                            return "---";
                                                                        default:
                                                                            return "";
                                                                    }
                                                                })()}
                                                            </Badge>
                                                        </Flex>
                                                    )}
                                                    {auditItem.new.type_daily && (
                                                        <Flex mb={'5px'}>
                                                            <>
                                                                <Badge color={"white"} bg={getColorSchemeForTypeDailyNew(auditItem.new.type_daily)} borderRadius={"5px"}>
                                                                    {(() => {
                                                                        switch (auditItem.new.type_daily) {
                                                                            case 1:
                                                                                return "Regular shift";
                                                                            case 2:
                                                                                return "Regular training";
                                                                            case 3:
                                                                                return "Additional hours";
                                                                            case 4:
                                                                                return "Regular coverage";
                                                                            case 5:
                                                                                return "Regular shadowing"
                                                                            case 6:
                                                                                return "No shift"
                                                                            case 7:
                                                                                return "Additional training"
                                                                            case 8:
                                                                                return "Additional coverage"
                                                                            case 9:
                                                                                return "Additional shadowing"
                                                                            case "1":
                                                                                return "Regular shift";
                                                                            case "2":
                                                                                return "Regular training";
                                                                            case "3":
                                                                                return "Additional hours";
                                                                            case "4":
                                                                                return "Regular coverage";
                                                                            case "5":
                                                                                return "Regular shadowing"
                                                                            case "6":
                                                                                return "No shift"
                                                                            case "7":
                                                                                return "Additional training"
                                                                            case "8":
                                                                                return "Additional coverage"
                                                                            case "9":
                                                                                return "Additional shadowing"
                                                                            default:
                                                                                return "Regular shift";
                                                                        }
                                                                    })()}
                                                                </Badge>
                                                            </>
                                                        </Flex>
                                                    )}
                                                    {/* {auditItem.new.id_operator_coverage && parseInt(auditItem.new.id_operator_coverage) === schedule.userC.id && (

                                                        <Flex direction='column' alignItems={'center'} mb={'5px'}>
                                                            <>
                                                                <Flex>
                                                                    <span style={{ color: '#999999', marginRight: '5px' }}>O. Coverage:</span>
                                                                </Flex>
                                                                <Flex>
                                                                    {schedule.userC.name}
                                                                </Flex>
                                                            </>
                                                        </Flex>
                                                    )} */}
                                                    {auditItem.new.hours && (

                                                        <Flex mb={'5px'}>
                                                            <>
                                                                <span style={{ color: '#999999', marginRight: '5px' }}>H:</span> {auditItem.new.hours}hr
                                                            </>
                                                        </Flex>
                                                    )}
                                                    {auditItem.new.minutes && (
                                                        <Flex mb={'5px'}>
                                                            <>
                                                                <span style={{ color: '#999999', marginRight: '5px' }}>M:</span> {auditItem.new.minutes}min
                                                            </>
                                                        </Flex>
                                                    )}
                                                    {auditItem.new.date_end && (
                                                        <Flex mb={'5px'} direction={'column'}>
                                                            <>
                                                                <Text textAlign={'center'} color={'#808080'}>
                                                                    End date:
                                                                </Text>
                                                                <Text textAlign={'center'}>
                                                                    {auditItem.new.date_end}
                                                                </Text>
                                                            </>
                                                        </Flex>
                                                    )}
                                                    {auditItem.new.duration !== undefined && auditItem.new.duration !== null && (
                                                        <Flex mb={'5px'} direction={'column'}>
                                                            <>
                                                                <Text textAlign={'center'} color={'#808080'}>
                                                                    Duration:
                                                                </Text>
                                                                <Text textAlign={'center'}>
                                                                    {auditItem.new.duration === 0 ? '00:00:00' : auditItem.new.duration}
                                                                </Text>
                                                            </>
                                                        </Flex>
                                                    )}
                                                    {auditItem.new.date_start && (
                                                        <Flex mb={'5px'} direction={'column'}>
                                                            <>
                                                                <Text textAlign={'center'} color={'#808080'}>
                                                                    Start date:
                                                                </Text>
                                                                <Text textAlign={'center'}>
                                                                    {auditItem.new.date_start}
                                                                </Text>
                                                            </>
                                                        </Flex>
                                                    )}
                                                    {auditItem.new.generate && (
                                                        <Flex mb={'5px'} direction={'column'}>
                                                            <>
                                                                <Badge color={"white"} bg={'#7080fc'} borderRadius={"5px"}>
                                                                    {(() => {
                                                                        switch (auditItem.new.generate) {
                                                                            case 1:
                                                                                return "Shift Generated";
                                                                            case "1":
                                                                                return "Shift Generated";
                                                                            default:
                                                                                return "Shift Generated";
                                                                        }
                                                                    })()}
                                                                </Badge>
                                                            </>
                                                        </Flex>
                                                    )}
                                                </Flex>
                                            </Td>

                                        </Tr>
                                    ))
                                ) : (
                                    <Tr>
                                        <Th colSpan={'9'} >
                                            <Text
                                                color='#1c2147'
                                                fontSize={"25px"}
                                                textAlign={"center"}
                                                paddingTop={"15px"}
                                                paddingBottom={"15px"}
                                                bg={"#f2f2f2"}
                                                textTransform={'none'}
                                                fontFamily={'DM Sans'}
                                                letterSpacing="-1px"
                                            >
                                                No data
                                            </Text>
                                        </Th>
                                    </Tr>
                                )}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </ModalBody>
                <ModalFooter justifyContent="center" mt={'40px'} mb={'20px'}>
                    <Button bg="#7080fc" color={'white'} _hover={{ background: "#808080", color: 'white' }} mr={3} onClick={onClose} borderRadius={'5px'} size={'md'}>
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}
export default DetailAudits; 