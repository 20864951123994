import React from 'react';
import {
    Table, Thead, Tbody, Tr, Th, Td, Button, ButtonGroup, Flex, Box, Text, Spinner
} from '@chakra-ui/react'
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import { TbReportSearch } from "react-icons/tb";

function PrenominalRD({ setCurrentPage, reportsD, isLoading, total, currentPage, totalPages }) {


    return (
        <>
            <Flex
                direction='column'
                w='100%'>
                <Table id='ticketsTable' size={'sm'}>
                    <Thead bg={"white"} >
                        <Tr>
                            <Th color='#808080' textAlign={'center'}>ID</Th>
                            <Th color='#808080' textAlign={'center'}>Full Name</Th>
                            <Th color='#808080' textAlign={'center'}>Email</Th>
                            <Th color='#808080' textAlign={'center'}>Hour salary</Th>
                            <Th color='#808080' textAlign={'center'}>Regular hours</Th>
                            <Th color='#808080' textAlign={'center'}>Regular hours payment</Th>
                            <Th color='#808080' textAlign={'center'}>Rent equipment</Th>
                            <Th color='#808080' textAlign={'center'}>Attendance Bonus</Th>
                            <Th color='#808080' textAlign={'center'}>Extra hours &gt; 44</Th>
                            <Th color='#808080' textAlign={'center'}>Extra hours &gt; 65</Th>
                            <Th color='#808080' textAlign={'center'}>Total extra hours</Th>
                            <Th color='#808080' textAlign={'center'}>Payment Extra hours &gt; 44</Th>
                            <Th color='#808080' textAlign={'center'}>Payment Extra hours &gt; 65</Th>
                            <Th color='#808080' textAlign={'center'}>Total payment extra hours</Th>
                            <Th color='#808080' textAlign={'center'}>Total</Th>
                        </Tr>
                    </Thead>
                    <Tbody fontSize={'14px'} color={"#1c2147"} fontWeight={"bold"}>
                        {reportsD && reportsD.length > 0 ? (
                            reportsD.map((reportsD, index) => (
                                <Tr className={index % 2 === 0 ? 'white-row' : 'gray-row'} key={index}>
                                    <Td textAlign={'center'}>
                                        {reportsD.mat_dp ? (
                                            reportsD.mat_dp
                                        ) : (
                                            "MATP"
                                        )}
                                    </Td>
                                    <Td textAlign={'center'}>
                                        {reportsD.name} {reportsD.last_name}
                                    </Td>
                                    <Td textAlign={'center'}>
                                        {reportsD.email}
                                    </Td>
                                    <Td textAlign={'center'}>
                                        ${reportsD.salary}
                                    </Td>
                                    <Td textAlign={'center'}>
                                        {reportsD.total_effective}
                                    </Td>
                                    <Td textAlign={'center'}>
                                        ${reportsD.total_pay_regular_hours}
                                    </Td>
                                    <Td textAlign={'center'}>
                                        ${reportsD.rent_equipment}
                                    </Td>
                                    <Td textAlign={'center'}>
                                        ${reportsD.attendance_bonus}
                                    </Td>
                                    <Td textAlign={'center'}>
                                        {reportsD.extra_hours_45}
                                    </Td>
                                    <Td textAlign={'center'}>
                                        {reportsD.extra_hours_65}
                                    </Td>
                                    <Td textAlign={'center'}>
                                        {reportsD.total_extra_hours}
                                    </Td>
                                    <Td textAlign={'center'}>
                                        ${reportsD.payment_extra_hours_45}
                                    </Td>
                                    <Td textAlign={'center'}>
                                        ${reportsD.payment_extra_hours_65}
                                    </Td>
                                    <Td textAlign={'center'}>
                                        ${reportsD.total_payment_extra_hours}
                                    </Td>
                                    <Td textAlign={'center'}>
                                        ${reportsD.total_pay_complete}
                                    </Td>
                                </Tr>
                            ))
                        ) : (
                            <Tr>
                                {!isLoading ? (
                                    <Th colSpan={'20'}>
                                        <Flex display={'flex'} justifyContent={'center'} alignItems={'center'} bg={"#f2f2f2"}>
                                            <Text
                                                color='#1c2147'
                                                fontSize={"25px"}
                                                textAlign={"center"}
                                                paddingTop={"15px"}
                                                paddingBottom={"15px"}
                                                textTransform={'none'}
                                                fontFamily={'DM Sans'}
                                                letterSpacing="-1px"
                                                mr={'20px'}
                                            >
                                                Loading...
                                            </Text>
                                            <Spinner color='blue.500' />
                                        </Flex>
                                    </Th>
                                ) : (
                                    <Th colSpan={'20'}>
                                        <Text
                                            color='#1c2147'
                                            fontSize={"25px"}
                                            textAlign={"center"}
                                            paddingTop={"15px"}
                                            paddingBottom={"15px"}
                                            bg={"#f2f2f2"}
                                            textTransform={'none'}
                                            fontFamily={'DM Sans'}
                                            letterSpacing="-1px"
                                        >
                                            No data
                                        </Text>
                                    </Th>
                                )}
                            </Tr>
                        )}
                    </Tbody>
                </Table>
            </Flex>
            <Box width={"100%"} display={"flex"} mt={"30px"}>
                <Flex display={'flex'} justifyContent={'flex-start'} width={"max-content"} pb={"10px"} mt={"8px"}  >
                    {total === 0 ? (
                        <>
                            <Flex bg={'#f2f2f2'} width={'150px'} display={'flex'} justifyContent={'center'}>
                                <Box mt={'5px'} mr={2} ml={4}>
                                    <TbReportSearch color={"#1c2147"} fontWeight={'bold'} />
                                </Box>
                                <Text fontSize={'14px'} mt={1} color={"#1c2147"} fontWeight={'bold'}>
                                    No reports
                                </Text>
                            </Flex>
                        </>
                    ) : (
                        <>
                            <Flex bg={'#f2f2f2'} width={'150px'} display={'flex'} justifyContent={'center'}>
                                <Text fontSize={'14px'} mt={1} mr={3} color={"#1c2147"} fontWeight={'bold'}>
                                    {total}
                                </Text>
                                <Box mt={'5px'} mr={1}>
                                    <TbReportSearch color={"#1c2147"} fontWeight={'bold'} />
                                </Box>
                                <Text fontSize={'14px'} mt={1} color={"#1c2147"} fontWeight={'bold'}>
                                    {total === 1 ? 'Report' : 'Reports'}
                                </Text>
                            </Flex>
                        </>
                    )}
                </Flex>
                <ButtonGroup isAttached variant="outline" ml={"34%"} justifyContent={'center'} width={"max-content"}>
                    <Button
                        borderRadius={'0px'}
                        onClick={() => setCurrentPage(currentPage - 1)}
                        isDisabled={currentPage === 1}
                        bg="#808080" color={"white"}
                        _hover={{ background: "#808080", }}
                        _active={{ background: "#808080", }}
                    ><BsArrowLeftShort fontSize="20px" />
                    </Button>
                    <Text class="btnTextPages">{currentPage} of {totalPages}</Text>
                    <Button
                        borderRadius={'0px'} ml={4}
                        onClick={() => setCurrentPage(currentPage + 1)}
                        isDisabled={currentPage === totalPages}
                        bg="#808080" color={"white"}
                        _hover={{ background: "#808080", }}
                        _active={{ background: "#808080", }}
                    ><BsArrowRightShort fontSize="20px" />
                    </Button>
                </ButtonGroup>
            </Box>
        </>
    );
}
export default PrenominalRD; 