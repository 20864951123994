import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2'

import {
    Button,
    Flex, Grid, Box, Badge,
    Text, Textarea,
    Input, Select,
    useToast,
    FormControl, FormErrorMessage
} from '@chakra-ui/react'
import { AddIcon, StarIcon, RepeatClockIcon, DeleteIcon } from '@chakra-ui/icons'
import { GoClockFill } from "react-icons/go";
import Select2 from 'react-select'
import Card from "components/card/Card.js";
import moment from 'moment';

function CreateSchedule({ onBackButtonClick }) {

    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState([]);
    const [selectedOperator, setSelectedOperator] = useState([]);
    const [selectedCoverage, setSelectedCoverage] = useState([]);
    const [users, setUsers] = useState([]);
    const [userCoverage, setUserCoverage] = useState([]);
    const [selectedWorkType, setSelectedWorkType] = useState([]);
    const [userCoverageOptions, setUserCoverageOptions] = useState(userCoverage);
    const [loading, setLoading] = useState(false);
    const toast = useToast();
    const [resetKey, setResetKey] = useState(0);
    const [errorMsg, setErrorMsg] = useState("");
    const [detail, setDetail] = useState(null);
    const [scheduleHistory, setScheduleHistory] = useState([]);
    const [showSecondSelect, setShowSecondSelect] = useState(false);

    const [formData, setFormData] = useState({
        date: new Date().toISOString().substr(0, 10),
        description: '',
        time_start: '08:00:00',
        hours: 8,
        minutes: 30,
        time_end: '16:30:00',
        status: 1
    });

    const clearFilters = () => {
        setFormData({
            date: new Date().toISOString().substr(0, 10),
            description: '',
            time_start: '08:00:00',
            hours: 8,
            minutes: 30,
            time_end: '16:30:00',
            status: 1
        });
        setSelectedClient([]);
        setSelectedOperator([]);
        setUsers([]);
        setSelectedCoverage([]);
        setUserCoverageOptions([]);
        setSelectedWorkType([]);
        setShowSecondSelect(false);
        setResetKey(resetKey + 1);
        setErrorMsg('');
        setDetail(null);
    }


    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'hours') {
            const isValidHours = /^\d+$/.test(value) && parseInt(value, 10) >= 0 && parseInt(value, 10) <= 23;

            if (isValidHours || value === '') {
                setFormData((prevData) => ({
                    ...prevData,
                    [name]: value,
                }));
            }
        }
        else if (name === 'minutes') {
            const isValidMinutes = /^\d+$/.test(value) && parseInt(value, 10) >= 0 && parseInt(value, 10) <= 59;

            if (isValidMinutes || value === '') {
                setFormData((prevData) => ({
                    ...prevData,
                    [name]: value,
                }));
            }
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };




    const calculateTimeEnd = () => {
        const { time_start, hours, minutes } = formData;

        if (time_start && hours && minutes) {
            const startTime = moment(`2000-01-01 ${time_start}`, 'YYYY-MM-DD HH:mm');

            const endTime = startTime.add(hours, 'hours').add(minutes, 'minutes');

            const formattedTimeEnd = endTime.format('HH:mm');
            setFormData((prevData) => ({
                ...prevData,
                time_end: formattedTimeEnd,
            }));
        }
    };


    const fetchData = async () => {
        try {
            const response = axios.get("/userClient").then((response) => {
                const clientOptions = response.data.client.map((client) => ({
                    label: client.name,
                    value: client.id,
                }));
                const coverageOptions = response.data.user.map((user) => ({
                    label: `${user.name} ${user.last_name}`,
                    value: user.id,
                }));
                setClients(clientOptions);
                setUserCoverage(coverageOptions);
            });
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setLoading(false);
        }
    };



    useEffect(() => {
        fetchData();
        calculateTimeEnd();
    }, [resetKey, formData.time_start, formData.hours, formData.minutes]);


    const handleClientChange = (selectedOption) => {
        setSelectedClient(selectedOption);

        if (selectedOption) {
            axios
                .get(`/userClient?id_client=${selectedOption.value}`)
                .then((response) => {
                    const userOptions = response.data.data.map((user) => ({
                        label: user.user ? `${user.user.name} ${user.user.last_name}` : '',
                        value: user.id_user,
                    }));
                    setUsers(userOptions);
                });
        } else {
            setUsers([]);
        }
    };

    const typeDaily = [
        { value: '', label: 'Work Type' },
        { value: '1', label: 'Regular shift' },
        { value: '2', label: 'Regular training' },
        { value: '4', label: 'Regular coverage' },
        { value: '5', label: 'Regular shadowing' },
        { value: '3', label: 'Additional hours' },
        { value: '7', label: 'Additional training' },
        { value: '8', label: 'Additional coverage' },
        { value: '9', label: 'Additional shadowing' },
    ];

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            border: '2px solid transparent',
            borderRadius: '8px',
            backgroundColor: '#f2f2f2',
            marginRight: '20px',
            textAlign: 'center',
            width: '250px',
            '&:hover': {
                border: '2px solid transparent',
            },
            '&:focus-visible': {
                outline: 'none',
                boxShadow: 'none'
            },
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: '#f2f2f2',
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#808080' : state.isFocused ? 'white' : '#808080',
            backgroundColor: state.isSelected ? '#f2f2f2' : state.isFocused ? '#bcc3f3' : 'white',
        }),
    };

    const handleWorkTypeChange = (selectedOption) => {
        setSelectedWorkType(selectedOption);

        if (selectedOption && selectedOption.value === '4' || selectedOption && selectedOption.value === '5' || selectedOption && selectedOption.value === '8' || selectedOption && selectedOption.value === '9') {
            setShowSecondSelect(true);
            setUserCoverageOptions(userCoverage);
        } else {
            setShowSecondSelect(false);
            setUserCoverageOptions([]);
        }
    };


    const handleSubmit = async () => {

        const selectOperator = selectedOperator.value;
        const selectCoverage = selectedCoverage.value;
        const selectClient = selectedClient.value;
        const selectTypeDaily = selectedWorkType.value;

        const formSubmitData = {
            id_operator: selectOperator,
            id_operator_coverage: selectCoverage,
            id_client: selectClient,
            date: formData.date,
            time_start: formData.time_start,
            time_end: formData.time_end,
            durationH: formData.hours,
            durationM: formData.minutes,
            type_daily: selectTypeDaily,
            description: formData.description,
            status: formData.status
        };

        try {
            const response = await axios.post('/schedule/create', formSubmitData);
            if (response.data.msg) {
                setErrorMsg(response.data.msg);
            } else {
                const createdDetail = response.data.detail;

                setScheduleHistory((prevHistory) => [...prevHistory, createdDetail]);

                setDetail(createdDetail);

                toast({
                    title: 'Schedule Added',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
                clearFilters();
            }
        } catch (error) {
            toast({
                title: "Error Creating a Schedule",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            console.error('Error creating schedule:', error);
        }
    };

    const getColorSchemeForTypeDaily = (typeDaily) => {
        const colorMap = {
            1: '#7080fc',
            2: '#fcd677',
            3: '#8a6dce',
            4: '#dd67ae',
            5: '#fca066',
            7: '#fcd677',
            8: '#dd67ae',
            9: '#fca066'
        };
        return colorMap[typeDaily] || '#7080fc';
    };

    const deleteSchedule = async (schedule) => {
        const confirmDelete = await Swal.fire({
            title: 'Do you want to delete this schedule?',
            text: "The Schedule will be permanently removed",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#b9ce67',
            cancelButtonColor: '#fc7466',
            confirmButtonText: "Delete",
            cancelButtonText: "Cancel",
            closeOnConfirm: true,
            closeOnCancel: false
        }).then((result) => {
            return result.isConfirmed
        });

        if (confirmDelete) {
            setLoading(true);
            try {
                const response = await axios.post(`/schedule/delete/${schedule.id}`);
                toast({
                    title: "Schedule Delete it!",
                    status: "success",
                    position: 'top',
                    duration: 3000,
                    isClosable: true,
                });

                setScheduleHistory((prevScheduleHistory) =>
                    prevScheduleHistory.filter((item) => item.id !== schedule.id)
                );
            } catch (error) {
                console.error(error);

            } finally {
                setLoading(false);
            }
        }
    };

    const filterOptions = (candidate, input) => {
        const words = input.toLowerCase().split(' ');
        return words.every(word =>
            candidate.label.toLowerCase().includes(word)
        );
    };

    return (
        <Grid
            display={'flex'}
            column={3}
            gap={{ base: "20px", xl: "50px" }}
            mt={5}
        >
            <Card h="1100"
                display={'block'}
                width="100%"
                overflow="auto"
            >

                <Flex display={'flex'} justifyContent={'center'} mt={'60px'}>
                    <Text fontSize={'25px'} color={'#1c2147'} fontWeight={'bold'}>
                        <StarIcon mb={1.5} /> User & Client
                    </Text>
                </Flex>


                <Flex display='flex' justifyContent="center" mt={'50px'}>
                    <Select2
                        options={clients}
                        value={selectedClient}
                        onChange={handleClientChange}
                        styles={customStyles}
                        key={`clientSelect-${resetKey}`}
                    />
                    <Select2
                        options={users}
                        styles={customStyles}
                        value={selectedOperator}
                        onChange={(selectedOption) => setSelectedOperator(selectedOption)}
                        key={`userSelect-${resetKey}`}
                        isSearchable
                        filterOption={filterOptions}
                    />
                </Flex>


                <Flex display='flex' justifyContent="center" mt={'50px'}>

                    <Select2
                        options={typeDaily}
                        value={selectedWorkType}
                        onChange={handleWorkTypeChange}
                        styles={customStyles}
                        key={`typeDailySelect-${resetKey}`}
                    />

                    {showSecondSelect && (
                        <Select2
                            options={userCoverageOptions}
                            styles={customStyles}
                            value={selectedCoverage}
                            onChange={(selectedOption) => setSelectedCoverage(selectedOption)}
                            key={`CoverageSelect-${resetKey}`}
                        />
                    )}
                </Flex>


                <Flex display={'flex'} justifyContent={'center'} mt={'60px'}>
                    <Text fontSize={'25px'} color={'#1c2147'} fontWeight={'bold'} display="flex"
                        justifyContent="space-between">

                        <Box mt={1.5} mr={2}>
                            <GoClockFill />
                        </Box>
                        Date & Time
                    </Text>
                </Flex>


                <Flex display='flex' justifyContent="center" mt={'50px'}>
                    <Input
                        type='date'
                        bg={'#f2f2f2'}
                        width={'400px'}
                        mr={'50px'}
                        color={'#808080'}
                        textAlign={'center'}
                        value={formData.date}
                        onChange={handleChange}
                        name='date' />
                    <Select
                        fontSize={'md'}
                        borderRadius={'5px'}
                        width={'400px'}
                        name='status'
                        value={formData.status}
                        onChange={handleChange}
                        color={'#808080'}
                        background={"#f2f2f2"}
                        textAlign={"center"}
                        errorBorderColor='#fc7466'
                        placeholder='Status'>
                        <option value='1'>Normal</option>
                        <option value='12'>Agent Overnight</option>
                        <option value="11">Surecall O/C</option>
                        <option value='14'>Stand by</option>
                        <option value='3'>Holiday</option>
                        <option value='15'>Holiday Off</option>
                        <option value='4'>Vacation</option>
                        <option value='5'>Maternity Leave</option>
                        <option value='6'>Permission request</option>
                        <option value='7'>Wedding Leave</option>
                        <option value='8'>Medical Leave</option>
                        <option value='9'>Paternity Leave</option>
                        <option value='10'>Bereavement Leave</option>
                    </Select>
                </Flex>


                <Flex display='flex' justifyContent="center" mt={'50px'}>
                    <FormControl isInvalid={errorMsg}>
                        <Input type='time' bg={'#f2f2f2'} color={'#808080'} textAlign={'center'} value={formData.time_start} onChange={handleChange} name='time_start' />
                        {errorMsg && <FormErrorMessage>{errorMsg}</FormErrorMessage>}
                    </FormControl>
                    <Input
                        type='text'
                        width={'120px'}
                        ml={'20px'}
                        mr={'10px'}
                        bg={'#f2f2f2'}
                        color={'#808080'}
                        textAlign={'center'}
                        value={formData.hours}
                        onChange={handleChange}
                        name='hours'
                        min={0}
                        max={23}
                    />
                    <Text mt={'6px'} mr={'10px'} color={'#808080'}>
                        :
                    </Text>
                    <Input type='text' width={'120px'} mr={'20px'} bg={'#f2f2f2'} color={'#808080'} textAlign={'center'} value={formData.minutes} onChange={handleChange} name='minutes' />
                    <FormControl isInvalid={errorMsg}>
                        <Input type='time' bg={'#f2f2f2'} color={'#808080'} textAlign={'center'} value={formData.time_end} readOnly name='time_end' />
                        {errorMsg && <FormErrorMessage>{errorMsg}</FormErrorMessage>}
                    </FormControl>
                </Flex>

                <Flex display='flex' justifyContent="center" mt={'50px'}>
                    <Textarea bg={'white'} mr={4} placeholder='Description' value={formData.description} onChange={handleChange} name='description' />
                </Flex>


                <Flex justifyContent="center" mt={'100px'}>
                    <Button
                        borderRadius={"5px"}
                        bg={"#b9ce67"}
                        color={"white"}
                        _hover={{ background: "#8aad01", color: 'white' }}
                        onClick={handleSubmit}
                        mr={'30px'}
                    >
                        Add
                        <AddIcon ml={2} />
                    </Button>
                    <Button
                        borderRadius={"5px"}
                        bg={"#fcd677"}
                        color={"white"}
                        _hover={{ background: "#808080", color: 'white' }}
                        onClick={clearFilters}
                    >
                        Reset
                        <RepeatClockIcon ml={2} />
                    </Button>
                </Flex>
            </Card>

            <Card maxWidth='auto'>
                <Flex display={'flex'} justifyContent={'center'} mt={'40px'}>
                    <Text color={'#808080'} fontWeight={'bold'} fontSize={'25px'}>
                        ASSIGNED SCHEDULES
                    </Text>

                </Flex>

                <Flex display={'flex'} justifyContent={'center'} mt={'40px'}>
                    <Box >
                        <Flex>
                            <Flex flexDirection="column">
                                {scheduleHistory.map((schedule, index) => (
                                    <Flex key={index} direction={'row'}>
                                        <Box display={'flex'} width={'600px'} mt={'30px'} bg={'#f2f2f2'} height={'50px'} justifyContent={'space-evenly'} ml={'50px'} mr={'50px'} borderRadius={'5px'}>
                                            <Flex alignItems="center">
                                                <Text fontWeight={'bold'} color={'#1c2147'} fontSize={'15px'} ml={'10px'} width={'150px'}>
                                                    {schedule.name}
                                                </Text>
                                            </Flex>
                                            <Flex alignItems="center" >
                                                <Text fontSize={'14px'} fontWeight={'bold'} color={getColorSchemeForTypeDaily(schedule.type_daily)}>
                                                    {schedule.client}
                                                </Text>
                                            </Flex>
                                            <Flex alignItems="center" >
                                                <Badge color={"white"} bg={getColorSchemeForTypeDaily(schedule.type_daily)} fontSize='10px' borderRadius={"5px"} width={'auto'} textAlign={'center'} mr={'10px'}>
                                                    {(() => {
                                                        switch (schedule.type_daily) {
                                                            case 1:
                                                                return "Regular shift";
                                                            case 2:
                                                                return "Regular training";
                                                            case 3:
                                                                return "Additional hours";
                                                            case 4:
                                                                return "Regular coverage";
                                                            case 5:
                                                                return "Regular shadowing"
                                                            case 6:
                                                                return "No shift"
                                                            case 7:
                                                                return "Additional training"
                                                            case 8:
                                                                return "Additional coverage"
                                                            case 9:
                                                                return "Additional shadowing"
                                                            default:
                                                                return "Regular shift";
                                                        }
                                                    })()}
                                                </Badge>
                                            </Flex>
                                            <Flex alignItems="center" >

                                                <Text fontSize="12px" fontWeight="bold" color="#808080">
                                                    {schedule.time_s}-{schedule.time_e}
                                                </Text>
                                            </Flex>
                                            <Flex alignItems="center">
                                                <Text fontSize={'12px'} fontWeight={'bold'} color={'#808080'}>
                                                    {schedule.date}
                                                </Text>
                                            </Flex>
                                            <Flex alignItems="center">
                                                <Button bg={'#fc7466'} color={'white'} _hover={{ background: "#de5b5b", color: 'white' }} borderRadius={'5px'} size={'xs'} onClick={() => deleteSchedule(schedule)}>
                                                    <DeleteIcon />
                                                </Button>
                                            </Flex>
                                        </Box>
                                    </Flex>
                                ))}
                            </Flex>
                        </Flex>
                    </Box>
                </Flex>
            </Card>
        </Grid>
    );
}
export default CreateSchedule; 