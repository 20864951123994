import React, { useState, useEffect, useRef } from 'react';
import {
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter,
  Button,
  Flex, Box, Heading,
  Image,Text,
  FormControl,
  useToast, Select
} from '@chakra-ui/react';
import { EditIcon, CloseIcon } from '@chakra-ui/icons'
import avatar4 from 'assets/img/dashboards/grey_world.png';
import axios from 'axios';
import Select2 from 'react-select'
import { FaUser } from "react-icons/fa";


const ModalClient = ({ isOpen, onClose, user, onUserUpdate, clients, setFlashing, userType }) => {

  const toast = useToast();

  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    type_user_id: user?.type_user_id || '',
  });

  const clientsOptions = clients.map(client => ({
    value: client.id,
    label: client.name,
  }));

  const allClientsOption = {
    value: 'selectAll',
    label: 'Select All',
  };

  const userClientsOptions = Array.isArray(user.user_clients)
    ? user.user_clients.map(client => ({
      value: client.id_client,
      label: client.client.name,
    }))
    : [];

  const selectedClientIds = userClientsOptions.map(option => option.value);

  const isAllSelected = selectedClientIds.length === clientsOptions.length;
  const initialSelectedOptions = isAllSelected
    ? [allClientsOption, ...userClientsOptions]
    : userClientsOptions;

  const [selectClient, setSelectClient] = useState(initialSelectedOptions);

  const handleClientChange = selectedOptions => {
    if (selectedOptions.some(option => option.value === 'selectAll')) {
      setSelectClient([allClientsOption, ...clientsOptions]);
    } else {
      setSelectClient(selectedOptions);
    }
  };


  const handleUpdate = async (e) => {
    setLoading(true);
    e.preventDefault();

    const selectedIds = selectClient.map((option) => option.value);

    const formSubmitData = {
      data: selectedIds,
    };

    try {
      const response = await axios.post(
        `/employees/updateClient/${user.id}`,
        formSubmitData
      );

      onUserUpdate();
      setFlashing(formSubmitData);
      toast({
        title: "Client Updated",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error(error);

      toast({
        title: "Error Updating Client",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: '2px solid transparent',
      borderRadius: '5px',
      width: '280px',
      '&:hover': {
        border: '2px solid transparent',
      },
      '&:focus-visible': {
        outline: 'none',
        boxShadow: 'none'
      },
    }),
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: 'white',
      width: '265px'
    }),
    option: (provided, state) => ({
      ...provided,
      color: '#808080',
    }),
  };

  const handleUpdateTypeUser = async () => {

    const formSubmitData = {
      type_user_id: formData.type_user_id,
    };

    try {
      const response = await axios.post(`/employees/type_user_id/${user.id}`, formSubmitData);
      if (response.status === 200) {
        onUserUpdate();
        setFlashing(formSubmitData);
        toast({
          title: "User Type Updated",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error Updating User Type",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      console.error('Error Updating User Type:', error);
    }
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  if (!user) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'3xl'} isCentered closeOnOverlayClick={false}
      closeOnEsc={false}>
      <ModalOverlay />
      <ModalContent class='modalContentEditClient'>
        <ModalHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} color={'#1c2147'} mt={'3%'} mb={'5%'} fontWeight={'bold'} fontSize={'35px'}>
          <Flex direction={'column'}>
            <Text textAlign={'center'}>
              Update Client & Update User Type
            </Text>
            <Flex style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} color={'#7080fc'} fontWeight={'bold'} fontSize={'22px'}>
            <FaUser size={'18px'} />
              <span style={{ marginLeft: '8px' }}>
                <span style={{ marginRight: '8px', color: '#7080fc' }}>
                  {user.name} {user.last_name}
                </span>
              </span>
            </Flex>
          </Flex>
        </ModalHeader>

        <ModalBody ml={10} mr={10}>
          <Box
            paddingBottom={"20px"}
          >
           
            <Flex  justifyContent={"center"} >
              <FormControl isInvalid={!selectClient} width={"max-content"} pl={"8%"} mr={'20px'} >
                Client
                <Select2
                  isMulti
                  options={[allClientsOption, ...clientsOptions]}
                  value={isAllSelected ? [allClientsOption, ...clientsOptions] : selectClient}
                  onChange={handleClientChange}
                  styles={customStyles}
                />
              </FormControl>
              <Button class="buttonUpUs" onClick={handleUpdate}>
                <EditIcon />
              </Button>
            </Flex>
          </Box>


          <Box>
            
            <Flex  justifyContent={'center'}>
              <FormControl width='max-content' mr={'20px'} pl={"8%"}>
                User Type
                <Select
                  background="white"
                  textAlign={"center"}
                  name='type_user_id'
                  color={'#cccccc'}
                  defaultValue={formData.type_user_id}
                  onChange={handleChange}
                >
                  <option value=''>Select</option>
                  {userType.map((c) => (
                    <option key={c.id} value={c.id}>
                      {c.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <Button class="buttonCreate2" onClick={handleUpdateTypeUser}>
                <EditIcon />
              </Button>
            </Flex>
          </Box>

        </ModalBody>
        <ModalFooter justifyContent="center" pt={"5%"} pb={"10%"}>
          <Button class="buttonCancel" onClick={onClose} >
            Close <CloseIcon ml={2} />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalClient;