import axios from 'axios';
import React from "react";
import {
    Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody,
    Button, Flex, Image
} from '@chakra-ui/react';
import survey from 'assets/img/home/Ticket_System_HORI.webp';

function ModalSurvey({ isOpen, onClose }) {

    const startSurvey = async () => {
        try {
            const response = await axios.post('/confirmSurvey');

            if (response.status === 200) {
                window.open('https://docs.google.com/forms/d/e/1FAIpQLSdzOkSrbUQBpTyDow9JzU_XXizDv_a7NY-o69DygOPpxXyz3g/viewform', '_blank');
                onClose();
            }

        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'3xl'} closeOnOverlayClick={false} closeOnEsc={false} isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton />
                <ModalBody marginTop={'4%'} marginBottom={'4%'}>
                    <Flex display={'flex'} justifyContent={'center'} position={'relative'}>
                        <Image src={survey} />
                        <Button
                            position={'absolute'}
                            top={'85%'}
                            left={'80%'}
                            transform={'translate(-50%, -50%)'}
                            size={'md'}
                            color={'white'}
                            bg={'blue.400'}
                            borderRadius={'5px'}
                            _hover={{ background: '#fabb1c', color: 'white' }}
                            onClick={startSurvey}
                        >
                            Start now
                        </Button>
                    </Flex>

                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

export default ModalSurvey;
