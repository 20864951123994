import { Box, useStyleConfig } from "@chakra-ui/react";
function CardProfile(props) {
  const { variant, children, ...rest } = props;
  const styles = useStyleConfig("Card", { variant });

  return (
    <Box __css={styles} {...rest}  width={'100%'} height={'100%'}>
      {children}
    </Box>
  );
}

export default CardProfile;
