import React from 'react';
import {
    Button, Flex, Input
} from '@chakra-ui/react'
import { RepeatIcon } from '@chakra-ui/icons'
import Select2 from 'react-select'
import { AiOutlineClear } from "react-icons/ai";

function FiltersSalaryMX({ optionsUsers, userTypeSearch, handleSelectChangeF, setCurrentPage, customStyles, resetKey, filterOptions, endDateFilter, setEndDateFilter, clearFilters }) {


    return (
        <>
            <Flex
                direction='row'
                w='100%'
                bg="gray.200"
                p={2}
                borderRadius="16px"
                mt={'2%'}
                mb={'1%'}
            >
                <Flex align="center">
                    <Select2
                        options={optionsUsers}
                        value={optionsUsers.find(option => option.value === userTypeSearch)}
                        onChange={(selectedOption) => {
                            handleSelectChangeF('user', selectedOption);
                            setCurrentPage(1);
                        }}
                        styles={customStyles}
                        placeholder="Select operator"
                        key={`userSelect-${resetKey}`}
                        isSearchable
                        filterOption={filterOptions}
                    />
                    <Flex
                        border={'1px solid #808080'}
                        display={'flex'}
                        borderRadius={'4px'}
                        mr={4}
                        ml={'5%'}
                    >
                        <Input
                            width={'100%'}
                            border={'0px'}
                            type="date"
                            fontSize={"sm"}
                            value={endDateFilter}
                            onChange={(e) => {
                                setEndDateFilter(e.target.value);
                                setCurrentPage(1);
                            }}
                            _focusVisible={'none'}
                            color={'#808080'}
                        />
                        <Button bg={'none'} color={'#808080'} onClick={() => {
                            setEndDateFilter('');
                        }}>
                            <RepeatIcon />
                        </Button>
                    </Flex>
                    <Button bg={'transparent'} _hover={{ backgroundColor: 'transparent' }} color={'#808080'} padding={"0px"} title="Clear filters" onClick={clearFilters}>
                        <AiOutlineClear size={'26px'} />
                    </Button>
                </Flex>
            </Flex>
        </>
    );
}
export default FiltersSalaryMX; 