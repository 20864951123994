import React from 'react';
import { Icon } from '@chakra-ui/react';
import { MdHome } from 'react-icons/md';
import { MdDashboard } from "react-icons/md";
import { IoTicketSharp } from "react-icons/io5";
import { FaRegCalendar,FaUsers } from "react-icons/fa6";

import DashboardsDefault from 'views/admin/dashboards/default';
import DefaultUser from 'views/admin/main/others/messages/indexUser';
import IncidentsAgentOverview from 'views/admin/main/incidents/indexAgent';
import DailyOverview from 'views/admin/main/schedules/dailyschedule';

import IncidentsOverview from 'views/admin/main/incidents';
import MyScheduleOverview from 'views/admin/main/schedules/myschedule';
import MedicalNotesOverview from 'views/admin/main/Medical_Notes';
// import IncidentsAgentOverview from 'views/admin/main/incidents/indexAgent';



const routesQA = [
  // --- Dashboards ---
  {
    name: 'Home',
    layout: '/admin',
    path: '/home',
    icon: <Icon as={MdHome} width="30px" height="30px" color="inherit" />,
    component: <DashboardsDefault />,
  },
  {
    name: 'Dashboard',
    layout: '/admin',
    path: '/dashboards',
    icon: <Icon as={MdDashboard} width="30px" height="30px" color="inherit" />,
    component: <DailyOverview />,
  }, 
   {
    name: 'My schedule',
    path: '/my-schedule', 
    icon: <Icon as={FaRegCalendar} width="30px" height="30px" color="inherit" />,
    layout: '/admin',
    component: <MyScheduleOverview/>,
  },
  {
    name: 'Tickets',
    icon: <Icon as={IoTicketSharp} width="30px" height="30px" color="inherit" />,
    path: '/Ticket',
    collapse: true,
    items: [
      
      {
        name: 'Completed',
        layout: '/admin',
        path: '/ticket/dashboard',
        exact: false,
        component: <IncidentsOverview />,
      },
      {
        name: 'Ongoing',
        layout: '/admin',
        path: '/ticketU/chat',
        exact: false,
        component: <DefaultUser />,
      },
      {
        name: 'My Completed',
        layout: '/admin',
        path: '/ticketU/finish',
        exact: false,
        component: <IncidentsAgentOverview />,
      },
      
    ],
  },
  {
    name: 'Human Resources',
    icon: <Icon as={FaUsers} width="30px" height="30px" color="inherit" />,
    path: '/hr',
    collapse: true,
    items: [
      
      {
        name: 'Medical Notes',
        path: '/MedicalNotes',
        layout: '/admin',
        component: <MedicalNotesOverview />,
      },
    ],
  },
  
];

export default routesQA;
