import React from 'react';
import {
    Button, Flex, Text, Input, Progress
} from '@chakra-ui/react'
import { RepeatIcon } from '@chakra-ui/icons'
import Select2 from 'react-select'
import { AiOutlineClear } from "react-icons/ai";
import { RiFileExcel2Fill } from "react-icons/ri";

function BillingFilters({ handleDownloadExcel, clientSearch, usersClients, optionsUsers, userTypeSearch, handleSelectChangeF, setCurrentPage, customStyles, resetKey, filterOptions, optionClients, handleClientChange, startDateFilter, setStartDateFilter, endDateFilter, setEndDateFilter, clearFilters, isLoadingBar }) {


    return (
        <>
            <Flex
                mt={'2%'}
                direction='row'
                w='100%'
                justifyContent="flex-end"
            >
                <Flex align="center">
                    <Button class="buttonCreate" onClick={handleDownloadExcel}>
                        <Text mr={2}>
                            Export
                        </Text>
                        <RiFileExcel2Fill />
                    </Button>
                </Flex>
            </Flex>
            <Flex
                direction='row'
                w='max-content'
                bg="gray.200"
                p={2}
                borderRadius="16px"
                mb={'1%'}
            >
                <Flex align="center">
                    <Select2
                        options={clientSearch > 0 ? usersClients : optionsUsers}
                        value={clientSearch > 0 ? usersClients.find(option => option.value === userTypeSearch) : optionsUsers.find(option => option.value === userTypeSearch)}
                        onChange={(selectedOption) => {
                            handleSelectChangeF('user', selectedOption);
                            setCurrentPage(1);
                        }}
                        styles={customStyles}
                        placeholder="Select operator"
                        key={`userSelect-${resetKey}`}
                        isSearchable
                        filterOption={filterOptions}
                    />
                    <Select2
                        options={optionClients}
                        value={optionClients.find(option => option.value === clientSearch)}
                        onChange={(selectedOption) => {
                            handleClientChange(selectedOption);
                            setCurrentPage(1);
                        }}
                        styles={customStyles}
                        placeholder="Select client"
                        key={`clientSelect-${resetKey}`}
                    />
                    <Flex
                        border={'1px solid #808080'}
                        display={'flex'}
                        borderRadius={'4px'}
                        ml={4} mr={4}
                    >
                        <Input
                            width={'100%'}
                            border={'0px'}
                            type="date"
                            fontSize={"sm"}
                            value={startDateFilter}
                            onChange={(e) => {
                                setStartDateFilter(e.target.value);
                                setCurrentPage(1);
                            }}
                            _focusVisible={'none'}
                            color={'#808080'}
                        />
                        <Button bg={'none'} color={'#808080'} onClick={() => {
                            setStartDateFilter('');
                        }}>
                            <RepeatIcon />
                        </Button>
                    </Flex>
                    <Flex
                        border={'1px solid #808080'}
                        display={'flex'}
                        borderRadius={'4px'}
                        mr={4}
                    >
                        <Input
                            width={'100%'}
                            border={'0px'}
                            type="date"
                            fontSize={"sm"}
                            value={endDateFilter}
                            onChange={(e) => {
                                setEndDateFilter(e.target.value);
                                setCurrentPage(1);
                            }}
                            _focusVisible={'none'}
                            color={'#808080'}
                        />
                        <Button bg={'none'} color={'#808080'} onClick={() => {
                            setEndDateFilter('');
                        }}>
                            <RepeatIcon />
                        </Button>
                    </Flex>
                    <Button bg={'transparent'} _hover={{ backgroundColor: 'transparent' }} color={'#808080'} padding={"0px"} title="Clear filters" onClick={clearFilters}>
                        <AiOutlineClear size={'26px'} />
                    </Button>
                </Flex>
            </Flex>
            {!isLoadingBar ? (
                null
            ) : (
                <Flex width={'100%'} direction={'column'} mb={'2%'} alignItems={'center'} mt={'1%'}>
                    <Text fontWeight={'bold'} color={'#1c2147'}>
                        Generating file, please wait...
                    </Text>
                    <Progress size='xs' isIndeterminate width={'400px'} mb={'1%'} />
                </Flex>
            )}

        </>
    );
}
export default BillingFilters; 