import {
    TableContainer, Box,
    useToast
} from '@chakra-ui/react'
import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2'
import axios from 'axios';
import ModalUpdate from '../update/ModalUpdate';
import ModalUpdateBZ from '../update/ModalUpdateBZ';
import ModalUpdateRD from '../update/ModalUpdateRD';
import ModalUpdateCOL from '../update/ModalUpdateCOL';
import UserInfoModal from '../showInfo/UserInfoModal';
import UserInfoModalBZ from '../showInfo/UserInfoModalBZ';
import UserInfoModalRD from '../showInfo/UserInfoModalRD';
import UserInfoModalCOL from '../showInfo/UserInfoModalCOL';
import UserExport from './tableExport';
import UserList from './table';
import ModalClient from '../modals/ModalClient';
import ModalCreateSchedule from '../modals/ModalCreateSchedule';
import ModalCUT from '../modals/modalLocation';
import CreateUser from '../modals/create';
import UpdateAnydesk from '../modals/updateAnydesk';
import UserHeaderButtons from './headerButtons';
import UserSearch from './search';
import ChangePassword from '../modals/changePassword';

const UserFunctions = () => {

    const toast = useToast();
    const [users, setUsers] = useState([]);
    const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [client, setClient] = useState(null);
    const [timezone, setTimezone] = useState(null);
    const [loading, setLoading] = useState(false);
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [clientModalOpen, setClientModalOpen] = useState(false);
    const [clientModalOpenCut, setClientModalOpenCut] = useState(false);
    const [schedulesModalOpen, setSchedulesModalOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [total, setTotal] = useState('');
    const [locationSearch, setLocationSearch] = useState('');
    const [location, setLocation] = useState([]);
    const [clientSearch, setClientSearch] = useState('');
    const [clientS, setClientS] = useState([]);
    const [userTypeS, setUserTypeS] = useState([]);
    const [userTypeSearch, setUserTypeSearch] = useState('');
    const [statusSearch, setStatusSearch] = useState('');
    const [flashing, setFlashing] = useState(false);
    const [resetKey, setResetKey] = useState(0);
    const [days, setDays] = useState([]);
    const [daysOff, setDaysOff] = useState([]);
    const [modalCreateIsOpen, setModalCreateIsOpen] = useState(false);
    const [ModalAnydeskIsOpen, setModalAnydeskIsOpen] = useState(false);
    const [userSearch, setUserSearch] = useState('');
    const [userAuth, setUserAuth] = useState([]);
    const [userList, setUserList] = useState([]);
    const [usersClients, setUsersClients] = useState([]);
    const [locationS, setLocationS] = useState([]);
    const [usersE, setUsersE] = useState([]);
    const [ModalChangePassword, setModalChangePassword] = useState(false);
    const [activeSearch, setActiveSearch] = useState('1');


    const openModalCreate = () => {
        setModalCreateIsOpen(true);
    }

    const filterOptions = (candidate, input) => {
        const words = input.toLowerCase().split(' ');
        return words.every(word =>
            candidate.label.toLowerCase().includes(word)
        );
    };
    const fetchDataAuth = async () => {
        try {
            const response = await axios.get("/user");
            const user = parseInt(response.data.user.type_user_id, 10);
            setUserAuth(user);
        } catch (error) {
            console.error('Error al obtener datos del usuario:', error);
        }
    };

    const clearFilters = () => {
        setUserTypeSearch(null);
        setClientSearch(null);
        setUserSearch(null);
        setStatusSearch('');
        setLocationSearch('');
        setResetKey(resetKey + 1);
        setCurrentPage(1);
        setActiveSearch('1');
    }

    const optionsUserType = userTypeS.map(userTypeS => ({
        value: userTypeS.id,
        label: userTypeS.name,
    }));

    const optionClients = clientS.map(clientS => ({
        value: clientS.id,
        label: clientS.name,
    }));

    const optionsUsers = userList.map(userList => ({
        value: userList.id,
        label: `${userList.name} ${userList.last_name}`,
    }));

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: 'transparent',
            borderRadius: '5px',
            borderColor: '#808080',
            marginLeft: '20px',
            fontSize: '16px',
            height: '40px',
            width: '220px',
            '&:hover': {
                borderColor: '#808080',
            },
            '&:focus-visible': {
                outline: 'none',
                boxShadow: 'none'
            },
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: 'white',
            width: '265px'
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#808080' : state.isFocused ? 'white' : '#808080',
            backgroundColor: state.isSelected ? '#f2f2f2' : state.isFocused ? '#bcc3f3' : 'white',
        }),
    };


    const fetchData = async () => {
        try {
            const response = await axios.get(
                `/users?page=${currentPage}`
                + `${locationSearch ? `&location=${locationSearch.trim()}` : ''}`
                + `${clientSearch ? `&client=${encodeURIComponent(clientSearch)}` : ''}`
                + `${userTypeSearch ? `&type_user=${encodeURIComponent(userTypeSearch)}` : ''}`
                + `${statusSearch ? `&status=${statusSearch.trim()}` : ''}`
                + `${userSearch ? `&name=${encodeURIComponent(userSearch)}` : ''}`
                + `${activeSearch ? `&active=${activeSearch.trim()}` : ''}`

            );
            const responseData = response.data;
            setUsers(responseData.data.data);
            setLocation(responseData.location);
            setClientS(responseData.client);
            setDays(responseData.days);
            setDaysOff(responseData.daysOff);
            setUserList(responseData.userList);
            setUserTypeS(responseData.type_user);
            setTotalPages(responseData.data.last_page);
            setTotal(responseData.data.total)
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setLoading(false);
        }
    };

    const exportUsers = () => {
        axios.get(
            `/usersE?
            ${locationSearch ? `&location=${locationSearch}` : ''}
          ${clientSearch ? `&client=${clientSearch}` : ''}
          ${userTypeSearch ? `&type_user=${userTypeSearch}` : ''}
          ${statusSearch ? `&status=${statusSearch}` : ''}
          ${userSearch ? `&name=${userSearch}` : ''}`, {
            responseType: 'blob'
        })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Users.xlsx');
                document.body.appendChild(link);
                link.click();
            })
            .catch(error => {
                console.error('Error al descargar el archivo Excel:', error);
            });
    };


    const handleClientChange = (selectedOption) => {
        setClientSearch(selectedOption.value);

        if (selectedOption) {
            axios
                .get(`/userClient?id_client=${selectedOption.value}`)
                .then((response) => {
                    const userOptions = response.data.data.map((user) => ({
                        label: user.user ? `${user.user.name} ${user.user.last_name}` : '',
                        value: user.id_user,
                    }));
                    setUsersClients(userOptions);
                });
        } else {
            setUsersClients([]);
        }
    };

    useEffect(() => {
        fetchData();
        fetchDataAuth();

        if (flashing) {
            const intervalId = setInterval(() => {
                setFlashing(false);
            }, 7000);

            return () => {
                clearInterval(intervalId);
            };
        }
    }, [currentPage, userSearch, locationSearch, clientSearch, userTypeSearch, statusSearch, flashing, resetKey, activeSearch]);

    const handleSelectChangeF = (componentName, selectedOption) => {
        switch (componentName) {
            case 'userT':
                setUserTypeSearch(selectedOption.value);
                break;
            case 'client':
                setClientSearch(selectedOption.value);
                break;
            case 'user':
                setUserSearch(selectedOption.value);
                break;
            default:
                break;
        }
    };

    const opciones = [
        { value: 'https://portaldp.com/665d63aad693f7feae395aa6f7aa3e40', label: 'Mexico' },
        { value: 'https://portaldp.com/2c1848fa64a105251fdc39741e7e3d4e', label: 'Mexico (Remote)' },
        { value: 'https://portaldp.com/7568ae25ee1adb236ced1386a10a9e84', label: 'Belize' },
        { value: 'https://portaldp.com/6563648f8280b50c6db45593faf7a7d2', label: 'Dominican Republic' },
        { value: 'https://portaldp.com/f17d34c9bb31c05210e1957ed05e8a0b', label: 'Colombia' },
    ];

    const [opcionSeleccionada, setOpcionSeleccionada] = useState(opciones[0].value);

    const handleSelectChange = (event) => {
        setOpcionSeleccionada(event.target.value);
    };


    const handleUpdateTU = async (user) => {
        try {
            const response = await axios.get(`/users/editTU/${user.id}`);
            const timezone = response.data.timezone;
            const location = response.data.location;
            const { user: form } = response.data;

            setSelectedUser({ ...user, form });
            setTimezone(timezone);
            setLocationS(location);
            setClientModalOpenCut(true);
        } catch (error) {
            console.error('Error fetching user details:', error);
        }
    };

    const handleUpdateClient = async (user) => {
        try {
            const response = await axios.get(`/users/editClient/${user.id}`);
            const { user: form } = response.data;
            const client = response.data.client;

            setSelectedUser({ ...user, form });
            setClient(client);
            setClientModalOpen(true);
        } catch (error) {
            console.error('Error fetching user details:', error);
        }
    };

    const handleChangePassword = async (user) => {

        try {
            const response = await axios.get(`/users/edit/${user.id}`);
            const { user: type_employee, status, form, location } = response.data;
            setSelectedUser({ ...user, type_employee, status, form, location });
            setModalChangePassword(true);

        } catch (error) {
            console.error('Error fetching anydesk details:', error);
        }
    };

    const handleCopyClick = () => {
        const input = document.getElementById('textoInput');
        input.select();
        document.execCommand('copy');

        toast({
            title: 'Link copied!',
            status: 'success',
            duration: 3000,
            position: 'top',
            isClosable: true,
        });
    };

    const getColorSchemeForLocation = (locationId) => {
        const colorMap = {
            1: '#b9ce67',
            2: '#fca066',
            3: '#fca066',
            4: '#fc7466',
            5: '#fcd677',
            6: '#8a6dce',
        };

        return colorMap[locationId] || '#b9ce67';
    };

    const handleInfo = async (user) => {
        try {
            const response = await axios.get(`/users/${user.id}`);
            const { user: type_employee, status, form, location, date } = response.data;

            setSelectedUser({ ...user, type_employee, status, form, location, date });
            setIsInfoModalOpen(true);
        } catch (error) {
            console.error('Error fetching user details:', error);
        }
    };

    const handleUpdateNew = async (user) => {
        try {
            const response = await axios.get(`/users/edit/${user.id}`);
            const { user: type_employee, status, location } = response.data;
            setSelectedUser(user);
            setUpdateModalOpen(true);
        } catch (error) {
            console.error('Error fetching user details:', error);
        }
    };

    const handleDelete = async (user) => {
        const confirmDelete = await Swal.fire({
            title: 'Do you want to delete this user?',
            text: 'The user will be deleted.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2C5282',
            cancelButtonColor: '#E53E3E',
            confirmButtonText: 'Delete it'
        }).then((result) => {
            return result.isConfirmed
        });
        if (confirmDelete) {
            setLoading(true);
            setSelectedUser(user);
            try {
                const response = await axios.delete(`/users/delete/${user.id}`);
                toast({
                    title: "User Eliminated Succesfully!!!",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
                setFlashing(user);
                fetchData();
            } catch (error) {
                console.error(error);
                toast({
                    title: "Error Deleting the User!!!",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            } finally {
                setLoading(false);
            }
        }
    };

    const handleRestore = async (user) => {
        const confirmDelete = await Swal.fire({
            title: 'Do you want to retore this user?',
            text: 'The user will be restored.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2C5282',
            cancelButtonColor: '#E53E3E',
            confirmButtonText: 'Restore it'
        }).then((result) => {
            return result.isConfirmed
        });
        if (confirmDelete) {
            setLoading(true);
            setSelectedUser(user);
            try {
                const response = await axios.delete(`/users/restore/${user.id}`);
                toast({
                    title: "User Restored Succesfully!!!",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
                setFlashing(user);
                fetchData();
            } catch (error) {
                console.error(error);
                toast({
                    title: "Error Restoring the User!!!",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            } finally {
                setLoading(false);
            }
        }
    };

    const handleUpdateAD = async (user) => {

        try {
            const response = await axios.get(`/users/edit/${user.id}`);
            const { user: type_employee, status, form, location } = response.data;
            setSelectedUser({ ...user, type_employee, status, form, location });
            setModalAnydeskIsOpen(true);

        } catch (error) {
            console.error('Error fetching anydesk details:', error);
        }
    };

    const closeInfoModal = () => {
        setIsInfoModalOpen(false);
    };

    const closeModalCreate = () => {
        setModalCreateIsOpen(false);
    }

    const closeModalUpdate = () => {
        setModalAnydeskIsOpen(false);
    }

    const closeModalUpdateInfo = () => {
        setUpdateModalOpen(false);
    }

    const closeModalChangePassword = () => {
        setModalChangePassword(false);
    }


    const handleReset = async (user) => {

        Swal.fire({
            title: 'Reset Password',
            text: 'Are you sure you want to reset the password?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#1c2147',
            cancelButtonColor: '#fc7466',
            confirmButtonText: 'Yes, reset it!'

        }).then(async (result) => {
            if (result.isConfirmed) {

                setLoading(true);
                setSelectedUser(user);

                try {

                    const response = await axios.post(`/resetPassword/${user.id}`);
                    const { name, newPassword } = response.data;

                    Swal.fire({
                        title: 'Password Reset',
                        html: `Password Reset For User <strong>${name}</strong>. The New Password Is: <strong>${newPassword}</strong>`,
                        icon: 'success',
                        confirmButtonColor: '#1c2147',
                        confirmButtonText: 'OK',
                        position: 'center'
                    });
                    setFlashing(user);

                } catch (error) {
                    console.error(error);
                    toast({
                        title: "Unauthorized to change password for this user!",
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                    });
                } finally {
                    setLoading(false);

                }
            }
        });
    };

    const handleCreateSchedule = async (user) => {
        try {
            const response = await axios.get(`/users/createSchedule/${user.id}`);
            const responseData = response.data;

            setSelectedUser(responseData);
            setSchedulesModalOpen(true);
        } catch (error) {
            console.error('Error fetching user details:', error);
        }
    };

    const renderModal = () => {
        switch (selectedUser.location_id) {
            case 1:
                return <UserInfoModal isOpen={isInfoModalOpen} onClose={closeInfoModal} user={selectedUser} />;
            case 2:
                return <UserInfoModalBZ isOpen={isInfoModalOpen} onClose={closeInfoModal} user={selectedUser} />;
            case 3:
                return <UserInfoModalBZ isOpen={isInfoModalOpen} onClose={closeInfoModal} user={selectedUser} />;
            case 4:
                return <UserInfoModalRD isOpen={isInfoModalOpen} onClose={closeInfoModal} user={selectedUser} />;
            case 5:
                return <UserInfoModalCOL isOpen={isInfoModalOpen} onClose={closeInfoModal} user={selectedUser} />;
            default:
                return null;
        }
    };

    const renderUpdateModal = () => {
        switch (selectedUser.location_id) {
            case 1:
                return <ModalUpdate isOpen={updateModalOpen} onClose={() => setUpdateModalOpen(false)} user={selectedUser} updateUser={fetchData} setFlashing={setFlashing} />;
            case 2:
                return <ModalUpdateBZ isOpen={updateModalOpen} onClose={() => setUpdateModalOpen(false)} user={selectedUser} updateUser={fetchData} setFlashing={setFlashing} />;
            case 3:
                return <ModalUpdateBZ isOpen={updateModalOpen} onClose={() => setUpdateModalOpen(false)} user={selectedUser} updateUser={fetchData} setFlashing={setFlashing} />;
            case 4:
                return <ModalUpdateRD isOpen={updateModalOpen} onClose={() => setUpdateModalOpen(false)} user={selectedUser} updateUser={fetchData} setFlashing={setFlashing} />;
            case 5:
                return <ModalUpdateCOL isOpen={updateModalOpen} onClose={() => setUpdateModalOpen(false)} user={selectedUser} updateUser={fetchData} setFlashing={setFlashing} />;
            case 6:
                return <ModalUpdateCOL isOpen={updateModalOpen} onClose={() => setUpdateModalOpen(false)} user={selectedUser} updateUser={fetchData} setFlashing={setFlashing} />;
            default:
                return null;
        }
    }

    const getRowBackground = (user) => {
        if (selectedUser && selectedUser.id === user.id) {
            if (flashing) {
                return '#fcd677';
            }
        }
    };

    const getWhatsAppLink = (phoneNumber, locationId) => {
        const countryCodeMap = {
            1: '52',
            2: '501',
            3: '501',
            4: '1',
            5: '57'
        };

        const countryCode = countryCodeMap[locationId] || '';

        // Add the country code to the URL
        const fullPhoneNumber = `+${countryCode}${phoneNumber}`;

        // Replace white spaces with %20 in the number phone (If have anyone)
        const sanitizedPhoneNumber = fullPhoneNumber.replace(/\s/g, '%20');

        // Build the ULR whatsapp
        return `https://wa.me/${sanitizedPhoneNumber}`;
    }



    return (
        <TableContainer>
            <Box>
                <UserHeaderButtons
                    openModalCreate={openModalCreate}
                    userAuth={userAuth}
                    opciones={opciones}
                    opcionSeleccionada={opcionSeleccionada}
                    handleCopyClick={handleCopyClick}
                    handleDownloadExcel={exportUsers}
                    handleSelectChange={handleSelectChange}
                />
                <UserSearch
                    customStyles={customStyles}
                    usersClients={usersClients}
                    clientSearch={clientSearch}
                    optionsUsers={optionsUsers}
                    userTypeSearch={userTypeSearch}
                    handleSelectChangeF={handleSelectChangeF}
                    setCurrentPage={setCurrentPage}
                    filterOptions={filterOptions}
                    resetKey={resetKey}
                    optionsUserType={optionsUserType}
                    optionClients={optionClients}
                    handleClientChange={handleClientChange}
                    statusSearch={statusSearch}
                    setStatusSearch={setStatusSearch}
                    locationSearch={locationSearch}
                    setLocationSearch={setLocationSearch}
                    clearFilters={clearFilters}
                    location={location}
                    activeSearch={activeSearch}
                    setActiveSearch={setActiveSearch}
                    userAuth={userAuth}
                />
            </Box>
            <UserList
                location={location}
                userAuth={userAuth}
                customStyles={customStyles}
                users={users}
                getWhatsAppLink={getWhatsAppLink}
                getRowBackground={getRowBackground}
                getColorSchemeForLocation={getColorSchemeForLocation}
                timezone={timezone}
                setFlashing={setFlashing}
                handleInfo={handleInfo}
                handleUpdate={handleUpdateNew}
                handleUpdateAD={handleUpdateAD}
                handleUpdateClient={handleUpdateClient}
                handleUpdateTU={handleUpdateTU}
                handleCreateSchedule={handleCreateSchedule}
                handleReset={handleReset}
                handleDelete={handleDelete}
                handleChangePassword={handleChangePassword}
                loading={loading}
                total={total}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPages={totalPages}
                handleRestore={handleRestore}
            />
            <UserExport
                usersE={usersE}
                getRowBackground={getRowBackground}
                getColorSchemeForLocation={getColorSchemeForLocation}
            />
            {isInfoModalOpen && renderModal()}
            {updateModalOpen && renderUpdateModal()}
            {clientModalOpen && (
                <ModalClient isOpen={clientModalOpen} onClose={() => setClientModalOpen(false)} user={selectedUser} onUserUpdate={fetchData} clients={client} setFlashing={setFlashing} userType={userTypeS} />
            )}
            {clientModalOpenCut && (
                <ModalCUT isOpen={clientModalOpenCut} onClose={() => setClientModalOpenCut(false)} user={selectedUser} onUserUpdate={fetchData} timezone={timezone} setFlashing={setFlashing} location={locationS} />
            )}
            {ModalAnydeskIsOpen && (
                <UpdateAnydesk isOpen={ModalAnydeskIsOpen} onClose={closeModalUpdate} user={selectedUser} onAnydeskUpdated={fetchData} setFlashing={setFlashing} closeMessages={null} />)}
            {schedulesModalOpen && (
                <ModalCreateSchedule isOpen={schedulesModalOpen} onClose={() => setSchedulesModalOpen(false)} user={selectedUser} days={days} daysOff={daysOff} />
            )}
            <CreateUser isOpen={modalCreateIsOpen} onClose={closeModalCreate} onUserCreated={fetchData} userType={userTypeS} clients={clientS} />
            {ModalChangePassword && (
                <ChangePassword isOpen={ModalChangePassword} onClose={closeModalChangePassword} user={selectedUser} userData={fetchData} setFlashing={setFlashing} />
            )}
        </TableContainer>
    );
}

export default UserFunctions;
