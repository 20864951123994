import React from 'react';
import {
  Table, Thead, Tbody, Tr, Th, Td,
  Button, ButtonGroup,
  Text, Flex, Box, Badge,
  Menu, MenuItem, MenuList, MenuButton
} from '@chakra-ui/react'
import { SettingsIcon } from '@chakra-ui/icons'
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import { TbPhotoCheck } from "react-icons/tb";
import { FaUser } from "react-icons/fa6";


function PicturesPanelList({ total, currentPage, profilePictures, setCurrentPage, totalPages, handleValidation }) {

  const getColorSchemeForLocation = (locationId) => {
    const colorMap = {
      1: '#b9ce67',
      2: '#fca066',
      3: '#fca066',
      4: '#fc7466',
      5: '#fcd677',
      6: '#8a6dce',
    };

    return colorMap[locationId] || '#b9ce67';
  };

  return (
    <>
      <Flex
        direction='column'
        w='100%'
      >
        <Table size={'sm'} mt={"2%"}>
          <Thead bg={"white"}>
            <Tr>
              <Th textAlign={'center'}>ID</Th>
              <Th textAlign={'center'}>Name</Th>
              <Th textAlign={'center'}>Position</Th>
              <Th textAlign={'center'}>Location</Th>
              <Th textAlign={'center'}>Status</Th>
              <Th textAlign={'center'}>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {profilePictures && profilePictures.length > 0 ? (
              profilePictures.map((profilePictures, index) => (
                <Tr key={profilePictures.id} className={index % 2 === 0 ? 'white-row' : 'gray-row'}>

                  <Td color='#808080' textAlign={'center'}>{profilePictures.profile.mat_dp}</Td>
                  <Td color='#1c2147' fontWeight='bold' textAlign={'center'}>
                    {profilePictures.name} {profilePictures.last_name}
                  </Td>
                  <Td textAlign={'center'}>
                    <Badge
                      backgroundColor={'#7080fc'}
                      color='white'
                      width={'180px'}
                      fontSize={'10px'}
                      borderRadius={'5px'}
                    >
                      {profilePictures.user_type.name}
                    </Badge>
                  </Td>
                  <Td textAlign={'center'}>
                    <Flex display={"flex"} justifyContent={'space-evenly'}>
                      <Badge bg={profilePictures.status_employee_id === 1 ? '#ff9fb8' : '#8a6dce'} width={"88px"} color={"white"} borderRadius={'5px'} >
                        {profilePictures.status ? profilePictures.status.name : 'No Status'}
                      </Badge>
                      <Text>
                        <Badge bg={getColorSchemeForLocation(profilePictures.location_id)} width={"50px"} color={"white"} borderRadius={'5px'}>
                          {(() => {
                            switch (profilePictures.location_id) {
                              case 1:
                                return "MX";
                              case 2:
                                return "BZ";
                              case 3:
                                return "BZ";
                              case 4:
                                return "RD";
                              case 5:
                                return "COL";
                              case 6:
                                return "GTM";
                              default:
                                return "MX";
                            }
                          })()}
                        </Badge>
                      </Text>
                    </Flex>
                  </Td>
                  <Td textAlign={'center'}>
                    <Badge
                      backgroundColor={'#fabb1c'}
                      color='white'
                      width={'180px'}
                      fontSize={'10px'}
                      borderRadius={'5px'}
                    >
                      {profilePictures.path_confirm === 1 ? 'Pending' : '---'}
                    </Badge>
                  </Td>
                  <Td textAlign={'center'}>
                    <Menu>
                      <MenuButton borderRadius={"5px"} as={Button} _active={{ background: "#808080", color: 'white' }} bg="#1c2147" color={"white"} _hover={{ background: "#808080", color: 'white' }}>
                        <SettingsIcon pb={"2px"} />
                      </MenuButton>
                      <MenuList>
                        <MenuItem isDisabled>
                          <div style={{ fontWeight: 'bold', textAlign: 'center', width: "100%" }}>Actions</div>
                        </MenuItem>
                        <MenuItem
                          display={"flex"}
                          justifyContent={"center"}
                          flexDirection={"column"}
                          alignItems="center">
                          <Button
                            borderRadius={"5px"}
                            bg={"#f2f2f2"}
                            color={"#1c2147"}
                            _hover={{ background: "#7080fc", color: 'white' }}
                            width={"100%"}
                            justifyContent="space-between"
                            onClick={() => handleValidation(profilePictures)}
                            alignItems="center">
                            <Text flex={1} textAlign="center">
                              Validate picture
                            </Text>
                            <TbPhotoCheck ml={2} verticalAlign="middle" />
                          </Button>
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Td>
                </Tr>
              ))
            ) : (
              <Tr>
                <Th colSpan={'12'} >
                  <Text
                    color='#1c2147'
                    fontSize={"25px"}
                    textAlign={"center"}
                    paddingTop={"15px"}
                    paddingBottom={"15px"}
                    bg={"#f2f2f2"}
                    textTransform={'none'}
                    fontFamily={'DM Sans'}
                    letterSpacing="-1px"
                  >
                    No data
                  </Text>
                </Th>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Flex >
      <Box width={"100%"} display={"flex"} mt={"30px"}>
        <Flex display={'flex'} justifyContent={'flex-start'} width={"max-content"} pb={"10px"} mt={"8px"}  >
          {total === 0 ? (
            <>
              <Flex bg={'#f2f2f2'} width={'250px'} display={'flex'} justifyContent={'center'}>
                <Box mt={'5px'} mr={2}>
                  <FaUser color={"#1c2147"} fontWeight={'bold'} />
                </Box>
                <Text fontSize={'14px'} mt={1} color={"#1c2147"} fontWeight={'bold'}>
                  No users to be validated
                </Text>


              </Flex>
            </>
          ) : (
            <>
              <Flex bg={'#f2f2f2'} width={'auto'} display={'flex'} justifyContent={'center'}>
                <Text fontSize={'14px'} mt={1} mr={3} color={"#1c2147"} fontWeight={'bold'} ml={'20px'}>
                  {total}
                </Text>
                <Box mt={'5px'} mr={1}>
                  <FaUser color={"#1c2147"} fontWeight={'bold'} />
                </Box>
                <Text fontSize={'14px'} mt={1} color={"#1c2147"} fontWeight={'bold'} mr={3}>
                  {total === 1 ? 'User to be validated' : 'Users to be validated'}
                </Text>
              </Flex>
            </>
          )}
        </Flex>
        <ButtonGroup isAttached variant="outline" ml={"26%"} justifyContent={'center'} display={'flex'} width={"max-content"} >
          <Button
            borderRadius={'0px'}
            onClick={() => setCurrentPage(currentPage - 1)}
            isDisabled={currentPage === 1}
            bg="#808080" color={"white"}
            _hover={{ background: "#808080", }}
            _active={{ background: "#808080", }}
          ><BsArrowLeftShort fontSize="20px" />
          </Button>
          <Text class="btnTextPages">{currentPage} of {totalPages}</Text>
          <Button
            borderRadius={'0px'} ml={4}
            onClick={() => setCurrentPage(currentPage + 1)}
            isDisabled={currentPage === totalPages}
            bg="#808080" color={"white"}
            _hover={{ background: "#808080", }}
            _active={{ background: "#808080", }}
          ><BsArrowRightShort fontSize="20px" />
          </Button>
        </ButtonGroup>
      </Box>
    </>

  );
}
export default PicturesPanelList; 