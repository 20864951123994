import axios from 'axios';
import React, { useEffect, useState } from "react";
import Swal from 'sweetalert2'
import {
    Modal, ModalOverlay, ModalContent, ModalFooter, ModalBody, ModalCloseButton,
    Box, Flex, Text, Button,
    Textarea, useToast, FormControl, FormErrorMessage, VStack
} from '@chakra-ui/react';

import bgMundochat from "assets/img/dashboards/Mundo_chat_2.png";
import { FaRegTrashAlt } from "react-icons/fa";


function ModalNotesPermissions({ isOpen, onClose, permission, userAuth }) {

    const toast = useToast();
    const [formErrors, setFormErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [buttonText, setButtonText] = useState("Add");
    const [notes, setNotes] = useState([]);
    const [formData, setFormData] = useState({
        description: '',
    });

    const clearForm = () => {
        setFormData({
            description: '',
        });
    };

    const fetchData = async (medicalNotes) => {
        try {
            const response = await axios.get(`/notesPermissions/${permission.id}`);
            setNotes(response.data);
        } catch (error) {
            console.error('Error fetching Notes:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const deleteNote = async (note) => {
        const confirmDelete = await Swal.fire({
            title: 'Do you want to delete this note?',
            text: 'The note will be deleted.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#b9ce67',
            cancelButtonColor: '#fc7466',
            confirmButtonText: "Delete",
            cancelButtonText: "Cancel",
            closeOnConfirm: true,
            closeOnCancel: false
        }).then((result) => {
            return result.isConfirmed
        });

        if (confirmDelete) {
            try {
                const response = await axios.delete(`/notesPermissions/delete/${note.id}`);
                toast({
                    title: " Note deleted",
                    status: "success",
                    position: 'top',
                    duration: 3000,
                    isClosable: true,
                });
                fetchData();
            } catch (error) {
                console.error(error);
                toast({
                    title: "Error deleting Note",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            }
        }
    };

    const handleSubmit = async () => {
        setIsSubmitting(true);
        setButtonText("Submitting...");

        const formSubmitData = {
            description: formData.description,
            permission_id: permission.id,
        };
        try {
            const response = await axios.post(`/notesPermissions/create`, formSubmitData);
            toast({
                title: 'Note created successfully',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            clearForm();
            fetchData();
            setFormErrors({});
            setButtonText("Add");
        } catch (error) {
            toast({
                title: "Error Creating a note",
                status: "error",
                duration: 1000,
                isClosable: true,
            });
            console.error('Error creating employee:', error);
            setFormErrors(error.response.data.errors);
            setButtonText("Add");
        } finally {
            setIsSubmitting(false);
        }
    };


    useEffect(() => {
        fetchData();
    }, []
    );


    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'full'} isCentered
            closeOnOverlayClick={false}
            closeOnEsc={false}>
            <ModalOverlay />
            <ModalContent borderRadius={'20px'} width={"75%"} bg={"#f2f2f2"} mt={"60px"} mb={"20px"}>

                <ModalCloseButton bg={"#cccccc"} _hover={{ background: '#fc7466' }} />
                <ModalBody display={"flex"} flexDirection={"row"}>
                    <Flex width={"100%"} justify={"space-evenly"} mt={"8%"}>

                        <Box borderRadius={"30px"} bg={"white"}
                            width={"600px"}
                            height={"800px"}
                            overflow='hidden'
                            px={{ base: "10px", md: "40px" }}
                            position='static'
                            style={{
                                overflowY: 'auto',
                                backgroundImage: `url(${bgMundochat})`,
                                backgroundSize: 'auto',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                            }}
                        >
                            <Text mt={'40px'} fontSize={"50px"} color={'#1c2147'} fontWeight={"bold"} textAlign={'left'}>
                                Notes
                            </Text>

                            <Box fontSize={"16px"} color={'#b3b3b3'}   >
                                <Flex style={{ display: 'flex' }} flexDirection={"column"} width={"max-content"} color={'#7080fc'} fontSize={'24px'}  >

                                </Flex>
                            </Box>

                            <Flex direction={'column'} height={"560px"} overflowY={"auto"}>
                                {notes && notes.length > 0 ? (
                                    notes.map((note, index) => (
                                        <Flex p={1}
                                            key={note.id}
                                            boxSizing='border-box'
                                            direction={'column'}
                                        >
                                            <VStack spacing={3}>
                                                <Flex flexDirection={"column"} width={"100%"}  >
                                                    <Box width={"100%"} mt={2} mb={4} height={"4px"} bg={"#e6e6e6"}></Box>
                                                    <Flex mt={'1%'} >
                                                        <Box textAlign={'center'} color={'#b3b3b3'} textColor={"#b3b3b3"} fontWeight='medium' bg={"white"} borderRadius={"10px"} ml={4} >
                                                            <Text
                                                                width={"380px"}
                                                                textAlign={"left"}
                                                                fontSize={'14px'}
                                                                p={2} textColor={"black"}
                                                                whiteSpace={'pre-wrap'}
                                                            >
                                                                {note.description}
                                                            </Text>
                                                        </Box>
                                                        {[1, 2, 3, 4, 5, 6, 7, 37].includes(userAuth.type_user_id) ? (<Button
                                                            ml={2}
                                                            onClick={() => deleteNote(note)}
                                                            bg={"transparent"}
                                                            _hover={{ background: "transparent" }}
                                                        >
                                                            <FaRegTrashAlt size={"22px"} />
                                                        </Button>
                                                        ) : null}
                                                    </Flex>
                                                    <Flex ml={12} mt={2} justifyContent={'left'}>
                                                        <Text color={'#808080'} fontSize={'11px'} textAlign={'center'} mr={"4px"}>
                                                            {note.user_id === 1 ? 'Eri System' : `${note.user.name} ${note.user.last_name}`}
                                                        </Text>
                                                        <Text color={'#808080'} fontSize={'11px'} textAlign={'center'} mr={"4px"}>
                                                            {note.created}
                                                        </Text>
                                                        <span style={{ color: '#808080', fontSize: '12px' }}></span>
                                                    </Flex>
                                                </Flex>


                                            </VStack>
                                            <span style={{ borderTop: '4px', borderColor: '#808080', width: '100%' }}></span>
                                        </Flex>
                                    ))
                                ) : (
                                    <Box>
                                        <Text mt={"50%"} bg={"#f2f2f2"} fontSize={"24px"} fontWeight={"bold"} textAlign={"center"}>
                                            No notes
                                        </Text>
                                    </Box>
                                )}
                            </Flex>
                        </Box>
                        <Box mr={"5px"} width={"550px"} height={"max-content"} p={10} borderRadius={"30px"} bg={"white"} >
                            <FormControl isInvalid={!!formErrors.description}>
                                <Textarea borderColor={"#b3b3b3"}
                                    placeholder="Add a description here..."
                                    mt={'40px'}
                                    name="description"
                                    minHeight={"200px"}
                                    maxHeight={"510px"}
                                    className='custom-placeholderInputs'
                                    background="white"
                                    value={formData.description}
                                    onChange={handleInputChange}
                                    errorBorderColor='#fc7466'
                                >
                                </Textarea>
                                {formErrors.description && (
                                    <FormErrorMessage>This field is required.</FormErrorMessage>
                                )}
                            </FormControl>
                            <Box mt={'10%'} display={'flex'} alignContent={"center"} justifyContent={"center"}>
                                <Button
                                    bg={'#5868e0'}
                                    _hover={{ background: "#8aad01", color: 'white' }}
                                    width={"120px"}
                                    color={'white'}
                                    mr={3}
                                    borderRadius='8px'
                                    fontWeight='bold'
                                    onClick={handleSubmit}
                                    isDisabled={isSubmitting}
                                >
                                    <Text mr={1}>
                                        {buttonText}
                                    </Text>
                                </Button>
                                <Button bg="#fc7466" color={'white'} _hover={{ background: "#808080", color: 'white' }} width={"120px"} mr={3} borderRadius='8px' fontWeight='bold' onClick={onClose}>

                                    <Text mr={1}>
                                        Exit notes
                                    </Text>
                                </Button>
                            </Box>
                        </Box>
                    </Flex>
                </ModalBody >
                <ModalFooter justifyContent="center" bg={"#f2f2f2"} borderRadius={"0 0 20px 20px"}>
                    <Flex bg={"#f2f2f2"}
                        backdropFilter='blur(20px)'
                        w={{ base: "calc(100% - 20px)", md: "calc(100% - 0px)" }}
                        justifyContent={'end'}
                    >
                    </Flex>
                </ModalFooter>
            </ModalContent >
        </Modal >
    );
}



export default ModalNotesPermissions;
