import React, { useState } from 'react';
import axios from 'axios';
import {
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody,
    Button, Input,
    Box, Flex, Heading, SimpleGrid,
    FormControl, FormErrorMessage,
    Image,Text,
    useToast,
} from '@chakra-ui/react';
import avatar4 from 'assets/img/dashboards/grey_world.png';
import { CloseIcon, DeleteIcon } from '@chakra-ui/icons'

const UpdateUserType = ({ isOpen, onClose, onUserTypeUpdated, usertype, setFlashing }) => {
    const toast = useToast();

    const [formErrors, setFormErrors] = useState({});

    const [formData, setFormData] = useState({
        name: usertype?.name || '',
    });

    const clearForm = () => {
        setFormData({
            name: ''
        });
        setFormErrors({});
    };

    const handleSubmit = async (id) => {
        try {
            const response = await axios.put(`/usertype/update/${usertype.id}`, formData);
            if (response.status === 200) {
                onUserTypeUpdated();
                setFlashing(formData);
                toast({
                    title: 'Type user Edit Success',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
                clearForm();
                onClose();
                setFormErrors({});
            }
        } catch (error) {
            toast({
                title: "Error Editing User Type",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            console.error('Error Editing User Type:', error);
            setFormErrors(error.response.data.errors);
        }
    };

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    return (
        <Modal size="lg" isOpen={isOpen} onClose={() => {
            onClose();
            clearForm();
        }}
            closeOnOverlayClick={false}
            closeOnEsc={false}
        >
            <ModalOverlay />
            <ModalContent class='modalContenEditUserType'>
            <ModalHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} color={'#1c2147'} mt={'3%'} mb={'5%'} fontWeight={'bold'} fontSize={'35px'}>
                    <Flex direction={'column'}>
                        <Text textAlign={'center'}>
                            Edit User Type
                        </Text>
                        <Flex style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} color={'#7080fc'} fontWeight={'bold'} fontSize={'22px'}>
                            <span style={{ marginLeft: '8px' }}>
                                <span style={{ marginRight: '8px', color: '#7080fc' }}>
                                    {usertype.name}
                                </span>
                            </span>
                        </Flex>
                    </Flex>
                </ModalHeader>
                <ModalBody>
                    <Box>
                        
                        <SimpleGrid
                            columns='1'
                            gap='40px'
                            maxW='100%'
                            w={{ base: "100%", md: "unset" }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <FormControl ml={10} isInvalid={!!formErrors.name}>
                                    <Input
                                        type="text"
                                        placeholder="Enter user type for edit"
                                        name='name'
                                        className='custom-placeholderInputs'
                                        background="white"
                                        value={formData.name}
                                        onChange={handleChange}
                                        errorBorderColor='#fc7466'
                                    />
                                    {formErrors.name && (
                                        <FormErrorMessage>This field is required.</FormErrorMessage>
                                    )}
                                </FormControl>

                                <Button
                                    padding={"0px"}
                                    borderRadius={"50px"}
                                    bg={"transparent"}
                                    _hover={{ bg: "transparent" }}
                                    onClick={clearForm}
                                    title="Delete All"
                                    ml={1}
                                >
                                    <DeleteIcon color={"#808080"} />
                                </Button>
                            </div>
                        </SimpleGrid>
                    </Box>
                </ModalBody>
                <ModalFooter justifyContent="center" pt={"10%"} pb={"10%"}>
                    <Button class="buttonCreate" onClick={handleSubmit}>
                        Update user type
                    </Button>
                    <Button class="buttonCancel" onClick={() => {
                        onClose();
                        clearForm();
                    }}>
                        Cancel <CloseIcon ml={2} />
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
export default UpdateUserType; 